import _ from 'lodash';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import {
  getFirestore,
  getDoc,
  getDocs,
  collection,
  doc,
} from 'firebase/firestore';
import axios from 'axios';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
} from 'redux-saga/effects';
import actions from './actions';
import profileActions from '../profile/actions';
import authActions from '../auth/actions';
import notification from '../../components/notification';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

const getProfileFromStore = (state) => state.Profile.profileOnDB;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getCustomUserUidFromStore = (state) => state.CustomUsers.customUserUid;

const getCreatedCustomUserProfileFromStore = (state) => state.CustomUsers.createdCustomUserProfile;

function createCustomUserOnDB(user, addressUid, profile, idToken) {
  const auth = getAuth();
  const { currentUser } = auth;
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid: currentUser.uid,
    professional: profile,
    userData: user,
    addressUid,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/createCustomUser`,
    bodyParameters,
    config,
  );
}

export function* createCustomUserRequest() {
  yield takeLatest(actions.CREATE_CUSTOM_USER_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CUSTOM_USERS_FETCH_OR_UPDATING_WAITING });
      const addressUid = yield select(getSelectedAddressFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const idToken = yield call(getIdToken);
      const { status } = yield call(createCustomUserOnDB, action.payload, addressUid, profile, idToken);
      if (status === 201) {
        yield put({
          type: actions.CUSTOM_USERS_FETCH_REQUEST,
        });
      }
    } catch (error) {
      if (error?.response?.data?.code === 'auth/email-already-exists') {
        yield put({
          type: actions.CREATE_CUSTOM_USER_ERROR_WITH_MESSAGE,
          payload: 'E-mail já está em uso.',
        });
      } else {
        yield put({
          type: actions.CREATE_CUSTOM_USER_ERROR,
        });
      }
    }
  });
}

// function saveCustomUserOnDB(user, key) {
//   const fs = getFirestore();
//   const auth = getAuth();
//   const { currentUser } = auth;
//   const docRef = doc(
//     fs,
//     'professionals',
//     currentUser.uid,
//     'customUsers',
//     key,
//   );
//   const obj = { ...user };
//   delete obj.avatar;
//   delete obj.birthday;
//   delete obj.council;
//   delete obj.keywords;
//   delete obj.phones;
//   delete obj.plans;
//   delete obj.id;
//   delete obj.cities;
//   delete obj.cpf;
//   delete obj.visible;
//   return updateDoc(docRef, {
//     ...user,
//     avatar: deleteField(),
//     birthday: deleteField(),
//     council: deleteField(),
//     keywords: deleteField(),
//     phones: deleteField(),
//     plans: deleteField(),
//     id: deleteField(),
//     cities: deleteField(),
//     cpf: deleteField(),
//     visible: deleteField(),
//   });
// }

function saveCustomUserOnDB(user, key, idToken) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const obj = { ...user };
  delete obj.avatar;
  delete obj.birthday;
  delete obj.council;
  delete obj.keywords;
  delete obj.phones;
  delete obj.plans;
  delete obj.id;
  delete obj.cities;
  delete obj.cpf;
  delete obj.visible;
  const auth = getAuth();
  const { currentUser } = auth;
  const bodyParameters = {
    uid: key,
    userData: obj,
    mainUser: currentUser.uid,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/updateCustomUser`,
    bodyParameters,
    config,
  );
}

export function* saveCustomUserRequest() {
  yield takeLatest(actions.SAVE_CUSTOM_USER_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CUSTOM_USERS_FETCH_OR_UPDATING_WAITING });
      const { user, key } = action.payload;
      const idToken = yield call(getIdToken);
      yield call(saveCustomUserOnDB, user, key, idToken);
      yield put({
        type: actions.CUSTOM_USERS_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_CUSTOM_USER_ERROR,
      });
    }
  });
}

function getCustomUsersFromDB(mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  const colRef = collection(
    fs,
    'professionals',
    uid,
    'customUsers',
  );
  // return true;
  return getDocs(colRef);
  // return fs.collection('professionals').doc(uid).collection('customUsers')
  //   .get();
}

function getCustomHealthProfessionalFromFirestore(id) {
  const fs = getFirestore();
  const docRef = doc(
    fs,
    'professionals',
    id,
  );
  return getDoc(docRef);
  // return fs.collection('professionals').doc(id)
  //   .get();
}

export function* getCustomUsersRequest() {
  yield takeLatest(actions.CUSTOM_USERS_FETCH_REQUEST, function* () {
    try {
      yield put({ type: actions.FETCHING_CUSTOM_USERS });
      const mainUser = yield select(getMainUserFromStore);
      const snapshot = yield call(getCustomUsersFromDB, mainUser);
      const dataArr = [];
      const verifiedOnlyDataArr = [];
      const professionalsArr = [];
      const verifiedOnlyProfessionalsArr = [];
      if (!snapshot.empty) {
        // There is saved users
        let index = -1;
        let verifiedOnlyIndex = -1;
        snapshot.forEach((user) => {
          index += 1;
          const defaultPagesPermissions = [
            {
              key: 'agenda',
              label: 'Agenda',
              permission: true,
            },
            {
              key: 'notifications',
              label: 'Notificações',
              permission: true,
            },
            {
              key: 'rules',
              label: 'Horários',
              permission: true,
            },
            {
              key: 'contacts',
              label: 'Pacientes',
              permission: true,
            },
            {
              key: 'profile',
              label: 'Perfil',
              permission: false,
            },
            {
              key: 'reports',
              label: 'Relatórios',
              permission: false,
            },
            {
              key: 'procedures',
              label: 'Procedimentos',
              permission: false,
            },
            {
              key: 'exams',
              label: 'Exames',
              permission: false,
            },
            {
              key: 'financial',
              label: 'Financeiro',
              permission: false,
            },
            {
              key: 'inventory',
              label: 'Estoque',
              permission: false,
            },
            {
              key: 'settings',
              label: 'Configurações',
              permission: false,
            },
            {
              key: 'dashboard',
              label: 'Dashboard',
              permission: false,
            },
          ];
          const defaultSensitiveDataPermissions = [
            {
              key: 'records',
              label: 'Evoluções',
              permission: true,
            },
            {
              key: 'prescriptions',
              label: 'Prescrições',
              permission: true,
            },
            {
              key: 'certificates',
              label: 'Atestados',
              permission: true,
            },
            {
              key: 'exams',
              label: 'Exames',
              permission: true,
            },
            {
              key: 'financial',
              label: 'Financeiro',
              permission: true,
            },
          ];
          dataArr.push({
            ...user.data(),
            id: user.id,
            // pagesPermissions: user.data().pagesPermissions ? [...user.data().pagesPermissions] : [...defaultPagesPermissions],
            // sensitiveDataPermissions: user.data().sensitiveDataPermissions
            //   ? [...user.data().sensitiveDataPermissions]
            //   : [...defaultSensitiveDataPermissions],
            pagesPermissions: user.data().pagesPermissions
              ? _.merge(defaultPagesPermissions, user.data().pagesPermissions)
              : defaultPagesPermissions,
            sensitiveDataPermissions: user.data().sensitiveDataPermissions
              ? _.merge(defaultSensitiveDataPermissions, user.data().sensitiveDataPermissions)
              : defaultSensitiveDataPermissions,
          });
          if (user.data().healthProfessional) {
            professionalsArr.push({
              dataArrIndex: index,
              id: user.id,
            });
          }
          if (user.data().verified) {
            verifiedOnlyIndex += 1;
            verifiedOnlyDataArr.push({
              ...user.data(),
              id: user.id,
              // pagesPermissions: user.data().pagesPermissions ? [...user.data().pagesPermissions] : [...defaultPagesPermissions],
              // sensitiveDataPermissions: user.data().sensitiveDataPermissions
              //   ? [...user.data().sensitiveDataPermissions]
              //   : [...defaultSensitiveDataPermissions],
              pagesPermissions: user.data().pagesPermissions
                ? _.merge(defaultPagesPermissions, user.data().pagesPermissions)
                : defaultPagesPermissions,
              sensitiveDataPermissions: user.data().sensitiveDataPermissions
                ? _.merge(defaultSensitiveDataPermissions, user.data().sensitiveDataPermissions)
                : defaultSensitiveDataPermissions,
            });
            if (user.data().healthProfessional) {
              verifiedOnlyProfessionalsArr.push({
                dataArrIndex: verifiedOnlyIndex,
                id: user.id,
              });
            }
          }
        });
      } else {
        // Custom users is empty
      }
      if (professionalsArr.length > 0) {
        const professionalsDataArr = yield all(professionalsArr.map((el) => call(
          getCustomHealthProfessionalFromFirestore,
          el.id,
        )));
        professionalsDataArr.forEach((el, i) => {
          if (el.data()) {
            const { dataArrIndex } = professionalsArr[i];
            dataArr[dataArrIndex] = {
              ...dataArr[dataArrIndex],
              ...el.data(),
            };
          }
        });
      }
      if (verifiedOnlyProfessionalsArr.length > 0) {
        const professionalsDataArr = yield all(verifiedOnlyProfessionalsArr.map((el) => call(
          getCustomHealthProfessionalFromFirestore,
          el.id,
        )));
        professionalsDataArr.forEach((el, i) => {
          if (el.data()) {
            const { dataArrIndex } = verifiedOnlyProfessionalsArr[i];
            verifiedOnlyDataArr[dataArrIndex] = {
              ...verifiedOnlyDataArr[dataArrIndex],
              ...el.data(),
            };
          }
        });
      }
      yield put({
        type: actions.CUSTOM_USERS_FETCH_SUCCESS,
        payload: {
          allCustomUsers: dataArr,
          customUsers: verifiedOnlyDataArr,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CUSTOM_USERS_FETCH_ERROR,
      });
    }
  });
}

function removeCustomUserOnDB(userData, idToken) {
  const auth = getAuth();
  const { currentUser } = auth;
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid: currentUser.uid,
    userData,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/deleteCustomUser`,
    bodyParameters,
    config,
  );
}

export function* removeCustomUserRequest() {
  yield takeLatest(actions.REMOVE_CUSTOM_USER_REQUEST, function* (action) {
    try {
      yield put({ type: actions.REMOVING_CUSTOM_USERS });
      const idToken = yield call(getIdToken);
      yield call(
        removeCustomUserOnDB,
        action.payload,
        idToken,
      );
      // throw new Error('aaa');
      yield put({
        type: actions.CUSTOM_USERS_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      if (error?.response?.data?.code) {
        yield put({
          type: actions.REMOVE_CUSTOM_USER_ERROR_WITH_MESSAGE,
          payload: error?.response?.data?.code === 'auth/user-not-found' ? 'Usuário não foi encontrado.' : error,
        });
        yield call(sleep, 500);
        yield put({ type: actions.CUSTOM_USERS_FETCH_REQUEST });
      } else {
        yield put({ type: actions.REMOVE_CUSTOM_USER_ERROR });
      }
    }
  });
}

function emailLinkSigninRequest({ email, link }) {
  const auth = getAuth();
  if (isSignInWithEmailLink(auth, link)) {
    return signInWithEmailLink(auth, email, link);
  }
  return null;
}

export function* emailSigninRequest() {
  yield takeLatest(actions.EMAIL_SIGNIN_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CUSTOM_USERS_FETCH_OR_UPDATING_WAITING });
      const result = yield call(emailLinkSigninRequest, action.payload);
      if (result && result.user && result.user.uid) {
        // yield put({
        //   type: authActions.LOGIN_REQUEST_SUCCESS,
        //   payload: {
        //     token: result.user.uid,
        //     profile: result.user,
        //   },
        // });
        yield put({
          type: actions.EMAIL_SIGNIN_SUCCESS,
          payload: {
            uid: result.user.uid,
            profile: result.user,
          },
        });
      } else {
        throw new Error('Could not complete sign in request');
      }
    } catch (error) {
      console.warn(error);
      notification('error', 'Não foi possível validar o e-mail.');
      yield put({
        type: actions.EMAIL_SIGNIN_ERROR,
        payload: error,
      });
    }
  });
}

function updateUserPasswordRequest(uid, password, idToken) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    password,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/setCustomUserPassword`,
    bodyParameters,
    config,
  );
}

export function* completeEmailLinkSigninRequest() {
  yield takeLatest(actions.COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CUSTOM_USERS_FETCH_OR_UPDATING_WAITING });
      const idToken = yield call(getIdToken);
      const customUserUid = yield select(getCustomUserUidFromStore);
      const customUserProfile = yield select(getCreatedCustomUserProfileFromStore);
      const { status } = yield call(
        updateUserPasswordRequest,
        customUserUid,
        action.payload.password,
        idToken,
      );
      if (status === 201) {
        yield put({
          type: authActions.LOGIN_REQUEST_SUCCESS,
          payload: {
            token: customUserUid,
            profile: customUserProfile,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      // "error.response.status" to check error status code.
      let errorCounter = 0;
      if (action.payload.errorCounter) {
        errorCounter = action.payload.errorCounter + 1;
      } else {
        errorCounter += 1;
      }
      if (errorCounter <= 3) {
        // The request was aborted because there was no available instance.
        // Need to retry.
        yield call(sleep, 3000);
        yield put({
          type: actions.COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_REQUEST,
          payload: {
            ...action.payload,
            errorCounter,
          },
        });
      } else {
        // Unknown error.
        yield put({
          type: actions.COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_ERROR,
          payload: error,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCustomUsersRequest),
    fork(saveCustomUserRequest),
    fork(createCustomUserRequest),
    fork(removeCustomUserRequest),
    fork(emailSigninRequest),
    fork(completeEmailLinkSigninRequest),
  ]);
}

import actions from './actions';

const initState = {
  firstName: '',
  lastName: '',
  crm: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  remember: false,
  error: {},
  user: null,
  idToken: null,
  professional: null,
  unified: {},
  resetEmailResponse: null,
  changePasswordResponse: null,
  changePasswordModalVisible: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.REQUEST_RELOGIN:
      return {
        ...state,
        requestRelogin: true,
      };
    case actions.NAME_CHANGED:
      return { ...state, firstName: action.payload };
    case actions.LAST_NAME_CHANGED:
      return { ...state, lastName: action.payload };
    // case actions.CRM_CHANGED:
    //   return { ...state, crm: action.payload };
    case actions.EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case actions.PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case actions.PASSWORD_CONFIRMATION_CHANGED:
      return { ...state, passwordConfirmation: action.payload };
    case actions.REMEMBER_USER:
      return { ...state, remember: action.payload };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.payload.token,
        user: action.payload.profile,
        professional: action.payload.professional,
        unified: action.payload.unified,
        authInProgress: false,
        awaitingFacebookResponse: false,
        requestRelogin: false,
        customUser: action.payload.customUser,
        mainUser: action.payload.mainUser,
        healthProfessional: action.payload.healthProfessional,
        needChangeLeakedPassword: action.payload.needChangeLeakedPassword,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        idToken: false,
        user: false,
        professional: false,
        authInProgress: false,
        awaitingFacebookResponse: false,
      };
    case actions.SET_REQUEST_LOGIN_VALUE:
      return {
        ...state,
        requestRelogin: false,
      };
    case actions.AUTH_IN_PROGRESS:
      return {
        ...state,
        authInProgress: true,
      };
    case actions.AWAITING_FB_LOGIN_RESPONSE:
      return {
        ...state,
        awaitingFacebookResponse: true,
      };
    case actions.LOGOUT:
      return {
        ...state,
        firstName: '',
        lastName: '',
        crm: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        remember: false,
        error: {},
        idToken: false,
        user: false,
        professional: false,
        unified: {},
        customUser: null,
        mainUser: null,
      };
    case actions.RESET_PASSWORD_REQUEST_WAITING:
      return {
        ...state,
        resetEmailResponse: 'waiting',
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetEmailResponse: 'reset_success',
      };
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetEmailResponse: action.payload,
      };
    case actions.CHANGE_PASSWORD_REQUEST_WAITING:
      return {
        ...state,
        changePasswordResponse: 'waiting',
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordResponse: 'change_success',
      };
    case actions.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordResponse: action.payload,
      };
    case actions.CHECK_USER_DATA_EXISTS_SUCCESS:
      return {
        ...state,
        userExists: action.payload,
      };
    case actions.CHECK_USER_DATA_EXISTS_ERROR:
      return {
        ...state,
        userExists: null,
      };
    case actions.TOGGLE_CHANGE_PASSWORD_MODAL_VISIBLE:
      return {
        ...state,
        changePasswordModalVisible: !state.changePasswordModalVisible,
      };
    case actions.SET_CHANGE_LEAKED_PASSWORD:
      return {
        ...state,
        needChangeLeakedPassword: false,
      };
    default:
      return state;
  }
}

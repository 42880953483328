import actions from './actions';
import authActions from '../auth/actions';
import profileActions from '../profile/actions';

const initState = {
  // updatingMedicineModel: false,
  // removingMedicineModel: false,
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case profileActions.PROFILE_INFO_SUCCESS:
      return {
        ...state,
        memedUserToken: undefined,
      };
    case actions.FETCH_MEMED_SETTINGS_SUCCESS:
      return {
        ...state,
        memedSettings: action.payload,
        updatingUseMemed: false,
      };
    case actions.UPDATING_USE_MEMED:
      return {
        ...state,
        updatingUseMemed: true,
      };
    case actions.SET_MEMED_USER_TOKEN:
      return {
        ...state,
        memedUserToken: action.payload.token,
        memedUserData: action.payload.user,
      };
    case actions.ERROR_UPDATING_MEMED_USER:
      return {
        ...state,
        memedUserError: true,
      };
    case actions.MEMED_INITIALIZED:
      return {
        ...state,
        memedInitialized: action.payload,
      };
    case actions.SET_OPEN_MEMED:
      return {
        ...state,
        openMemed: action.payload,
      };
    case actions.PRINT_MEMED_PRESCRIPTION:
      return {
        ...state,
        printMemedWithId: action.payload,
      };
    case actions.DOWNLOADING_MEMED_PRESCRIPTION_PDF:
      return {
        ...state,
        downloadingMemedPdf: true,
      };
    case actions.GET_MEMED_PRESCRIPTION_PDF_SUCCESS:
      return {
        ...state,
        memedPdfArray: action.payload,
        downloadingMemedPdf: false,
      };
    case actions.GET_MEMED_PRESCRIPTION_PDF_ERROR:
      return {
        ...state,
        downloadingMemedPdf: null,
      };
    case actions.CLEAR_MEMED_USER_ERROR:
      return {
        ...state,
        memedUserError: false,
      };
    case actions.SELECT_THIRD_PARTY_MEMED_USER:
      return {
        ...state,
        selectedMemedUser: action.payload.id,
      };
    default:
      return state;
  }
}

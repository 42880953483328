export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  CANCEL_LISTENERS: 'CANCEL_LISTENERS',
  CANCEL_LISTENERS_SUCCESS: 'CANCEL_LISTENERS_SUCCESS',
  SET_CANCEL_LISTENERS: 'SET_CANCEL_LISTENERS',
  COLLAPSE_CHANGE: 'COLLAPSE_CHANGE',
  PERMANENT_COLLAPSE_CHANGE: 'PERMANENT_COLLAPSE_CHANGE',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLOSE_ALL: 'CLOSE_ALL',
  SELECT_ADDRESS: 'SELECT_ADDRESS',
  CHECK_APP_VERSION: 'CHECK_APP_VERSION',
  NEED_APP_RELOAD: 'NEED_APP_RELOAD',
  CHANGE_GENERAL_SETTINGS_KEY: 'CHANGE_GENERAL_SETTINGS_KEY',
  PAGE_WITH_ABSOLUTE_FOOTER_MOUNTED: 'PAGE_WITH_ABSOLUTE_FOOTER_MOUNTED',
  PAGE_WITH_ABSOLUTE_FOOTER_UNMOUNTED: 'PAGE_WITH_ABSOLUTE_FOOTER_UNMOUNTED',
  // CHECK_SERVICE_CONTRACT: 'CHECK_SERVICE_CONTRACT',
  FETCH_SERVICE_CONTRACT_SUCCESS: 'FETCH_SERVICE_CONTRACT_SUCCESS',
  FETCH_SERVICE_CONTRACT_ERROR: 'FETCH_SERVICE_CONTRACT_ERROR',
  ACCEPT_SERVICE_CONTRACT_REQUEST: 'ACCEPT_SERVICE_CONTRACT_REQUEST',
  ACCEPT_SERVICE_CONTRACT_SUCCESS: 'ACCEPT_SERVICE_CONTRACT_SUCCESS',
  ACCEPT_SERVICE_CONTRACT_ERROR: 'ACCEPT_SERVICE_CONTRACT_ERROR',
  SAVING_SERVICE_CONTRACT: 'SAVING_SERVICE_CONTRACT',
  FETCH_SYSTEM_UPDATES_CHANGELOG: 'FETCH_SYSTEM_UPDATES_CHANGELOG',
  FETCH_SYSTEM_UPDATES_CHANGELOG_SUCCESS: 'FETCH_SYSTEM_UPDATES_CHANGELOG_SUCCESS',
  FETCH_COMPLETE_SYSTEM_UPDATES_CHANGELOG_SUCCESS: 'FETCH_COMPLETE_SYSTEM_UPDATES_CHANGELOG_SUCCESS',
  FETCH_SYSTEM_ALERT_SUCCESS: 'FETCH_SYSTEM_ALERT_SUCCESS',
  UPDATE_SYSTEM_ALERT: 'UPDATE_SYSTEM_ALERT',
  UPDATE_SYSTEM_ALERT_SUCCESS: 'UPDATE_SYSTEM_ALERT_SUCCESS',
  UPDATE_SYSTEM_ALERT_ERROR: 'UPDATE_SYSTEM_ALERT_ERROR',
  SCROOL_WRAPPER_TO_BOTTOM: 'SCROOL_WRAPPER_TO_BOTTOM',
  CHANGE_SAVED_ALL_CHANGES: 'CHANGE_SAVED_ALL_CHANGES',
  DISCARD_SAVED_ALL_CHANGES: 'DISCARD_SAVED_ALL_CHANGES',
  CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD: 'CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD',
  CHECK_MAINTENANCE: 'CHECK_MAINTENANCE',
  SET_MAINTENANCE: 'SET_MAINTENANCE',
  CHECK_SHOW_POPOVER_COMPONENT_TO_PRINT: 'CHECK_SHOW_POPOVER_COMPONENT_TO_PRINT',
  SET_SHOW_POPOVER_COMPONENT_TO_PRINT: 'SET_SHOW_POPOVER_COMPONENT_TO_PRINT',
  SAVE_ON_CACHE_SHOW_POPOVER_COMPONENT_TO_PRINT: 'SAVE_ON_CACHE_SHOW_POPOVER_COMPONENT_TO_PRINT',
  SET_PREVENT_CLOSE_MODAL_VISIBLE: 'SET_PREVENT_CLOSE_MODAL_VISIBLE',
  CHANGE_FLOAT_BUTTON_VALUE: 'CHANGE_FLOAT_BUTTON_VALUE',
  cancelListeners: () => ({
    type: actions.CANCEL_LISTENERS,
  }),
  setRemoveListener: (value) => ({
    type: actions.SET_CANCEL_LISTENERS,
    payload: value,
  }),
  toggleCollapsed: () => ({
    type: actions.COLLAPSE_CHANGE,
  }),
  toggleCollapsedPermanent: (forceOpenSidebar = false) => ({
    type: actions.PERMANENT_COLLAPSE_CHANGE,
    payload: forceOpenSidebar,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  changeOpenKeys: (openKeys) => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  closeAll: () => ({ type: actions.CLOSE_ALL }),
  selectAddressGlobally: (address) => ({
    type: actions.SELECT_ADDRESS,
    payload: address,
  }),
  checkAppVersion: () => ({
    type: actions.CHECK_APP_VERSION,
  }),
  changeGeneralSettingsKey: (key = '') => ({
    type: actions.CHANGE_GENERAL_SETTINGS_KEY,
    payload: key,
  }),
  pageMounted: (path) => ({
    type: actions.PAGE_WITH_ABSOLUTE_FOOTER_MOUNTED,
    payload: path,
  }),
  pageUnmounted: (path) => ({
    type: actions.PAGE_WITH_ABSOLUTE_FOOTER_UNMOUNTED,
    payload: path,
  }),
  // checkServiceContract: () => ({
  //   type: actions.CHECK_SERVICE_CONTRACT,
  // }),
  acceptServiceContract: () => ({
    type: actions.ACCEPT_SERVICE_CONTRACT_REQUEST,
  }),
  getSystemUpdatesChangelog: (completeChangelog) => ({
    type: actions.FETCH_SYSTEM_UPDATES_CHANGELOG,
    payload: {
      completeChangelog,
    },
  }),
  scrollWrapperToBottom: () => ({
    type: actions.SCROOL_WRAPPER_TO_BOTTOM,
  }),
  changeSavedAllChanges: (id, obj) => ({
    type: actions.CHANGE_SAVED_ALL_CHANGES,
    payload: {
      id,
      obj,
    },
  }),
  changeSpecificSavedAllChanges: (id, field, fieldValue) => ({
    type: actions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
    payload: {
      id,
      field,
      fieldValue,
    },
  }),
  saveOnCacheAlreadyShownPopoverComponentToPrint: () => ({
    type: actions.SAVE_ON_CACHE_SHOW_POPOVER_COMPONENT_TO_PRINT,
  }),
  setPreventCloseModalVisible: (value) => ({
    type: actions.SET_PREVENT_CLOSE_MODAL_VISIBLE,
    payload: {
      value,
    },
  }),
  updateSystemAlerts: (alert) => ({
    type: actions.UPDATE_SYSTEM_ALERT,
    payload: alert,
  }),
  changeFloatButtonValues: (value) => ({
    type: actions.CHANGE_FLOAT_BUTTON_VALUE,
    payload: value,
  }),
};
export default actions;

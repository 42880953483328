import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  query,
  onValue,
  orderByKey,
  orderByChild,
  startAt,
  endAt,
  limitToLast,
  get,
  child,
  push,
} from 'firebase/database';
import moment from 'moment-timezone';
import {
  all,
  takeEvery,
  takeLatest,
  fork,
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import {
  rrulestr,
} from 'rrule';
import analyticsActions from './actions';
import agendaActions from '../agenda/actions';

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

const getFullBatchedAppointmentsArrFromStore = (state) => state.Agenda.fullBatchedAppointmentsArr;

const getPersistedBatchedAppointmentsFromStore = (state) => state.Agenda.persistedBatchedAppointments;

// function retrieveAnalyticsData() {
//   return axios.get(`${ROOT_URL}/googleBigQuery`);
// }

// export function* requestsFetch() {
//   yield takeEvery(analyticsActions.GOOGLE_ANALYTICS_REQUEST, function* () {
//     try {
//       const data = yield call(retrieveAnalyticsData);
//       yield put({
//         type: analyticsActions.GOOGLE_ANALYTICS_SUCCESS,
//         payload: data.data,
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

// function retrievePatientData() {
//   return axios.get(`${ROOT_URL}/getPatientsProfile`);
// }

// export function* fetchUserDataRequest() {
//   yield takeEvery(analyticsActions.FETCH_USER_DATA_REQUEST, function* () {
//     try {
//       const data = yield call(retrievePatientData);
//       yield put({
//         type: analyticsActions.FETCH_USER_DATA_SUCCESS,
//         payload: data.data,
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

function fetchReferalListFromDB(mainUser, unified, currentAddress) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  if (unified.id && unified.address.some((a) => a === currentAddress)) {
    return get(child(dbRef, `unified/${unified.id}/referralList`));
  }
  return get(child(dbRef, `users/${uid}/referralList`));
}

function fetchReferralListMonthlyFromDB(addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const start = moment().tz('America/Sao_Paulo').subtract(1, 'year').format('YYYY-MM');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(query(
      ref(db, `/unified/${unified.id}/referralListMonthly`),
      orderByKey(),
      startAt(start),
      limitToLast(12),
    ));
    // return new Promise((resolve) => {
    //   onValue(query(
    //     ref(db, `/unified/${unified.id}/referralListMonthly`),
    //     orderByKey(),
    //     startAt(start),
    //     limitToLast(12),
    //   ), resolve, { onlyOnce: true });
    // });
  }
  return get(query(
    ref(db, `/users/${uid}/referralListMonthly`),
    orderByKey(),
    startAt(start),
    limitToLast(12),
  ));
  // return new Promise((resolve) => {
  //   onValue(query(
  //     ref(db, `/users/${uid}/referralListMonthly`),
  //     orderByKey(),
  //     startAt(start),
  //     limitToLast(12),
  //   ), resolve, { onlyOnce: true });
  // });
}

export function* fetchReferalList() {
  yield takeEvery(analyticsActions.FETCH_REFERRAL_LIST_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const referralList = yield call(fetchReferalListFromDB, mainUser, unified, currentAddress);
      const referralListMonthly = yield call(fetchReferralListMonthlyFromDB, currentAddress, unified, mainUser);
      yield put({
        type: analyticsActions.FETCH_REFERRAL_LIST_SUCCESS,
        payload: {
          referralList: referralList.val(),
          referralListMonthly: referralListMonthly.val(),
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchRequestingDoctorListFromDB(mainUser, unified, currentAddress) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  if (unified.id && unified.address.some((a) => a === currentAddress)) {
    return get(child(dbRef, `unified/${unified.id}/requestingDoctorList`));
  }
  return get(child(dbRef, `users/${uid}/requestingDoctorList`));
}

function fetchRequestingDoctorListMonthlyFromDB(addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const start = moment().tz('America/Sao_Paulo').subtract(1, 'year').format('YYYY-MM');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(query(
      ref(db, `/unified/${unified.id}/requestingDoctorListMonthly`),
      orderByKey(),
      startAt(start),
      limitToLast(12),
    ));
  }
  return get(query(
    ref(db, `/users/${uid}/requestingDoctorListMonthly`),
    orderByKey(),
    startAt(start),
    limitToLast(12),
  ));
}

export function* fetchRequestingDoctorList() {
  yield takeEvery(analyticsActions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const requestingDoctorList = yield call(fetchRequestingDoctorListFromDB, mainUser, unified, currentAddress);
      const requestingDoctorListMonthly = yield call(fetchRequestingDoctorListMonthlyFromDB, currentAddress, unified, mainUser);
      yield put({
        type: analyticsActions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS,
        payload: {
          requestingDoctorList: requestingDoctorList.val(),
          requestingDoctorListMonthly: requestingDoctorListMonthly.val(),
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchAppointmentsFromDB(agendaId, start, end, mode, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  // const start = moment().tz('America/Sao_Paulo').subtract(1, 'months').format('YYYY-MM-DD');
  return new Promise((resolve) => {
    onValue(query(
      ref(db, `/requests/${uid}/${agendaId}/${mode}`),
      orderByChild('time'),
      startAt(start),
      endAt(`${end}\uf8ff`),
    ), resolve, { onlyOnce: true });
  });
}

export function* getRangeAppointments() {
  yield takeLatest(analyticsActions.GET_RANGE_APPOINTMENTS_REQUEST, function* (action) {
    try {
      yield put({ type: analyticsActions.FETCHING_APPOINTMENTS });
      const mainUser = yield select(getMainUserFromStore);
      let agendaId = yield select(getSelectedAgendaFromStore);
      if (_.isEmpty(agendaId)) {
        yield take(agendaActions.SELECT_AGENDA);
        agendaId = yield select(getSelectedAgendaFromStore);
      }
      let fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      if (_.isUndefined(fullBatchedAppointmentsArr)) {
        yield take(agendaActions.SET_FULL_BATCHED_APPOINTMENTS_ARR);
        fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      }
      let persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      if (_.isUndefined(persistedBatchedAppointments)) {
        yield take(agendaActions.SET_PERSISTED_BATCHED_APPOINTMENTS);
        persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      }
      const { start, end } = action.payload;
      const [
        confirmed,
        canceled,
        pending,
        rejected,
        accepted,
      ] = yield all([
        call(fetchAppointmentsFromDB, agendaId, start, end, 'confirmed', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'canceled', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'pending', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'rejected', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'accepted', mainUser),
      ]);
      let analyticsConfirmed = [];
      let analyticsCanceled = [];
      let analyticsPending = [];
      let analyticsRejected = [];
      let analyticsAccepted = [];
      if (confirmed.val()) {
        analyticsConfirmed = _.map(confirmed.val(), (val, id) => {
          const { duration } = val;
          return {
            ...val,
            start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
            id,
            end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
            allDay: false,
            blockAll: val.allDay ? val.allDay : false,
            type: 'confirmed',
          };
        });
      }
      const db = getDatabase();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const batchedDatesEvents = [];
      fullBatchedAppointmentsArr.forEach((el) => {
        const rule = rrulestr(el.rrule);
        const ruleDtstart = moment(rule.origOptions.dtstart).format('YYYY-MM-DD');
        const batchedDates = rule.between(moment(start, 'YYYY-MM-DD').subtract(1, 'day').toDate(), moment(end, 'YYYY-MM-DD').add(1, 'day').endOf('day').toDate(), true);
        const duration = moment.duration(el.appointmentModel.duration).asMinutes() || 15;
        const timeStr = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').format('HH:mm');
        batchedDates.forEach((date) => {
          const dateStr = moment(date).utcOffset(0).format('YYYY-MM-DD');
          if (dateStr >= ruleDtstart) {
            let alreadyPersisted = false;
            if (persistedBatchedAppointments) {
              alreadyPersisted = Object.values(persistedBatchedAppointments).some((obj) => {
                const splittedTime = obj.time.split(' ')[0];
                if (splittedTime === dateStr && el.batchedId === obj.batchedId) {
                  return true;
                }
                return false;
              });
            }
            if (!alreadyPersisted) {
              const startTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').toDate();
              const endTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').add(duration, 'm').toDate();
              const pushKey = push(child(ref(db), `/requests/${uid}/${agendaId}/confirmed`)).key;
              if (el.blocked) {
                batchedDatesEvents.push({
                  ...el.appointmentModel,
                  batchedId: el.batchedId,
                  id: pushKey,
                  time: `${dateStr} ${timeStr}`,
                  start: startTime,
                  end: endTime,
                  type: 'confirmed',
                });
              } else {
                batchedDatesEvents.push({
                  ...el.appointmentModel,
                  batchedId: el.batchedId,
                  id: pushKey,
                  time: `${dateStr} ${timeStr}`,
                  start: startTime,
                  end: endTime,
                  allDay: false,
                  blockAll: false,
                  type: 'confirmed',
                });
              }
            }
          }
        });
      });
      analyticsConfirmed = [...analyticsConfirmed, ...batchedDatesEvents];
      if (canceled.val()) {
        analyticsCanceled = _.map(canceled.val(), (val, id) => {
          const { duration } = val;
          return {
            ...val,
            start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
            id,
            end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
            allDay: false,
            blockAll: val.allDay ? val.allDay : false,
            canceled: val.canceled ? val.canceled : true,
            type: 'canceled',
          };
        });
      }
      if (pending.val()) {
        analyticsPending = _.map(pending.val(), (val, id) => (
          {
            ...val,
            id,
            pending: true,
            type: 'pending',
          }
        ));
      }
      if (rejected.val()) {
        analyticsRejected = _.map(rejected.val(), (val, id) => (
          {
            ...val,
            id,
            pending: true,
            type: 'pending',
          }
        ));
      }
      if (accepted.val()) {
        analyticsAccepted = _.map(accepted.val(), (val, id) => (
          {
            ...val,
            id,
            pending: true,
            type: 'pending',
          }
        ));
      }
      yield put({
        type: analyticsActions.GET_RANGE_APPOINTMENTS_SUCCESS,
        payload: {
          appointments: {
            confirmed: analyticsConfirmed,
            canceled: analyticsCanceled,
            pending: analyticsPending,
            rejected: analyticsRejected,
            accepted: analyticsAccepted,
          },
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: analyticsActions.GET_RANGE_APPOINTMENTS_ERROR });
    }
  });
}

export default function* rootSaga() {
  // yield all([fork(requestsFetch)]);
  yield all([
    // fork(requestsFetch),
    fork(fetchReferalList),
    fork(fetchRequestingDoctorList),
    fork(getRangeAppointments),
  ]);
}

import React, { Component } from 'react';
import Tooltip from './uielements/tooltip';

export default class extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }

  handleVisibleChange() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    const { seectedColor, mode } = this.props;
    // const content = () => (
    //   <ColorChooserDropdown className="isoColorOptions">
    //     {Object.values(colors).map((color, index) => {
    //       const onClick = () => {
    //         this.hide();
    //         changeColor(index);
    //       };
    //       const style = {
    //         background: color,
    //       };
    //       return <Button key={index} onClick={onClick} style={style} />;
    //     })}
    //   </ColorChooserDropdown>
    // );
    const text = () => {
      if (mode === 'private') {
        return 'Consulta Particular';
      }
      return 'Consulta Conveniada';
    };
    return (
      // <Popover
      //   content={content()}
      //   // trigger="click"
      //   open={this.state.visible}
      //   onOpenChange={this.handleVisibleChange}
      // >
      //   <Button
      //     style={{ backgroundColor: seectedColor }}
      //     className="isoColorChooser"
      //   />
      // </Popover>
      <Tooltip title={text()}>
        <div className="isoColorChooser" style={{ backgroundColor: seectedColor, zIndex: 1 }} />
      </Tooltip>
    );
  }
}

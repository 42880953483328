import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  FacebookAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  reauthenticateWithCredential,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  updatePassword,
} from 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { eventChannel } from 'redux-saga';
import {
  all,
  takeEvery,
  put,
  call,
  take,
  select,
  fork,
} from 'redux-saga/effects';
import actions from './actions';
import appActions from '../app/actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getUserFromStore = (state) => state.Auth.user;

const getNeedChangeLeakedPassword = (state) => state.Auth.needChangeLeakedPassword;

// const getRemoveListenersFromStore = (state) => state.App.removeListeners;

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function getFacebookCredentials() {
  return new FacebookAuthProvider();
}

function signInWithRedirectFunction(provider) {
  const auth = getAuth();
  return signInWithRedirect(auth, provider);
}

function signInWithPopupFunction(provider) {
  const auth = getAuth();
  return signInWithPopup(auth, provider);
}

function reauthenticate(currentPassword) {
  const auth = getAuth();
  const user = auth.currentUser;
  const cred = EmailAuthProvider.credential(user.email, currentPassword);
  return reauthenticateWithCredential(user, cred);
}

// function getRedirectResult() {
//   return firebase.auth().getRedirectResult();
// }

function getAuthChannel() {
  const auth = getAuth();
  const authChannel = eventChannel((emit) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => emit({ user }));
    return unsubscribe;
  });
  return authChannel;
}

export function* loginRequestWithFB() {
  yield takeEvery(actions.LOGIN_REQUEST_WITH_FB, function* (action) {
    try {
      const popup = action.payload;
      const provider = yield call(getFacebookCredentials);
      yield localStorage.setItem('facebook_login', true);
      if (!popup) {
        yield call(signInWithRedirectFunction, provider);
      } else {
        yield call(signInWithPopupFunction, provider);
        yield put({
          type: actions.CHECK_AUTHORIZATION,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.LOGIN_ERROR,
        payload: error,
      });
    }

    // if (user) {
    //   channel.close();
    //   yield put({
    //     type: actions.LOGIN_REQUEST_WITH_FB_SUCCESS,
    //     payload: {
    //       token: action.payload,
    //       profile: response.user,
    //     },
    //   });
    // } else {
    //   yield put({
    //     type: actions.LOGIN_ERROR,
    //     payload: {},
    //   });
    // }
  });
}

const getUserInfoFromStore = (state) => ({
  firstName: state.Auth.firstName,
  lastName: state.Auth.lastName,
  // crm: state.Auth.crm,
  email: state.Auth.email,
  password: state.Auth.password,
});

const checkRememberUser = (state) => state.Auth.remember;

function signUpUser({ email, password }) {
  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, email, password);
}

function capitalize(str) {
  const splitStr = str.split(/[ ,]+/);
  let newStr = '';
  splitStr.forEach((word, index) => {
    if (index + 1 === splitStr.length) {
      newStr = `${newStr + word.charAt(0).toUpperCase() + word.slice(1)}`;
    } else {
      newStr = `${newStr + word.charAt(0).toUpperCase() + word.slice(1)} `;
    }
    // newStr = `${newStr + word.charAt(0).toUpperCase() + word.slice(1)} `;
  });
  return newStr;
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function saveUserInfoOnDB(firstName, lastName, email, uid, idToken, professional, avatar = null) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let url;
  if (professional) {
    url = 'createProfessional';
  } else {
    url = 'createPatient';
  }
  return axios.post(
    `${ROOT_URL}/${url}`,
    {
      firstName: capitalize(firstName.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '')),
      lastName: capitalize(lastName.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '')),
      email,
      avatar,
      uid,
    },
    config,
  );
}

export function* signUpRequest() {
  yield takeEvery(actions.SIGN_UP_REQUEST, function* (action) {
    try {
      yield put({ type: actions.AUTH_IN_PROGRESS });
      const state = yield select(getUserInfoFromStore);
      const { user } = yield call(signUpUser, state);
      const idToken = yield call(getIdToken);
      yield call(
        saveUserInfoOnDB,
        state.firstName,
        state.lastName,
        user.email,
        user.uid,
        idToken,
        action.payload,
      );
      yield put({
        type: actions.SIGN_UP_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.LOGIN_ERROR,
        payload: error,
      });
    }
  });
}

export function* signUpSuccess() {
  yield takeEvery(actions.SIGN_UP_SUCCESS, function* () {
    yield put({
      type: actions.LOGIN_REQUEST,
      payload: {
        professional: true,
      },
    });
  });
}

// function verifyUserIsPublic(uid) {
//   const db = firebase.database();
//   return db.ref(`/public/${uid}/personal`).once('value');
// }

// function FectchInfoFromDB(uid) {
//   const db = firebase.database();
//   return db.ref(`/users/${uid}/personal`).once('value');
// }

function signInUser({ email, password }, remember) {
  const auth = getAuth();
  if (!remember) {
    setPersistence(auth, browserSessionPersistence)
      .then(() => (
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        signInWithEmailAndPassword(auth, email, password)
      ))
      .catch((error) => {
        // Handle Errors here.
        console.warn(error);
      });
  }
  return signInWithEmailAndPassword(auth, email, password);
}

export function* signInRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* (action) {
    try {
      yield put({ type: actions.AUTH_IN_PROGRESS });
      const state = yield select(getUserInfoFromStore);
      const remember = yield select(checkRememberUser);
      let user = null;
      if (action.payload
        && action.payload.email
        && action.payload.password) {
        ({ user } = yield call(signInUser, action.payload, remember));
      } else {
        ({ user } = yield call(signInUser, state, remember));
      }
      if (user) {
        yield put({
          type: actions.LOGIN_REQUEST_SUCCESS,
          payload: {
            token: user.uid,
            profile: user,
            // professional: action.payload.professional,
          },
        });
      } else {
        throw new Error('Login failed.');
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.LOGIN_ERROR,
        payload: error,
      });
    }
  });
}

function accessTokenResult() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdTokenResult();
}

function forceRefreshAccessTokenResult() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdTokenResult(true);
}

export function* loginSuccess() {
  yield takeEvery([
    actions.LOGIN_REQUEST_SUCCESS,
    actions.LOGIN_REQUEST_WITH_FB_SUCCESS,
    actions.CHECK_AUTHORIZATION_SUCCESS,
    // actions.LOGIN_PATIENT_SUCCESS,
  ], function* (action) {
    const idTokenResult = yield call(accessTokenResult);
    const professional = idTokenResult.claims.professional
      ? idTokenResult.claims.professional
      : false;
    const unifiedId = idTokenResult.claims.unified
      ? idTokenResult.claims.unified
      : null;
    const unifiedAddress = idTokenResult.claims.unifiedAddresses
      ? Object.keys(idTokenResult.claims.unifiedAddresses)
      : [];
    const unified = {
      id: unifiedId,
      address: unifiedAddress,
    };
    const customUser = idTokenResult.claims.customUser
      ? idTokenResult.claims.customUser
      : null;
    const mainUser = idTokenResult.claims.mainUser
      ? idTokenResult.claims.mainUser
      : null;
    const healthProfessional = idTokenResult.claims.healthProfessional
      ? idTokenResult.claims.healthProfessional
      : null;
    const creationTime = moment(action.payload.profile.metadata.creationTime).format('YYYY-MM-DD');
    const needChangeLeakedPassword = !idTokenResult.claims.changedLeakedPassword && creationTime <= '2023-05-04' && !mainUser;
    // const healthProfessional = true;
    localStorage.removeItem('facebook_login');
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        ...action.payload,
        professional,
        unified,
        customUser,
        mainUser,
        healthProfessional,
        needChangeLeakedPassword,
      },
    });
    // yield localStorage.setItem('professional', action.payload.professional);
  });
}

export function* authStateListener() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* () {
    try {
      const channel = yield call(getAuthChannel);
      yield takeEvery(channel, function* ({ user }) {
        if (user) {
          const userFromStore = yield select(getUserFromStore);
          if (userFromStore.uid !== user.uid) {
            yield put({
              type: actions.REQUEST_RELOGIN,
            });
          }
        } else {
          // User has been disconnected
          yield put({
            type: actions.REQUEST_RELOGIN,
          });
        }
      });
      yield take(appActions.CANCEL_LISTENERS);
      channel.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield put({ type: appActions.CANCEL_LISTENERS });
    yield put({ type: actions.CLEAR_STATES });
    const auth = getAuth();
    signOut(auth);
  });
}

function checkFacebookRequest() {
  return localStorage.getItem('facebook_login');
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const facebookAuthRequested = yield call(checkFacebookRequest);
    if (facebookAuthRequested) {
      yield put({ type: actions.AWAITING_FB_LOGIN_RESPONSE });
    }
    const channel = yield call(getAuthChannel);
    const { user } = yield take(channel);
    if (user) {
      channel.close();
      if (facebookAuthRequested) {
        // Save user info on database
        const firstName = user.displayName.substr(0, user.displayName.indexOf(' '));
        const lastName = user.displayName.substr(user.displayName.indexOf(' ') + 1);
        const { email } = user;
        const avatar = user.photoURL;
        try {
          const idToken = yield call(getIdToken);
          yield call(
            saveUserInfoOnDB,
            firstName,
            lastName,
            email,
            user.uid,
            idToken,
            false,
            avatar,
          );
        } catch (error) {
          localStorage.removeItem('facebook_login');
          console.warn(error);
          yield put({
            type: actions.LOGIN_ERROR,
            payload: error,
          });
        }
      }
      yield put({
        type: actions.CHECK_AUTHORIZATION_SUCCESS,
        payload: {
          token: user.uid,
          profile: user,
        },
      });
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
      });
    }
  });
}

function checkCooldownOnDB(email) {
  return axios.post(`${ROOT_URL}/passwordResetEmailCooldown`, { email });
}

export function* resetPasswordRequest() {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function* (action) {
    try {
      yield put({ type: actions.RESET_PASSWORD_REQUEST_WAITING });
      const email = action.payload;
      const emailEnabled = yield call(checkCooldownOnDB, email);
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_FIREBASE_DOMAIN}/signin`,
        handleCodeInApp: true,
      };
      const auth = getAuth();
      if (emailEnabled?.data === true) {
        // yield call([auth, sendPasswordResetEmail], email, actionCodeSettings);
        yield call(sendPasswordResetEmail, auth, email, actionCodeSettings);
        yield put({ type: actions.RESET_PASSWORD_SUCCESS });
      } else {
        yield put({
          type: actions.RESET_PASSWORD_ERROR,
          payload: emailEnabled.data,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.RESET_PASSWORD_ERROR,
        payload: error,
      });
    }
  });
}

function setChangedLeakedPassword(idToken) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  return axios.post(`${ROOT_URL}/setChangedLeakedPassword`, {}, config);
}

export function* changePasswordRequest() {
  yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, function* (action) {
    const { oldPassword, newPassword } = action.payload;
    yield put({ type: actions.CHANGE_PASSWORD_REQUEST_WAITING });
    yield call(sleep, 500);
    const needChangeLeakedPassword = yield select(getNeedChangeLeakedPassword);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      yield call(reauthenticate, oldPassword);
      // yield call([auth.currentUser, auth.currentUser.updatePassword], newPassword);
      yield call(updatePassword, user, newPassword);
      if (needChangeLeakedPassword) {
        const idToken = yield call(getIdToken);
        yield call(setChangedLeakedPassword, idToken);
        const newIdToken = yield call(forceRefreshAccessTokenResult);
        if (newIdToken.claims.changedLeakedPassword) {
          yield all([
            put({ type: actions.CHANGE_PASSWORD_SUCCESS }),
            put({ type: actions.SET_CHANGE_LEAKED_PASSWORD }),
          ]);
        } else {
          yield all([
            put({ type: actions.CHANGE_PASSWORD_SUCCESS }),
            put({ type: actions.LOGOUT }),
          ]);
        }
      } else {
        yield put({ type: actions.CHANGE_PASSWORD_SUCCESS });
      }
    } catch (error) {
      const customError = { ...error };
      if (customError.code === 'auth/wrong-password') {
        customError.code = 'auth/wrong-change-password';
      }
      console.warn(customError);
      yield put({
        type: actions.CHANGE_PASSWORD_ERROR,
        payload: customError,
      });
    }
  });
}

function getUserData(uid) {
  return axios.post(
    `${ROOT_URL}/checkUserData`,
    { uid },
  );
}

export function* checkUserDataRequest() {
  yield takeEvery(actions.CHECK_USER_DATA_EXISTS_REQUEST, function* (action) {
    try {
      const { status } = yield call(getUserData, action.payload);
      if (status === 200) {
        yield put({
          type: actions.CHECK_USER_DATA_EXISTS_SUCCESS,
          payload: true,
        });
      } else {
        yield put({
          type: actions.CHECK_USER_DATA_EXISTS_SUCCESS,
          payload: false,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHECK_USER_DATA_EXISTS_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequestWithFB),
    fork(signUpRequest),
    fork(signUpSuccess),
    fork(signInRequest),
    fork(loginSuccess),
    fork(logout),
    fork(authStateListener),
    fork(resetPasswordRequest),
    fork(changePasswordRequest),
    // Patient auth methods
    // fork(signUpPatientRequest),
    // fork(signUpPatientSuccess),
    fork(checkUserDataRequest),
  ]);
}

import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  selectedChat: 'default',
  chatBadgeCount: 0,
  chatMessages: {},
  allChatMessages: {},
  oldChatMessages: {},
  noOlderMessages: {},
  // chatBadgeCount: {},
  listenerLimit: 12,
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    // case actions.MESSAGES_CHAT_FETCH_SUCCESS:
    //   return {
    //     ...state,
    //     chatMessages: action.payload,
    //   };
    case actions.RESET_CHAT:
      return {
        ...state,
        chatBadgeCount: 0,
        chatMessages: {},
        allChatMessages: {},
      };
    case actions.MESSAGES_CHAT_FETCH_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [action.payload.chatId]: action.payload.messages,
        },
      };
    case actions.MESSAGES_CHAT_FETCH_ERROR:
      return {
        ...state,
        // chatMessages: null,
        chatMessages: {},
      };
    case actions.ALL_MESSAGES_CHAT_FETCH_SUCCESS:
      return {
        ...state,
        allChatMessages: {
          ...state.allChatMessages,
          [action.payload.chatId]: action.payload.messages,
        },
        loadingOlderMessages: false,
      };
    case actions.SAVE_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        lastMessageAdded: action.payload.lastMessageAdded,
      };
    case actions.SET_CHAT_BADGE_COUNT:
      return {
        ...state,
        chatBadgeCount: action.payload,
      };
    case actions.CHANGE_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case actions.SET_CHAT_LISTENER_LIMIT:
      return {
        ...state,
        listenerLimit: action.payload,
      };
    case actions.FETCH_CHAT_OLDER_MESSAGES_SUCCESS:
      return {
        ...state,
        oldChatMessages: {
          ...state.oldChatMessages,
          [action.payload.chatId]: action.payload.messages,
        },
        loadingOlderMessages: false,
      };
    case actions.FETCH_CHAT_OLDER_MESSAGES_ERROR:
      return {
        ...state,
        loadingOlderMessages: null,
      };
    case actions.FETCHING_CHAT_OLDER_MESSAGES:
      return {
        ...state,
        loadingOlderMessages: true,
      };
    case actions.NO_OLDER_CHAT_MESSAGES:
      return {
        ...state,
        noOlderMessages: {
          ...state.noOlderMessages,
          [action.payload.chatId]: true,
        },
      };
    default:
      return state;
  }
}

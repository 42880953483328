import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  update,
  push,
  child,
  get,
} from 'firebase/database';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocsFromServer,
  collection,
} from 'firebase/firestore';
import {
  getStorage,
  ref as sRef,
  uploadBytes,
  getDownloadURL,
  // deleteObject,
} from 'firebase/storage';
import axios from 'axios';
import {
  all,
  takeEvery,
  takeLatest,
  select,
  call,
  put,
  fork,
  // take,
} from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
import customUsersActions from '../customUsers/actions';
// import procedureActions from '../procedures/actions';
import notification from '../../components/notification';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getProfileFromStore = (state) => _.cloneDeep(state.Profile.profile);

const getMainUserFromStore = (state) => state.Auth.mainUser;

// const getProfileOnDBFromStore = (state) => _.cloneDeep(state.Profile.profileOnDB);

const getCustomHealthProfessionalProfileFromStore = (state) => _.cloneDeep(state.Profile.customHealthProfessionalProfile);

// const getAdressesFromStore = (state) => _.cloneDeep(state.Profile.profile.addresses);

const getAvatarFileFromStore = (state) => state.Profile.avatarFile;

function accessTokenResult() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdTokenResult();
}

function fectchInfoFromDB(professional, mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const path = professional ? 'professionals' : 'patients';
  const userRef = doc(
    fs,
    path,
    uid,
  );
  return getDoc(userRef);
}

function fetchAddressesFromDB(professional, mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (professional) {
    const colRef = collection(
      fs,
      'professionals',
      uid,
      'addresses',
    );
    return getDocsFromServer(colRef);
  }
  return false;
}

function fetchServiceContractFromDB(professional, mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (professional) {
    const contractRef = doc(
      fs,
      'professionals',
      uid,
      'documents',
      'service-contract',
    );
    return getDoc(contractRef);
  }
  return false;
}

function fetchCustomUserInfoFromDB(mainUser) {
  const auth = getAuth();
  const { currentUser } = auth;
  const fs = getFirestore();
  const userRef = doc(
    fs,
    'professionals',
    mainUser,
    'customUsers',
    currentUser.uid,
  );
  return getDoc(userRef);
}

function fetchCustomHealthProfessionalProfileFromDB() {
  const auth = getAuth();
  const { currentUser } = auth;
  const fs = getFirestore();
  const userRef = doc(
    fs,
    'professionals',
    currentUser.uid,
  );
  return getDoc(userRef);
}

function denormalizeProfileData(info, addresses, mainUser) {
  const profile = _.cloneDeep(info);
  if (profile.plans) {
    const orderedPlans = _.orderBy(profile.plans, [(plan) => plan.toLowerCase()]);
    profile.plans = [...orderedPlans];
    profile.systemPlans = [...orderedPlans];
  }
  if (addresses) {
    if (addresses.length === 0) {
      const fs = getFirestore();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const colRef = collection(
        fs,
        'professionals',
        uid,
        'addresses',
      );
      const profileRef = doc(colRef);
      profile.addresses = [{
        title: 'Endereço',
        address: '',
        name: '',
        phones: [],
        lat: -14.235004,
        lng: -51.92528,
        key: '0',
        closable: false,
        addressForVerification: null,
        id: profileRef.id,
      }];
    } else {
      let key = -1;
      let phoneKey = -1;
      profile.addresses = [];
      _.orderBy(addresses, ['timestamp', 'name', 'address']).forEach((el) => {
      // addresses.reverse().forEach((el) => {
        key += 1;
        profile.addresses.push({
          ...el,
          key: `${key + 1}`,
          id: el.id,
          // title: `Endereço ${key + 1}`,
          title: el.name ? el.name : `Endereço ${key + 1}`,
          addressForVerification: el.address,
          closable: key !== 0,
          name: el.name ? el.name : '',
          phones: el.phones && el.phones.length > 0
            ? _.map(el.phones, (values, pushKey) => {
              phoneKey += 1;
              return {
                ...values,
                id: pushKey,
                key: phoneKey,
              };
            }) : [],
        });
      });
    }
  } else if (profile.phones.length > 0) {
    let key = 1;
    profile.phones.forEach((phone, index) => {
      profile.phones[index].key = key;
      // phone.key = key;
      key += 1;
    });
  }
  return profile;
}

export function* getProfileInfo() {
  yield takeEvery([
    actions.PROFILE_INFO_REQUEST,
    authActions.LOGIN_SUCCESS,
  ], function* () {
    try {
      const idTokenResult = yield call(accessTokenResult);
      const professional = idTokenResult.claims.professional
        ? idTokenResult.claims.professional
        : false;
      const mainUser = idTokenResult.claims.mainUser
        ? idTokenResult.claims.mainUser
        : null;
      const customHealthProfessional = idTokenResult.claims.healthProfessional
        ? idTokenResult.claims.healthProfessional
        : null;
      const [info, querySnapshot, serviceContract] = yield all([
        call(fectchInfoFromDB, professional, mainUser),
        call(fetchAddressesFromDB, professional, mainUser),
        call(fetchServiceContractFromDB, professional, mainUser),
      ]);
      if (info.exists) {
        let addresses = [];
        if (!querySnapshot) {
          // Patient profile.
          addresses = null;
        } else {
          querySnapshot.forEach((document) => {
            addresses.push({
              ...document.data(),
              id: document.id,
            });
          });
        }
        const profile = yield call(
          denormalizeProfileData,
          {
            ...info.data(),
            id: info.id,
            serviceContract: serviceContract.exists ? serviceContract.data() : null,
          },
          addresses,
          mainUser,
        );
        let customUserProfile = {};
        let customHealthProfessionalProfile = {};
        if (mainUser) {
          const response = yield call(fetchCustomUserInfoFromDB, mainUser);
          customUserProfile = response.exists() ? response.data() : {};
          if (customHealthProfessional) {
            const customHealthProfessionalResponse = yield call(fetchCustomHealthProfessionalProfileFromDB, mainUser);
            // customHealthProfessionalProfile = customHealthProfessionalResponse.exists ? customHealthProfessionalResponse.data() : {};
            customHealthProfessionalProfile = customHealthProfessionalResponse.exists
              ? yield call(
                denormalizeProfileData,
                {
                  ...customHealthProfessionalResponse.data(),
                  id: customHealthProfessionalResponse.id,
                },
                addresses,
                mainUser,
              ) : {};
          }
        }
        const avatarBase64 = _.isEmpty(customHealthProfessionalProfile)
          ? profile.avatar
          : customHealthProfessionalProfile.avatar;
        const actionsArr = [];
        actionsArr.push(put({
          type: actions.PROFILE_INFO_SUCCESS,
          payload: {
            profile,
            customUserProfile,
            customHealthProfessionalProfile,
            avatarBase64,
          },
        }));
        if (!_.isEmpty(customHealthProfessionalProfile)) {
          actionsArr.push(put({
            type: customUsersActions.CUSTOM_USERS_FETCH_REQUEST,
          }));
        }
        yield all(actionsArr);
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.PROFILE_INFO_ERROR });
    }
  });
}

// export function* saveAddressRequest() {
//   yield takeEvery(actions.SAVE_ADDRESS_REQUEST, function* (action) {
//     try {
//       const addresses = yield select(getAdressesFromStore);
//       const {
//         // index, address, lat, lng,
//         addressObj, index,
//       } = action.payload;
//       // addresses[index].address = address;
//       // addresses[index].lat = lat;
//       // addresses[index].lng = lng;
//       // addresses[index].addressForVerification = address;
//       addresses[index] = {
//         ...addresses[index],
//         ...addressObj,
//         addressForVerification: addressObj.address,
//       };
//       yield put({
//         type: actions.SAVE_ADDRESS_SUCCESS,
//         payload: addresses,
//       });
//     } catch (error) {
//       console.warn(error);
//       yield put({ type: actions.SAVE_ADDRESS_ERROR });
//     }
//   });
// }

export function* savePatientPhones() {
  yield takeLatest(actions.PATIENT_PHONE_CHANGED, function* (action) {
    try {
      const { phone, phoneIndex } = action.payload;
      const userProfile = yield select(getProfileFromStore);
      const editedPhone = phone.replace(/\s/g, '');
      userProfile.phones[phoneIndex].phone = editedPhone;
      yield put({
        type: actions.PATIENT_PROFILE_CHANGED,
        payload: userProfile,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* savePatientPhoneMode() {
  yield takeLatest(actions.PATIENT_PHONE_MODE_CHANGED, function* (action) {
    try {
      const { mode, phoneIndex } = action.payload;
      const userProfile = yield select(getProfileFromStore);
      userProfile.phones[phoneIndex].mode = mode;
      let str = userProfile.phones[phoneIndex].phone;
      if (mode === 'phone' && str.length === 11) {
        str = str.substring(0, str.length - 1);
        userProfile.phones[phoneIndex].phone = str;
      }
      yield put({
        type: actions.PATIENT_PROFILE_CHANGED,
        payload: userProfile,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

// export function* savePhones() {
//   yield takeLatest(actions.PHONE_CHANGED, function* (action) {
//     try {
//       const { phone, addressIndex, phoneIndex } = action.payload;
//       const addresses = yield select(getAdressesFromStore);
//       const editedPhone = phone.replace(/\s/g, '');
//       addresses[addressIndex].phones[phoneIndex].phone = editedPhone;
//       yield put({
//         type: actions.SAVE_PHONE_SUCCESS,
//         payload: addresses,
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

// export function* savePhoneMode() {
//   yield takeLatest(actions.PHONE_MODE_CHANGED, function* (action) {
//     try {
//       const { mode, addressIndex, phoneIndex } = action.payload;
//       const addresses = yield select(getAdressesFromStore);
//       addresses[addressIndex].phones[phoneIndex].mode = mode;
//       let str = addresses[addressIndex].phones[phoneIndex].phone;
//       if (mode === 'phone' && str.length === 11) {
//         str = str.substring(0, str.length - 1);
//         addresses[addressIndex].phones[phoneIndex].phone = str;
//       }
//       yield put({
//         type: actions.SAVE_PHONE_SUCCESS,
//         payload: addresses,
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

function getStorageDownloadURL(mainUser, isCustomHealthProfessional) {
  const storage = getStorage();
  // const storageRef = sRef(storage, `unified/${unified.id}/records/${seectedId}/${pushKey}/${item.name}`);
  // const storageRef = firebase.storage().ref();
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (isCustomHealthProfessional) {
    ({ uid } = currentUser);
  } else if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const storageRef = sRef(storage, `users/${uid}/images/avatar`);
  return getDownloadURL(storageRef);
  // const avatarImagesRef = storageRef.child(`users/${uid}/images/avatar`);
  // return avatarImagesRef.getDownloadURL();
}

function uploadAvatarOnDB(file, isCustomHealthProfessional, mainUser) {
  const storage = getStorage();
  // const storageRef = firebase.storage().ref();
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (isCustomHealthProfessional) {
    ({ uid } = currentUser);
  } else if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const avatarImagesRef = sRef(storage, `users/${uid}/images/avatar`);
  return uploadBytes(avatarImagesRef, file);
  // const avatarImagesRef = storageRef.child(`users/${uid}/images/avatar`);
  // return avatarImagesRef.put(file);
}

function normalizeProfileData(param, profileOnDB, avatar) {
  const profile = _.cloneDeep(param);
  if (profile.systemPlans) {
    profile.plans = [...profile.systemPlans];
    delete profile.systemPlans;
    delete profile.customPlans;
  }
  if (profile.addresses) {
    profile.addresses.forEach((address, i) => {
      if (address.phones && address.phones.length > 0) {
        const phonesArr = address.phones.filter((el) => el.phone.length > 0);
        profile.addresses[i].phones = phonesArr;
      }
    });
  }
  if (profile.noCouncil) {
    profile.council = {
      region: 'pr',
      specialties: [],
      type: 'crm',
      value: '',
      rqe: '',
    };
  }
  const newProfile = { ...profile };
  if (avatar) {
    newProfile.avatar = avatar;
  }
  delete newProfile.serviceContract;
  const removedAddresses = _.differenceBy(profileOnDB.addresses, profile.addresses, 'id');
  return { newProfile, removedAddresses };
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function savePatientProfileOnDB(profile, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  const userRef = doc(
    fs,
    'patients',
    uid,
  );
  return setDoc(userRef, profile);
}

function saveProfileOnDB(profile, isCustomHealthProfessional, removedAddresses, idToken, mainUser) {
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (isCustomHealthProfessional) {
    ({ uid } = currentUser);
  } else if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/saveProfile`,
    {
      profile,
      removedAddresses,
      uid,
      isCustomHealthProfessional,
    },
    config,
  );
}

function saveCustomPlansOnDB(
  arr,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const elements = {};
  arr.forEach((el) => {
    let pushKey = '';
    if (el.id.length !== 20) {
      pushKey = push(child(ref(db), `users/${uid}/customPlans`)).key;
    } else {
      pushKey = el.id;
    }
    elements[pushKey] = {
      name: el.name,
    };
  });
  updates[`users/${uid}/customPlans`] = elements;
  // return true;
  return update(dbRef, updates);
}

export function* saveProfile() {
  yield takeEvery(actions.SAVE_PROFILE_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SAVING_PROFILE });
      const idTokenResult = yield call(accessTokenResult);
      const mainUser = idTokenResult.claims.mainUser
        ? idTokenResult.claims.mainUser
        : null;
      const professional = idTokenResult.claims.professional
        ? idTokenResult.claims.professional
        : false;
      const {
        patient,
      } = action.payload;
      if (!patient) {
        const {
          profile,
          isCustomHealthProfessional,
        } = action.payload;
        let profileRedux = {};
        if (isCustomHealthProfessional) {
          profileRedux = yield select(getCustomHealthProfessionalProfileFromStore);
        } else {
          profileRedux = yield select(getProfileFromStore);
        }
        // const profile = yield select(getProfileFromStore);
        // const profileOnDB = yield select(getProfileOnDBFromStore);
        const avatarFile = yield select(getAvatarFileFromStore);
        let downloadURL = null;
        if (avatarFile) {
          yield call(uploadAvatarOnDB, avatarFile, isCustomHealthProfessional, mainUser);
          downloadURL = yield call(getStorageDownloadURL, mainUser, isCustomHealthProfessional);
        } else if (profile.avatar) {
          downloadURL = profile.avatar;
        }
        // const { newProfile, removedAddresses } = yield call(normalizeProfileData, profile, profileOnDB, downloadURL);
        const { newProfile, removedAddresses } = yield call(normalizeProfileData, profile, profileRedux, downloadURL);
        const idToken = yield call(getIdToken);
        yield call(saveProfileOnDB, newProfile, isCustomHealthProfessional, removedAddresses, idToken, mainUser);
        if (profile.customPlans) {
          yield call(saveCustomPlansOnDB, profile.customPlans, mainUser);
        }
      } else {
        const profile = yield select(getProfileFromStore);
        if (professional) {
          const { newProfile, removedAddresses } = yield call(normalizeProfileData, profile, profile, null);
          const idToken = yield call(getIdToken);
          yield call(saveProfileOnDB, newProfile, false, removedAddresses, idToken, mainUser);
        } else {
          yield call(savePatientProfileOnDB, profile, mainUser);
        }
      }
      yield put({
        type: actions.PROFILE_INFO_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao salvar o perfil', 'Tente novamente mais tarde.');
      yield put({
        type: actions.SAVE_PROFILE_ERROR,
      });
    }
  });
}

function sendEmailCloud(payload) {
  return axios.post(`${ROOT_URL}/sendGenericEmail`, payload);
}

export function* sendEmail() {
  yield takeEvery(actions.SEND_EMAIL_REQUEST, function* (action) {
    try {
      const response = yield call(sendEmailCloud, action.payload);
      if (response) {
        yield put({
          type: actions.SEND_EMAIL_SUCCESS,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SEND_EMAIL_ERROR });
    }
  });
}

function getPlansFromCloud() {
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, 'utils/plans'));
}

export function* getPlansRequest() {
  yield takeEvery(actions.PLANS_FETCH_REQUEST, function* () {
    try {
      const plans = yield call(getPlansFromCloud);
      if (plans.val()) {
        const editedPlans = _.map(plans.val(), (val, id) => (
          { ...val, id }
        ));
        yield put({
          type: actions.PLANS_FETCH_SUCCESS,
          payload: _.sortBy(editedPlans, ['name']),
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.PLANS_FETCH_ERROR });
    }
  });
}

function getCustomPlansDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/customPlans`));
}

export function* getCustomPlans() {
  yield takeEvery([
    actions.PROFILE_INFO_SUCCESS,
    actions.GET_CUSTOM_PLANS_REQUEST,
  ], function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      // let profileOnDB = yield select(getProfileOnDBFromStore);
      // if (_.isEmpty(profileOnDB)) {
      //   yield take(actions.PROFILE_INFO_SUCCESS);
      //   profileOnDB = yield select(getProfileOnDBFromStore);
      // }
      const customPlans = yield call(getCustomPlansDB, mainUser);
      if (customPlans.val()) {
        const objArr = _.map(customPlans.val(), (val, id) => (
          { ...val, id }
        ));
        const stringArr = objArr.map((el) => el.name);
        yield put({
          type: actions.GET_CUSTOM_PLANS_SUCCESS,
          payload: {
            objArr,
            stringArr,
          },
        });
      } else {
        yield put({
          type: actions.NO_CUSTOM_PLANS_SAVED,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.GET_CUSTOM_PLANS_ERROR });
    }
  });
}

// function saveCustomPlanOnDB(
//   value,
//   mainUser,
// ) {
//   const db = getDatabase();
//   const dbRef = ref(db);
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const auth = getAuth();
//     const { currentUser } = auth;
//     ({ uid } = currentUser);
//   }
//   const updates = {};
//   const pushKey = push(child(ref(db), `users/${uid}/customPlans`)).key;
//   updates[`users/${uid}/customPlans/${pushKey}/name`] = value;
//   // return true;
//   return update(dbRef, updates);
// }

// export function* createCustomPlan() {
//   yield takeLatest(actions.CREATE_CUSTOM_PLAN_REQUEST, function* (action) {
//     try {
//       yield put({
//         type: actions.CREATING_CUSTOM_PLAN,
//       });
//       const mainUser = yield select(getMainUserFromStore);
//       yield call(
//         saveCustomPlanOnDB,
//         action.payload.value,
//         mainUser,
//       );
//       yield put({
//         type: actions.GET_CUSTOM_PLANS_REQUEST,
//       });
//     } catch (error) {
//       console.warn(error);
//       notification('warning', 'Sua conexão com a internet está instável.');
//       yield put({ type: actions.CREATE_CUSTOM_PLAN_ERROR });
//     }
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(getProfileInfo),
    fork(sendEmail),
    // fork(saveAddressRequest),
    // fork(savePhones),
    // fork(savePhoneMode),
    fork(saveProfile),
    fork(savePatientPhones),
    fork(savePatientPhoneMode),
    fork(getPlansRequest),
    fork(getCustomPlans),
    // fork(createCustomPlan),
  ]);
}

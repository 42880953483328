const actions = {
  GOOGLE_ANALYTICS_REQUEST: 'GOOGLE_ANALYTICS_REQUEST',
  GOOGLE_ANALYTICS_SUCCESS: 'GOOGLE_ANALYTICS_SUCCESS',
  FETCH_USER_DATA_REQUEST: 'FETCH_USER_DATA_REQUEST',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_REFERRAL_LIST_REQUEST: 'FETCH_REFERRAL_LIST_REQUEST',
  FETCH_REFERRAL_LIST_SUCCESS: 'FETCH_REFERRAL_LIST_SUCCESS',
  FETCH_REFERRAL_LIST_ERROR: 'FETCH_REFERRAL_LIST_ERROR',
  FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST: 'FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST',
  FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS: 'FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS',
  FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_ERROR: 'FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_ERROR',
  GET_RANGE_APPOINTMENTS_REQUEST: 'GET_RANGE_APPOINTMENTS_REQUEST',
  GET_RANGE_APPOINTMENTS_SUCCESS: 'GET_RANGE_APPOINTMENTS_SUCCESS',
  GET_RANGE_APPOINTMENTS_ERROR: 'GET_RANGE_APPOINTMENTS_ERROR',
  FETCHING_APPOINTMENTS: 'FETCHING_APPOINTMENTS',
  analyticsRequest: () => ({
    type: actions.GOOGLE_ANALYTICS_REQUEST,
  }),
  fetchUserDataRequest: (userId) => ({
    type: actions.FETCH_USER_DATA_REQUEST,
    payload: userId,
  }),
  getReferalList: () => ({
    type: actions.FETCH_REFERRAL_LIST_REQUEST,
  }),
  getRequestingDoctorList: () => ({
    type: actions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST,
  }),
  getRangeAppointments: (start, end) => ({
    type: actions.GET_RANGE_APPOINTMENTS_REQUEST,
    payload: {
      start,
      end,
    },
  }),
};
export default actions;

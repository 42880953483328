const prescriptionsActions = {
  FETCH_PRESCRIPTION_SETTINGS_REQUEST: 'FETCH_PRESCRIPTION_SETTINGS_REQUEST',
  FETCH_PRESCRIPTION_SETTINGS_SUCCESS: 'FETCH_PRESCRIPTION_SETTINGS_SUCCESS',
  FETCH_PRESCRIPTION_SETTINGS_ERROR: 'FETCH_PRESCRIPTION_SETTINGS_ERROR',
  UPDATING_USE_DEFAULT_PRESCRIPTION: 'UPDATING_USE_DEFAULT_PRESCRIPTION',
  CHANGE_USE_DEFAULT_PRESCRIPTION_REQUEST: 'CHANGE_USE_DEFAULT_PRESCRIPTION_REQUEST',
  CHANGE_USE_DEFAULT_PRESCRIPTION_ERROR: 'CHANGE_USE_DEFAULT_PRESCRIPTION_ERROR',
  PATIENT_PRESCRIPTIONS_FETCH_REQUEST: 'PATIENT_PRESCRIPTIONS_FETCH_REQUEST',
  PATIENT_PRESCRIPTIONS_FETCH_SUCCESS: 'PATIENT_PRESCRIPTIONS_FETCH_SUCCESS',
  CHANGE_PRESCRIPTION: 'CHANGE_PRESCRIPTION',
  CHANGE_PRESCRIPTION_GENERAL_OBS: 'CHANGE_PRESCRIPTION_GENERAL_OBS',
  DISCARD_PRESCRIPTION: 'DISCARD_PRESCRIPTION',
  SAVE_PRESCRIPTION_REQUEST: 'SAVE_PRESCRIPTION_REQUEST',
  SAVE_PRESCRIPTION_ERROR: 'SAVE_PRESCRIPTION_ERROR',
  RESET_PRESCRIPTION: 'RESET_PRESCRIPTION',
  RESET_PRESCRIPTION_GENERAL_OBS: 'RESET_PRESCRIPTION_GENERAL_OBS',
  PRESCRIPTION_MODELS_FETCH_REQUEST: 'PRESCRIPTION_MODELS_FETCH_REQUEST',
  PRESCRIPTION_MODELS_FETCH_SUCCESS: 'PRESCRIPTION_MODELS_FETCH_SUCCESS',
  PRESCRIPTION_MODELS_FETCH_ERROR: 'PRESCRIPTION_MODELS_FETCH_ERROR',
  PRESCRIPTION_SECTION_START_UPDATE: 'PRESCRIPTION_SECTION_START_UPDATE',
  SAVE_PRESCRIPTION_MODEL_REQUEST: 'SAVE_PRESCRIPTION_MODEL_REQUEST',
  SAVE_PRESCRIPTION_MODEL_ERROR: 'SAVE_PRESCRIPTION_MODEL_ERROR',
  UPDATE_PRESCRIPTION_MODEL_REQUEST: 'UPDATE_PRESCRIPTION_MODEL_REQUEST',
  START_SAVING_PRESCRIPTION_MODEL: 'START_SAVING_PRESCRIPTION_MODEL',
  REMOVE_PRESCRIPTION_MODEL_REQUEST: 'REMOVE_PRESCRIPTION_MODEL_REQUEST',
  REMOVE_PRESCRIPTION_MODEL_ERROR: 'REMOVE_PRESCRIPTION_MODEL_ERROR',
  START_REMOVING_PRESCRIPTION_MODEL: 'START_REMOVING_PRESCRIPTION_MODEL',
  CHANGE_COLLAPSE_PRESCRIPTION_REQUEST: 'CHANGE_COLLAPSE_PRESCRIPTION_REQUEST',
  CHANGE_COLLAPSE_PRESCRIPTION_SUCCESS: 'CHANGE_COLLAPSE_PRESCRIPTION_SUCCESS',
  CHANGE_COLLAPSE_PRESCRIPTION_ERROR: 'CHANGE_COLLAPSE_PRESCRIPTION_ERROR',
  CHANGE_HIDE_PRESCRIPTION_REQUEST: 'CHANGE_HIDE_PRESCRIPTION_REQUEST',
  CHANGE_HIDE_PRESCRIPTION_SUCCESS: 'CHANGE_HIDE_PRESCRIPTION_SUCCESS',
  CHANGE_HIDE_PRESCRIPTION_ERROR: 'CHANGE_HIDE_PRESCRIPTION_ERROR',
  CHANGING_HIDE_PRESCRIPTION: 'CHANGING_HIDE_PRESCRIPTION',
  GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS: 'GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS',
  GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS_SUCCESS: 'GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS_SUCCESS',
  SET_SHOW_HIDDEN_PRESCRIPTIONS: 'SET_SHOW_HIDDEN_PRESCRIPTIONS',
  SET_SHOW_HIDDEN_PRESCRIPTIONS_SUCCESS: 'SET_SHOW_HIDDEN_PRESCRIPTIONS_SUCCESS',
  SET_NEW_PRESCRIPTION: 'SET_NEW_PRESCRIPTION',
  SET_NEW_PRESCRIPTION_GENERAL_OBS: 'SET_NEW_PRESCRIPTION_GENERAL_OBS',
  changeUseDefaultPrescription: (value) => ({
    type: prescriptionsActions.CHANGE_USE_DEFAULT_PRESCRIPTION_REQUEST,
    payload: value,
  }),
  getPrescriptions: (paginate = false) => ({
    type: prescriptionsActions.PATIENT_PRESCRIPTIONS_FETCH_REQUEST,
    payload: {
      paginate,
    },
  }),
  changePrescriptions: (prescriptions, id, copy = false) => ({
    type: prescriptionsActions.CHANGE_PRESCRIPTION,
    payload: {
      prescriptions,
      id,
      copy,
    },
  }),
  changePrescriptionGeneralObs: (obs, id) => ({
    type: prescriptionsActions.CHANGE_PRESCRIPTION_GENERAL_OBS,
    payload: {
      obs,
      id,
    },
  }),
  discardPrescriptions: (id) => ({
    type: prescriptionsActions.DISCARD_PRESCRIPTION,
    payload: {
      id,
    },
  }),
  savePrescription: (arr) => ({
    type: prescriptionsActions.SAVE_PRESCRIPTION_REQUEST,
    payload: arr,
  }),
  getPrescriptionModels: () => ({
    type: prescriptionsActions.PRESCRIPTION_MODELS_FETCH_REQUEST,
  }),
  savePrescriptionModel: (model) => ({
    type: prescriptionsActions.SAVE_PRESCRIPTION_MODEL_REQUEST,
    payload: {
      model,
    },
  }),
  removePrescriptionModel: (id) => ({
    type: prescriptionsActions.REMOVE_PRESCRIPTION_MODEL_REQUEST,
    payload: {
      id,
    },
  }),
  updatePrescriptionModel: (model, id) => ({
    type: prescriptionsActions.UPDATE_PRESCRIPTION_MODEL_REQUEST,
    payload: {
      model,
      id,
    },
  }),
  changeCollapsePrescription: (id, value) => ({
    type: prescriptionsActions.CHANGE_COLLAPSE_PRESCRIPTION_REQUEST,
    payload: {
      id,
      value,
    },
  }),
  changeHidePrescription: (item) => ({
    type: prescriptionsActions.CHANGE_HIDE_PRESCRIPTION_REQUEST,
    payload: item,
  }),
  getShowHiddenPrescriptions: () => ({
    type: prescriptionsActions.GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS,
  }),
  toggleShowHiddenPrescriptions: (value) => ({
    type: prescriptionsActions.SET_SHOW_HIDDEN_PRESCRIPTIONS,
    value,
  }),
};
export default prescriptionsActions;

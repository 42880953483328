import _ from 'lodash';
import actions from './actions';

const initState = {
  newPatients: {},
  didReadMessage: null,
  savedAllChangesProp: {},
  patientAppointments: [],
};

export default function analyticsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GOOGLE_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: action.payload,
      };
    case actions.FETCH_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        referralList: action.payload.referralList,
        referralListMonthly: action.payload.referralListMonthly,
      };
    case actions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        requestingDoctorList: action.payload.requestingDoctorList,
        requestingDoctorListMonthly: action.payload.requestingDoctorListMonthly,
      };
    case actions.GET_RANGE_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: _.cloneDeep(action.payload.appointments),
        fetchingAppointments: false,
      };
    case actions.GET_RANGE_APPOINTMENTS_ERROR:
      return {
        ...state,
        fetchingAppointments: null,
      };
    case actions.FETCHING_APPOINTMENTS:
      return {
        ...state,
        fetchingAppointments: true,
      };
    default:
      return state;
  }
}

import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  child,
  get,
} from 'firebase/database';
import { eventChannel } from 'redux-saga';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
  select,
  takeEvery,
  take,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
// import axios from 'axios';
import actions from './actions';
import appActions from '../app/actions';
import agendaActions from '../agenda/actions';
import { notification } from '../../components';

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

function createWaitListListener(mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const proceduresRef = ref(db, `/users/${uid}/waitList`);
    const unsubscribe = onValue(proceduresRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

function fetchSelectedDataOnDB(agendaId, appointmentId, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `/requests/${uid}/${agendaId}/confirmed/${appointmentId}`));
}

export function* requestWaitListPatients() {
  // yield takeLatest(actions.REQUEST_WAIT_LIST, function* () {
  yield takeLatest(agendaActions.SELECT_AGENDA, function* () {
    try {
      yield put({ type: actions.REQUEST_WAIT_LIST_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      const waitListListener = yield call(createWaitListListener, mainUser);
      yield takeEvery(waitListListener, function* (waitList) {
        const dataAux = { ...waitList };
        const appointmentsArr = [];
        Object.keys(dataAux).forEach((agendaId) => {
          if (dataAux[agendaId].patients) {
            Object.keys(dataAux[agendaId].patients).forEach((patientId) => {
              dataAux[agendaId].patients[patientId].id = patientId;
              dataAux[agendaId].patients[patientId].agendaId = agendaId;
              if (dataAux[agendaId].patients[patientId].appointmentId) {
                appointmentsArr.push({
                  appointmentId: dataAux[agendaId].patients[patientId].appointmentId,
                  agendaId,
                  patientId,
                });
              }
            });
          }
        });
        const appointmentsArrResponse = yield all(appointmentsArr.map((appointment) => call(
          fetchSelectedDataOnDB,
          appointment.agendaId,
          appointment.appointmentId,
          mainUser,
        )));
        appointmentsArrResponse.forEach((item, index) => {
          if (item.val()) {
            const selectedData = item.val();
            const auxAppointmentsArr = { ...appointmentsArr[index] };
            const { duration } = selectedData;
            const startTime = moment(selectedData.time, 'YYYY/MM/DD HH:mm');
            let endTime = moment(selectedData.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm');
            if (endTime.isAfter(startTime, 'day')) {
              endTime = moment(selectedData.time, 'YYYY/MM/DD HH:mm');
              endTime.set({ hour: 23, minute: 59 });
            }
            dataAux[auxAppointmentsArr.agendaId].patients[auxAppointmentsArr.patientId].selectedData = {
              ...selectedData,
              start: startTime.toDate(),
              id: item.key,
              end: endTime.toDate(),
              allDay: false,
              blockAll: selectedData.allDay ? selectedData.allDay : false,
              type: 'confirmed',
            };
          }
        });
        yield put({
          type: actions.REQUEST_WAIT_LIST_SUCCED,
          payload: {
            patients: { ...dataAux },
          },
        });
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
      ]);
      waitListListener.close();
      if (actionTriggered?.type === 'CANCEL_LISTENERS') {
        // It is the general "cancel all listeners".
        yield put({
          type: appActions.CANCEL_LISTENERS_SUCCESS,
        });
      }
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao gerar lista de espera', 'Tente novamente mais tarde.');
      yield put({
        type: actions.REQUEST_WAIT_LIST_ERROR,
      });
    }
  });
}

function requestPatientListInformationOnDB(id, addressUid, unified = {}, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/patients/${id}`));
  }
  return get(child(dbRef, `/users/${uid}/patients/${id}`));
}

export function* requestPatientListInformation() {
  yield takeEvery(actions.REQUEST_PATIENTS_FROM_LIST, function* (action) {
    try {
      yield put({ type: actions.REQUEST_PATIENTS_FROM_LIST_WAITING });
      const { id, list } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const data = yield all(Object.keys(list?.patients || {}).map((s) => call(requestPatientListInformationOnDB, s, addressUid, unified, mainUser)));
      const userList = {};
      data.forEach((dataItem) => {
        const item = dataItem.val();
        userList[dataItem.key] = item;
      });
      yield put({
        type: actions.REQUEST_PATIENTS_FROM_LIST_SUCCED,
        payload: {
          userList,
        },
      });
      if (id) {
        yield put({
          type: actions.FILL_LOADED_LIST,
          payload: {
            id,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao listar os pacientes', 'Tente novamente mais tarde.');
      yield put({
        type: actions.REQUEST_PATIENTS_FROM_LIST_ERROR,
      });
    }
  });
}

function deletePatientFromListOnDB(waitListObj, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updates = {};
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  updates[`/users/${uid}/waitList/${waitListObj.agendaId}/patients/${waitListObj.id}`] = null;
  if (waitListObj.appointmentId) {
    updates[`/requests/${uid}/${waitListObj.agendaId}/confirmed/${waitListObj.appointmentId}/addPatientToWaitList`] = false;
  }
  return update(dbRef, updates);
}

export function* deletePatientFromList() {
  yield takeLatest(actions.DELETE_PATIENT_FROM_LIST, function* (action) {
    try {
      const { waitListObj } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      yield call(deletePatientFromListOnDB, waitListObj, mainUser);
      yield put({ type: actions.REQUEST_WAIT_LIST });
    } catch (error) {
      yield put({ type: actions.DELETE_PATIENT_FROM_LIST_ERROR });
      console.warn(error);
      notification('error', 'Algo deu errado ao remover o paciente', 'Tente novamente mais tarde.');
    }
  });
}

function checkIfPatientAlreadyExists(listId, patientId, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `/users/${uid}/waitList/${listId}/patients/${patientId}`));
}

function addPatientToListOnDB(
  listId,
  patientId,
  observation,
  useAutomation,
  preferences,
  mainUser,
  date = null,
  fullObj = {},
) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updates = {};
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const obj = {
    ...fullObj,
    observation,
    useAutomation,
    preferences,
    date: date || moment().tz('America/Sao_Paulo').format(),
  };
  if (obj.selectedData) {
    delete obj.selectedData;
  }
  updates[`/users/${uid}/waitList/${listId}/patients/${patientId}`] = obj;
  if (fullObj.appointmentId) {
    updates[`/requests/${uid}/${listId}/confirmed/${fullObj.appointmentId}/patientWaitListObj`] = obj;
  }
  return update(dbRef, updates);
}

export function* addPatientToList() {
  yield takeEvery(actions.ADD_PATIENT_TO_LIST, function* (action) {
    try {
      yield put({ type: actions.ADD_PATIENT_TO_LIST_WAITING });
      const {
        listId,
        patientId,
        observation,
        selectedPatientToEdit,
        useAutomation,
        preferences,
      } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      if (selectedPatientToEdit) {
        yield call(
          addPatientToListOnDB,
          listId,
          patientId,
          observation,
          useAutomation,
          preferences,
          mainUser,
          selectedPatientToEdit.date,
          selectedPatientToEdit,
        );
        yield put({ type: actions.ADD_PATIENT_TO_LIST_SUCCESS });
        yield put({ type: actions.REQUEST_WAIT_LIST });
      } else {
        const hasUser = yield call(checkIfPatientAlreadyExists, listId, patientId, mainUser);
        if (hasUser.exists()) {
          notification('error', 'O paciente já se encontra na lista', 'Você não consegue ter o mesmo paciente duas vezes na mesma lista');
          yield put({ type: actions.ADD_PATIENT_TO_LIST_SUCCESS });
        } else {
          yield call(
            addPatientToListOnDB,
            listId,
            patientId,
            observation,
            useAutomation,
            preferences,
            mainUser,
          );
          yield put({ type: actions.ADD_PATIENT_TO_LIST_SUCCESS });
          yield put({ type: actions.REQUEST_WAIT_LIST });
          yield put({
            type: actions.REQUEST_PATIENTS_FROM_LIST,
            payload: {
              list: {
                patients: {
                  [patientId]: {},
                },
              },
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao adicionar o paciente na lista', 'Tente novamente mais tarde.');
      yield put({ type: actions.ADD_PATIENT_TO_LIST_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(requestWaitListPatients),
    fork(requestPatientListInformation),
    fork(deletePatientFromList),
    fork(addPatientToList),
  ]);
}

import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  waitList: null,
  waitListUsers: {},
  loadedListId: [],
  addingPatientToList: undefined,
  performingAction: false,
  waitListSlotArr: [],
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.ADD_PATIENT_TO_LIST_RESET:
      return {
        ...state,
        addingPatientToList: undefined,
      };
    case actions.REQUEST_WAIT_LIST_SUCCED:
      return {
        ...state,
        performingAction: false,
        waitList: {
          ...action.payload.patients,
        },
      };
    case actions.REQUEST_PATIENTS_FROM_LIST_SUCCED:
      return {
        ...state,
        waitListUsers: {
          ...state.waitListUsers,
          ...action.payload.userList,
        },
      };
    case actions.FILL_LOADED_LIST:
      return {
        ...state,
        loadedListId: [...state.loadedListId, action.payload.id],
      };
    case actions.ADD_PATIENT_TO_LIST_WAITING:
      return {
        ...state,
        addingPatientToList: true,
      };
    case actions.ADD_PATIENT_TO_LIST_SUCCESS:
      return {
        ...state,
        addingPatientToList: false,
      };
    case actions.ADD_PATIENT_TO_LIST_ERROR:
      return {
        ...state,
        addingPatientToList: undefined,
      };
    case actions.DELETE_PATIENT_FROM_LIST:
      return {
        ...state,
        performingAction: true,
      };
    case actions.DELETE_PATIENT_FROM_LIST_ERROR:
      return {
        ...state,
        performingAction: false,
      };
    case actions.SET_WAIT_LIST_SLOT:
      return {
        ...state,
        waitListSlotArr: action.payload.slotArr,
      };
    case actions.SET_OPEN_WAIT_LIST_COMPONENT:
      return {
        ...state,
        waitListVisible: action.payload.value,
      };
    default:
      return state;
  }
}

const actions = {
  SET_GIS_USER_PAYLOAD: 'SET_GIS_USER_PAYLOAD',
  GIS_AUTHORIZATION_REQUEST: 'GIS_AUTHORIZATION_REQUEST',
  SET_GIS_CLIENT: 'SET_GIS_CLIENT',
  SET_GIS_ACCESS_TOKEN: 'SET_GIS_ACCESS_TOKEN',
  GAPI_IS_READY_TO_USE: 'GAPI_IS_READY_TO_USE',
  GOOGLE_AUTH_IN_PROGRESS: 'GOOGLE_AUTH_IN_PROGRESS',
  CONTINUE_WITHOUT_GIS_AUTHORIZATION: 'CONTINUE_WITHOUT_GIS_AUTHORIZATION',
  RESET_GIS_ACCESS_TOKEN: 'RESET_GIS_ACCESS_TOKEN',
  setGisUserPayload: (payload) => ({
    type: actions.SET_GIS_USER_PAYLOAD,
    payload,
  }),
  setGisClient: (client) => ({
    type: actions.SET_GIS_CLIENT,
    payload: client,
  }),
  getGisAuthorization: () => ({
    type: actions.GIS_AUTHORIZATION_REQUEST,
  }),
  setGisAccessToken: (accessToken, profileData = {}) => ({
    type: actions.SET_GIS_ACCESS_TOKEN,
    payload: {
      accessToken,
      profileData,
    },
  }),
  checkGapi: () => ({
    type: actions.GAPI_IS_READY_TO_USE,
  }),
};

export default actions;

import actions from './actions';
import recordActions from '../records/actions';
import authActions from '../auth/actions';

const initState = {
  newEntranceTab: '1',
};

export default function entranceDrawer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.CHANGE_NEW_RECORD_TAB:
      return {
        ...state,
        newEntranceTab: action.payload,
      };
    case actions.TOGGLE_NEW_ENTRANCE_DRAWER:
      return {
        ...state,
        newEntranceDrawer: action.payload,
        newEntranceTab: '1',
      };
    case recordActions.DISCARD_RECORD_MASK_CHANGES:
      return {
        ...state,
        newEntranceTab: '1',
      };
    case actions.TOGGLE_NEW_ENTRANCE_GRAPH:
      return {
        ...state,
        newEntranceDrawer: 'Records',
        newEntranceTab: '2',
      };
    default:
      return state;
  }
}

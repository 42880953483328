const actions = {
  GET_FINANCIAL_SETTINGS_REQUEST: 'GET_FINANCIAL_SETTINGS_REQUEST',
  GET_FINANCIAL_SETTINGS_SUCCESS: 'GET_FINANCIAL_SETTINGS_SUCCESS',
  GET_FINANCIAL_SETTINGS_ERROR: 'GET_FINANCIAL_SETTINGS_ERROR',
  CHANGE_INVOICE_AUTOMATION_REQUEST: 'CHANGE_INVOICE_AUTOMATION_REQUEST',
  CHANGE_INVOICE_AUTOMATION_ERROR: 'CHANGE_INVOICE_AUTOMATION_ERROR',
  CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST: 'CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST',
  CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_ERROR: 'CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_ERROR',
  CHECK_FINANCIAL_TRANSACTIONS_REQUEST: 'CHECK_FINANCIAL_TRANSACTIONS_REQUEST',
  CHECK_FINANCIAL_TRANSACTIONS_SUCCESS: 'CHECK_FINANCIAL_TRANSACTIONS_SUCCESS',
  CHECK_FINANCIAL_TRANSACTIONS_ERROR: 'CHECK_FINANCIAL_TRANSACTIONS_ERROR',
  CHECKING_FINANCIAL_PAYMENTS: 'CHECKING_FINANCIAL_PAYMENTS',
  GET_BALANCE_REQUEST: 'GET_BALANCE_REQUEST',
  GET_BALANCE_SUCCESS: 'GET_BALANCE_SUCCESS',
  GET_BALANCE_ERROR: 'GET_BALANCE_ERROR',
  GET_CASH_BALANCE_REQUEST: 'GET_CASH_BALANCE_REQUEST',
  GET_CASH_BALANCE_SUCCESS: 'GET_CASH_BALANCE_SUCCESS',
  GET_CASH_BALANCE_ERROR: 'GET_CASH_BALANCE_ERROR',
  RESET_BALANCE: 'RESET_BALANCE',
  RESET_CASH_BALANCE: 'RESET_CASH_BALANCE',
  GET_OPERATIONS_LIST_REQUEST: 'GET_OPERATIONS_LIST_REQUEST',
  GET_OPERATIONS_LIST_SUCCESS: 'GET_OPERATIONS_LIST_SUCCESS',
  GET_OPERATIONS_LIST_ERROR: 'GET_OPERATIONS_LIST_ERROR',
  OPERATIONS_LIST_WAITING: 'OPERATIONS_LIST_WAITING',
  GET_BANK_STATEMENT_REQUEST: 'GET_BANK_STATEMENT_REQUEST',
  GET_BANK_STATEMENT_SUCCESS: 'GET_BANK_STATEMENT_SUCCESS',
  GET_BANK_STATEMENT_ERROR: 'GET_BANK_STATEMENT_ERROR',
  FETCHING_BANK_STATEMENT: 'FETCHING_BANK_STATEMENT',
  GET_COST_CENTER_REQUEST: 'GET_COST_CENTER_REQUEST',
  GET_COST_CENTER_SUCCESS: 'GET_COST_CENTER_SUCCESS',
  GET_COST_CENTER_ERROR: 'GET_COST_CENTER_ERROR',
  SELECT_COST_CENTER_PROFILE: 'SELECT_COST_CENTER_PROFILE',
  SELECT_COST_CENTER_PROFILE_ARR: 'SELECT_COST_CENTER_PROFILE_ARR',
  CREATE_COST_CENTER_PROFILE_REQUEST: 'CREATE_COST_CENTER_PROFILE_REQUEST',
  CREATE_COST_CENTER_PROFILE_SUCCESS: 'CREATE_COST_CENTER_PROFILE_SUCCESS',
  CREATE_COST_CENTER_PROFILE_ERROR: 'CREATE_COST_CENTER_PROFILE_ERROR',
  COST_CENTER_FETCH_OR_UPDATING_WAITING: 'COST_CENTER_FETCH_OR_UPDATING_WAITING',
  REMOVE_COST_CENTER_PROFILE_REQUEST: 'REMOVE_COST_CENTER_PROFILE_REQUEST',
  REMOVE_COST_CENTER_PROFILE_SUCCESS: 'REMOVE_COST_CENTER_PROFILE_SUCCESS',
  REMOVE_COST_CENTER_PROFILE_ERROR: 'REMOVE_COST_CENTER_PROFILE_ERROR',
  REMOVING_COST_CENTER_PROFILE: 'REMOVING_COST_CENTER_PROFILE',
  CREATE_MANUAL_INCOME_REQUEST: 'CREATE_MANUAL_INCOME_REQUEST',
  CREATE_MANUAL_INCOME_SUCCESS: 'CREATE_MANUAL_INCOME_SUCCESS',
  CREATE_MANUAL_INCOME_ERROR: 'CREATE_MANUAL_INCOME_ERROR',
  CREATING_MANUAL_OPS: 'CREATING_MANUAL_OPS',
  GET_FINANCIAL_CONTACTS_REQUEST: 'GET_FINANCIAL_CONTACTS_REQUEST',
  GET_FINANCIAL_CONTACTS_SUCCESS: 'GET_FINANCIAL_CONTACTS_SUCCESS',
  GET_FINANCIAL_CONTACTS_ERROR: 'GET_FINANCIAL_CONTACTS_ERROR',
  CREATE_MANUAL_EXPENSE_REQUEST: 'CREATE_MANUAL_EXPENSE_REQUEST',
  CREATE_MANUAL_EXPENSE_SUCCESS: 'CREATE_MANUAL_EXPENSE_SUCCESS',
  CREATE_MANUAL_EXPENSE_ERROR: 'CREATE_MANUAL_EXPENSE_ERROR',
  GET_FINALIZED_APPOINTMENTS_REQUEST: 'GET_FINALIZED_APPOINTMENTS_REQUEST',
  GET_FINALIZED_APPOINTMENTS_SUCCESS: 'GET_FINALIZED_APPOINTMENTS_SUCCESS',
  GET_FINALIZED_APPOINTMENTS_ERROR: 'GET_FINALIZED_APPOINTMENTS_ERROR',
  RESET_FINALIZED_APPOINTMENTS: 'RESET_FINALIZED_APPOINTMENTS',
  FETCHING_FINALIZED_APPOINTMENTS_LIST: 'FETCHING_FINALIZED_APPOINTMENTS_LIST',
  UPDATE_FINALIZED_APPOINTMENT_REQUEST: 'UPDATE_FINALIZED_APPOINTMENT_REQUEST',
  UPDATE_FINALIZED_APPOINTMENT_SUCCESS: 'UPDATE_FINALIZED_APPOINTMENT_SUCCESS',
  UPDATE_FINALIZED_APPOINTMENT_ERROR: 'UPDATE_FINALIZED_APPOINTMENT_ERROR',
  UPDATING_FINALIZED_APPOINTMENT: 'UPDATING_FINALIZED_APPOINTMENT',
  REMOVE_FINALIZED_APPOINTMENT_REQUEST: 'REMOVE_FINALIZED_APPOINTMENT_REQUEST',
  REMOVE_FINALIZED_APPOINTMENT_SUCCESS: 'REMOVE_FINALIZED_APPOINTMENT_SUCCESS',
  REMOVE_FINALIZED_APPOINTMENT_ERROR: 'REMOVE_FINALIZED_APPOINTMENT_ERROR',
  REMOVING_FINALIZED_APPOINTMENT: 'REMOVING_FINALIZED_APPOINTMENT',
  GET_MANUAL_OPS_REQUEST: 'GET_MANUAL_OPS_REQUEST',
  GET_MANUAL_OPS_SUCCESS: 'GET_MANUAL_OPS_SUCCESS',
  GET_MANUAL_OPS_ERROR: 'GET_MANUAL_OPS_ERROR',
  RESET_MANUAL_OPS: 'RESET_MANUAL_OPS',
  FETCHING_MANUAL_OPS_LIST: 'FETCHING_MANUAL_OPS_LIST',
  UPDATE_MANUAL_OPS_REQUEST: 'UPDATE_MANUAL_OPS_REQUEST',
  UPDATE_MANUAL_OPS_SUCCESS: 'UPDATE_MANUAL_OPS_SUCCESS',
  UPDATE_MANUAL_OPS_ERROR: 'UPDATE_MANUAL_OPS_ERROR',
  UPDATING_MANUAL_OPS: 'UPDATING_MANUAL_OPS',
  REMOVE_MANUAL_OPS_REQUEST: 'REMOVE_MANUAL_OPS_REQUEST',
  REMOVE_MANUAL_OPS_SUCCESS: 'REMOVE_MANUAL_OPS_SUCCESS',
  REMOVE_MANUAL_OPS_ERROR: 'REMOVE_MANUAL_OPS_ERROR',
  REMOVING_MANUAL_OPS: 'REMOVING_MANUAL_OPS',
  GET_BALANCE_HISTORY_REQUEST: 'GET_BALANCE_HISTORY_REQUEST',
  GET_BALANCE_HISTORY_SUCCESS: 'GET_BALANCE_HISTORY_SUCCESS',
  GET_BALANCE_HISTORY_ERROR: 'GET_BALANCE_HISTORY_ERROR',
  RESET_BALANCE_HISTORY: 'RESET_BALANCE_HISTORY',
  FETCHING_BALANCE_HISTORY: 'FETCHING_BALANCE_HISTORY',
  GET_NEXT_TRANSACTIONS_REQUEST: 'GET_NEXT_TRANSACTIONS_REQUEST',
  GET_NEXT_TRANSACTIONS_SUCCESS: 'GET_NEXT_TRANSACTIONS_SUCCESS',
  GET_NEXT_TRANSACTIONS_ERROR: 'GET_NEXT_TRANSACTIONS_ERROR',
  FETCHING_NEXT_TRANSACTIONS: 'FETCHING_NEXT_TRANSACTIONS',
  FETCHING_MONTH_INCOME_VALUES: 'FETCHING_MONTH_INCOME_VALUES',
  // GET_MONTH_INCOME_REQUEST: 'GET_MONTH_INCOME_REQUEST',
  GET_MONTH_INCOME_SUCCESS: 'GET_MONTH_INCOME_SUCCESS',
  GET_MONTH_INCOME_ERROR: 'GET_MONTH_INCOME_ERROR',
  // GET_TRANSACTIONS_LIST_REQUEST: 'GET_TRANSACTIONS_LIST_REQUEST',
  // GET_TRANSACTIONS_LIST_SUCCESS: 'GET_TRANSACTIONS_LIST_SUCCESS',
  // GET_TRANSACTIONS_LIST_ERROR: 'GET_TRANSACTIONS_LIST_ERROR',
  // FETCHING_TRANSACTIONS_LIST: 'FETCHING_TRANSACTIONS_LIST',
  CHANGE_FINALIZED_APPOINTMENT_LISTENER_TIMER: 'CHANGE_FINALIZED_APPOINTMENT_LISTENER_TIMER',
  CHANGE_MANUAL_OPS_LISTENER_TIMER: 'CHANGE_MANUAL_OPS_LISTENER_TIMER',
  CONFIRM_CASH_PAYMENT_REQUEST: 'CONFIRM_CASH_PAYMENT_REQUEST',
  CONFIRM_CASH_PAYMENT_SUCCESS: 'CONFIRM_CASH_PAYMENT_SUCCESS',
  CONFIRM_CASH_PAYMENT_ERROR: 'CONFIRM_CASH_PAYMENT_ERROR',
  RELOAD_TRANSACTIONS_LIST_REQUEST: 'RELOAD_TRANSACTIONS_LIST_REQUEST',
  LOADING_CASH_PAYMENT: 'LOADING_CASH_PAYMENT',
  UPDATE_BANK_STATEMENT_LIST_REQUEST: 'UPDATE_BANK_STATEMENT_LIST_REQUEST',
  CHECK_CASH_BALANCE_HISTORY: 'CHECK_CASH_BALANCE_HISTORY',
  CHANGE_PATIENT_FINANCIAL_HISTORY_TRANSACTIONS_ARRAY: 'CHANGE_PATIENT_FINANCIAL_HISTORY_TRANSACTIONS_ARRAY',
  GET_CARD_TAXES_REQUEST: 'GET_CARD_TAXES_REQUEST',
  GET_CARD_TAXES_SUCCESS: 'GET_CARD_TAXES_SUCCESS',
  GET_CARD_TAXES_ERROR: 'GET_CARD_TAXES_ERROR',
  FETCHING_CARD_TAXES: 'FETCHING_CARD_TAXES',
  SAVE_CARD_SETTINGS_REQUEST: 'SAVE_CARD_SETTINGS_REQUEST',
  SAVE_CARD_SETTINGS_SUCCESS: 'SAVE_CARD_SETTINGS_SUCCESS',
  SAVE_CARD_SETTINGS_ERROR: 'SAVE_CARD_SETTINGS_ERROR',
  SAVING_CARD_TAXES: 'SAVING_CARD_TAXES',
  REMOVE_CARD_SETTINGS_REQUEST: 'REMOVE_CARD_SETTINGS_REQUEST',
  REMOVE_CARD_SETTINGS_SUCCESS: 'REMOVE_CARD_SETTINGS_SUCCESS',
  REMOVE_CARD_SETTINGS_ERROR: 'REMOVE_CARD_SETTINGS_ERROR',
  REMOVING_CARD_SETTINGS: 'REMOVING_CARD_SETTINGS',
  getFinancialSettings: () => ({
    type: actions.GET_FINANCIAL_SETTINGS_REQUEST,
  }),
  changeInvoiceAutomation: (value) => ({
    type: actions.CHANGE_INVOICE_AUTOMATION_REQUEST,
    payload: value,
  }),
  changeShowFinalizedAppointmentModal: (value) => ({
    type: actions.CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST,
    payload: value,
  }),
  checkFinancialTransactions: () => ({
    type: actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
  }),
  getBalance: () => ({
    type: actions.GET_BALANCE_REQUEST,
  }),
  getCashBalance: () => ({
    type: actions.GET_CASH_BALANCE_REQUEST,
  }),
  getOperationsList: () => ({
    type: actions.GET_OPERATIONS_LIST_REQUEST,
  }),
  requestBankStatement: (startDate, endDate) => ({
    type: actions.GET_BANK_STATEMENT_REQUEST,
    payload: {
      startDate,
      endDate,
    },
  }),
  getCostCenter: () => ({
    type: actions.GET_COST_CENTER_REQUEST,
  }),
  selectCostCenterProfile: (value, address) => ({
    type: actions.SELECT_COST_CENTER_PROFILE,
    payload: {
      selectedCostCenterProfile: value,
      address,
    },
  }),
  selectCostCenterProfileArr: (value, address) => ({
    type: actions.SELECT_COST_CENTER_PROFILE_ARR,
    payload: {
      selectedCostCenterProfileArr: value,
      address,
    },
  }),
  createCostCenterProfile: (values) => ({
    type: actions.CREATE_COST_CENTER_PROFILE_REQUEST,
    payload: values,
  }),
  removeCostCenterProfile: (id) => ({
    type: actions.REMOVE_COST_CENTER_PROFILE_REQUEST,
    payload: id,
  }),
  createManualIncome: (values) => ({
    type: actions.CREATE_MANUAL_INCOME_REQUEST,
    payload: values,
  }),
  createManualExpense: (values) => ({
    type: actions.CREATE_MANUAL_EXPENSE_REQUEST,
    payload: values,
  }),
  getFinalizedAppointmentsList: () => ({
    type: actions.GET_FINALIZED_APPOINTMENTS_REQUEST,
  }),
  updateFinalizeAppointment: (values = {}, appointmentInfo = {}) => ({
    type: actions.UPDATE_FINALIZED_APPOINTMENT_REQUEST,
    payload: {
      // selectedData,
      // invoice,
      values,
      appointmentInfo,
    },
  }),
  removeFinalizedAppointment: (item) => ({
    type: actions.REMOVE_FINALIZED_APPOINTMENT_REQUEST,
    payload: item,
  }),
  getManualOpsList: () => ({
    type: actions.GET_MANUAL_OPS_REQUEST,
  }),
  updateManualOps: (values = {}) => ({
    type: actions.UPDATE_MANUAL_OPS_REQUEST,
    payload: {
      values,
    },
  }),
  removeManualOps: (item) => ({
    type: actions.REMOVE_MANUAL_OPS_REQUEST,
    payload: item,
  }),
  getBalanceHistory: () => ({
    type: actions.GET_BALANCE_HISTORY_REQUEST,
  }),
  getNextTransactions: () => ({
    type: actions.GET_NEXT_TRANSACTIONS_REQUEST,
  }),
  // getTransactionsList: (transactions) => ({
  //   type: actions.GET_TRANSACTIONS_LIST_REQUEST,
  //   payload: {
  //     transactions,
  //   },
  // }),
  changeFinalizedAppointmentListenerTime: (value, customValue = null) => ({
    type: actions.CHANGE_FINALIZED_APPOINTMENT_LISTENER_TIMER,
    payload: {
      value,
      customValue,
    },
  }),
  changeManualOpsListenerTime: (value, customValue = null) => ({
    type: actions.CHANGE_MANUAL_OPS_LISTENER_TIMER,
    payload: {
      value,
      customValue,
    },
  }),
  confirmCashPayment: (date, value, transaction, bankStatement = false, patientFinancialHistoryId = false) => ({
    type: actions.CONFIRM_CASH_PAYMENT_REQUEST,
    payload: {
      date,
      value,
      transaction,
      bankStatement,
      patientFinancialHistoryId,
    },
  }),
  getCardTaxes: () => ({
    type: actions.GET_CARD_TAXES_REQUEST,
  }),
  saveCardTaxes: (values) => ({
    type: actions.SAVE_CARD_SETTINGS_REQUEST,
    payload: values,
  }),
  removeCardTaxes: (id) => ({
    type: actions.REMOVE_CARD_SETTINGS_REQUEST,
    payload: id,
  }),
};

export default actions;

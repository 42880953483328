import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  get,
  update,
  push,
  child,
} from 'firebase/database';
import {
  getFirestore,
  getDocs,
  query as fsQuery,
  collection,
  limit,
  where,
} from 'firebase/firestore';
import {
  all,
  takeLatest,
  fork,
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';
import actions from './actions';

const getMainUserFromStore = (state) => state.Auth.mainUser;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function substanciaQueryFromFirestore(search) {
  const fs = getFirestore();
  let startAt = search.toUpperCase();
  startAt = startAt
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const colRef = collection(fs, 'medicines');
  const q = fsQuery(
    colRef,
    where('formatedSubstancia', '>=', startAt),
    where('formatedSubstancia', '<', `${startAt}\uf8ff`),
    limit(20),
  );
  return getDocs(q);
  // return fs.collection('medicines')
  //   .where('formatedSubstancia', '>=', startAt)
  //   .where('formatedSubstancia', '<', `${startAt}\uf8ff`)
  //   .limit(20)
  //   .get();
}

function medicamentoQueryFromFirestore(search) {
  const fs = getFirestore();
  let startAt = search.toUpperCase();
  startAt = startAt
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const colRef = collection(fs, 'medicines');
  const q = fsQuery(
    colRef,
    where('formatedMedicamento', '>=', startAt),
    where('formatedMedicamento', '<', `${startAt}\uf8ff`),
    limit(20),
  );
  return getDocs(q);
  // return fs.collection('medicines')
  //   .where('formatedMedicamento', '>=', startAt)
  //   .where('formatedMedicamento', '<', `${startAt}\uf8ff`)
  //   .limit(20)
  //   .get();
}

function keywordsQueryFromFirestore(search) {
  const fs = getFirestore();
  let startAt = search.toUpperCase();
  startAt = startAt
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(' ');
  const colRef = collection(fs, 'medicines');
  const q = fsQuery(
    colRef,
    where('keywords', 'array-contains-any', startAt.slice(0, 10)),
    limit(20),
  );
  return getDocs(q);
  // return fs.collection('medicines')
  //   .where('keywords', 'array-contains-any', startAt.slice(0, 10))
  //   .orderBy('keywords')
  //   .limit(20)
  //   .get();
}

function codigoQueryFromFirestore(search) {
  const fs = getFirestore();
  const colRef = collection(fs, 'medicines');
  const q = fsQuery(
    colRef,
    where('codigo', '==', search),
    limit(20),
  );
  return getDocs(q);
  // return fs.collection('medicines')
  //   .where('codigo', '==', search)
  //   .limit(20)
  //   .get();
}

function* fetchMedicinesRequest() {
  yield takeLatest(actions.FETCH_MEDICINES_REQUEST, function* (action) {
    try {
      yield call(sleep, 500);
      const [apresentacao, termo, keywords, codigo] = yield all([
        call(substanciaQueryFromFirestore, action.payload.value),
        call(medicamentoQueryFromFirestore, action.payload.value),
        call(keywordsQueryFromFirestore, action.payload.value),
        call(codigoQueryFromFirestore, action.payload.value),
      ]);
      const apresentacaoArr = [];
      apresentacao.forEach((document) => {
        apresentacaoArr.push({
          ...document.data(),
          id: document.id,
          // value: `${document.data().medicamento}${document.data().substancia ? ` (${document.data().substancia})` : ''}`,
          value: `${document.data().medicamento}${document.data().apresentacao ? ` - ${document.data().apresentacao}` : ''}`,
        });
      });
      const termoArr = [];
      termo.forEach((document) => {
        termoArr.push({
          ...document.data(),
          id: document.id,
          // value: `${document.data().medicamento}${document.data().substancia ? ` (${document.data().substancia})` : ''}`,
          value: `${document.data().medicamento}${document.data().apresentacao ? ` - ${document.data().apresentacao}` : ''}`,
        });
      });
      const keywordsArr = [];
      keywords.forEach((document) => {
        keywordsArr.push({
          ...document.data(),
          id: document.id,
          // value: `${document.data().medicamento}${document.data().substancia ? ` (${document.data().substancia})` : ''}`,
          value: `${document.data().medicamento}${document.data().apresentacao ? ` - ${document.data().apresentacao}` : ''}`,
        });
      });
      const codigoArr = [];
      codigo.forEach((document) => {
        codigoArr.push({
          ...document.data(),
          id: document.id,
          // value: `${document.data().medicamento}${document.data().substancia ? ` (${document.data().substancia})` : ''}`,
          value: `${document.data().medicamento}${document.data().apresentacao ? ` - ${document.data().apresentacao}` : ''}`,
        });
      });
      const finalArr = [...apresentacaoArr, ...termoArr, ...keywordsArr, ...codigoArr];
      yield put({
        type: actions.FETCH_MEDICINES_SUCCESS,
        payload: {
          // medicinesArr: finalArr,
          medicinesArr: _.uniqBy(finalArr, 'id'),
          medKey: action.payload.medKey,
        },
      });
    } catch (error) {
      console.warn(error);
      // yield put({
      //   type: actions.PATIENTS_FETCH_ERROR,
      // });
    }
  });
}

function getMedicinesModelsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/medicineModels`));
}

export function* getMedicineModels() {
  yield takeEvery(actions.MEDICINE_MODELS_FETCH_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const models = yield call(getMedicinesModelsFromDB, mainUser);
      if (!models.val()) {
        yield put({
          type: actions.MEDICINE_MODELS_FETCH_SUCCESS,
          payload: {
            models: [],
          },
        });
      } else {
        const editedModels = _.map(models.val(), (val, id) => (
          {
            ...val,
            id,
            // key: id,
            // model: true,
          }
        ));
        yield put({
          type: actions.MEDICINE_MODELS_FETCH_SUCCESS,
          payload: {
            models: editedModels,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.MEDICINE_MODELS_FETCH_ERROR,
      });
    }
  });
}

function saveMedicineModelOnDB(newMedicine, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/users/${uid}/medicineModels`)).key;
  updates[`users/${uid}/medicineModels/${pushKey}`] = {
    ...newMedicine,
    medicine: {
      ...newMedicine.medicine,
      // key: pushKey,
      model: true,
      id: pushKey,
    },
  };
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveMedicineModel() {
  yield takeEvery(actions.SAVE_MEDICINE_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_MEDICINE_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveMedicineModelOnDB, action.payload.model, mainUser);
      yield all([
        yield put({
          type: actions.MEDICINE_MODELS_FETCH_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_MEDICINE_MODEL_ERROR });
    }
  });
}

function removeMedicineModelOnDB(medicineId, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/medicineModels/${medicineId}`] = null;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeMedicineModel() {
  yield takeEvery(actions.REMOVE_MEDICINE_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_REMOVING_MEDICINE_MODEL,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeMedicineModelOnDB, action.payload.id, mainUser);
      yield all([
        yield put({
          type: actions.MEDICINE_MODELS_FETCH_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REMOVE_MEDICINE_MODEL_ERROR });
    }
  });
}

function updateMedicineModelOnDB({ model, id }, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/medicineModels/${id}`] = model;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateMedicineModel() {
  yield takeEvery(actions.UPDATE_MEDICINE_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_MEDICINE_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      const model = _.cloneDeep(action.payload);
      if (model.modelName) {
        delete model.modelName;
      }
      yield call(updateMedicineModelOnDB, model, mainUser);
      yield all([
        yield put({
          type: actions.MEDICINE_MODELS_FETCH_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_MEDICINE_MODEL_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchMedicinesRequest),
    fork(getMedicineModels),
    fork(saveMedicineModel),
    fork(removeMedicineModel),
    fork(updateMedicineModel),
  ]);
}

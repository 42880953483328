import _ from 'lodash';
import moment from 'moment-timezone';
import {
  rrulestr,
} from 'rrule';

const format = 'HH:mm';

function createConfirmedArr(
  confirmed,
  batchedArr = [],
  currentDate,
  limitDate,
  duration,
  rules,
  modeArr,
  skip = false,
) {
  const normalizedConfirmed = {};
  const modeAvailableConfirmed = {};
  if (!skip) {
    batchedArr.forEach((el) => {
      const rule = rrulestr(el.rrule);
      const batchedDates = rule.between(currentDate.toDate(), limitDate.toDate(), true);
      const reqDuration = moment.duration(el.appointmentModel.duration).asMinutes() || 15;
      const startTimeMoment = moment(el.appointmentModel.time, 'YYYY/MM/DD HH:mm');
      let endTime = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').add(reqDuration, 'm').format(format);
      let endTimeMoment = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').add(reqDuration, 'm');
      if (endTimeMoment.isAfter(startTimeMoment, 'day')) {
        endTimeMoment = moment(el.appointmentModel.time, 'YYYY/MM/DD HH:mm');
        endTimeMoment.set({ hour: 23, minute: 59 });
        endTime = '23:59';
      }
      const nAppointments = Math.floor(reqDuration / duration);
      const newTime = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').format(format);
      batchedDates.forEach((date) => {
        const strTime = moment(date).utcOffset(0).format('YYYY-MM-DD');
        const weekday = moment(strTime, 'YYYY-MM-DD').format('ddd');
        if (!normalizedConfirmed[strTime]) {
          normalizedConfirmed[strTime] = [];
        }
        normalizedConfirmed[strTime].push({
          time: newTime,
          day: strTime,
          end: endTime,
          nAppointments: nAppointments || 1,
        });
        if (rules[weekday] && modeArr.length === 2 && !rules[weekday].onDemand) {
          // There is limits for number of plan and private appointments
          if (!modeAvailableConfirmed[strTime]) {
            modeAvailableConfirmed[strTime] = {
              plan: 0,
              private: 0,
            };
          }
          if (el.appointmentModel.proceduresArr) {
            const foundPlan = el.appointmentModel.proceduresArr.find((proc) => {
              if (proc.mode && proc.mode.type === 'plan') {
                return true;
              }
              return false;
            });
            if (foundPlan) {
              modeAvailableConfirmed[strTime] = {
                ...modeAvailableConfirmed[strTime],
                plan: modeAvailableConfirmed[strTime].plan + nAppointments,
              };
            } else {
              modeAvailableConfirmed[strTime] = {
                ...modeAvailableConfirmed[strTime],
                private: modeAvailableConfirmed[strTime].private + nAppointments,
              };
            }
          }
        }
      });
    });

    confirmed.forEach((request) => {
      if (!moment(request.time, 'YYYY-MM-DD HH:mm').isValid()) {
        return;
      }
      const strTime = moment(request.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
      const weekday = moment(strTime, 'YYYY-MM-DD').format('ddd');
      const newTime = moment(request.time, 'YYYY-MM-DD HH:mm').format(format);
      const reqDuration = moment.duration(request.duration).asMinutes();
      const endTime = moment(request.time, 'YYYY-MM-DD HH:mm').add(reqDuration, 'm').format(format);
      const nAppointments = Math.floor(reqDuration / duration);
      if (!normalizedConfirmed[strTime]) {
        normalizedConfirmed[strTime] = [];
      }
      normalizedConfirmed[strTime].push({
        time: newTime,
        day: strTime,
        end: endTime,
        nAppointments: nAppointments || 1,
      });
      if (rules[weekday] && modeArr.length === 2 && !rules[weekday].onDemand) {
        // There is limits for number of plan and private appointments
        if (!modeAvailableConfirmed[strTime]) {
          modeAvailableConfirmed[strTime] = {
            plan: 0,
            private: 0,
          };
        }
        if (request.proceduresArr) {
          const foundPlan = request.proceduresArr.find((el) => {
            if (el.mode && el.mode.type === 'plan') {
              return true;
            }
            return false;
          });
          if (foundPlan) {
            modeAvailableConfirmed[strTime] = {
              ...modeAvailableConfirmed[strTime],
              plan: modeAvailableConfirmed[strTime].plan + nAppointments,
            };
          } else {
            modeAvailableConfirmed[strTime] = {
              ...modeAvailableConfirmed[strTime],
              private: modeAvailableConfirmed[strTime].private + nAppointments,
            };
          }
        } else if (request.mode === 'plan') {
          modeAvailableConfirmed[strTime] = {
            ...modeAvailableConfirmed[strTime],
            plan: modeAvailableConfirmed[strTime].plan + nAppointments,
          };
        } else if (request.mode === 'private') {
          modeAvailableConfirmed[strTime] = {
            ...modeAvailableConfirmed[strTime],
            private: modeAvailableConfirmed[strTime].private + nAppointments,
          };
        }
      }
    });
  }
  return { normalizedConfirmed, modeAvailableConfirmed };
}

function createPendingArr(pending, duration, rules, modeArr, skip = false) {
  const normalizedPending = {};
  const modeAvailablePending = {};
  if (!skip) {
    pending.forEach((request) => {
      if (!moment(request.time, 'YYYY-MM-DD HH:mm').isValid()) {
        return;
      }
      const strTime = moment(request.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
      const weekday = moment(strTime, 'YYYY-MM-DD').format('ddd');
      const newTime = moment(request.time, 'YYYY-MM-DD HH:mm').format(format);
      const reqDuration = moment.duration(request.duration).asMinutes();
      const endTime = moment(request.time, 'YYYY-MM-DD HH:mm').add(reqDuration, 'm').format(format);
      const nAppointments = Math.floor(reqDuration / duration);
      if (!normalizedPending[strTime]) {
        normalizedPending[strTime] = [];
      }
      normalizedPending[strTime].push({
        time: newTime,
        day: strTime,
        end: endTime,
        nAppointments: nAppointments || 1,
      });
      if (rules[weekday] && modeArr.length === 2 && !rules[weekday].onDemand) {
        // There is limits for number of plan and private appointments
        if (!modeAvailablePending[strTime]) {
          modeAvailablePending[strTime] = {
            plan: 0,
            private: 0,
          };
        }
        if (request.proceduresArr) {
          const foundPlan = request.proceduresArr.find((el) => {
            if (el.mode && el.mode.type === 'plan') {
              return true;
            }
            return false;
          });
          if (foundPlan) {
            modeAvailablePending[strTime] = {
              ...modeAvailablePending[strTime],
              plan: modeAvailablePending[strTime].plan + nAppointments,
            };
          } else {
            modeAvailablePending[strTime] = {
              ...modeAvailablePending[strTime],
              private: modeAvailablePending[strTime].private + nAppointments,
            };
          }
        } else if (request.mode === 'plan') {
          modeAvailablePending[strTime] = {
            ...modeAvailablePending[strTime],
            plan: modeAvailablePending[strTime].plan + nAppointments,
          };
        } else if (request.mode === 'private') {
          modeAvailablePending[strTime] = {
            ...modeAvailablePending[strTime],
            private: modeAvailablePending[strTime].private + nAppointments,
          };
        }
      }
    });
  }
  return { normalizedPending, modeAvailablePending };
}

function verifyPlanAvailable(day, confirmedMode, pendingMode, type, limit) {
  let confirmedCounter = 0;
  if (confirmedMode[day] && confirmedMode[day][type]) {
    confirmedCounter += confirmedMode[day][type];
  }
  let pendingCounter = 0;
  if (pendingMode[day] && pendingMode[day][type]) {
    pendingCounter += pendingMode[day][type];
  }
  if (confirmedCounter + pendingCounter >= limit) {
    return false;
  }
  return true;
}

function verifyFistDay(schedule, normalizedConfirmed, normalizedPending, day) {
  const final = schedule[day].filter((item) => {
    if (normalizedConfirmed[day]) {
      const foundConfirmed = normalizedConfirmed[day].some((busyConfirmed) => {
        if ((busyConfirmed.time < item.time && busyConfirmed.end > item.time)
          || (busyConfirmed.time >= item.time && busyConfirmed.end <= item.end)
          || (busyConfirmed.time >= item.time && busyConfirmed.time < item.end)) {
          return true;
        }
        return false;
      });
      if (foundConfirmed) {
        return false;
      }
    }
    if (normalizedPending[day]) {
      const foundPending = normalizedPending[day].some((busyPending) => {
        if ((busyPending.time < item.time && busyPending.end > item.time)
          || (busyPending.time >= item.time && busyPending.end <= item.end)
          || (busyPending.time >= item.time && busyPending.time < item.end)) {
          return true;
        }
        return false;
      });
      if (foundPending) {
        return false;
      }
    }
    return true;
  });
  if (final.length > 0) {
    return true;
  }
  return false;
}

function normalizeData(
  confirmed,
  pending,
  batchedArr,
  rules,
  dateSent,
  strDuration,
  mode,
) {
  const schedule = {};
  let currentDate;
  const momentCurrentDate = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');
  if (momentCurrentDate === dateSent) {
    currentDate = moment().tz('America/Sao_Paulo').format();
  } else {
    currentDate = moment(dateSent, 'YYYY-MM-DD').tz('America/Sao_Paulo').format();
  }
  let limitMonth = moment(dateSent);
  limitMonth = moment(limitMonth).add(6, 'M');

  let firstDay = null;
  const duration = moment.duration(strDuration).asMinutes() || 5;
  const modeArr = Object.keys(mode || {});

  const editedRules = _.map(rules, (val, id) => (
    { ...val, id }
  ));
  let verifyStartMorning;
  let verifyEndMorning;
  let verifyStartAfternoon;
  let verifyEndAfternoon;
  let nAppointmentsTotal = 0;
  editedRules.forEach((el) => {
    if (el.morningBegin.length > 0 && el.morningEnd.length > 0) {
      verifyStartMorning = moment(el.morningBegin, format);
      verifyEndMorning = moment(el.morningEnd, format);
      const diff = verifyEndMorning.diff(verifyStartMorning, 'minutes');
      nAppointmentsTotal += Math.floor(diff / duration);
    }
    if (el.afternoonBegin.length > 0 && el.afternoonEnd.length > 0) {
      verifyStartAfternoon = moment(el.afternoonBegin, format);
      verifyEndAfternoon = moment(el.afternoonEnd, format);
      const diff = verifyEndAfternoon.diff(verifyStartAfternoon, 'minutes');
      nAppointmentsTotal += Math.floor(diff / duration);
    }
  });
  let skip = false;
  if (nAppointmentsTotal <= 0) {
    skip = true;
  }

  const { normalizedConfirmed, modeAvailableConfirmed } = createConfirmedArr(
    confirmed,
    batchedArr,
    moment(currentDate),
    moment(dateSent, 'YYYY-MM-DD').tz('America/Sao_Paulo').add(24, 'M'),
    duration,
    rules,
    modeArr,
    skip,
  );
  const { normalizedPending, modeAvailablePending } = createPendingArr(
    pending,
    duration,
    rules,
    modeArr,
    skip,
  );
  // while (moment(currentDate).isSameOrBefore(limitMonth, 'month')) {
  if (!skip) {
    while (moment(currentDate).isSameOrBefore(limitMonth, 'month') || !firstDay) {
      const strTime = moment(currentDate).tz('America/Sao_Paulo').format('YYYY-MM-DD');
      let weekday = moment(strTime).format('ddd');
      if (weekday === 'sáb') {
        weekday = 'sab';
      }
      schedule[strTime] = [];
      if (rules[weekday]) {
        let startMorning;
        let endMorning;
        let startAfternoon;
        let endAfternoon;
        let nAppointmentsMorning = 0;
        let nAppointmentsAfternoon = 0;
        const {
          morningBegin, morningEnd,
          afternoonBegin, afternoonEnd,
        } = rules[weekday];
        schedule[strTime] = [];
        if (morningBegin.length > 0 && morningEnd.length > 0) {
          startMorning = moment(morningBegin, format);
          endMorning = moment(morningEnd, format);
          const diff = endMorning.diff(startMorning, 'minutes');
          nAppointmentsMorning = Math.floor(diff / duration);
          let time = startMorning.clone();
          for (let i = 0; i < nAppointmentsMorning; i += 1) {
            const newTime = time.format(format);
            const endTime = moment(newTime, format).add(duration, 'm').format(format);
            schedule[strTime].push({
              time: newTime,
              day: strTime,
              end: endTime,
            });
            time = time.add(duration, 'm');
          }
        }
        if (afternoonBegin.length > 0 && afternoonEnd.length > 0) {
          startAfternoon = moment(afternoonBegin, format);
          endAfternoon = moment(afternoonEnd, format);
          const diff = endAfternoon.diff(startAfternoon, 'minutes');
          nAppointmentsAfternoon = Math.floor(diff / duration);
          let time = startAfternoon.clone();
          for (let i = 0; i < nAppointmentsAfternoon; i += 1) {
            const newTime = time.format(format);
            const endTime = moment(newTime, format).add(duration, 'm').format(format);
            schedule[strTime].push({
              time: newTime,
              day: strTime,
              end: endTime,
            });
            time = time.add(duration, 'm');
          }
        }
      }
      if (!firstDay && moment(currentDate).isSameOrAfter(dateSent, 'day')) {
        if (verifyFistDay(schedule, normalizedConfirmed, normalizedPending, strTime)) {
          firstDay = strTime;
        }
      }
      currentDate = moment(currentDate).add(1, 'd');
    }
  }
  const fullSchedule = {};
  const finalSchedule = {};
  const finalModeAvailable = {};
  const busy = { ...normalizedConfirmed, ...normalizedPending };
  const busySchedule = {};
  Object.keys(busy).forEach((day) => {
    busySchedule[day] = [];
    busy[day].forEach((el) => {
      let newTime = el.time;
      for (let i = 0; i < el.nAppointments; i += 1) {
        busySchedule[day].push({
          time: newTime,
          day: el.day,
          end: el.end,
        });
        newTime = moment(newTime, format).add(duration, 'm').format(format);
      }
      busySchedule[day] = _.uniqBy(busySchedule[day], 'time');
    });
  });
  const gapSchedule = {};
  Object.keys(busySchedule).forEach((day) => {
    gapSchedule[day] = false;
    const orderedBusy = _.orderBy(busySchedule[day], ['time']);
    orderedBusy.forEach((el, index) => {
      // const momentStart = moment(el.time, format);
      const currentEnd = moment(el.end, format);
      if (orderedBusy[index + 1]) {
        const nextStart = moment(orderedBusy[index + 1].time, format);
        const diff = nextStart.diff(currentEnd, 'minutes');
        if (diff >= duration) {
          let weekday = moment(day).format('ddd');
          if (weekday === 'sáb') {
            weekday = 'sab';
          }
          if (schedule[day]) {
            let isValid = false;
            if (rules[weekday]) {
              const {
                morningBegin, morningEnd,
                afternoonBegin, afternoonEnd,
              } = rules[weekday];
              if ((currentEnd.format(format) >= morningBegin && nextStart.format(format) <= morningEnd)
                || (currentEnd.format(format) >= afternoonBegin && nextStart.format(format) <= afternoonEnd)) {
                isValid = true;
              }
            }
            if (isValid) {
              if (!gapSchedule[day]) {
                gapSchedule[day] = [];
                gapSchedule[day].push({
                  // end: el.end,
                  // time: orderedBusy[index + 1].time,
                  time: el.end,
                  end: orderedBusy[index + 1].time,
                  duration,
                });
              } else {
                gapSchedule[day].push({
                  // end: el.end,
                  // time: orderedBusy[index + 1].time,
                  time: el.end,
                  end: orderedBusy[index + 1].time,
                  duration,
                });
              }
            }
          }
        }
      }
    });
  });
  Object.keys(schedule).forEach((day) => {
    fullSchedule[day] = [];
    finalSchedule[day] = [];
    finalModeAvailable[day] = {};
    // Building final schedule to show on calendar
    fullSchedule[day] = schedule[day].map((item) => {
      if (normalizedConfirmed[day]) {
        const foundConfirmed = normalizedConfirmed[day].some((busyConfirmed) => {
          if ((busyConfirmed.time < item.time && busyConfirmed.end > item.time)
            || (busyConfirmed.time >= item.time && busyConfirmed.end <= item.end)
            || (busyConfirmed.time >= item.time && busyConfirmed.time < item.end)) {
            return true;
          }
          return false;
        });
        if (foundConfirmed) {
          return {
            ...item,
            free: false,
          };
        }
      }
      if (normalizedPending[day]) {
        const foundPending = normalizedPending[day].some((busyPending) => {
          if ((busyPending.time < item.time && busyPending.end > item.time)
            || (busyPending.time >= item.time && busyPending.end <= item.end)
            || (busyPending.time >= item.time && busyPending.time < item.end)) {
            return true;
          }
          return false;
        });
        if (foundPending) {
          return {
            ...item,
            free: false,
          };
        }
      }
      finalSchedule[day].push(item);
      return {
        ...item,
        free: true,
      };
    });
    // Building final modeAvailable
    const weekday = moment(day).format('ddd');
    if (rules[weekday] && modeArr.length === 2 && !rules[weekday].onDemand) {
      finalModeAvailable[day] = {
        plan: verifyPlanAvailable(
          day,
          modeAvailableConfirmed,
          modeAvailablePending,
          'plan',
          rules[weekday].plan,
        ),
        private: verifyPlanAvailable(
          day,
          modeAvailableConfirmed,
          modeAvailablePending,
          'private',
          rules[weekday].private,
        ),
      };
    } else {
      finalModeAvailable[day] = { ...mode };
    }
  });
  return {
    firstDay,
    fullSchedule,
    finalSchedule,
    busySchedule,
    finalModeAvailable,
    gapSchedule,
  };
}

export default normalizeData;

import { store } from './store';
import authActions from './auth/actions';
import appActions from './app/actions';
import Firebase from '../helpers/firebase';

export default () => new Promise((res) => {
  if (Firebase.isValid) {
    store.dispatch(authActions.checkAuthorization());
    store.dispatch(appActions.checkAppVersion());
    // Firebase.initializePush();
    res();
  }
});

import notification from './feedback/notification';

const createNotification = (type, message, description, duration) => {
  notification[type]({
    message,
    description,
    duration,
  });
};
export default createNotification;

import _ from 'lodash';
import moment from 'moment-timezone';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  pdfProfilesArr: [],
  selectedPdfProfile: 'default',
  specialPrescriptionDate: moment().tz('America/Sao_Paulo').format(),
  signatureDate: moment().tz('America/Sao_Paulo').format(),
  allUsersPdfSettings: {},
};

export default function pdfReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.CHANGE_CUSTOM_HEADER_TEXT:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          header: {
            ...state.pdfSettings.header,
            text: action.payload,
          },
        },
      };
    case actions.SET_PDF_PAGE_SIZE_SUCCESS:
      return {
        ...state,
        pageSize: action.value,
      };
    case actions.SET_PDF_PAGE_ORIENTATION_SUCCESS:
      return {
        ...state,
        pageOrientation: action.value,
      };
    case actions.CHANGE_HEADER_MODE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          mode: action.payload,
        },
      };
    case actions.CHANGE_HEADER_LOGO_MODE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          header: {
            ...state.pdfSettings.header,
            logoMode: action.payload,
          },
        },
      };
    case actions.CHANGE_HEADER_LOGO_WIDTH_MODE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          header: {
            ...state.pdfSettings.header,
            fullWidthLogo: action.payload,
          },
        },
      };
    case actions.CHANGE_RENDER_SIGNATURE_DATE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            renderSignatureDate: action.payload,
          },
        },
      };
    case actions.CHANGE_RENDER_SIGNATURE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            renderSignature: action.payload,
          },
        },
      };
    case actions.SAVE_LOGO:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          logo: action.payload,
        },
      };
    case actions.SAVE_LOGO_AS_FILE:
      return {
        ...state,
        logoFile: action.payload,
      };
    case actions.PDF_SETTINGS_FETCH_SUCCESS:
      return {
        ...state,
        updatingPdfSettings: false,
        pdfProfilesArr: _.cloneDeep(action.payload.pdfProfilesArr),
        pdfProfilesArrOnDB: _.cloneDeep(action.payload.pdfProfilesArr),
        allUsersPdfSettings: _.cloneDeep(action.payload.allUsersPdfSettings),
      };
    case actions.UPLOAD_PDF_SETTINGS_START_UPDATE:
      return {
        ...state,
        updatingPdfSettings: true,
      };
    case actions.DISCARD_PDF_SETTINGS_CHANGES_SUCCESS:
      return {
        ...state,
        pdfProfilesArr: _.cloneDeep(state.pdfProfilesArrOnDB),
        newEntranceTab: '1',
        logoFile: null,
      };
    case actions.CHANGE_HEADER_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          header: {
            ...state.pdfSettings.header,
            fontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_CERTIFICATE_MAIN_TEXT_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            certificateMainTextFontSize: action.payload,
          },
        },
      };
    case actions.TOGGLE_REGULAR_PRESCRIPTION_PATIENT_INFO:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            showRegularPrescriptionPatientInfo: action.payload,
          },
        },
      };
    case actions.TOGGLE_SPECIAL_PRESCRIPTION_PATIENT_INFO:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            showSpecialPrescriptionPatientInfo: action.payload,
          },
        },
      };
    case actions.CHANGE_PATIENT_NAME_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            patientNameFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_PATIENT_ADDRESS_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            patientAddressFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_PRESCRIPTIONS_MEDICATION_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            prescriptionsMedicationFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_PRESCRIPTIONS_USE_MODE_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            prescriptionsUseModeFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_PRESCRIPTIONS_DESCRIPTION_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            prescriptionsDescriptionFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_PRESCRIPTIONS_QUANTITY_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            prescriptionsQuantityFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_EXAMS_ITEM_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            examsItemFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_EXAMS_OBS_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            examsObsFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_EXAMS_QUANTITY_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            examsQuantityFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_SIGNATURE_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            signatureFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_SIGNATURE_DATE_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            signatureDateFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_ADDRESS_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            addressFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_OPTICAL_OBS_FONT_SIZE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          content: {
            ...state.pdfSettings.content,
            opticalObservationFontSize: action.payload,
          },
        },
      };
    case actions.CHANGE_HEADER_MARGIN:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          headerMargin: action.payload,
        },
      };
    case actions.CHANGE_FOOTER_MARGIN:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footerMargin: action.payload,
        },
      };
    case actions.CHANGE_HEADER_MARGIN_A5:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          headerMarginA5: action.payload,
        },
      };
    case actions.CHANGE_FOOTER_MARGIN_A5:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footerMarginA5: action.payload,
        },
      };
    case actions.CHANGE_LEFT_MARGIN:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          leftMargin: action.payload,
        },
      };
    case actions.CHANGE_RIGHT_MARGIN:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          rightMargin: action.payload,
        },
      };
    case actions.CHANGE_LEFT_MARGIN_A5:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          leftMarginA5: action.payload,
        },
      };
    case actions.CHANGE_RIGHT_MARGIN_A5:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          rightMarginA5: action.payload,
        },
      };
    case actions.TOGGLE_RENDER_HEADER:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          header: {
            ...state.pdfSettings.header,
            renderHeader: action.payload,
          },
        },
      };
    case actions.TOGGLE_RENDER_FOOTER:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            renderFooter: action.payload,
          },
        },
      };
    case actions.TOGGLE_RENDER_DATE_SPECIAL_PRESCRIPTION:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          header: {
            ...state.pdfSettings.header,
            renderDateSpecialPrescription: action.payload,
          },
        },
      };
    case actions.CHANGE_SPECIAL_PRESCRIPTION_DATE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          specialPrescriptionDate: action.payload,
        },
      };
    case actions.CHANGE_SPECIAL_PRESCRIPTION_DATE_ON_DB:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          specialPrescriptionDate: action.payload,
        },
        pdfSettingsOnDB: {
          ...state.pdfSettings,
          specialPrescriptionDate: action.payload,
        },
      };
    case actions.CHANGE_SIGNATURE_DATE:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          signatureDate: action.payload,
        },
      };
    case actions.CHANGE_SIGNATURE_DATE_ON_DB:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          signatureDate: action.payload,
        },
        pdfSettingsOnDB: {
          ...state.pdfSettings,
          signatureDate: action.payload,
        },
      };
    case actions.CHANGE_RENDER_ADDRESS:
      return {
        ...state,
        pdfSettings: {
          ...state.pdfSettings,
          footer: {
            ...state.pdfSettings.footer,
            renderAddress: action.payload,
          },
        },
      };
    case actions.CREATE_PDF_PROFILE_SUCCESS:
      return {
        ...state,
        selectedPdfProfile: action.payload.key,
        creatingPdfProfile: false,
        pdfSettings: _.cloneDeep(action.payload.pdfSettings),
        // pdfProfilesArr: _.cloneDeep(action.payload.pdfProfilesArr),
      };
    case actions.CREATE_PDF_PROFILE_ERROR:
      return {
        ...state,
        creatingPdfProfile: null,
      };
    case actions.SELECT_PDF_PROFILE_SUCCESS:
      return {
        ...state,
        selectedPdfProfile: action.payload.key,
        pdfSettings: _.cloneDeep(action.payload.pdfSettings),
        // pdfSettingsOnDB: _.cloneDeep(action.payload.pdfSettings),
        selectedProfessionalPdfProfile: action.payload.selectedProfessionalPdfProfile,
      };
    case actions.CREATING_PDF_PROFILE:
      return {
        ...state,
        creatingPdfProfile: true,
      };
    case actions.UPDATE_PDF_SETTINGS_ON_DB:
      return {
        ...state,
        pdfSettingsOnDB: _.cloneDeep(action.payload.pdfSettings),
        logoFile: null,
      };
    case actions.REMOVE_PDF_PROFILE_SUCCESS:
      return {
        ...state,
        removingPdfProfile: false,
      };
    case actions.REMOVING_PDF_PROFILE:
      return {
        ...state,
        removingPdfProfile: action.payload,
      };
    case actions.SELECT_PROFESSIONAL_PDF_PROFILE_REQUEST:
      return {
        ...state,
        selectedProfessionalPdfProfile: action.payload.key,
      };
    case actions.RESET_SELECT_PROFESSIONAL_PDF_PROFILE:
      return {
        ...state,
        selectedProfessionalPdfProfile: _.cloneDeep(state.selectedProfessionalPdfProfileRender),
      };
    case actions.SELECT_PROFESSIONAL_PDF_PROFILE_RENDER:
      return {
        ...state,
        selectedProfessionalPdfProfileRender: _.cloneDeep(state.selectedProfessionalPdfProfile),
      };
    default:
      return state;
  }
}

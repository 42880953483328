import moment from 'moment-timezone';
import actions from './actions';
import authActions from '../auth/actions';

const colors = ['#7ED321', '#de1b1b', '#511E78', '#ff9009', '#42a5f5'];
// const todos = new fakeData(5, colors.length).getAll();

const initState = {
  agendasArr: [],
  colors,
  // googleIsLoggedIn: false,
  newPatients: {},
  didReadMessage: null,
  patientAppointments: [],
  firstDay: {},
  freeSchedule: {},
  finalSchedule: {},
  onlyBusySchedule: {},
  modesAvailable: {},
  gapSchedule: {},
  messageCenter: {},
  currentAgendaStartDateListener: moment().tz('America/Sao_Paulo').format('YYYY-MM-DD'),
  modalConfirmationMessage: false,
  onlineRequestSelectedData: {},
  agendaColor: {},
  batchedStartEndDates: [],
};

export default function todoReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        agendasArr: action.payload,
        savingAgendasArr: false,
        removingAgenda: false,
        savingAgendaLabel: false,
        errorFetchingAgendas: false,
        fetchingAgendas: false,
      };
    case actions.FETCHING_AGENDAS:
      return {
        ...state,
        errorFetchingAgendas: false,
        fetchingAgendas: true,
      };
    case actions.FETCH_AGENDAS_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        errorFetchingAgendas: true,
        fetchingAgendas: false,
      };
    case actions.SELECT_AGENDA:
      return {
        ...state,
        selectedAgenda: action.payload,
        // Resetting agenda on change.
        // confirmed: undefined,
        // rejected: undefined,
        // allRejected: undefined,
        // pending: undefined,
        // allPendings: undefined,
        // canceled: undefined,
        // accepted: undefined,
        // allAccepted: undefined,
        // firstDay: {},
        // freeSchedule: {},
        // finalSchedule: {},
        // onlyBusySchedule: {},
        // modesAvailable: {},
      };
    case actions.CREATING_NEW_AGENDA_ID:
      return {
        ...state,
        creatingNewAgendaId: true,
      };
    case actions.CREATE_NEW_AGENDA_ID_SUCCESS:
      return {
        ...state,
        creatingNewAgendaId: false,
        selectedAgenda: action.payload.selectedAgenda,
      };
    case actions.CREATE_NEW_AGENDA_ID_ERROR:
      return {
        ...state,
        creatingNewAgendaId: null,
      };
    case actions.SAVING_AGENDA_SETTINGS:
      return {
        ...state,
        savingAgendasArr: true,
      };
    case actions.SAVE_AGENDA_SETTINGS_ERROR:
      return {
        ...state,
        savingAgendasArr: null,
      };
    case actions.DELETING_AGENDA:
      return {
        ...state,
        removingAgenda: true,
      };
    case actions.RESET_AGENDA:
      return {
        ...state,
        confirmed: undefined,
        rejected: undefined,
        allRejected: undefined,
        pending: undefined,
        allPendings: undefined,
        canceled: undefined,
        accepted: undefined,
        allAccepted: undefined,
        fullBatchedAppointmentsArr: undefined,
        batchedAppointments: undefined,
        persistedBatchedAppointments: undefined,
        firstDay: {},
        freeSchedule: {},
        finalSchedule: {},
        onlyBusySchedule: {},
        modesAvailable: {},
      };
    case actions.RESET_CONFIRMED:
      return {
        ...state,
        confirmed: [],
      };
    case actions.RESET_PAST_CONFIRMED:
      return {
        ...state,
        pastConfirmed: [],
      };
    case actions.RESET_CANCELED:
      return {
        ...state,
        canceled: [],
      };
    case actions.RESET_PAST_CANCELED:
      return {
        ...state,
        pastCanceled: [],
      };
    case actions.PENDING_FETCH_SUCCESS:
      return {
        ...state,
        pending: action.payload.data,
      };
    case actions.ALL_PENDING_FETCH_SUCCESS:
      return {
        ...state,
        allPendings: {
          ...state.allPendings,
          [action.payload.id]: action.payload.data,
        },
      };
    case actions.CONFIRMED_FETCH_SUCCESS:
      return {
        ...state,
        confirmed: action.payload.data,
        // updatingRequest: false,
        confirmingPatientRequest: false,
        cancelingRequest: false,
        alreadyCanceled: false,
        finalizingAppointment: false,
        blockingDays: false,
        unblockingDay: false,
        // blockingTime: false,
      };
    case actions.PAST_CONFIRMED_FETCH_SUCCESS:
      return {
        ...state,
        pastConfirmed: action.payload.data,
        confirmingPatientRequest: false,
        cancelingRequest: false,
        alreadyCanceled: false,
        finalizingAppointment: false,
        blockingDays: false,
        unblockingDay: false,
        loadingPastAgenda: false,
      };
    case actions.FETCHING_PAST_CONFIRMED:
      return {
        ...state,
        loadingPastAgenda: true,
      };
    case actions.REJECTED_FETCH_SUCCESS:
      return {
        ...state,
        rejected: action.payload.data,
        rejectingRequest: false,
      };
    case actions.ALL_REJECTED_FETCH_SUCCESS:
      return {
        ...state,
        allRejected: {
          ...state.allRejected,
          [action.payload.id]: action.payload.data,
        },
      };
    case actions.CANCELED_FETCH_SUCCESS:
      return {
        ...state,
        canceled: action.payload.data,
      };
    case actions.PAST_CANCELED_FETCH_SUCCESS:
      return {
        ...state,
        pastCanceled: action.payload.data,
      };
    case actions.ACCEPTED_FETCH_SUCCESS:
      return {
        ...state,
        accepted: action.payload.data,
      };
    case actions.ALL_ACCEPTED_FETCH_SUCCESS:
      return {
        ...state,
        allAccepted: {
          ...state.allAccepted,
          [action.payload.id]: action.payload.data,
        },
      };
    case actions.CONFIRM_REQUEST_SUCCESS:
      return {
        ...state,
        updatingRequest: false,
      };
    case actions.CHANGE_PAST_LISTENER_PARAM:
      return {
        ...state,
        pastAgendaListenerParams: action.payload,
      };
    case actions.CONFIRM_MANUAL_CONFIRMATION_REQUEST_SUCCESS:
      return {
        ...state,
        modalVisible: action.payload.modalVisible,
        onlineRequestSelectedData: action.payload.selectedData,
      };
    case actions.CLEAR_MANUAL_CONFIRMATION_DATA:
      return {
        ...state,
        onlineRequestSelectedData: {},
        modalVisible: '',
      };
    case actions.CONFIRM_REQUEST_FAIL:
      return {
        ...state,
        updatingRequest: null,
      };
    case actions.ABORT_CONFIRM_REQUEST:
      return {
        ...state,
        updatingRequest: undefined,
      };
    case actions.REJECT_REQUEST_SUCCESS:
      return {
        ...state,
        rejectingRequest: false,
      };
    case actions.REJECT_REQUEST_FAIL:
      return {
        ...state,
        rejectingRequest: null,
      };
    case actions.START_SAVING_ARRIVED_TIME:
      return {
        ...state,
        savingArrivedTime: action.payload,
      };
    case actions.ARRIVED_TIME_SUCCESS:
      return {
        ...state,
        savingArrivedTime: false,
      };
    case actions.ARRIVED_TIME_ERROR:
      return {
        ...state,
        savingArrivedTime: null,
      };
    case actions.START_SAVING_PATIENT_MISSED_APPOINTMENT:
      return {
        ...state,
        didPatientMissedAppointment: action.payload,
      };
    case actions.PATIENT_MISSED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        didPatientMissedAppointment: false,
      };
    case actions.PATIENT_MISSED_APPOINTMENT_ERROR:
      return {
        ...state,
        didPatientMissedAppointment: null,
      };
    case actions.START_PATIENT_BEING_ATTENDING:
      return {
        ...state,
        startedAttendance: action.payload,
      };
    case actions.PATIENT_BEING_ATTENDING_SUCCESS:
      return {
        ...state,
        startedAttendance: false,
      };
    case actions.PATIENT_BEING_ATTENDING_ERROR:
      return {
        ...state,
        startedAttendance: null,
      };
    case actions.MESSAGE_READ_SUCCESS:
      return {
        ...state,
        didReadMessage: action.payload,
      };
    case actions.CLEAR_DID_READ_MESSAGE:
      return {
        ...state,
        didReadMessage: null,
      };
    case actions.START_SAVING_APPOINTMENT_EDIT:
      return {
        ...state,
        updatingAppointment: true,
      };
    case actions.EDIT_APPOINTMENT_TIME_SUCCESS:
      return {
        ...state,
        updatingAppointment: false,
        initialMessageError: false,
      };
    case actions.EDIT_APPOINTMENT_TIME_ERROR:
      return {
        ...state,
        updatingAppointment: null,
      };
    case actions.EDIT_APPOINTMENT_NOTES_SUCCESS:
      return {
        ...state,
        editAppointmentNote: {
          [action.payload.id]: action.payload.text,
        },
        savingAppointmentNotes: false,
      };
    case actions.EDIT_APPOINTMENT_NOTES_ERROR:
      return {
        ...state,
        editAppointmentNote: null,
        savingAppointmentNotes: null,
      };
    case actions.SAVING_APPOINTMENT_NOTES:
      return {
        ...state,
        savingAppointmentNotes: true,
      };
    case actions.CANCEL_APPOINTMENT_SUCCESS:
      return {
        ...state,
        cancelingRequest: false,
      };
    case actions.CANCEL_APPOINTMENT_ERROR:
      return {
        ...state,
        cancelingRequest: null,
      };
    case actions.APPOINTMENT_DOES_NOT_EXIST:
      return {
        ...state,
        cancelingRequest: null,
        alreadyCanceled: true,
      };
    case actions.FINALIZE_APPOINTMENT_ERROR:
      return {
        ...state,
        finalizingAppointment: null,
      };
    case actions.BLOCK_DAYS_ERROR:
      return {
        ...state,
        blockingDays: null,
      };
    case actions.START_UPDATING_REQUEST:
      return {
        ...state,
        updatingRequest: action.payload,
      };
    case actions.START_CONFIRMING_PATIENT_REQUEST:
      return {
        ...state,
        confirmingPatientRequest: action.payload,
      };
    case actions.START_FINALIZING_APPOINTMENT:
      return {
        ...state,
        finalizingAppointment: action.payload,
      };
    case actions.START_CANCELING_APPOINTMENT:
      return {
        ...state,
        cancelingRequest: action.payload,
      };
    case actions.START_BLOCKING_DAYS:
      return {
        ...state,
        blockingDays: true,
      };
    case actions.START_UNBLOCKING_DAY:
      return {
        ...state,
        unblockingDay: action.payload,
      };
    case actions.START_UNBLOCKING_MULTIPLE_DAYS:
      return {
        ...state,
        unblockingMultipleDays: true,
      };
    case actions.UNBLOCK_DAY_SUCCESS:
      return {
        ...state,
        unblockingDay: false,
        unblockingMultipleDays: false,
      };
    case actions.UNBLOCK_DAY_ERROR:
      return {
        ...state,
        unblockingDay: null,
        unblockingMultipleDays: null,
      };
    case actions.START_BLOCKING_TIME:
      return {
        ...state,
        blockingTime: true,
      };
    case actions.BLOCK_TIME_SUCCESS:
      return {
        ...state,
        blockingTime: false,
      };
    case actions.BLOCK_TIME_ERROR:
      return {
        ...state,
        blockingTime: null,
      };
    case actions.PATIENT_CONFIRMED_APPOINTMENT_ERROR:
      return {
        ...state,
        confirmingPatientRequest: null,
      };
    case actions.REJECTING_REQUEST:
      return {
        ...state,
        rejectingRequest: action.payload,
      };
    // case actions.PATIENT_INFO_FETCH_SUCCESS:
    //   return {
    //     ...state,
    //     newPatients: { ...state.newPatients, ...action.payload },
    //   };
    case actions.SETTING_NEW_APPOINTMENT:
      return {
        ...state,
        settingNewAppointment: true,
      };
    case actions.MANUALLY_SET_NEW_APPOINTMENT_SUCCESS:
      return {
        ...state,
        settingNewAppointment: false,
        initialMessageError: false,
        scheduledEvent: action.payload,
      };
    case actions.MANUALLY_SET_NEW_APPOINTMENT_FAIL:
      return {
        ...state,
        settingNewAppointment: null,
      };
    case actions.EDIT_WHOLE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        updatingWholeAppointment: false,
        initialMessageError: false,
      };
    case actions.EDIT_WHOLE_APPOINTMENT_ERROR:
      return {
        ...state,
        updatingWholeAppointment: null,
      };
    case actions.START_EDITING_APPOINTMENT_REQUEST:
      return {
        ...state,
        updatingWholeAppointment: true,
      };
    case actions.FAILED_TO_SEND_INITIAL_MESSAGE_NOTIFICATION:
      return {
        ...state,
        initialMessageError: true,
      };
    case actions.BUILD_FREE_SCHEDULE_SUCCESS:
      return {
        ...state,
        firstDay: action.payload.firstDay,
        freeSchedule: action.payload.freeSchedule,
        finalSchedule: action.payload.finalSchedule,
        onlyBusySchedule: action.payload.onlyBusySchedule,
        modesAvailable: action.payload.modesAvailable,
        gapSchedule: action.payload.gapSchedule,
      };
    case actions.START_CLEARING_APPOINTMENT_STATUS:
      return {
        ...state,
        startedClearing: action.payload,
      };
    case actions.CLEAR_APPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
        startedClearing: false,
      };
    case actions.CLEAR_APPOINTMENT_STATUS_ERROR:
      return {
        ...state,
        startedClearing: null,
      };
    case actions.UPDATE_APPOINTMENT_VALUE_SUCCESS:
      return {
        ...state,
        updatingAppointmentValue: false,
      };
    case actions.START_UPDATING_APPOINTMENT_VALUE:
      return {
        ...state,
        updatingAppointmentValue: true,
      };
    case actions.UPDATE_APPOINTMENT_VALUE_ERROR:
      return {
        ...state,
        updatingAppointmentValue: null,
      };
    case actions.START_SET_SURGERY:
      return {
        ...state,
        settingSurgery: action.payload,
      };
    case actions.SET_SURGERY_SUCCESS:
      return {
        ...state,
        settingSurgery: false,
      };
    case actions.SET_SURGERY_ERROR:
      return {
        ...state,
        settingSurgery: null,
      };
    case actions.GET_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload,
        downloadingPatientAppointments: false,
      };
    case actions.GET_PATIENT_APPOINTMENTS_ERROR:
      return {
        ...state,
        patientAppointments: [],
        downloadingPatientAppointments: false,
      };
    case actions.DOWNLOADING_PATIENT_APPOINTMENTS:
      return {
        ...state,
        downloadingPatientAppointments: true,
      };
    case actions.RESET_PATIENT_APPOINTMENTS:
      return {
        ...state,
        patientAppointments: [],
        downloadingPatientAppointments: false,
      };
    case actions.START_FINALIZE_APPOINTMENT_WITH_INVOICE:
      return {
        ...state,
        savingFinalizedAppointments: action.payload,
      };
    case actions.FINALIZE_APPOINTMENT_WITH_INVOICE_SUCCESS:
      return {
        ...state,
        savingFinalizedAppointments: false,
      };
    case actions.FINALIZE_APPOINTMENT_WITH_INVOICE_ERROR:
      return {
        ...state,
        savingFinalizedAppointments: null,
      };
    case actions.DOWNLOADING_FINALIZED_APPOINTMENT:
      return {
        ...state,
        downloadingFinalizedAppointment: true,
      };
    case actions.GET_FINALIZED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        currentFinalizedAppointment: action.payload.finalizedAppointment,
        downloadingFinalizedAppointment: false,
      };
    case actions.CLEAR_FINALIZED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        currentFinalizedAppointment: null,
        downloadingFinalizedAppointment: false,
      };
    case actions.GET_FINALIZED_APPOINTMENT_ERROR:
      return {
        ...state,
        currentFinalizedAppointment: null,
        downloadingFinalizedAppointment: null,
      };
    case actions.SET_MESSAGES_CENTER_NUMBER:
      return {
        ...state,
        messageCenter: action.payload,
      };
    case actions.GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS:
      return {
        ...state,
        newEntranceModalFullWidth: action.payload,
      };
    case actions.SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS:
      return {
        ...state,
        newEntranceModalFullWidth: action.payload,
      };
    case actions.SET_NEW_AGENDA_LISTENER:
      return {
        ...state,
        currentAgendaStartDateListener: action.payload.start,
      };
    case actions.SET_MODAL_CONFIRMATION_MESSAGE:
      return {
        ...state,
        modalConfirmationMessage: action.payload,
      };
    case actions.CHANGE_CALENDAR_COLOR:
      return {
        ...state,
        agendaColor: action.payload,
      };
    case actions.START_SETTING_SELECTED_LABEL:
      return {
        ...state,
        settingSelectedLabel: action.payload,
      };
    case actions.SET_SELECTED_LABEL_SUCCESS:
      return {
        ...state,
        settingSelectedLabel: false,
      };
    case actions.SET_SELECTED_LABEL_ERROR:
      return {
        ...state,
        settingSelectedLabel: null,
      };
    case actions.SAVING_AGENDA_LABEL:
      return {
        ...state,
        savingAgendaLabel: action.payload,
      };
    case actions.CHANGE_BATCHED_START_END_DATES:
      return {
        ...state,
        batchedStartEndDates: [action.payload.start, action.payload.end],
      };
    case actions.SET_FULL_BATCHED_APPOINTMENTS_ARR:
      return {
        ...state,
        fullBatchedAppointmentsArr: action.payload.arr,
      };
    case actions.RESET_BATCHED_APPOINTMENTS:
      return {
        ...state,
        batchedAppointments: [],
        fullBatchedAppointmentsArr: [],
        persistedBatchedAppointments: [],
      };
    case actions.SET_BATCHED_APPOINTMENTS:
      return {
        ...state,
        batchedAppointments: action.payload.arr,
      };
    case actions.SET_PERSISTED_BATCHED_APPOINTMENTS:
      return {
        ...state,
        persistedBatchedAppointments: action.payload.obj,
      };
    case actions.START_PERSISTING_BATCHED_APPOINTMENT:
      return {
        ...state,
        persistingBatchedAppointment: true,
      };
    case actions.PERSIST_BATCHED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        persistingBatchedAppointment: false,
      };
    case actions.PERSIST_BATCHED_APPOINTMENT_ERROR:
      return {
        ...state,
        persistingBatchedAppointment: null,
      };
    case actions.CONTINUE_EDIT_BATCHED_APPOINTMENT:
      return {
        ...state,
        editedBatchedAppointmentMessage: action.payload,
      };
    default:
      return state;
  }
}

const actions = {
  APPOINTMENTS_INFO_REQUEST: 'APPOINTMENTS_INFO_REQUEST',
  APPOINTMENTS_INFO_ERROR: 'APPOINTMENTS_INFO_ERROR',
  APPOINTMENTS_INFO_SUCCED: 'APPOINTMENTS_INFO_SUCCED',
  APPOINTMENTS_INFO_WAITING: 'APPOINTMENTS_INFO_WAITING',
  RESET_REPORT_APPOINTMENTS: 'RESET_REPORT_APPOINTMENTS',
  START_PDF_WEB_WORKER_REQUEST: 'START_PDF_WEB_WORKER_REQUEST',
  START_PDF_WEB_WORKER_SUCCESS: 'START_PDF_WEB_WORKER_SUCCESS',
  START_PDF_WEB_WORKER: 'START_PDF_WEB_WORKER',
  RENDERING_PDF_WITH_WEB_WORKER: 'RENDERING_PDF_WITH_WEB_WORKER',
  STOP_PDF_WEB_WORKER: 'STOP_PDF_WEB_WORKER',
  requestAppointmentsInfoByDate: (date, endDate) => ({
    type: actions.APPOINTMENTS_INFO_REQUEST,
    payload: {
      date,
      endDate,
    },
  }),
  resetReportAppointments: () => ({
    type: actions.RESET_REPORT_APPOINTMENTS,
  }),
  startPdfWebWorker: (params) => ({
    type: actions.START_PDF_WEB_WORKER_REQUEST,
    payload: {
      params,
    },
  }),
  stopPdfWebWorker: () => ({
    type: actions.STOP_PDF_WEB_WORKER,
  }),
};

export default actions;

import _ from 'lodash';
import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Input, Button } from 'antd';
import { connect } from 'react-redux';
import authAction from '../../redux/auth/actions';
import Firebase from '../../helpers/firebase';
import notification from '../notification';
import './style.css';

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function ReloginModal({
  user,
  login,
  logout,
  error,
  requestRelogin,
  setRequestLogin,
}) {
  const [password, setPassword] = useState('');
  const [iconLoading, setIconLoading] = useState(false);

  const prevError = usePrevious(error);
  useEffect(() => {
    if (!_.isEmpty(error)
    && requestRelogin
    && prevError !== error) {
      notification('error', Firebase.displayErrorMessage(error));
      setRequestLogin(false);
    }
  }, [error, prevError, requestRelogin, setRequestLogin]);

  useEffect(() => {
    setIconLoading(false);
  }, [requestRelogin]);

  const changePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleModalOk = () => {
    if (password.length === 0) {
      notification('error', 'Digite sua senha.');
    } else {
      login(user.email, password);
      setIconLoading(true);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      open={requestRelogin}
      closable={false}
      // onOk={this.handleOk}
      // onCancel={this.handleCancel}
      zIndex={1501}
      styles={{
        body: {
          padding: '32px 32px 24px',
        },
      }}
    >
      <div className="relogin-modal-modal-body-wrapper">
        <div className="relogin-modal-modal-body">
          <InfoCircleOutlined />
          <span className="relogin-modal-modal-title">
            Sua sessão expirou!
          </span>
          <div className="relogin-modal-modal-content">
            <div>
              <ul
                style={{
                  listStyle: 'disc',
                  margin: '10px 0 0 0',
                  padding: 0,
                  fontWeight: 300,
                }}
              >
                Insira novamente sua senha para refazer o
                <i style={{ fontWeight: '500' }}>&nbsp;login</i>
                <li style={{ marginLeft: '16px', marginBottom: '5px', fontWeight: 500 }}>
                  {user.email}
                </li>
                <li style={{ marginLeft: '16px' }}>
                  <Input
                    // size="large"
                    placeholder="Senha"
                    type="password"
                    value={password}
                    onChange={changePassword}
                    style={{
                      minWidth: '150px',
                      width: 'auto',
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="relogin-modal-modal-btns">
            <Button
              onClick={logout}
              type="primary"
              disabled={iconLoading}
              style={{
                backgroundColor: '#f5222d',
                borderColor: '#f5222d',
                marginRight: '8px',
              }}
            >
              Sair
            </Button>
            <Button
              onClick={handleModalOk}
              type="primary"
              loading={iconLoading}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default connect(
  (state) => ({
    isLoggedIn: state.Auth.user,
    isProfessional: state.Auth.professional,
    user: state.Auth.user,
    error: state.Auth.error,
    requestRelogin: state.Auth.requestRelogin,
    reloadApp: state.App.reloadApp,
  }),
  authAction,
)(ReloginModal);

const actions = {
  CUSTOM_USERS_FETCH_REQUEST: 'CUSTOM_USERS_FETCH_REQUEST',
  CUSTOM_USERS_FETCH_SUCCESS: 'CUSTOM_USERS_FETCH_SUCCESS',
  CUSTOM_USERS_FETCH_ERROR: 'CUSTOM_USERS_FETCH_ERROR',
  FETCHING_CUSTOM_USERS: 'FETCHING_CUSTOM_USERS',
  SAVE_CUSTOM_USER_REQUEST: 'SAVE_CUSTOM_USER_REQUEST',
  SAVE_CUSTOM_USER_SUCCESS: 'SAVE_CUSTOM_USER_SUCCESS',
  SAVE_CUSTOM_USER_ERROR: 'SAVE_CUSTOM_USER_ERROR',
  CREATE_CUSTOM_USER_REQUEST: 'CREATE_CUSTOM_USER_REQUEST',
  CREATE_CUSTOM_USER_SUCCESS: 'CREATE_CUSTOM_USER_SUCCESS',
  CREATE_CUSTOM_USER_ERROR: 'CREATE_CUSTOM_USER_ERROR',
  CREATE_CUSTOM_USER_ERROR_WITH_MESSAGE: 'CREATE_CUSTOM_USER_ERROR_WITH_MESSAGE',
  CUSTOM_USERS_FETCH_OR_UPDATING_WAITING: 'CUSTOM_USERS_FETCH_OR_UPDATING_WAITING',
  REMOVE_CUSTOM_USER_REQUEST: 'REMOVE_CUSTOM_USER_REQUEST',
  REMOVE_CUSTOM_USER_SUCCESS: 'REMOVE_CUSTOM_USER_SUCCESS',
  REMOVE_CUSTOM_USER_ERROR: 'REMOVE_CUSTOM_USER_ERROR',
  REMOVING_CUSTOM_USERS: 'REMOVING_CUSTOM_USERS',
  REMOVE_CUSTOM_USER_ERROR_WITH_MESSAGE: 'REMOVE_CUSTOM_USER_ERROR_WITH_MESSAGE',
  COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_REQUEST: 'COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_REQUEST',
  COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_SUCCESS: 'COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_SUCCESS',
  COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_ERROR: 'COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_ERROR',
  EMAIL_SIGNIN_REQUEST: 'EMAIL_SIGNIN_REQUEST',
  EMAIL_SIGNIN_SUCCESS: 'EMAIL_SIGNIN_SUCCESS',
  EMAIL_SIGNIN_ERROR: 'EMAIL_SIGNIN_ERROR',
  getCustomUsers: () => ({
    type: actions.CUSTOM_USERS_FETCH_REQUEST,
  }),
  saveCustomUser: (user, key) => ({
    type: actions.SAVE_CUSTOM_USER_REQUEST,
    payload: {
      user,
      key,
    },
  }),
  createCustomUser: (user) => ({
    type: actions.CREATE_CUSTOM_USER_REQUEST,
    payload: user,
  }),
  removeCustomUser: (value) => ({
    type: actions.REMOVE_CUSTOM_USER_REQUEST,
    payload: value,
  }),
  emailSigninRequest: (email, link) => ({
    type: actions.EMAIL_SIGNIN_REQUEST,
    payload: {
      email,
      link,
    },
  }),
  completeEmailLinkSignin: (email, password, link) => ({
    type: actions.COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_REQUEST,
    payload: {
      email,
      password,
      link,
    },
  }),
};

export default actions;

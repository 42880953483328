const medicinesActions = {
  FETCH_MEDICINES_REQUEST: 'FETCH_MEDICINES_REQUEST',
  FETCH_MEDICINES_SUCCESS: 'FETCH_MEDICINES_SUCCESS',
  FETCH_MEDICINES_ERROR: 'FETCH_MEDICINES_ERROR',
  MEDICINE_MODELS_FETCH_REQUEST: 'MEDICINE_MODELS_FETCH_REQUEST',
  MEDICINE_MODELS_FETCH_SUCCESS: 'MEDICINE_MODELS_FETCH_SUCCESS',
  MEDICINE_MODELS_FETCH_ERROR: 'MEDICINE_MODELS_FETCH_ERROR',
  SAVE_MEDICINE_MODEL_REQUEST: 'SAVE_MEDICINE_MODEL_REQUEST',
  START_SAVING_MEDICINE_MODEL: 'START_SAVING_MEDICINE_MODEL',
  SAVE_MEDICINE_MODEL_ERROR: 'SAVE_MEDICINE_MODEL_ERROR',
  REMOVE_MEDICINE_MODEL_REQUEST: 'REMOVE_MEDICINE_MODEL_REQUEST',
  START_REMOVING_MEDICINE_MODEL: 'START_REMOVING_MEDICINE_MODEL',
  REMOVE_MEDICINE_MODEL_ERROR: 'REMOVE_MEDICINE_MODEL_ERROR',
  UPDATE_MEDICINE_MODEL_REQUEST: 'UPDATE_MEDICINE_MODEL_REQUEST',
  fetchMedicines: (value, medKey) => ({
    type: medicinesActions.FETCH_MEDICINES_REQUEST,
    payload: {
      value,
      medKey,
    },
  }),
  getMedicineModels: () => ({
    type: medicinesActions.MEDICINE_MODELS_FETCH_REQUEST,
  }),
  saveMedicineModel: (model) => ({
    type: medicinesActions.SAVE_MEDICINE_MODEL_REQUEST,
    payload: {
      model,
    },
  }),
  removeMedicineModel: (id) => ({
    type: medicinesActions.REMOVE_MEDICINE_MODEL_REQUEST,
    payload: {
      id,
    },
  }),
  updateMedicineModel: (model, id) => ({
    type: medicinesActions.UPDATE_MEDICINE_MODEL_REQUEST,
    payload: {
      model,
      id,
    },
  }),
};
export default medicinesActions;

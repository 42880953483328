const actions = {
  PERSIST_NEW_RECORD: 'PERSIST_NEW_RECORD',
  PERSIST_NEW_PRESCRIPTION: 'PERSIST_NEW_PRESCRIPTION',
  PERSIST_NEW_PRESCRIPTION_GENERAL_OBS: 'PERSIST_NEW_PRESCRIPTION_GENERAL_OBS',
  PERSIST_NEW_CERTIFICATE: 'PERSIST_NEW_CERTIFICATE',
  PERSIST_NEW_MEDICAL_REPORT: 'PERSIST_NEW_MEDICAL_REPORT',
  PERSIST_NEW_OTHER_DOCUMENT: 'PERSIST_NEW_OTHER_DOCUMENT',
  DISCARD_PERSISTED_ENTRANCE: 'DISCARD_PERSISTED_ENTRANCE',
  CHECK_REDUX_PERSIST_DATA: 'CHECK_REDUX_PERSIST_DATA',
  checkReduxPersistData: () => ({
    type: actions.CHECK_REDUX_PERSIST_DATA,
  }),
};
export default actions;

import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  updatingCertificateModel: false,
  removingCertificateModel: false,
  certificates: {},
  newCertificate: {},
  newUnformattedCertificate: {},
  updatingMedicalReportModel: false,
  removingMedicalReportModel: false,
  medicalReports: {},
  newMedicalReport: {},
  newUnformattedMedicalReport: {},
  otherDocuments: {},
  newOtherDocument: {},
  newUnformattedOtherDocument: {},
  newCertificateTabMode: 'certificate',
  certificateListScreenMode: 'certificate',
  lastCertificate: {},
  noNextCertificatePage: {},
  lastMedicalReport: {},
  noNextMedicalReportPage: {},
  lastOtherDocument: {},
  noNextOtherDocumentPage: {},
};

export default function certificatesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SET_NEW_CERTIFICATE:
      return {
        ...state,
        newCertificate: _.cloneDeep(action.payload.newCertificate),
      };
    case actions.SET_NEW_MEDICAL_REPORT:
      return {
        ...state,
        newMedicalReport: _.cloneDeep(action.payload.newMedicalReport),
      };
    case actions.SET_NEW_OTHER_DOCUMENT:
      return {
        ...state,
        newOtherDocument: _.cloneDeep(action.payload.newOtherDocument),
      };
    case actions.DISCARD_NEW_CERTIFICATE_CHANGES: {
      const newCertificate = _.cloneDeep(state.newCertificate);
      delete newCertificate[action.payload.id];
      const newMedicalReport = _.cloneDeep(state.newMedicalReport);
      delete newMedicalReport[action.payload.id];
      const newOtherDocument = _.cloneDeep(state.newOtherDocument);
      delete newOtherDocument[action.payload.id];
      return {
        ...state,
        // newCertificate: {},
        discardedNewCertificate: true,
        // newCertificate: {
        //   ...state.newCertificate,
        //   [action.payload.id]: {
        //     text: { ops: [] },
        //   },
        // },
        newCertificate,
        newUnformattedCertificate: {
          ...state.newUnformattedCertificate,
          [action.payload.id]: {
            text: { ops: [] },
          },
        },
        // newMedicalReport: {
        //   ...state.newMedicalReport,
        //   [action.payload.id]: {
        //     text: { ops: [] },
        //   },
        // },
        newMedicalReport,
        newUnformattedMedicalReport: {
          ...state.newUnformattedMedicalReport,
          [action.payload.id]: {
            text: { ops: [] },
          },
        },
        // newOtherDocument: {
        //   ...state.newOtherDocument,
        //   [action.payload.id]: {
        //     text: { ops: [] },
        //   },
        // },
        newOtherDocument,
        newUnformattedOtherDocument: {
          ...state.newUnformattedOtherDocument,
          [action.payload.id]: {
            text: { ops: [] },
          },
        },
      };
    }
    case actions.GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        updatingCertificates: false,
        savingHideCertificate: false,
        certificates: {
          ...state.certificates,
          [action.payload.id]: action.payload.certificates,
        },
        lastCertificate: {
          ...state.lastCertificate,
          [action.payload.id]: action.payload.lastCertificate,
        },
        noNextCertificatePage: {
          ...state.noNextCertificatePage,
          [action.payload.id]: action.payload.noNextCertificatePage,
        },
      };
    case actions.CERTIFICATES_SECTION_START_UPDATE:
      return {
        ...state,
        updatingCertificates: action.payload.mode,
      };
    case actions.SAVE_CERTIFICATE_ERROR:
      return {
        ...state,
        updatingCertificates: null,
      };
    case actions.CHANGE_CERTIFICATE:
      return {
        ...state,
        discardedNewCertificate: false,
        newCertificate: {
          ...state.newCertificate,
          [action.payload.id]: action.payload.certificate,
        },
      };
    case actions.CHANGE_UNFORMATTED_CERTIFICATE:
      return {
        ...state,
        // discardedNewCertificate: false,
        newUnformattedCertificate: {
          ...state.newCertificate,
          [action.payload.id]: action.payload.certificate,
        },
      };
    case actions.CERTIFICATE_MODELS_FETCH_SUCCESS:
      return {
        ...state,
        certificateModels: action.payload.models,
        updatingCertificateModel: false,
        removingCertificateModel: false,
      };
    case actions.SAVE_CERTIFICATE_MODEL_ERROR:
      return {
        ...state,
        updatingCertificateModel: null,
      };
    case actions.REMOVE_CERTIFICATE_MODEL_ERROR:
      return {
        ...state,
        removingCertificateModel: null,
      };
    case actions.START_SAVING_CERTIFICATE_MODEL:
      return {
        ...state,
        updatingCertificateModel: true,
      };
    case actions.START_REMOVING_CERTIFICATE_MODEL:
      return {
        ...state,
        removingCertificateModel: action.payload,
      };
    case actions.MEDICAL_REPORT_MODELS_FETCH_SUCCESS:
      return {
        ...state,
        medicalReportModels: action.payload.models,
        updatingCertificateModel: false,
        removingCertificateModel: false,
      };
    case actions.GET_MEDICAL_REPORTS_SUCCESS:
      return {
        ...state,
        updatingCertificates: false,
        savingHideMedicalReport: false,
        medicalReports: {
          ...state.medicalReports,
          [action.payload.id]: action.payload.medicalReports,
        },
        lastMedicalReport: {
          ...state.lastMedicalReport,
          [action.payload.id]: action.payload.lastMedicalReport,
        },
        noNextMedicalReportPage: {
          ...state.noNextMedicalReportPage,
          [action.payload.id]: action.payload.noNextMedicalReportPage,
        },
      };
    case actions.CHANGE_MEDICAL_REPORT:
      return {
        ...state,
        discardednewMedicalReport: false,
        newMedicalReport: {
          ...state.newMedicalReport,
          [action.payload.id]: action.payload.medicalReport,
        },
      };
    case actions.CHANGE_UNFORMATTED_MEDICAL_REPORT:
      return {
        ...state,
        // discardednewMedicalReport: false,
        newUnformattedMedicalReport: {
          ...state.newUnformattedMedicalReport,
          [action.payload.id]: action.payload.medicalReport,
        },
      };
    case actions.OTHER_DOCUMENT_MODELS_FETCH_SUCCESS:
      return {
        ...state,
        otherDocumentModels: action.payload.models,
        updatingCertificateModel: false,
        removingCertificateModel: false,
      };
    case actions.GET_OTHER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        updatingCertificates: false,
        savingHideOtherDocument: false,
        otherDocuments: {
          ...state.otherDocuments,
          [action.payload.id]: action.payload.otherDocuments,
        },
        lastOtherDocument: {
          ...state.lastOtherDocument,
          [action.payload.id]: action.payload.lastOtherDocument,
        },
        noNextOtherDocumentPage: {
          ...state.noNextOtherDocumentPage,
          [action.payload.id]: action.payload.noNextOtherDocumentPage,
        },
      };
    case actions.CHANGE_OTHER_DOCUMENT:
      return {
        ...state,
        discardednewMedicalReport: false,
        newOtherDocument: {
          ...state.newOtherDocument,
          [action.payload.id]: action.payload.otherDocument,
        },
      };
    case actions.CHANGE_UNFORMATTED_OTHER_DOCUMENT:
      return {
        ...state,
        // discardednewMedicalReport: false,
        newUnformattedOtherDocument: {
          ...state.newUnformattedOtherDocument,
          [action.payload.id]: action.payload.otherDocument,
        },
      };
    case actions.CHANGE_NEW_CERTIFICATE_TAB_MODE:
      return {
        ...state,
        newCertificateTabMode: action.payload,
      };
    case actions.CHANGE_CERTIFICATE_LIST_SCREEN_MODE:
      return {
        ...state,
        certificateListScreenMode: action.payload,
      };
    case actions.GET_SHOW_HIDDEN_CERTIFICATES_STATUS_SUCCESS:
      return {
        ...state,
        showHiddenCertificates: action.value,
        savingHideCertificate: false,
      };
    case actions.GET_SHOW_HIDDEN_MEDICAL_REPORTS_STATUS_SUCCESS:
      return {
        ...state,
        showHiddenMedicalReports: action.value,
        savingHideMedicalReport: false,
      };
    case actions.GET_SHOW_HIDDEN_OTHER_DOCUMENTS_STATUS_SUCCESS:
      return {
        ...state,
        showHiddenOtherDocuments: action.value,
        savingHideOtherDocument: false,
      };
    case actions.CHANGING_HIDE_CERTIFICATE:
      return {
        ...state,
        savingHideCertificate: action.payload,
      };
    case actions.CHANGING_HIDE_MEDICAL_REPORT:
      return {
        ...state,
        savingHideMedicalReport: action.payload,
      };
    case actions.CHANGING_HIDE_OTHER_DOCUMENT:
      return {
        ...state,
        savingHideOtherDocument: action.payload,
      };
    case actions.CHANGE_HIDE_CERTIFICATE_ERROR:
      return {
        ...state,
        savingHideCertificate: null,
      };
    case actions.CHANGE_HIDE_MEDICAL_REPORT_ERROR:
      return {
        ...state,
        savingHideMedicalReport: null,
      };
    case actions.CHANGE_HIDE_OTHER_DOCUMENT_ERROR:
      return {
        ...state,
        savingHideOtherDocument: null,
      };
    default:
      return state;
  }
}

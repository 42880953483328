const actions = {
  GET_INVENTORY_SETTINGS_REQUEST: 'GET_INVENTORY_SETTINGS_REQUEST',
  GET_INVENTORY_SETTINGS_SUCCESS: 'GET_INVENTORY_SETTINGS_SUCCESS',
  GET_INVENTORY_SETTINGS_ERROR: 'GET_INVENTORY_SETTINGS_ERROR',
  CHANGE_INVENTORY_INVOICE_AUTOMATION_REQUEST: 'CHANGE_INVENTORY_INVOICE_AUTOMATION_REQUEST',
  CHANGE_INVENTORY_INVOICE_AUTOMATION_ERROR: 'CHANGE_INVENTORY_INVOICE_AUTOMATION_ERROR',
  CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST: 'CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST',
  CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_ERROR: 'CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_ERROR',
  INVENTORY_ITEMS_LISTENER_REQUEST: 'INVENTORY_ITEMS_LISTENER_REQUEST',
  INVENTORY_ITEMS_FETCH_SUCCESS: 'INVENTORY_ITEMS_FETCH_SUCCESS',
  RESET_INVENTORY_LISTENER: 'RESET_INVENTORY_LISTENER',
  INVENTORY_FETCH_REQUEST: 'INVENTORY_FETCH_REQUEST',
  INVENTORY_FETCH_ERROR: 'INVENTORY_FETCH_ERROR',
  INVENTORY_OPS_FETCH_REQUEST: 'INVENTORY_OPS_FETCH_REQUEST',
  INVENTORY_OPS_FETCH_SUCCESS: 'INVENTORY_OPS_FETCH_SUCCESS',
  INVENTORY_OPS_FETCH_ERROR: 'INVENTORY_OPS_FETCH_ERROR',
  RESET_INVENTORY_OPS: 'RESET_INVENTORY_OPS',
  FETCHING_INVENTORY_OPS_LIST: 'FETCHING_INVENTORY_OPS_LIST',
  INVENTORY_PROVIDERS_FETCH_REQUEST: 'INVENTORY_PROVIDERS_FETCH_REQUEST',
  INVENTORY_PROVIDERS_FETCH_SUCCESS: 'INVENTORY_PROVIDERS_FETCH_SUCCESS',
  INVENTORY_CLASSES_FETCH_REQUEST: 'INVENTORY_CLASSES_FETCH_REQUEST',
  INVENTORY_CLASSES_FETCH_SUCCESS: 'INVENTORY_CLASSES_FETCH_SUCCESS',
  CREATE_INVENTORY_ITEM_REQUEST: 'CREATE_INVENTORY_ITEM_REQUEST',
  CREATE_INVENTORY_ITEM_SUCCESS: 'CREATE_INVENTORY_ITEM_SUCCESS',
  CREATE_INVENTORY_ITEM_ERROR: 'CREATE_INVENTORY_ITEM_ERROR',
  INVENTORY_FETCH_OR_UPDATING_WAITING: 'INVENTORY_FETCH_OR_UPDATING_WAITING',
  REMOVE_INVENTORY_ITEM_REQUEST: 'REMOVE_INVENTORY_ITEM_REQUEST',
  REMOVE_INVENTORY_ITEM_SUCCESS: 'REMOVE_INVENTORY_ITEM_SUCCESS',
  REMOVE_INVENTORY_ITEM_ERROR: 'REMOVE_INVENTORY_ITEM_ERROR',
  DEPOSIT_INVENTORY_ITEM_REQUEST: 'DEPOSIT_INVENTORY_ITEM_REQUEST',
  DEPOSIT_INVENTORY_ITEM_SUCCESS: 'DEPOSIT_INVENTORY_ITEM_SUCCESS',
  DEPOSIT_INVENTORY_ITEM_ERROR: 'DEPOSIT_INVENTORY_ITEM_ERROR',
  CREATE_INVENTORY_PROVIDER_REQUEST: 'CREATE_INVENTORY_PROVIDER_REQUEST',
  CREATE_INVENTORY_PROVIDER_SUCCESS: 'CREATE_INVENTORY_PROVIDER_SUCCESS',
  CREATE_INVENTORY_PROVIDER_ERROR: 'CREATE_INVENTORY_PROVIDER_ERROR',
  REMOVE_INVENTORY_PROVIDER_REQUEST: 'REMOVE_INVENTORY_PROVIDER_REQUEST',
  REMOVE_INVENTORY_PROVIDER_SUCCESS: 'REMOVE_INVENTORY_PROVIDER_SUCCESS',
  REMOVE_INVENTORY_PROVIDER_ERROR: 'REMOVE_INVENTORY_PROVIDER_REQUEST',
  INVENTORY_REMOVE_PROVIDER_WAITING: 'INVENTORY_REMOVE_PROVIDER_WAITING',
  WITHDRAW_INVENTORY_ITEM_REQUEST: 'WITHDRAW_INVENTORY_ITEM_REQUEST',
  WITHDRAW_INVENTORY_ITEM_SUCCESS: 'WITHDRAW_INVENTORY_ITEM_SUCCESS',
  WITHDRAW_INVENTORY_ITEM_ERROR: 'WITHDRAW_INVENTORY_ITEM_ERROR',
  UPDATE_INVENTORY_DEPOSIT_REQUEST: 'UPDATE_INVENTORY_DEPOSIT_REQUEST',
  UPDATE_INVENTORY_DEPOSIT_SUCCESS: 'UPDATE_INVENTORY_DEPOSIT_SUCCESS',
  UPDATE_INVENTORY_DEPOSIT_ERROR: 'UPDATE_INVENTORY_DEPOSIT_ERROR',
  CANCEL_OPERATION_REQUEST: 'CANCEL_OPERATION_REQUEST',
  CANCEL_OPERATION_SUCCESS: 'CANCEL_OPERATION_SUCCESS',
  CANCEL_OPERATION_ERROR: 'CANCEL_OPERATION_ERROR',
  CANCEL_OPERATION_WAITING: 'CANCEL_OPERATION_WAITING',
  UPDATE_INVENTORY_WITHDRAW_REQUEST: 'UPDATE_INVENTORY_WITHDRAW_REQUEST',
  UPDATE_INVENTORY_WITHDRAW_SUCCESS: 'UPDATE_INVENTORY_WITHDRAW_SUCCESS',
  UPDATE_INVENTORY_WITHDRAW_ERROR: 'UPDATE_INVENTORY_WITHDRAW_ERROR',
  CREATE_INVENTORY_CLASS_REQUEST: 'CREATE_INVENTORY_CLASS_REQUEST',
  CREATE_INVENTORY_CLASS_SUCCESS: 'CREATE_INVENTORY_CLASS_SUCCESS',
  CREATE_INVENTORY_CLASS_ERROR: 'CREATE_INVENTORY_CLASS_ERROR',
  REMOVE_INVENTORY_CLASS_REQUEST: 'REMOVE_INVENTORY_CLASS_REQUEST',
  REMOVE_INVENTORY_CLASS_SUCCESS: 'REMOVE_INVENTORY_CLASS_SUCCESS',
  REMOVE_INVENTORY_CLASS_ERROR: 'REMOVE_INVENTORY_CLASS_ERROR',
  UPDATE_INVENTORY_ITEM_REQUEST: 'UPDATE_INVENTORY_ITEM_REQUEST',
  UPDATE_INVENTORY_ITEM_SUCCESS: 'UPDATE_INVENTORY_ITEM_SUCCESS',
  UPDATE_INVENTORY_ITEM_ERROR: 'UPDATE_INVENTORY_ITEM_ERROR',
  UPDATE_INVENTORY_PROCEDURES_ARR_REQUEST: 'UPDATE_INVENTORY_PROCEDURES_ARR_REQUEST',
  CHANGE_INVENTORY_OPS_LISTENER_TIMER: 'CHANGE_INVENTORY_OPS_LISTENER_TIMER',
  getInventorySettings: () => ({
    type: actions.GET_INVENTORY_SETTINGS_REQUEST,
  }),
  changeInvoiceAutomation: (value) => ({
    type: actions.CHANGE_INVENTORY_INVOICE_AUTOMATION_REQUEST,
    payload: value,
  }),
  changeShowFinalizedAppointmentModal: (value) => ({
    type: actions.CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST,
    payload: value,
  }),
  getInventoryLitener: () => ({
    type: actions.INVENTORY_ITEMS_LISTENER_REQUEST,
  }),
  getInventory: () => ({
    type: actions.INVENTORY_FETCH_REQUEST,
  }),
  createInventoryItem: (item) => ({
    type: actions.CREATE_INVENTORY_ITEM_REQUEST,
    payload: item,
  }),
  removeInventoryItem: (key) => ({
    type: actions.REMOVE_INVENTORY_ITEM_REQUEST,
    payload: key,
  }),
  depositInventoryItem: (values) => ({
    type: actions.DEPOSIT_INVENTORY_ITEM_REQUEST,
    payload: values,
  }),
  createInventoryProvider: (item) => ({
    type: actions.CREATE_INVENTORY_PROVIDER_REQUEST,
    payload: item,
  }),
  removeInventoryProvider: (id) => ({
    type: actions.REMOVE_INVENTORY_PROVIDER_REQUEST,
    payload: id,
  }),
  withdrawInventoryItem: (values) => ({
    type: actions.WITHDRAW_INVENTORY_ITEM_REQUEST,
    payload: values,
  }),
  updateInventoryDeposit: (values) => ({
    type: actions.UPDATE_INVENTORY_DEPOSIT_REQUEST,
    payload: values,
  }),
  cancelOperationRow: (id) => ({
    type: actions.CANCEL_OPERATION_REQUEST,
    payload: id,
  }),
  getInventoryOps: () => ({
    type: actions.INVENTORY_OPS_FETCH_REQUEST,
  }),
  reloadInventoryProviders: () => ({
    type: actions.INVENTORY_PROVIDERS_FETCH_REQUEST,
  }),
  updateInventoryWithdraw: (values) => ({
    type: actions.UPDATE_INVENTORY_WITHDRAW_REQUEST,
    payload: values,
  }),
  createInventoryClass: (item) => ({
    type: actions.CREATE_INVENTORY_CLASS_REQUEST,
    payload: item,
  }),
  removeInventoryClass: (item) => ({
    type: actions.REMOVE_INVENTORY_CLASS_REQUEST,
    payload: item,
  }),
  updateInventoryItem: (item) => ({
    type: actions.UPDATE_INVENTORY_ITEM_REQUEST,
    payload: item,
  }),
  updateInventoryProceduresArr: (values, finalizeAppointment) => ({
    type: actions.UPDATE_INVENTORY_PROCEDURES_ARR_REQUEST,
    payload: {
      values,
      finalizeAppointment,
    },
  }),
  changeInventoryOpsListenerTime: (value, customValue = null) => ({
    type: actions.CHANGE_INVENTORY_OPS_LISTENER_TIMER,
    payload: {
      value,
      customValue,
    },
  }),
};

export default actions;

import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import moment from 'moment-timezone';
import {
  all,
  select,
  takeLatest,
  put,
  call,
  take,
  fork,
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import profileActions from '../profile/actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getProfileFromStore = (state) => state.Profile.profile;

const getProfessionalProfileFromStore = (state) => state.Profiles.profile;

function getHoursFromCloudFunction({
  uid,
  addressUid,
  agendaId,
  date,
}, { customHealthProfessional, mainUser }) {
  return axios.post(
    `${ROOT_URL}/buildFreeSchedule`,
    {
      uid,
      addressUid,
      // agendaId: 'mNoP59ry4eyzJz9spn5H', // agendaId
      // agendaId: 'G35LfFifeVkmWILtQd8q',
      agendaId,
      date,
      customHealthProfessional,
      mainUser,
    },
  );
}

function getProfileFromCloudFunction({ uid }) {
  return axios.post(`${ROOT_URL}/getProfessionalProfile`, { uid });
}

export function* searchHourRequest() {
  yield takeLatest(actions.SEARCH_HOUR_FETCH_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SEARCH_HOUR_BY_MAX_DATE });
      let professionalProfile = yield select(getProfessionalProfileFromStore);
      if (!professionalProfile.firstName) {
        yield take(actions.PROFILE_DATA_SUCCESS);
        professionalProfile = yield select(getProfessionalProfileFromStore);
      }
      const { data } = yield call(getHoursFromCloudFunction, action.payload, professionalProfile);
      yield put({
        type: actions.SEARCH_HOUR_FETCH_SUCCESS,
        payload: {
          data: _.isEmpty(data)
          || (data
            && _.isEmpty(data.modeAvailable)
            && _.isEmpty(data.schedule)
            && !data.firstDay) ? {
              schedule: {},
              firstDay: null,
              noSchedule: true,
            } : data,
          search: action.payload,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SEARCH_HOUR_FETCH_ERROR,
      });
    }
  });
}

export function* profileSearchRequest() {
  yield takeLatest(actions.PROFILE_DATA_REQUEST, function* (action) {
    try {
      const { data } = yield call(getProfileFromCloudFunction, action.payload);
      yield put({
        type: actions.PROFILE_DATA_SUCCESS,
        payload: {
          data,
        },
      });
    } catch (error) {
      yield put({
        type: actions.PROFILE_DATA_ERROR,
      });
    }
  });
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function setNewAppointmentOnDB(
  {
    uid,
    addressUid,
    agendaId,
    time,
    mode,
    plan,
  },
  idToken,
  timestamp,
  profile,
  { customHealthProfessional, mainUser },
) {
  const auth = getAuth();
  const { currentUser } = auth;
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const {
    firstName,
    lastName,
    birthday,
    phones,
    whatsapp,
    mobile,
    phone,
    phoneArr,
    avatar,
    cpf,
    local,
  } = profile;
  const userInfo = {
    cpf: cpf || '',
    firstName,
    avatar: avatar || '',
    lastName,
    mobile: mobile || '',
    phone: phone || '',
    birthday: birthday || '',
    phoneArr: phoneArr || null,
    whatsapp: whatsapp || '',
    local: local || false,
    phones: phones || null,
  };
  const bodyParameters = {
    uid,
    addressUid,
    agendaId,
    customHealthProfessional,
    mainUser,
    request: {
      professional: uid,
      user: currentUser.uid,
      time: `${time.day} ${time.time}`,
      mode,
      plan,
      timestamp,
      userInfo,
    },
  };
  const analytics = getAnalytics();
  logEvent(analytics, 'request_online_appointment', {
    professional_id: uid,
  });
  return axios.post(
    `${ROOT_URL}/setAppointmentRequest`,
    bodyParameters,
    config,
  );
}

export function* setNewAppointmentRequest() {
  yield takeLatest(actions.SET_NEW_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.REQUESTING_NEW_APPOINTMENT });
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      let professionalProfile = yield select(getProfessionalProfileFromStore);
      if (!professionalProfile.firstName) {
        yield take(actions.PROFILE_DATA_SUCCESS);
        professionalProfile = yield select(getProfessionalProfileFromStore);
      }
      const idToken = yield call(getIdToken);
      const timestamp = moment().tz('America/Sao_Paulo').format();
      const { status } = yield call(
        setNewAppointmentOnDB,
        action.payload,
        idToken,
        timestamp,
        profile,
        professionalProfile,
      );
      if (status === 201) {
        yield all([
          put({ type: actions.SET_NEW_APPOINTMENT_SUCCESS }),
          // put({
          //   type: actions.SEARCH_HOUR_FETCH_REQUEST,
          //   payload: {
          //     uid: action.payload.uid,
          //     addressUid: action.payload.addressUid,
          //     date: timestamp,
          //     firstTime: true,
          //   },
          // }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SET_NEW_APPOINTMENT_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchHourRequest),
    fork(profileSearchRequest),
    fork(setNewAppointmentRequest),
  ]);
}

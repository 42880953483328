const actions = {
  PROFILE_DATA_REQUEST: 'PROFILE_DATA_REQUEST',
  PROFILE_DATA_SUCCESS: 'PROFILE_DATA_SUCCESS',
  PROFILE_DATA_ERROR: 'PROFILE_DATA_ERROR',
  SEARCH_HOUR_FETCH_REQUEST: 'SEARCH_HOUR_FETCH_REQUEST',
  SEARCH_HOUR_FETCH_SUCCESS: 'SEARCH_HOUR_FETCH_SUCCESS',
  SEARCH_HOUR_FETCH_ERROR: 'SEARCH_HOUR_FETCH_ERROR',
  SEARCH_HOUR_BY_MAX_DATE: 'SEARCH_HOUR_BY_MAX_DATE',
  SET_NEW_APPOINTMENT_REQUEST: 'SET_NEW_APPOINTMENT_REQUEST',
  SET_NEW_APPOINTMENT_SUCCESS: 'SET_NEW_APPOINTMENT_SUCCESS',
  SET_NEW_APPOINTMENT_ERROR: 'SET_NEW_APPOINTMENT_ERROR',
  REQUESTING_NEW_APPOINTMENT: 'REQUESTING_NEW_APPOINTMENT',
  RESET_PROFESSIONAL_DATA: 'RESET_PROFESSIONAL_DATA',
  searchHourFetchRequest: (uid, addressUid, agendaId, date) => ({
    type: actions.SEARCH_HOUR_FETCH_REQUEST,
    payload: {
      uid,
      addressUid,
      agendaId,
      date,
    },
  }),
  profileSearchRequest: (uid) => ({
    type: actions.PROFILE_DATA_REQUEST,
    payload: {
      uid,
    },
  }),
  setNewAppointment: (uid, addressUid, agendaId, time, mode, plan = null) => ({
    type: actions.SET_NEW_APPOINTMENT_REQUEST,
    payload: {
      uid,
      addressUid,
      agendaId,
      time,
      mode,
      plan,
    },
  }),
  resetProfessionalProfileData: () => ({
    type: actions.RESET_PROFESSIONAL_DATA,
  }),
};
export default actions;

const certificateActions = {
  GET_ALL_CERTIFICATES_REQUEST: 'GET_ALL_CERTIFICATES_REQUEST',
  GET_CERTIFICATES_REQUEST: 'GET_CERTIFICATES_REQUEST',
  GET_CERTIFICATES_SUCCESS: 'GET_CERTIFICATES_SUCCESS',
  GET_CERTIFICATES_ERROR: 'GET_CERTIFICATES_REQUEST',
  GET_MEDICAL_REPORTS_REQUEST: 'GET_MEDICAL_REPORTS_REQUEST',
  GET_MEDICAL_REPORTS_SUCCESS: 'GET_MEDICAL_REPORTS_SUCCESS',
  GET_MEDICAL_REPORTS_ERROR: 'GET_MEDICAL_REPORTS_REQUEST',
  GET_OTHER_DOCUMENTS_REQUEST: 'GET_OTHER_DOCUMENTS_REQUEST',
  GET_OTHER_DOCUMENTS_SUCCESS: 'GET_OTHER_DOCUMENTS_SUCCESS',
  GET_OTHER_DOCUMENTS_ERROR: 'GET_OTHER_DOCUMENTS_ERROR',
  SAVE_CERTIFICATE_REQUEST: 'SAVE_CERTIFICATE_REQUEST',
  SAVE_CERTIFICATE_ERROR: 'SAVE_CERTIFICATE_ERROR',
  CERTIFICATES_SECTION_START_UPDATE: 'CERTIFICATES_SECTION_START_UPDATE',
  CHANGE_CERTIFICATE: 'CHANGE_CERTIFICATE',
  CHANGE_UNFORMATTED_CERTIFICATE: 'CHANGE_UNFORMATTED_CERTIFICATE',
  DISCARD_NEW_CERTIFICATE_CHANGES: 'DISCARD_NEW_CERTIFICATE_CHANGES',
  GET_ALL_CERTIFICATE_MODELS_REQUEST: 'GET_ALL_CERTIFICATE_MODELS_REQUEST',
  CERTIFICATE_MODELS_FETCH_REQUEST: 'CERTIFICATE_MODELS_FETCH_REQUEST',
  CERTIFICATE_MODELS_FETCH_SUCCESS: 'CERTIFICATE_MODELS_FETCH_SUCCESS',
  CERTIFICATE_MODELS_FETCH_ERROR: 'CERTIFICATE_MODELS_FETCH_ERROR',
  CERTIFICATE_SECTION_START_UPDATE: 'CERTIFICATE_SECTION_START_UPDATE',
  SAVE_CERTIFICATE_MODEL_REQUEST: 'SAVE_CERTIFICATE_MODEL_REQUEST',
  SAVE_CERTIFICATE_MODEL_ERROR: 'SAVE_CERTIFICATE_MODEL_ERROR',
  UPDATE_CERTIFICATE_MODEL_REQUEST: 'UPDATE_CERTIFICATE_MODEL_REQUEST',
  START_SAVING_CERTIFICATE_MODEL: 'START_SAVING_CERTIFICATE_MODEL',
  REMOVE_CERTIFICATE_MODEL_REQUEST: 'REMOVE_CERTIFICATE_MODEL_REQUEST',
  REMOVE_CERTIFICATE_MODEL_ERROR: 'REMOVE_CERTIFICATE_MODEL_ERROR',
  START_REMOVING_CERTIFICATE_MODEL: 'START_REMOVING_CERTIFICATE_MODEL',
  MEDICAL_REPORT_MODELS_FETCH_REQUEST: 'MEDICAL_REPORT_MODELS_FETCH_REQUEST',
  MEDICAL_REPORT_MODELS_FETCH_SUCCESS: 'MEDICAL_REPORT_MODELS_FETCH_SUCCESS',
  MEDICAL_REPORT_MODELS_FETCH_ERROR: 'MEDICAL_REPORT_MODELS_FETCH_ERROR',
  CHANGE_MEDICAL_REPORT: 'CHANGE_MEDICAL_REPORT',
  CHANGE_UNFORMATTED_MEDICAL_REPORT: 'CHANGE_UNFORMATTED_MEDICAL_REPORT',
  CHANGE_OTHER_DOCUMENT: 'CHANGE_OTHER_DOCUMENT',
  CHANGE_UNFORMATTED_OTHER_DOCUMENT: 'CHANGE_UNFORMATTED_OTHER_DOCUMENT',
  OTHER_DOCUMENT_MODELS_FETCH_REQUEST: 'OTHER_DOCUMENT_MODELS_FETCH_REQUEST',
  OTHER_DOCUMENT_MODELS_FETCH_SUCCESS: 'OTHER_DOCUMENT_MODELS_FETCH_SUCCESS',
  OTHER_DOCUMENT_MODELS_FETCH_ERROR: 'OTHER_DOCUMENT_MODELS_FETCH_ERROR',
  CHANGE_NEW_CERTIFICATE_TAB_MODE: 'CHANGE_NEW_CERTIFICATE_TAB_MODE',
  CHANGE_CERTIFICATE_LIST_SCREEN_MODE: 'CHANGE_CERTIFICATE_LIST_SCREEN_MODE',
  CHANGE_COLLAPSE_CERTIFICATE_REQUEST: 'CHANGE_COLLAPSE_CERTIFICATE_REQUEST',
  CHANGE_COLLAPSE_CERTIFICATE_SUCCESS: 'CHANGE_COLLAPSE_CERTIFICATE_SUCCESS',
  CHANGE_COLLAPSE_CERTIFICATE_ERROR: 'CHANGE_COLLAPSE_CERTIFICATE_ERROR',
  CHANGE_HIDE_CERTIFICATE_REQUEST: 'CHANGE_HIDE_CERTIFICATE_REQUEST',
  CHANGE_HIDE_CERTIFICATE_SUCCESS: 'CHANGE_HIDE_CERTIFICATE_SUCCESS',
  CHANGE_HIDE_CERTIFICATE_ERROR: 'CHANGE_HIDE_CERTIFICATE_ERROR',
  CHANGE_HIDE_MEDICAL_REPORT_ERROR: 'CHANGE_HIDE_MEDICAL_REPORT_ERROR',
  CHANGE_HIDE_OTHER_DOCUMENT_ERROR: 'CHANGE_HIDE_OTHER_DOCUMENT_ERROR',
  CHANGING_HIDE_CERTIFICATE: 'CHANGING_HIDE_CERTIFICATE',
  CHANGING_HIDE_MEDICAL_REPORT: 'CHANGING_HIDE_MEDICAL_REPORT',
  CHANGING_HIDE_OTHER_DOCUMENT: 'CHANGING_HIDE_OTHER_DOCUMENT',
  GET_SHOW_HIDDEN_CERTIFICATES_STATUS: 'GET_SHOW_HIDDEN_CERTIFICATES_STATUS',
  GET_SHOW_HIDDEN_CERTIFICATES_STATUS_SUCCESS: 'GET_SHOW_HIDDEN_CERTIFICATES_STATUS_SUCCESS',
  GET_SHOW_HIDDEN_MEDICAL_REPORTS_STATUS_SUCCESS: 'GET_SHOW_HIDDEN_MEDICAL_REPORTS_STATUS_SUCCESS',
  GET_SHOW_HIDDEN_OTHER_DOCUMENTS_STATUS_SUCCESS: 'GET_SHOW_HIDDEN_OTHER_DOCUMENTS_STATUS_SUCCESS',
  SET_SHOW_HIDDEN_CERTIFICATES: 'SET_SHOW_HIDDEN_CERTIFICATES',
  SET_SHOW_HIDDEN_CERTIFICATES_SUCCESS: 'SET_SHOW_HIDDEN_CERTIFICATES_SUCCESS',
  SET_NEW_CERTIFICATE: 'SET_NEW_CERTIFICATE',
  SET_NEW_MEDICAL_REPORT: 'SET_NEW_MEDICAL_REPORT',
  SET_NEW_OTHER_DOCUMENT: 'SET_NEW_OTHER_DOCUMENT',
  getAllCertificates: () => ({
    type: certificateActions.GET_ALL_CERTIFICATES_REQUEST,
  }),
  getCertificates: (paginate = false) => ({
    type: certificateActions.GET_CERTIFICATES_REQUEST,
    payload: {
      paginate,
    },
  }),
  getMedicalReports: (paginate = false) => ({
    type: certificateActions.GET_MEDICAL_REPORTS_REQUEST,
    payload: {
      paginate,
    },
  }),
  getOtherDocuments: (paginate = false) => ({
    type: certificateActions.GET_OTHER_DOCUMENTS_REQUEST,
    payload: {
      paginate,
    },
  }),
  saveCertificate: (text, files, mode) => ({
    type: certificateActions.SAVE_CERTIFICATE_REQUEST,
    payload: {
      text,
      files,
      mode,
    },
  }),
  changeCertificate: (text, id) => ({
    type: certificateActions.CHANGE_CERTIFICATE,
    payload: {
      certificate: {
        text,
      },
      id,
    },
  }),
  changeUnformattedCertificate: (text, id) => ({
    type: certificateActions.CHANGE_UNFORMATTED_CERTIFICATE,
    payload: {
      certificate: {
        text,
      },
      id,
    },
  }),
  getAllCertificateModels: () => ({
    type: certificateActions.GET_ALL_CERTIFICATE_MODELS_REQUEST,
  }),
  getCertificateModels: () => ({
    type: certificateActions.CERTIFICATE_MODELS_FETCH_REQUEST,
  }),
  saveCertificateModel: (model, mode) => ({
    type: certificateActions.SAVE_CERTIFICATE_MODEL_REQUEST,
    payload: {
      model,
      mode,
    },
  }),
  removeCertificateModel: (id, mode) => ({
    type: certificateActions.REMOVE_CERTIFICATE_MODEL_REQUEST,
    payload: {
      id,
      mode,
    },
  }),
  updateCertificateModel: (model, id, mode) => ({
    type: certificateActions.UPDATE_CERTIFICATE_MODEL_REQUEST,
    payload: {
      model,
      id,
      mode,
    },
  }),
  discardCertificateChanges: (id) => ({
    type: certificateActions.DISCARD_NEW_CERTIFICATE_CHANGES,
    payload: {
      id,
    },
  }),
  changeMedicalReport: (text, id) => ({
    type: certificateActions.CHANGE_MEDICAL_REPORT,
    payload: {
      medicalReport: {
        text,
      },
      id,
    },
  }),
  changeUnformattedMedicalReport: (text, id) => ({
    type: certificateActions.CHANGE_UNFORMATTED_MEDICAL_REPORT,
    payload: {
      medicalReport: {
        text,
      },
      id,
    },
  }),
  changeOtherDocument: (text, id) => ({
    type: certificateActions.CHANGE_OTHER_DOCUMENT,
    payload: {
      otherDocument: {
        text,
      },
      id,
    },
  }),
  changeUnformattedOtherDocument: (text, id) => ({
    type: certificateActions.CHANGE_UNFORMATTED_OTHER_DOCUMENT,
    payload: {
      otherDocument: {
        text,
      },
      id,
    },
  }),
  changeNewCertificateTabMode: (value) => ({
    type: certificateActions.CHANGE_NEW_CERTIFICATE_TAB_MODE,
    payload: value,
  }),
  changeCertificateListScreenMode: (value) => ({
    type: certificateActions.CHANGE_CERTIFICATE_LIST_SCREEN_MODE,
    payload: value,
  }),
  changeCollapseCertificate: (id, mode, value) => ({
    type: certificateActions.CHANGE_COLLAPSE_CERTIFICATE_REQUEST,
    payload: {
      id,
      mode,
      value,
    },
  }),
  changeHideCertificate: (item, mode) => ({
    type: certificateActions.CHANGE_HIDE_CERTIFICATE_REQUEST,
    payload: {
      item,
      mode,
    },
  }),
  getShowHiddenCertificates: () => ({
    type: certificateActions.GET_SHOW_HIDDEN_CERTIFICATES_STATUS,
  }),
  toggleShowHiddenCertificates: (value, mode) => ({
    type: certificateActions.SET_SHOW_HIDDEN_CERTIFICATES,
    payload: {
      value,
      mode,
    },
  }),
};
export default certificateActions;

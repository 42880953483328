import _ from 'lodash';
import {
  all,
  takeEvery,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import actions from './actions';
import appActions from '../app/actions';
import authActions from '../auth/actions';
import recordsActions from '../records/actions';
import prescriptionsActions from '../prescriptions/actions';
import certificateActions from '../certificate/actions';

const getUserTokenFromStore = (state) => state.Auth.idToken;

const getEntrancePersistFromStore = (state) => state.EntrancePersist;

const getRecordListFromStore = (state) => state.Records.recordList;

export function* persistNewRecord() {
  yield takeEvery(recordsActions.CHANGE_RECORD, function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      if (action.payload.id) {
        yield put({
          type: actions.PERSIST_NEW_RECORD,
          payload: {
            ...action.payload,
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* discardNewRecord() {
  yield takeEvery([
    recordsActions.DISCARD_NEW_RECORD_CHANGES,
    recordsActions.DISCARD_NEW_RECORD_CHANGES_AFTER_SAVING,
  ], function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      yield put({
        type: actions.DISCARD_PERSISTED_ENTRANCE,
        payload: {
          ...action.payload,
          mode: 'newRecord',
          uid: userTokenFromStore,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* persistNewPrescription() {
  yield takeEvery(prescriptionsActions.CHANGE_PRESCRIPTION, function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      if (action.payload.id) {
        yield put({
          type: actions.PERSIST_NEW_PRESCRIPTION,
          payload: {
            ...action.payload,
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* discardNewPrescription() {
  yield takeEvery([
    prescriptionsActions.DISCARD_PRESCRIPTION,
    prescriptionsActions.RESET_PRESCRIPTION,
  ], function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      const entrancePersist = yield select(getEntrancePersistFromStore);
      if (!_.isEmpty(entrancePersist)) {
        yield put({
          type: actions.DISCARD_PERSISTED_ENTRANCE,
          payload: {
            ...action.payload,
            mode: 'newPrescription',
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* persistNewPrescriptionGeneralObs() {
  yield takeEvery(prescriptionsActions.CHANGE_PRESCRIPTION_GENERAL_OBS, function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      if (action.payload.id) {
        yield put({
          type: actions.PERSIST_NEW_PRESCRIPTION_GENERAL_OBS,
          payload: {
            ...action.payload,
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* discardNewPrescriptionGeneralObs() {
  yield takeEvery([
    prescriptionsActions.DISCARD_PRESCRIPTION,
    prescriptionsActions.RESET_PRESCRIPTION,
  ], function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      const entrancePersist = yield select(getEntrancePersistFromStore);
      if (!_.isEmpty(entrancePersist)) {
        yield put({
          type: actions.DISCARD_PERSISTED_ENTRANCE,
          payload: {
            ...action.payload,
            mode: 'newPrescriptionGeneralObs',
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* persistNewCertificate() {
  yield takeEvery(certificateActions.CHANGE_CERTIFICATE, function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      if (action.payload.id) {
        yield put({
          type: actions.PERSIST_NEW_CERTIFICATE,
          payload: {
            ...action.payload,
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* persistNewMedicalReport() {
  yield takeEvery(certificateActions.CHANGE_MEDICAL_REPORT, function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      if (action.payload.id) {
        yield put({
          type: actions.PERSIST_NEW_MEDICAL_REPORT,
          payload: {
            ...action.payload,
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* persistNewOtherDocument() {
  yield takeEvery(certificateActions.CHANGE_OTHER_DOCUMENT, function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      if (action.payload.id) {
        yield put({
          type: actions.PERSIST_NEW_OTHER_DOCUMENT,
          payload: {
            ...action.payload,
            uid: userTokenFromStore,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* discardNewCertificate() {
  yield takeEvery([
    certificateActions.DISCARD_NEW_CERTIFICATE_CHANGES,
  ], function* (action) {
    try {
      const userTokenFromStore = yield select(getUserTokenFromStore);
      yield all([
        put({
          type: actions.DISCARD_PERSISTED_ENTRANCE,
          payload: {
            ...action.payload,
            mode: 'newCertificate',
            uid: userTokenFromStore,
          },
        }),
        put({
          type: actions.DISCARD_PERSISTED_ENTRANCE,
          payload: {
            ...action.payload,
            mode: 'newMedicalReport',
            uid: userTokenFromStore,
          },
        }),
        put({
          type: actions.DISCARD_PERSISTED_ENTRANCE,
          payload: {
            ...action.payload,
            mode: 'newOtherDocument',
            uid: userTokenFromStore,
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* checkReduxPersistData() {
  yield takeEvery(authActions.LOGIN_SUCCESS, function* () {
    try {
      let userTokenFromStore = yield select(getUserTokenFromStore);
      if (!userTokenFromStore) {
        yield take(authActions.LOGIN_SUCCESS);
        userTokenFromStore = yield select(getUserTokenFromStore);
      }
      const defaultSavedAllChangesObj = {
        record: true,
        prescription: true,
        certificate: true,
        medicalReport: true,
        otherDocument: true,
      };
      const savedAllChangesObj = {};
      const entrancePersist = yield select(getEntrancePersistFromStore);
      let recordList = yield select(getRecordListFromStore);
      if (_.isEmpty(recordList)) {
        yield take(recordsActions.GET_RECORDS_MASK_SUCCESS);
        recordList = yield select(getRecordListFromStore);
      }
      if (entrancePersist?.[userTokenFromStore]) {
        if (!_.isEmpty(entrancePersist[userTokenFromStore]?.newRecord)) {
          Object.keys(entrancePersist[userTokenFromStore].newRecord).forEach((patientId) => {
            Object.keys(entrancePersist[userTokenFromStore].newRecord[patientId]).forEach((recordKey) => {
              if (recordKey !== 'text' && !recordList.find((x) => x.mode === recordKey)) {
                delete entrancePersist[userTokenFromStore].newRecord[patientId][recordKey];
              }
            });
            if (_.isEmpty(entrancePersist[userTokenFromStore].newRecord[patientId])) {
              delete entrancePersist[userTokenFromStore].newRecord[patientId];
            }
          });
          yield put({
            type: recordsActions.SET_NEW_RECORD,
            payload: {
              newRecord: entrancePersist[userTokenFromStore].newRecord,
            },
          });
          Object.keys(entrancePersist[userTokenFromStore].newRecord).forEach((patientId) => {
            if (!savedAllChangesObj[patientId]) {
              savedAllChangesObj[patientId] = { ...defaultSavedAllChangesObj };
            }
            savedAllChangesObj[patientId].record = false;
          });
        }
        if (!_.isEmpty(entrancePersist[userTokenFromStore]?.newCertificate)) {
          yield put({
            type: certificateActions.SET_NEW_CERTIFICATE,
            payload: {
              newCertificate: entrancePersist[userTokenFromStore].newCertificate,
            },
          });
          Object.keys(entrancePersist[userTokenFromStore].newCertificate).forEach((patientId) => {
            if (!savedAllChangesObj[patientId]) {
              savedAllChangesObj[patientId] = { ...defaultSavedAllChangesObj };
            }
            savedAllChangesObj[patientId].certificate = false;
          });
        }
        if (!_.isEmpty(entrancePersist[userTokenFromStore]?.newMedicalReport)) {
          yield put({
            type: certificateActions.SET_NEW_MEDICAL_REPORT,
            payload: {
              newMedicalReport: entrancePersist[userTokenFromStore].newMedicalReport,
            },
          });
          Object.keys(entrancePersist[userTokenFromStore].newMedicalReport).forEach((patientId) => {
            if (!savedAllChangesObj[patientId]) {
              savedAllChangesObj[patientId] = { ...defaultSavedAllChangesObj };
            }
            savedAllChangesObj[patientId].medicalReport = false;
          });
        }
        if (!_.isEmpty(entrancePersist[userTokenFromStore]?.newOtherDocument)) {
          yield put({
            type: certificateActions.SET_NEW_OTHER_DOCUMENT,
            payload: {
              newOtherDocument: entrancePersist[userTokenFromStore].newOtherDocument,
            },
          });
          Object.keys(entrancePersist[userTokenFromStore].newOtherDocument).forEach((patientId) => {
            if (!savedAllChangesObj[patientId]) {
              savedAllChangesObj[patientId] = { ...defaultSavedAllChangesObj };
            }
            savedAllChangesObj[patientId].otherDocument = false;
          });
        }
        if (!_.isEmpty(entrancePersist[userTokenFromStore]?.newPrescription)) {
          yield put({
            type: prescriptionsActions.SET_NEW_PRESCRIPTION,
            payload: {
              newPrescription: entrancePersist[userTokenFromStore].newPrescription,
            },
          });
          Object.keys(entrancePersist[userTokenFromStore].newPrescription).forEach((patientId) => {
            if (!savedAllChangesObj[patientId]) {
              savedAllChangesObj[patientId] = { ...defaultSavedAllChangesObj };
            }
            savedAllChangesObj[patientId].prescription = false;
          });
        }
        if (!_.isEmpty(entrancePersist[userTokenFromStore]?.newPrescriptionGeneralObs)) {
          yield put({
            type: prescriptionsActions.SET_NEW_PRESCRIPTION_GENERAL_OBS,
            payload: {
              newPrescriptionGeneralObs: entrancePersist[userTokenFromStore].newPrescriptionGeneralObs,
            },
          });
          // Object.keys(entrancePersist[userTokenFromStore].newPrescriptionGeneralObs).forEach((patientId) => {
          //   if (!savedAllChangesObj[patientId]) {
          //     savedAllChangesObj[patientId] = { ...defaultSavedAllChangesObj };
          //   }
          //   savedAllChangesObj[patientId].prescription = false;
          // });
        }
      }
      let isEmpty = true;
      if (savedAllChangesObj) {
        const keyArr = Object.keys(savedAllChangesObj);
        for (let i = 0; i < keyArr.length; i += 1) {
          const patientId = keyArr[i];
          isEmpty = false;
          yield put({
            type: appActions.CHANGE_SAVED_ALL_CHANGES,
            payload: {
              id: patientId,
              obj: savedAllChangesObj[patientId],
            },
          });
        }
      }
      if (!isEmpty) {
        yield put({
          type: appActions.SET_PREVENT_CLOSE_MODAL_VISIBLE,
          payload: {
            value: true,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(persistNewRecord),
    fork(discardNewRecord),
    fork(persistNewPrescription),
    fork(discardNewPrescription),
    fork(persistNewPrescriptionGeneralObs),
    fork(discardNewPrescriptionGeneralObs),
    fork(persistNewCertificate),
    fork(persistNewMedicalReport),
    fork(persistNewOtherDocument),
    fork(discardNewCertificate),
    fork(checkReduxPersistData),
  ]);
}

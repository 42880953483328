import React from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import componentLoader from './componentLoader';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';

export default function asyncComponent(importComponent, hideNprogress = false) {
  class AsyncFunc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
      if (!hideNprogress) {
        Nprogress.start();
      }
    }

    async componentDidMount() {
      this.mounted = true;
      // const { default: Component } = await importComponent();
      const { default: Component } = await componentLoader(() => importComponent(), 3);
      if (!hideNprogress) {
        Nprogress.done();
      }
      if (this.mounted) {
        this.setState({
          // eslint-disable-next-line react/jsx-props-no-spreading
          component: <Component {...this.props} />,
        });
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { component } = this.state;
      const Component = component || <div />;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }
  return AsyncFunc;
}

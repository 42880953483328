const actions = {
  CLEAR_STATES: 'CLEAR_STATES',
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  CHECK_AUTHORIZATION_SUCCESS: 'CHECK_AUTHORIZATION_SUCCESS',
  LOGIN_REQUEST_WITH_FB: 'LOGIN_REQUEST_WITH_FB',
  LOGIN_REQUEST_WITH_FB_SUCCESS: 'LOGIN_REQUEST_WITH_FB_SUCCESS',
  AWAITING_FB_LOGIN_RESPONSE: 'AWAITING_FB_LOGIN_RESPONSE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  NAME_CHANGED: 'NAME_CHANGED',
  LAST_NAME_CHANGED: 'LAST_NAME_CHANGED',
  EMAIL_CHANGED: 'EMAIL_CHANGED',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  PASSWORD_CONFIRMATION_CHANGED: 'PASSWORD_CONFIRMATION_CHANGED',
  REMEMBER_USER: 'REMEMBER_USER',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  AUTH_IN_PROGRESS: 'AUTH_IN_PROGRESS',
  REQUEST_RELOGIN: 'REQUEST_RELOGIN',
  SET_REQUEST_LOGIN_VALUE: 'SET_REQUEST_LOGIN_VALUE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  RESET_PASSWORD_REQUEST_WAITING: 'RESET_PASSWORD_REQUEST_WAITING',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_REQUEST_WAITING: 'CHANGE_PASSWORD_REQUEST_WAITING',
  // User (patient) auth
  // SIGN_UP_PATIENT_REQUEST: 'SIGN_UP_PATIENT_REQUEST',
  // SIGN_UP_PATIENT_SUCCESS: 'SIGN_UP_PATIENT_SUCCESS',
  // LOGIN_PATIENT_SUCCESS: 'LOGIN_PATIENT_SUCCESS',
  // LOGIN_PATIENT_ERROR: 'LOGIN_PATIENT_ERROR',
  CHECK_USER_DATA_EXISTS_REQUEST: 'CHECK_USER_DATA_EXISTS_REQUEST',
  CHECK_USER_DATA_EXISTS_SUCCESS: 'CHECK_USER_DATA_EXISTS_SUCCESS',
  CHECK_USER_DATA_EXISTS_ERROR: 'CHECK_USER_DATA_EXISTS_ERROR',
  TOGGLE_CHANGE_PASSWORD_MODAL_VISIBLE: 'TOGGLE_CHANGE_PASSWORD_MODAL_VISIBLE',
  SET_CHANGE_LEAKED_PASSWORD: 'SET_CHANGE_LEAKED_PASSWORD',
  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION,
  }),
  loginWithFB: (popup = false) => ({
    type: actions.LOGIN_REQUEST_WITH_FB,
    payload: popup,
  }),
  login: (email = '', password = '') => ({
    type: actions.LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  }),
  signup: (professional = false) => ({
    type: actions.SIGN_UP_REQUEST,
    payload: professional,
  }),
  nameChanged: (text) => ({
    type: actions.NAME_CHANGED,
    payload: text,
  }),
  lastNameChanged: (text) => ({
    type: actions.LAST_NAME_CHANGED,
    payload: text,
  }),
  emailChanged: (text) => ({
    type: actions.EMAIL_CHANGED,
    payload: text,
  }),
  passwordChanged: (text) => ({
    type: actions.PASSWORD_CHANGED,
    payload: text,
  }),
  passwordConfirmationChanged: (text) => ({
    type: actions.PASSWORD_CONFIRMATION_CHANGED,
    payload: text,
  }),
  rememberUser: (value) => ({
    type: actions.REMEMBER_USER,
    payload: value,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  // User (patient) auth
  // signupPatient: () => ({
  //   type: actions.SIGN_UP_PATIENT_REQUEST,
  // }),
  setRequestLogin: (value) => ({
    type: actions.SET_REQUEST_LOGIN_VALUE,
    payload: value,
  }),
  resetPasswordEmail: (email) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload: email,
  }),
  updatePassword: (oldPassword, newPassword) => ({
    type: actions.CHANGE_PASSWORD_REQUEST,
    payload: {
      oldPassword,
      newPassword,
    },
  }),
  checkUserDataExists: (uid) => ({
    type: actions.CHECK_USER_DATA_EXISTS_REQUEST,
    payload: uid,
  }),
  toggleChangePasswordModalVisible: () => ({
    type: actions.TOGGLE_CHANGE_PASSWORD_MODAL_VISIBLE,
  }),
};
export default actions;

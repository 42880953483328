const actions = {
  SEARCH_BY_NAME_FETCH_REQUEST: 'SEARCH_BY_NAME_FETCH_REQUEST',
  SEARCH_BY_NAME_FETCH_SUCCESS: 'SEARCH_BY_NAME_FETCH_SUCCESS',
  SEARCH_BY_NAME_FETCH_ERROR: 'SEARCH_BY_NAME_FETCH_ERROR',
  SEARCHING_BY_NAME: 'SEARCHING_BY_NAME',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
  GET_SEARCH_FILTERS_REQUEST: 'GET_SEARCH_FILTERS_REQUEST',
  GET_SEARCH_FILTERS_SUCCESS: 'GET_SEARCH_FILTERS_SUCCESS',
  GET_SEARCH_FILTERS_ERROR: 'GET_SEARCH_FILTERS_ERROR',
  GETTING_SEARCH_FILTERS: 'GETTING_SEARCH_FILTERS',
  SEARCH_PROFESSIONALS_REQUEST: 'SEARCH_PROFESSIONALS_REQUEST',
  SEARCH_PROFESSIONALS_SUCCESS: 'SEARCH_PROFESSIONALS_SUCCESS',
  SEARCH_PROFESSIONALS_ERROR: 'SEARCH_PROFESSIONALS_ERROR',
  SEARCHING_PROFESSIONALS: 'SEARCHING_PROFESSIONALS',
  FILTER_PROFESSIONALS_LOCALLY: 'FILTER_PROFESSIONALS_LOCALLY',
  searchByNameFetch: (search, key) => ({
    type: actions.SEARCH_BY_NAME_FETCH_REQUEST,
    payload: {
      search,
      key,
    },
  }),
  clearSearchResult: () => ({
    type: actions.CLEAR_SEARCH_RESULTS,
  }),
  getSearchFilters: () => ({
    type: actions.GET_SEARCH_FILTERS_REQUEST,
  }),
  searchProfessionals: (filters = {}) => ({
    type: actions.SEARCH_PROFESSIONALS_REQUEST,
    payload: filters,
  }),
  filterProfessionalsLocally: (filters = {}, data = []) => ({
    type: actions.FILTER_PROFESSIONALS_LOCALLY,
    payload: {
      filters,
      data,
    },
  }),
};
export default actions;

const actions = {
  REQUEST_WAIT_LIST: 'REQUEST_WAIT_LIST',
  REQUEST_WAIT_LIST_ERROR: 'REQUEST_WAIT_LIST_ERROR',
  REQUEST_WAIT_LIST_SUCCED: 'REQUEST_WAIT_LIST_SUCCED',
  REQUEST_WAIT_LIST_WAITING: 'REQUEST_WAIT_LIST_WAITING',
  REQUEST_PATIENTS_FROM_LIST: 'REQUEST_PATIENTS_FROM_LIST',
  REQUEST_PATIENTS_FROM_LIST_ERROR: 'REQUEST_PATIENTS_FROM_LIST_ERROR',
  REQUEST_PATIENTS_FROM_LIST_SUCCED: 'REQUEST_PATIENTS_FROM_LIST_SUCCED',
  REQUEST_PATIENTS_FROM_LIST_WAITING: 'REQUEST_PATIENTS_FROM_LIST_WAITING',
  REQUEST_PATIENTS_FROM_LIST_RESET: 'REQUEST_PATIENTS_FROM_LIST_RESET',
  DELETE_PATIENT_FROM_LIST: 'DELETE_PATIENT_FROM_LIST',
  DELETE_PATIENT_FROM_LIST_ERROR: 'DELETE_PATIENT_FROM_LIST_ERROR',
  ADD_PATIENT_TO_LIST: 'ADD_PATIENT_TO_LIST',
  ADD_PATIENT_TO_LIST_WAITING: 'ADD_PATIENT_TO_LIST_WAITING',
  ADD_PATIENT_TO_LIST_SUCCESS: 'ADD_PATIENT_TO_LIST_SUCCESS',
  ADD_PATIENT_TO_LIST_ERROR: 'ADD_PATIENT_TO_LIST_ERROR',
  ADD_PATIENT_TO_LIST_RESET: 'ADD_PATIENT_TO_LIST_RESET',
  FILL_LOADED_LIST: 'FILL_LOADED_LIST',
  SET_WAIT_LIST_SLOT: 'SET_WAIT_LIST_SLOT',
  SET_OPEN_WAIT_LIST_COMPONENT: 'SET_OPEN_WAIT_LIST_COMPONENT',
  // requestAppointmentsInfoByDate: (date, endDate) => ({
  //   type: actions.APPOINTMENTS_INFO_REQUEST,
  //   payload: {
  //     date,
  //     endDate,
  //   },
  // }),
  // resetReportAppointments: () => ({
  //   type: actions.RESET_REPORT_APPOINTMENTS,
  // }),
  resetWaitListInput: () => ({
    type: actions.ADD_PATIENT_TO_LIST_RESET,
  }),
  requestWaitListPatients: () => ({
    type: actions.REQUEST_WAIT_LIST,
  }),
  requestPatientListInformation: (list, id) => ({
    type: actions.REQUEST_PATIENTS_FROM_LIST,
    payload: {
      list,
      id,
    },
  }),
  deletePatientFromList: (waitListObj) => ({
    type: actions.DELETE_PATIENT_FROM_LIST,
    payload: {
      waitListObj,
    },
  }),
  addPatientToList: (
    listId,
    patientId,
    observation,
    selectedPatientToEdit,
    useAutomation = false,
    preferences = null,
  ) => ({
    type: actions.ADD_PATIENT_TO_LIST,
    payload: {
      listId,
      patientId,
      observation,
      selectedPatientToEdit,
      useAutomation,
      preferences,
    },
  }),
  setWaitListOpen: (value) => ({
    type: actions.SET_OPEN_WAIT_LIST_COMPONENT,
    payload: {
      value,
    },
  }),
};

export default actions;

import _ from 'lodash';
import moment from 'moment-timezone';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  update,
  push,
  child,
  get,
  onValue,
} from 'firebase/database';
import {
  getFirestore,
  getDocs,
  query as fsQuery,
  collection,
  limit,
  where,
} from 'firebase/firestore';
import { eventChannel } from 'redux-saga';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { v1 as uuidv1 } from 'uuid';
import actions from './actions';
import appActions from '../app/actions';
import profileActions from '../profile/actions';

// const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getProfileFromStore = (state) => state.Profile.profileOnDB;

const getCustomItensFromStore = (state) => state.Exams.customExamItens;

function createDefaultProcedureOnDB(
  appointmentDuration,
  dataArr,
  currentAddress,
  mainUser,
  onlyMissingDefaults = false,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const procedures = [];
  if (!onlyMissingDefaults) {
    // Default appointment
    procedures.push(
      {
        name: 'Consulta',
        default: true,
        duration: appointmentDuration,
        modes: [...dataArr],
      },
    );
  }
  // First appointment
  procedures.push(
    {
      name: '1ª Consulta',
      default: true,
      duration: appointmentDuration,
      modes: [...dataArr],
    },
  );
  // // Appointment's return
  // procedures.push(
  //   {
  //     name: 'Retorno',
  //     default: true,
  //     duration: appointmentDuration,
  //     modes: [...dataArr],
  //   },
  // );
  // Exams
  procedures.push(
    {
      name: 'Exames',
      default: true,
      duration: appointmentDuration,
      modes: [...dataArr],
    },
  );
  const updates = {};
  procedures.forEach((el) => {
    const pushKey = push(child(dbRef, `/procedures/${uid}/${currentAddress}/procedures`)).key;
    updates[`/procedures/${uid}/${currentAddress}/procedures/${pushKey}`] = el;
  });
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function createProcedureOnDB(procedure, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(dbRef, `/procedures/${uid}/${currentAddress}/procedures`)).key;
  updates[`/procedures/${uid}/${currentAddress}/procedures/${pushKey}`] = procedure;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createProcedureRequest() {
  yield takeLatest(actions.CREATE_PROCEDURES_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.PROCEDURES_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(createProcedureOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.PROCEDURES_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_PROCEDURES_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function saveProcedureOnDB(procedure, key, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/procedures/${uid}/${currentAddress}/procedures/${key}`] = procedure;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveProcedureRequest() {
  yield takeLatest(actions.SAVE_PROCEDURES_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.PROCEDURES_FETCH_OR_UPDATING_WAITING });
      const { procedure, key } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveProcedureOnDB, procedure, key, currentAddress, mainUser);
      yield put({
        type: actions.SAVE_PROCEDURES_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_PROCEDURES_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function saveAllProceduresFromDB(procedures, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  procedures.forEach((proc) => {
    updates[`/procedures/${uid}/${currentAddress}/procedures/${proc.id}`] = proc;
  });
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function getProceduresFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `procedures/${uid}/${currentAddress}/procedures`));
}

function createProceduresListener(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    const proceduresRef = ref(db, `procedures/${uid}/${currentAddress}/procedures`);
    const unsubscribe = onValue(proceduresRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getProceduresListener() {
  yield takeEvery([
    actions.CREATE_PROCEDURES_LISTENER_REQUEST,
  ], function* () {
    const mainUser = yield select(getMainUserFromStore);
    const currentAddress = yield select(getSelectedAddressFromStore);
    const proceduresListener = yield call(createProceduresListener, currentAddress, mainUser);
    yield takeEvery(proceduresListener, function* (procedures) {
      const editedProcedures = _.map(procedures, (val, id) => (
        { ...val, id }
      ));
      yield put({
        type: actions.PROCEDURES_FETCH_SUCCESS,
        payload: {
          procedures: _.orderBy(editedProcedures, [(el) => el.name.toLowerCase()]),
          address: currentAddress,
        },
      });
    });
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      actions.PROCEDURES_FETCH_REQUEST,
      profileActions.NO_CUSTOM_PLANS_SAVED,
      profileActions.GET_CUSTOM_PLANS_SUCCESS,
    ]);
    proceduresListener.close();
    if (actionTriggered?.type === 'CANCEL_LISTENERS') {
      // It is the general "cancel all listeners".
      yield put({
        type: appActions.CANCEL_LISTENERS_SUCCESS,
      });
    }
  });
}

export function* getProceduresRequest() {
  yield takeLatest([
    actions.PROCEDURES_FETCH_REQUEST,
    profileActions.NO_CUSTOM_PLANS_SAVED,
    profileActions.GET_CUSTOM_PLANS_SUCCESS,
  ], function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const profile = yield select(getProfileFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const procedures = yield call(getProceduresFromDB, currentAddress, mainUser);
      if (procedures.val()) {
        const editedProcedures = _.map(procedures.val(), (val, id) => (
          { ...val, id }
        ));
        const bkpProcedures = [...editedProcedures];
        let shouldSave = false;
        if (profile.plans && profile.plans.length > 0) {
          editedProcedures.forEach((proc, index) => {
            const dataArr = [...proc.modes];
            // Checking plans that are in profile and not in procedure modes
            profile.plans.forEach((plan) => {
              const foundPlan = proc.modes.find((el) => el.name === plan);
              if (!foundPlan) {
                // const biggestKey = Math.max(...dataArr.map((el) => el.key), 0);
                dataArr.push({
                  key: uuidv1(),
                  name: plan,
                  mode: 'plan',
                  return: '',
                  value: '',
                  enabled: true,
                });
                shouldSave = true;
              }
            });
            // Checking plans that are in procedure modes and in profile
            const filteredDataArr = dataArr.filter((el) => {
              if (profile.plans.find((plan) => plan === el.name)
                || el.mode === 'private') {
                return true;
              }
              return false;
            });
            if (!_.isEqual(dataArr, filteredDataArr)) {
              shouldSave = true;
            }
            bkpProcedures[index].modes = [...filteredDataArr];
          });
        }
        const filteredDefaultProcedures = editedProcedures.filter((el) => el.default);
        const shouldCreateDefaults = filteredDefaultProcedures.length <= 1;
        if (shouldSave || shouldCreateDefaults) {
          if (shouldSave) {
            yield call(
              saveAllProceduresFromDB,
              bkpProcedures,
              currentAddress,
              mainUser,
            );
          }
          if (shouldCreateDefaults) {
            const appointmentDuration = '00:15';
            const dataArr = [];
            dataArr.push({
              key: uuidv1(),
              name: 'Particular',
              mode: 'private',
              return: '',
              value: '',
              enabled: true,
            });
            if (profile.plans && profile.plans.length > 0) {
              // let key = 1;
              profile.plans.forEach((plan) => {
                dataArr.push({
                  key: uuidv1(),
                  name: plan,
                  mode: 'plan',
                  return: '',
                  value: '',
                  enabled: true,
                });
                // key += 1;
              });
            }
            yield call(
              createDefaultProcedureOnDB,
              appointmentDuration,
              dataArr,
              currentAddress,
              mainUser,
              true, // Creating only the missing defaults.
            );
          }
          yield put({
            type: actions.PROCEDURES_FETCH_REQUEST,
          });
        } else {
          // editedProcedures.forEach((proc, index) => {
          //   if (proc.modes) {
          //     const orderedModes = _.orderBy(proc.modes, [(el) => el.name.toLowerCase()]);
          //     editedProcedures[index].modes = [...orderedModes];
          //   }
          // });
          // yield put({
          //   type: actions.PROCEDURES_FETCH_SUCCESS,
          //   payload: {
          //     procedures: _.orderBy(editedProcedures, [(el) => el.name.toLowerCase()]),
          //     address: currentAddress,
          //   },
          // });
          yield put({
            type: actions.CREATE_PROCEDURES_LISTENER_REQUEST,
          });
        }
      } else {
        // No procedure on DB yet
        const appointmentDuration = '00:15';
        const dataArr = [];
        dataArr.push({
          key: uuidv1(),
          name: 'Particular',
          mode: 'private',
          return: '',
          value: '',
          enabled: true,
        });
        if (profile.plans && profile.plans.length > 0) {
          // let key = 1;
          profile.plans.forEach((plan) => {
            dataArr.push({
              key: uuidv1(),
              name: plan,
              mode: 'plan',
              return: '',
              value: '',
              enabled: true,
            });
            // key += 1;
          });
        }
        yield call(
          createDefaultProcedureOnDB,
          appointmentDuration,
          dataArr,
          currentAddress,
          mainUser,
        );
        yield put({
          type: actions.PROCEDURES_FETCH_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.PROCEDURES_FETCH_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function removeProceduresOnDB(procedureArr, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  procedureArr.forEach((el) => {
    // updates[`/procedures/${uid}/${currentAddress}/procedures/${el}`] = null;
    updates[`/procedures/${uid}/${currentAddress}/procedures/${el}/disabled`] = moment().tz('America/Sao_Paulo').format();
  });
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeProceduresRequest() {
  yield takeLatest(actions.REMOVE_PROCEDURES_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.REMOVING_PROCEDURES });
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeProceduresOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.PROCEDURES_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_PROCEDURES_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function terminologiaQueryFromFirestore(search) {
  const fs = getFirestore();
  let startAt = search.toUpperCase();
  startAt = startAt
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const colRef = collection(fs, 'tuss');
  const q = fsQuery(
    colRef,
    where('formattedTerminologia', '>=', startAt),
    where('formattedTerminologia', '<', `${startAt}\uf8ff`),
    limit(50),
  );
  return getDocs(q);
  // return fs.collection('tuss')
  //   .where('formattedTerminologia', '>=', startAt)
  //   .where('formattedTerminologia', '<', `${startAt}\uf8ff`)
  //   .limit(50)
  //   .get();
}

// function normativaQueryFromFirestore(search) {
//   const fs = firebase.firestore();
//   let startAt = search.toUpperCase();
//   startAt = startAt
//     .replace(/\s+/g, ' ')
//     .replace(/^\s+|\s+$/g, '')
//     .normalize('NFD')
//     .replace(/[\u0300-\u036f]/g, '');
//   return fs.collection('tuss')
//     .where('formatedNormativa', '>=', startAt)
//     .where('formatedNormativa', '<', `${startAt}\uf8ff`)
//     .limit(50)
//     .get();
// }

function keywordsQueryFromFirestore(search) {
  const fs = getFirestore();
  let startAt = search.toUpperCase();
  startAt = startAt
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(' ');
  const constraints = [];
  startAt.forEach((el) => {
    constraints.push(where(`keywords.${el}`, '==', true));
  });
  constraints.push(limit(20));

  const colRef = collection(fs, 'tuss');
  const q = fsQuery(
    colRef,
    ...constraints,
  );
  return getDocs(q);
}

function tussCodigoQueryFromFirestore(search) {
  const fs = getFirestore();
  const colRef = collection(fs, 'tuss');
  const q = fsQuery(
    colRef,
    where('codigo', '==', search),
    limit(20),
  );
  return getDocs(q);
}

function getCustomItensFromStoreFiltered(search, itensFromStore = {}, currentAddress) {
  const filteredItens = [];
  if (itensFromStore?.[currentAddress]) {
    Object.keys(itensFromStore[currentAddress]).forEach((item) => {
      if (itensFromStore?.[currentAddress][item].codigo.toLowerCase().includes(search.toLowerCase())
      || itensFromStore?.[currentAddress][item].terminologia.toLowerCase().includes(search.toLowerCase())) {
        filteredItens.push(itensFromStore?.[currentAddress][item]);
      }
    });
  }
  return filteredItens;
}

function* fetchTussRequest() {
  yield takeLatest(actions.FETCH_TUSS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.FETCH_TUSS_WAITING });
      yield call(sleep, 500);
      const itensFromStore = yield select(getCustomItensFromStore);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const [terminologia, keywords, codigo, customExamItens] = yield all([
        call(terminologiaQueryFromFirestore, action.payload),
        // call(normativaQueryFromFirestore, action.payload),
        call(keywordsQueryFromFirestore, action.payload),
        call(tussCodigoQueryFromFirestore, action.payload),
        call(getCustomItensFromStoreFiltered, action.payload, itensFromStore, currentAddress),
      ]);
      const terminologiaArr = [];
      terminologia.forEach((doc) => {
        terminologiaArr.push({
          ...doc.data(),
          id: doc.id,
          value: `${doc.data().codigo} - ${doc.data().terminologia}`,
        });
      });
      // const normativaArr = [];
      // normativa.forEach((doc) => {
      //   normativaArr.push({
      //     ...doc.data(),
      //     id: doc.id,
      //     value: `${doc.data().codigo} - ${doc.data().terminologia}`,
      //   });
      // });
      const keywordsArr = [];
      keywords.forEach((doc) => {
        keywordsArr.push({
          ...doc.data(),
          id: doc.id,
          value: `${doc.data().codigo} - ${doc.data().terminologia}`,
        });
      });
      const codigoArr = [];
      codigo.forEach((doc) => {
        codigoArr.push({
          ...doc.data(),
          id: doc.id,
          value: `${doc.data().codigo} - ${doc.data().terminologia}`,
        });
      });
      const finalArr = [...customExamItens, ...terminologiaArr, ...keywordsArr, ...codigoArr];
      yield put({
        type: actions.FETCH_TUSS_SUCCESS,
        payload: _.uniqBy(finalArr, 'id'),
      });
    } catch (error) {
      yield put({ type: actions.FETCH_TUSS_ERROR });
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProceduresRequest),
    fork(getProceduresListener),
    fork(saveProcedureRequest),
    fork(createProcedureRequest),
    fork(removeProceduresRequest),
    fork(fetchTussRequest),
    // fork(getDefaultProceduresRequest),
  ]);
}

const actions = {
  PROCEDURES_FETCH_REQUEST: 'PROCEDURES_FETCH_REQUEST',
  PROCEDURES_FETCH_SUCCESS: 'PROCEDURES_FETCH_SUCCESS',
  PROCEDURES_FETCH_ERROR: 'PROCEDURES_FETCH_ERROR',
  // DEFAULT_PROCEDURES_FETCH_REQUEST: 'DEFAULT_PROCEDURES_FETCH_REQUEST',
  // DEFAULT_PROCEDURES_FETCH_SUCCESS: 'DEFAULT_PROCEDURES_FETCH_SUCCESS',
  // CUSTOM_PROCEDURES_FETCH_REQUEST: 'CUSTOM_PROCEDURES_FETCH_REQUEST',
  // CUSTOM_PROCEDURES_FETCH_SUCCESS: 'CUSTOM_PROCEDURES_FETCH_SUCCESS',
  CREATE_PROCEDURES_LISTENER_REQUEST: 'CREATE_PROCEDURES_LISTENER_REQUEST',
  SAVE_PROCEDURES_REQUEST: 'SAVE_PROCEDURES_REQUEST',
  SAVE_PROCEDURES_SUCCESS: 'SAVE_PROCEDURES_SUCCESS',
  SAVE_PROCEDURES_ERROR: 'SAVE_PROCEDURES_ERROR',
  CREATE_PROCEDURES_REQUEST: 'CREATE_PROCEDURES_REQUEST',
  CREATE_PROCEDURES_SUCCESS: 'CREATE_PROCEDURES_SUCCESS',
  CREATE_PROCEDURES_ERROR: 'CREATE_PROCEDURES_ERROR',
  PROCEDURES_FETCH_OR_UPDATING_WAITING: 'PROCEDURES_FETCH_OR_UPDATING_WAITING',
  REMOVE_PROCEDURES_REQUEST: 'REMOVE_PROCEDURES_REQUEST',
  REMOVE_PROCEDURES_SUCCESS: 'REMOVE_PROCEDURES_SUCCESS',
  REMOVE_PROCEDURES_ERROR: 'REMOVE_PROCEDURES_ERROR',
  REMOVING_PROCEDURES: 'REMOVING_PROCEDURES',
  FETCH_TUSS_REQUEST: 'FETCH_TUSS_REQUEST',
  FETCH_TUSS_WAITING: 'FETCH_TUSS_WAITING',
  FETCH_TUSS_SUCCESS: 'FETCH_TUSS_SUCCESS',
  FETCH_TUSS_ERROR: 'FETCH_TUSS_ERROR',
  getProcedures: () => ({
    type: actions.PROCEDURES_FETCH_REQUEST,
  }),
  saveProcedure: (procedure, key) => ({
    type: actions.SAVE_PROCEDURES_REQUEST,
    payload: {
      procedure,
      key,
    },
  }),
  createProcedure: (procedure) => ({
    type: actions.CREATE_PROCEDURES_REQUEST,
    payload: procedure,
  }),
  removeProcedures: (value) => ({
    type: actions.REMOVE_PROCEDURES_REQUEST,
    payload: value,
  }),
  getTuss: (value) => ({
    type: actions.FETCH_TUSS_REQUEST,
    payload: value,
  }),
};

export default actions;

import actions from './actions';
import agendaActions from '../agenda/actions';
import authActions from '../auth/actions';

const initState = {
  gapiIsReadyToUse: false,
  googleAuthInProgress: false,
};

export default function todoReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SET_GIS_USER_PAYLOAD:
      return {
        ...state,
        gisUserPayload: action.payload,
        googleAuthInProgress: false,
      };
    case actions.SET_GIS_CLIENT:
      return {
        ...state,
        gisClient: action.payload,
      };
    case actions.SET_GIS_ACCESS_TOKEN:
      return {
        ...state,
        gisAccessToken: action.payload.accessToken,
        gisUserProfile: action.payload.profileData,
      };
    case actions.RESET_GIS_ACCESS_TOKEN:
      return {
        ...state,
        gisAccessToken: undefined,
        gisUserProfile: undefined,
      };
    case actions.GOOGLE_AUTH_IN_PROGRESS:
      return {
        ...state,
        googleAuthInProgress: true,
      };
    case agendaActions.SETTING_NEW_APPOINTMENT:
      return {
        ...state,
        googleAuthInProgress: false,
      };
    case agendaActions.START_EDITING_APPOINTMENT_REQUEST:
      return {
        ...state,
        googleAuthInProgress: false,
      };
    case actions.GAPI_IS_READY_TO_USE:
      return {
        ...state,
        gapiIsReadyToUse: true,
      };
    default:
      return state;
  }
}

import antdPt from 'antd/locale/pt_BR';
import appLocaleData from 'react-intl/locale-data/pt';
import ptMessages from '../locales/pt_BR.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const PtLang = {
  messages: {
    ...ptMessages,
  },
  antd: antdPt,
  locale: 'pt-BR',
  data: appLocaleData,
};
export default PtLang;

import {
  getAuth,
} from 'firebase/auth';
import {
  getFirestore,
  getDocFromServer,
  doc,
} from 'firebase/firestore';
import axios from 'axios';
import {
  all,
  takeLatest,
  fork,
  call,
  put,
  select,
} from 'redux-saga/effects';
import actions from './actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getMainUserFromStore = (state) => state.Auth.mainUser;

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function removePinOnCloud(pin, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  return axios.post(
    `${ROOT_URL}/removePin`,
    { pin, uid },
    config,
  );
}

function* removePinRequest() {
  yield takeLatest(actions.REMOVE_PIN_REQUEST, function* (action) {
    try {
      yield put({ type: actions.VALIDATING_PIN });
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      // const { data } = yield call(removePinOnCloud, action.payload, idToken, mainUser);
      let data = '';
      for (let i = 0; i <= 2; i += 1) {
        try {
          ({ data } = yield call(
            removePinOnCloud,
            action.payload,
            idToken,
            mainUser,
          ));
          break;
        } catch (err) {
          if (i <= 1) {
            yield call(sleep, 3000);
          } else if (i === 2) {
            throw new Error('Failed to remove pin.');
          }
        }
      }
      if (data === 'Success') {
        yield all([
          yield put({
            type: actions.VERIFY_PIN_REQUEST,
            pin: action.payload,
          }),
          yield put({
            type: actions.REMOVE_PIN_SUCCESS,
          }),
        ]);
      }
      if (data === 'Incorrect pin.') {
        yield put({
          type: actions.REMOVE_PIN_ERROR,
        });
      }
    } catch (error) {
      console.warn(error.message);
      yield put({
        type: actions.VERIFY_PIN_INTERNAL_ERROR,
      });
    }
  });
}

function createPinOnCloud(pin, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  return axios.post(
    `${ROOT_URL}/createPin`,
    { pin, uid },
    config,
  );
}

function* createPinRequest() {
  yield takeLatest(actions.CREATE_PIN_REQUEST, function* (action) {
    try {
      yield put({ type: actions.VALIDATING_PIN });
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      // const { data } = yield call(createPinOnCloud, action.payload, idToken, mainUser);
      let data = '';
      for (let i = 0; i <= 2; i += 1) {
        try {
          ({ data } = yield call(
            createPinOnCloud,
            action.payload,
            idToken,
            mainUser,
          ));
          break;
        } catch (err) {
          if (i <= 1) {
            yield call(sleep, 3000);
          } else if (i === 2) {
            throw new Error('Failed to create pin.');
          }
        }
      }
      if (data === 'Success') {
        yield all([
          yield put({
            type: actions.VERIFY_PIN_REQUEST,
            pin: action.payload,
          }),
          yield put({
            type: actions.CREATE_PIN_SUCCESS,
          }),
        ]);
      }
    } catch (error) {
      console.warn(error.message);
      yield put({
        type: actions.VERIFY_PIN_INTERNAL_ERROR,
      });
    }
  });
}

function validatePinOnCloud(pin, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  return axios.post(
    `${ROOT_URL}/verifyPin`,
    { pin, uid },
    config,
  );
}

function fetchProfile(mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  // const userRef = fs.collection('professionals').doc(uid);
  // return userRef.get();
  const userRef = doc(fs, 'professionals', uid);
  return getDocFromServer(userRef);
}

function* verifyPin() {
  yield takeLatest(actions.VERIFY_PIN_REQUEST, function* (action) {
    try {
      yield put({ type: actions.VERIFY_PIN_WAITING });
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      // Initial check from user's profile to avoid unnecessary cloud function call
      const profileFromDB = yield call(fetchProfile, mainUser);
      if (!Object.prototype.hasOwnProperty.call(profileFromDB.data(), 'pin')) {
        console.info('No pin created.');
        yield put({
          type: actions.VERIFY_NO_PIN_CREATED,
        });
      } else {
        console.info('There is pin.');
        // const { data } = yield call(validatePinOnCloud, action.pin, idToken, mainUser);
        let data = '';
        for (let i = 0; i <= 2; i += 1) {
          try {
            ({ data } = yield call(
              validatePinOnCloud,
              action.pin,
              idToken,
              mainUser,
            ));
            break;
          } catch (err) {
            if (i <= 1) {
              yield call(sleep, 3000);
            } else if (i === 2) {
              throw new Error('Failed to verify pin.');
            }
          }
        }
        if (action.pin === ' ') {
          if (data === 'No pin created.') {
            yield put({
              type: actions.VERIFY_NO_PIN_CREATED,
            });
          } else {
            yield put({
              type: actions.PIN_IS_NECESSARY,
            });
          }
        } else if (data === 'Incorrect pin.') {
          yield put({
            type: actions.VERIFY_PIN_INVALIDATED,
          });
        } else if (data === 'No pin created.') {
          yield put({
            type: actions.VERIFY_NO_PIN_CREATED,
          });
        } else {
          yield put({
            type: actions.VERIFY_PIN_VALIDATED,
          });
        }
      }
    } catch (error) {
      console.warn(error.message);
      yield put({
        type: actions.VERIFY_PIN_INTERNAL_ERROR,
      });
      // yield put({
      //   type: actions.VERIFY_PIN_REQUEST,
      //   pin: action.pin,
      // });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(removePinRequest),
    fork(createPinRequest),
    fork(verifyPin),
  ]);
}

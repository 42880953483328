import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  specialties: {
    crm: {},
    cro: {},
    crn: {},
    crp: {},
    crfa: {},
    crbm: {},
    crefito: {},
    croo: {},
    crf: {},
  },
  firstDay: '',
  goToConfirmation: null,
  // schedule: {}, -> using undefined as initial value
  modeAvailable: {},
  profile: {
    plans: [],
    avatar: '',
    birthday: '',
    lastName: '',
    firstName: '',
    concil: {
      value: '',
      region: '',
      specialties: [],
      type: '',
    },
    visible: false,
    uid: '',
    addresses: [{
      phones: [{
        key: '',
        phone: '',
        mode: '',
      }],
      name: '',
      addresses: '',
      lng: '',
      lat: '',
    }],
  },
};

export default function scheduleReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SEARCH_HOUR_FETCH_SUCCESS:
      return {
        ...state,
        firstDay: action.payload.data.firstDay,
        schedule: { ...state.schedule, ...action.payload.data.schedule },
        noSchedule: action.payload.data.noSchedule,
        modeAvailable: action.payload.data.modeAvailable,
        appointmentRequestSuccess: false,
      };
    case actions.SEARCH_HOUR_FETCH_ERROR:
      return {
        ...state,
        searchByName: {
          ...state.searchByName,
          professionals: null,
        },
        errorLoadingSchedules: true,
      };
    case actions.SEARCH_HOUR_BY_MAX_DATE:
      return {
        ...state,
        errorLoadingSchedules: false,
      };
    case actions.PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile: action.payload.data,
      };
    case actions.PROFILE_DATA_ERROR:
      return {
        ...state,
        profile: {},
      };
    case actions.SET_NEW_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentRequestSuccess: true,
        requestingNewAppointment: false,
        goToConfirmation: true,
      };
    case actions.SET_NEW_APPOINTMENT_ERROR:
      return {
        ...state,
        appointmentRequestSuccess: null,
        requestingNewAppointment: false,
      };
    case actions.REQUESTING_NEW_APPOINTMENT:
      return {
        ...state,
        requestingNewAppointment: true,
        appointmentRequestSuccess: false,
      };
    case actions.RESET_PROFESSIONAL_DATA:
      return {
        ...state,
        ...initState,
        schedule: undefined,
      };
    default:
      return state;
  }
}

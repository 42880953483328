import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  updatingMedicineModel: false,
  removingMedicineModel: false,
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.FETCH_MEDICINES_SUCCESS:
      return {
        ...state,
        medicines: action.payload,
      };
    case actions.MEDICINE_MODELS_FETCH_SUCCESS:
      return {
        ...state,
        medicineModels: action.payload.models,
        updatingMedicineModel: false,
        removingMedicineModel: false,
      };
    case actions.SAVE_MEDICINE_MODEL_ERROR:
      return {
        ...state,
        updatingMedicineModel: null,
      };
    case actions.REMOVE_MEDICINE_MODEL_ERROR:
      return {
        ...state,
        removingMedicineModel: null,
      };
    case actions.START_SAVING_MEDICINE_MODEL:
      return {
        ...state,
        updatingMedicineModel: true,
      };
    case actions.START_REMOVING_MEDICINE_MODEL:
      return {
        ...state,
        removingMedicineModel: action.payload,
      };
    default:
      return state;
  }
}

import actions from './actions';
import appActions from '../app/actions';
import authActions from '../auth/actions';

const initState = {
  appointments: undefined,
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.APPOINTMENTS_INFO_SUCCED:
      return {
        ...state,
        appointments: action.payload.data,
        waitingAppointments: false,
      };
    case actions.APPOINTMENTS_INFO_ERROR:
      return {
        ...state,
        appointments: undefined,
        waitingAppointments: false,
        appointmentsFailed: true,
      };
    case actions.APPOINTMENTS_INFO_WAITING:
      return {
        ...state,
        waitingAppointments: true,
      };
    case actions.APPOINTMENTS_INFO_REQUEST:
      return {
        ...state,
        waitingAppointments: true,
        appointments: '',
        appointmentsFailed: false,
      };
    case appActions.SELECT_ADDRESS:
      return {
        ...state,
        appointments: undefined,
      };
    case actions.RESET_REPORT_APPOINTMENTS:
      return {
        ...state,
        appointments: '',
      };
    case actions.START_PDF_WEB_WORKER_REQUEST:
      return {
        ...state,
        pdfWebWorkerBlob: null,
        renderingPdfWithWebWorker: false,
        startTime: null,
        estimatedEndTime: null,
        pdfName: null,
        pdfDescription: null,
      };
    case actions.START_PDF_WEB_WORKER_SUCCESS:
      return {
        ...state,
        pdfWebWorkerBlob: action.payload.blob,
        renderingPdfWithWebWorker: false,
      };
    case actions.STOP_PDF_WEB_WORKER:
      return {
        ...state,
        pdfWebWorkerBlob: null,
        renderingPdfWithWebWorker: false,
        startTime: null,
        estimatedEndTime: null,
        pdfName: null,
        pdfDescription: null,
      };
    case actions.RENDERING_PDF_WITH_WEB_WORKER:
      return {
        ...state,
        renderingPdfWithWebWorker: true,
        startTime: action.payload.startTime,
        estimatedEndTime: action.payload.estimatedEndTime,
        pdfName: action.payload.pdfName,
        pdfDescription: action.payload.pdfDescription,
      };
    default:
      return state;
  }
}

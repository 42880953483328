import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
} from 'firebase/database';
import moment from 'moment';
import { eventChannel } from 'redux-saga';
// import axios from 'axios';
import {
  all,
  takeEvery,
  takeLatest,
  fork,
  put,
  take,
  call,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { notification } from '../../components';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getAppointmentInfoFromStore = (state) => state.PatientAppointments.appointmentInfo;

function generateCorrectLink(shortLink) {
  const diff = 20 - shortLink.length;
  let newShortLink = _.cloneDeep(shortLink);
  if (diff > 0) {
    for (let i = 0; i < diff; i += 1) {
      newShortLink = `${newShortLink}-`;
    }
  }
  return newShortLink;
}

function getAppointmentInfoFromCloudFunction(shortLink) {
  return axios.post(
    `${ROOT_URL}/getAppointmentInfo`,
    { id: shortLink },
  );
}

export function* requestAppointmentInfo() {
  yield takeLatest(actions.APPOINTMENT_INFO_REQUEST, function* (action) {
    try {
      const shortLink = yield call(generateCorrectLink, action.payload);
      const { data } = yield call(getAppointmentInfoFromCloudFunction, shortLink);
      yield put({
        type: actions.APPOINTMENT_INFO_REQUEST_SUCCESS,
        payload: {
          data,
          link: shortLink,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.APPOINTMENT_INFO_REQUEST_REJECTED,
      });
    }
  });
}

function respondAppointmentInCloudFunction({
  shortLink,
  action,
  userLocal,
  appointment,
}) {
  return axios.post(
    `${ROOT_URL}/patientRespondedNotification`,
    {
      id: shortLink,
      action,
      userLocal,
      appointmentInfo: appointment,
    },
  );
}

export function* requestRespondAppointment() {
  yield takeLatest(actions.PATIENT_RESPONDED_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.PATIENT_RESPONDED_REQUEST_PENDING,
        payload: action.payload.action,
      });
      const { data } = yield call(respondAppointmentInCloudFunction, action.payload);
      if (data === 'confirmed') {
        yield put({
          type: actions.APPOINTMENT_INFO_REQUEST,
          payload: action.payload.shortLink,
        });
      } else if (data === 'canceled') {
        yield put({
          type: actions.PATIENT_RESPONDED_REQUEST_REJECTED,
        });
      }
    } catch (error) {
      console.warn(error);
      if (action.payload.action === 'cancel') {
        notification('error', 'Não foi possível cancelar o agendamento', 'Entre em contato com a clínica.');
      }
      yield put({
        type: actions.PATIENT_RESPONDED_REQUEST_ERROR,
      });
    }
  });
}

function createConfirmedListener() {
  const db = getDatabase();
  const auth = getAuth();
  const { currentUser } = auth;
  const listener = eventChannel((emit) => {
    const confirmedRef = ref(db, `users/${currentUser.uid}/appointments/confirmed`);
    const unsubscribe = onValue(confirmedRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getConfirmed() {
  yield takeEvery(actions.PATIENT_APPOINTMENTS_FETCH, function* () {
    const confirmedListener = yield call(createConfirmedListener);
    while (true) {
      const confirmed = yield take(confirmedListener);
      const normalizedConfirmed = _.map(confirmed, (val, id) => {
        const { duration } = val;
        return {
          ...val,
          start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
          id,
          end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
          confirmedSection: true,
          allDay: false,
        };
      });
      yield put({
        type: actions.PATIENT_CONFIRMED_FETCH_SUCCESS,
        payload: {
          id: 'confirmed',
          data: normalizedConfirmed,
        },
      });
    }
  });
}

function createPendingListener() {
  const db = getDatabase();
  const auth = getAuth();
  const { currentUser } = auth;
  const listener = eventChannel((emit) => {
    const pendingRef = ref(db, `users/${currentUser.uid}/appointments/pending`);
    const unsubscribe = onValue(pendingRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getPending() {
  yield takeEvery(actions.PATIENT_APPOINTMENTS_FETCH, function* () {
    const pendingListener = yield call(createPendingListener);
    while (true) {
      const pending = yield take(pendingListener);
      const normalizedPending = _.map(pending, (val, id) => {
        const { duration } = val;
        return {
          ...val,
          start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
          id,
          end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
          pendingSection: true,
          allDay: false,
        };
      });
      yield put({
        type: actions.PATIENT_PENDING_FETCH_SUCCESS,
        payload: {
          id: 'pending',
          data: normalizedPending,
        },
      });
    }
  });
}

function createRejectedListener() {
  const db = getDatabase();
  const auth = getAuth();
  const { currentUser } = auth;
  const listener = eventChannel((emit) => {
    const rejectedRef = ref(db, `users/${currentUser.uid}/appointments/rejected`);
    const unsubscribe = onValue(rejectedRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getRejected() {
  yield takeEvery(actions.PATIENT_APPOINTMENTS_FETCH, function* () {
    const rejectedListener = yield call(createRejectedListener);
    while (true) {
      const rejected = yield take(rejectedListener);
      const normalizedRejected = _.map(rejected, (val, id) => {
        const { duration } = val;
        return {
          ...val,
          start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
          id,
          end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
          rejectedSection: true,
          allDay: false,
        };
      });
      yield put({
        type: actions.PATIENT_REJECTED_FETCH_SUCCESS,
        payload: {
          id: 'rejected',
          data: normalizedRejected,
        },
      });
    }
  });
}

function getTelemedDocumentFromDB() {
  const db = getDatabase();
  return new Promise((resolve) => {
    onValue(ref(db, 'utils/telemed'), resolve, { onlyOnce: true });
  });
}

export function* getTelemedDocument() {
  yield takeEvery(actions.FETCH_TELEMED_DOCUMENT_REQUEST, function* () {
    try {
      yield put({ type: actions.FETCHING_TELEMED_DOCUMENT });
      const telemed = yield call(getTelemedDocumentFromDB);
      if (telemed.val()) {
        const { version, document } = telemed.val();
        yield put({
          type: actions.FETCH_TELEMED_DOCUMENT_SUCCESS,
          payload: {
            version,
            document,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.FETCH_TELEMED_DOCUMENT_ERROR });
    }
  });
}

function acceptTelemedComplianceOnDB(
  queryConfirmed,
) {
  const bodyParameters = {
    queryConfirmed,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/acceptTelemedCompliance`,
    bodyParameters,
  );
}

export function* requestAcceptTelemedCompliance() {
  yield takeLatest(actions.ACCEPT_TELEMED_COMPLIANCE_REQUEST, function* () {
    try {
      yield put({ type: actions.UPDATING_TELEMED_COMPLIANCE });
      const { queryConfirmed } = yield select(getAppointmentInfoFromStore);
      const { status } = yield call(acceptTelemedComplianceOnDB, queryConfirmed);
      if (status === 201) {
        yield all([
          // put({ type: actions.ACCEPT_TELEMED_COMPLIANCE_SUCCESS }),
          put({
            type: actions.APPOINTMENT_INFO_REQUEST,
            payload: queryConfirmed.queryConfirmedKey || queryConfirmed.batchedId,
          }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.ACCEPT_TELEMED_COMPLIANCE_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getConfirmed),
    fork(getPending),
    fork(getRejected),
    fork(requestAppointmentInfo),
    fork(requestRespondAppointment),
    fork(getTelemedDocument),
    fork(requestAcceptTelemedCompliance),
  ]);
}

import _ from 'lodash';
import actions from './actions';

const initState = {};

export default function entrancePersistReducer(state = initState, action) {
  switch (action.type) {
    case actions.PERSIST_NEW_RECORD:
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          newRecord: {
            ...(state[action.payload.uid]?.newRecord || {}),
            [action.payload.id]: {
              ...(state[action.payload.uid]?.newRecord?.[action.payload.id] || {}),
              [action.payload.mode]: action.payload.values,
            },
          },
        },
      };
    case actions.PERSIST_NEW_PRESCRIPTION:
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          newPrescription: {
            ...(state[action.payload.uid]?.newPrescription || {}),
            [action.payload.id]: _.orderBy(action.payload.prescriptions, ['id'], ['desc']),
          },
        },
      };
    case actions.PERSIST_NEW_PRESCRIPTION_GENERAL_OBS:
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          newPrescriptionGeneralObs: {
            ...(state[action.payload.uid]?.newPrescriptionGeneralObs || {}),
            [action.payload.id]: action.payload.obs,
          },
        },
      };
    case actions.PERSIST_NEW_CERTIFICATE:
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          newCertificate: {
            ...(state[action.payload.uid]?.newCertificate || {}),
            [action.payload.id]: action.payload.certificate,
          },
        },
      };
    case actions.PERSIST_NEW_MEDICAL_REPORT:
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          newMedicalReport: {
            ...(state[action.payload.uid]?.newMedicalReport || {}),
            [action.payload.id]: action.payload.medicalReport,
          },
        },
      };
    case actions.PERSIST_NEW_OTHER_DOCUMENT:
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          newOtherDocument: {
            ...(state[action.payload.uid]?.newOtherDocument || {}),
            [action.payload.id]: action.payload.otherDocument,
          },
        },
      };
    case actions.DISCARD_PERSISTED_ENTRANCE: {
      const newEntrance = _.cloneDeep(state[action.payload.uid][action.payload.mode]);
      delete newEntrance[action.payload.id];
      return {
        ...state,
        [action.payload.uid]: {
          ...state[action.payload.uid],
          [action.payload.mode]: newEntrance,
        },
      };
    }
    default:
      return state;
  }
}

import _ from 'lodash';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Big from 'big.js';
import {
  RRule,
} from 'rrule';

export function timeDifference(givenTime) {
  const newGivenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - newGivenTime.getTime();
  const numberEnding = (number) => (number > 1 ? 's' : '');
  const number = (num) => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(newGivenTime.getUTCMonth() + 1);
      const day = number(newGivenTime.getUTCDate());
      const year = newGivenTime.getUTCFullYear();
      return `Solicitação feita em ${day}/${month}/${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `Solicitação feita ${days} dia${numberEnding(days)} atrás`;
      }
      const months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
      const month = months[newGivenTime.getUTCMonth()];
      const day = number(newGivenTime.getUTCDate());
      // return `${day} ${month}`;
      return `Solicitação feita no dia ${day} de ${month}.`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `Solicitação feita ${hours} hora${numberEnding(hours)} atrás`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `Solicitação feita ${minutes} minuto${numberEnding(minutes)} atrás`;
    }
    return 'Solicitação feita alguns segundos atrás';
  };
  return getTime();
}

export function regexPhone(mode, phone) {
  const mobileReg = /^\(?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
  const phoneReg = /^\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  let hasValidNumber = true;
  // if (!_.isUndefined(userProfile.whatsapp) && userProfile.whatsapp.match(mobileReg)) {
  //   // return true;
  //   hasValidNumber = true;
  // }
  if (mode === 'phone' && phone) {
    hasValidNumber = phone.match(phoneReg);
  }
  if ((mode === 'mobile' || mode === 'whatsapp') && phone) {
    hasValidNumber = phone.match(mobileReg);
  }
  return !!hasValidNumber;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return true;
  }
  if (email && re.test(email)) {
    return true;
  }
  return false;
  // return re.test(email);
}

export function getBirthday(value) {
  if (moment(value, 'MM-DD-YYYY', true).isValid() || moment(value, 'MM-DD-YYYY HH:mm:ss', true).isValid()) {
    return moment(value, 'MM-DD-YYYY').format('DD/MM/YYYY');
  }
  if (moment(value, 'DD/MM/YYYY', true).isValid() || moment(value, 'DD/MM/YYYY HH:mm:ss', true).isValid()) {
    return value;
  }
  if (moment(value, 'YYYY-MM-DD', true).isValid() || moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
    return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }
  return value;
}

export function getAge(birthday, showOnlyYear = false, showOnlyNumbers = false) {
  if (birthday) {
    let age = '';
    let momentAge = 0;
    let years = 0;
    let months = 0;
    let days = 0;
    if (moment(birthday, 'DD/MM/YYYY', true).isValid()) {
      momentAge = moment(birthday, 'DD/MM/YYYY');
      years = moment().diff(momentAge, 'years');
      momentAge.add(years, 'years');
      months = moment().diff(momentAge, 'months');
      momentAge.add(months, 'months');
      days = moment().diff(momentAge, 'days');
    } else if (moment(birthday, 'YYYY-MM-DD', true).isValid()) {
      momentAge = moment(birthday, 'YYYY-MM-DD');
      years = moment().diff(momentAge, 'years');
      momentAge.add(years, 'years');
      months = moment().diff(momentAge, 'months');
      momentAge.add(months, 'months');
      days = moment().diff(momentAge, 'days');
    } else if (moment(birthday, 'MM-DD-YYYY', true).isValid()) {
      momentAge = moment(birthday, 'MM-DD-YYYY');
      years = moment().diff(momentAge, 'years');
      momentAge.add(years, 'years');
      months = moment().diff(momentAge, 'months');
      momentAge.add(months, 'months');
      days = moment().diff(momentAge, 'days');
    } else {
      return '-';
    }
    if (years < 1) {
      if (months > 0 && days > 0) {
        age = `${months} ${months <= 1 ? 'mês' : 'meses'} e ${days} ${days <= 1 ? 'dia' : 'dias'}`;
      } else if (months > 0 && days === 0) {
        age = `${months} ${months <= 1 ? 'mês' : 'meses'}`;
      } else if (months === 0 && days > 0) {
        age = `${days} ${days <= 1 ? 'dia' : 'dias'}`;
      }
    } else if (years < 15) {
      if (months > 0) {
        age = `${years} ${years === 1 ? 'ano' : 'anos'} e ${months} ${months <= 1 ? 'mês' : 'meses'}`;
      } else if (months === 0) {
        age = `${years} ${years === 1 ? 'ano' : 'anos'}`;
      }
    } else {
      age = `${years} ${years === 1 ? 'ano' : 'anos'}`;
    }
    if (showOnlyYear) {
      if (years < 1) {
        if (months > 0 && days > 0) {
          age = `${months} ${months <= 1 ? 'mês' : 'meses'} e ${days} ${days <= 1 ? 'dia' : 'dias'}`;
        } else if (months > 0 && days === 0) {
          age = `${months} ${months <= 1 ? 'mês' : 'meses'}`;
        } else if (months === 0 && days > 0) {
          age = `${days} ${days <= 1 ? 'dia' : 'dias'}`;
        }
      }
      age = `${years} ${years === 1 ? 'ano' : 'anos'}`;
    }
    if (showOnlyNumbers) {
      return parseInt(age.replace(/\D/g, ''), 10);
    }
    return age;
  }
  return '-';
}

export function checkEnabledDays(agendasArr, selectedAgenda) {
  if (agendasArr) {
    const foundAgenda = agendasArr.find((el) => el.id === selectedAgenda);
    if (foundAgenda?.scheduleSettings?.rules) {
      return Object.keys(foundAgenda?.scheduleSettings.rules).map((item) => item);
    }
  }
  return ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
}

function degreesToRadians(degrees) { return (degrees * Math.PI) / 180; }

/**
 * Calculates the distance, in kilometers, between two locations, via the
 * Haversine formula. Note that this is approximate due to the fact that
 * the Earth's radius varies between 6356.752 km and 6378.137 km.
 *
 * @param {Object} location1 The first location given as .latitude and .longitude
 * @param {Object} location2 The second location given as .latitude and .longitude
 * @return {number} The distance, in kilometers, between the inputted locations.
 */
export function getDistance(location1, location2) {
  const radius = 6371; // Earth's radius in kilometers
  const latDelta = degreesToRadians(location2.latitude - location1.latitude);
  const lonDelta = degreesToRadians(location2.longitude - location1.longitude);

  const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2))
    + (Math.cos(degreesToRadians(location1.latitude))
      * Math.cos(degreesToRadians(location2.latitude))
      * Math.sin(lonDelta / 2)
      * Math.sin(lonDelta / 2));

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return radius * c;
}

export function recordStoreEnabler(recordsToStore) {
  let enableSaveButton = false;
  if (recordsToStore?.optical) {
    Object.keys(recordsToStore.optical).forEach((key) => {
      if (key.includes('field') && recordsToStore.optical[key]) {
        enableSaveButton = true;
      }
    });
  }
  if (recordsToStore?.pediatric) {
    if (Object.values(recordsToStore.pediatric).some((item) => item)) {
      enableSaveButton = true;
    }
  }
  return enableSaveButton;
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay],
  );

  return debouncedValue;
}

export function getFullPaymentCardTaxes(total, tax) {
  return new Big(total).times(new Big(tax).div(100)).round(3, Big.roundHalfEven).toNumber();
}

export function getSplitPaymentCardTaxes(
  splitOptions,
  splittedMonths,
  subtotal,
) {
  const { administrationTax } = splitOptions;
  const admTaxInCash = new Big(subtotal).times(new Big(administrationTax).div(100));
  if (splitOptions.splittedMode === 'individual') {
    const currentSplitValue = new Big(subtotal).div(splittedMonths);
    let totalValueMinusTaxes = new Big(0);
    for (let i = 0; i < splittedMonths; i += 1) {
      // Each iteration in a splitted month ('parcela').
      let tax = 0;
      // Setting the tax to use in this iteration.
      if (splitOptions.individualSplittedTaxes[i]) {
        tax = splitOptions.individualSplittedTaxes[i].value;
      } else {
        const len = splitOptions.individualSplittedTaxes.length;
        tax = splitOptions.individualSplittedTaxes[len - 1].value;
      }
      if (splitOptions.individualWithCompoundMethod) {
        // Compound = 'juros compostos'.
        const currentSplitValueMinusTaxes = currentSplitValue.div(
          new Big(1).plus(new Big(tax).div(100)).pow(i + 1),
        );
        totalValueMinusTaxes = totalValueMinusTaxes.plus(currentSplitValueMinusTaxes);
      } else {
        const currentSplitValueMinusTaxes = currentSplitValue.div(
          new Big(1).plus(new Big(tax).div(100)),
        );
        totalValueMinusTaxes = totalValueMinusTaxes.plus(currentSplitValueMinusTaxes);
      }
    }
    const taxesInCash = new Big(subtotal).minus(totalValueMinusTaxes);
    const totalTaxesInCash = admTaxInCash.plus(taxesInCash);
    return totalTaxesInCash.round(3, Big.roundHalfEven).toNumber();
  }
  if (splitOptions.splittedMode === 'interval') {
    let foundItem = null;
    // Getting the correct interval.
    splitOptions.intervalSplittedTaxes.some((el, j) => {
      if (el.splits === splittedMonths) {
        foundItem = el;
        return true;
      }
      if (el.splits > splittedMonths) {
        if (j === 0) {
          foundItem = el;
        } else {
          foundItem = splitOptions.intervalSplittedTaxes[j - 1];
        }
        return true;
      }
      return false;
    });
    if (foundItem) {
      const taxesInCash = new Big(subtotal).times(new Big(foundItem.value).div(100));
      const totalTaxesInCash = admTaxInCash.plus(taxesInCash);
      return totalTaxesInCash.round(3, Big.roundHalfEven).toNumber();
      // const totalMinusTaxes = new Big(subtotal).minus(new Big(subtotal).times(new Big(foundItem.value).div(100)));
      // const currentSplitValue = totalMinusTaxes.div(splittedMonths);
    }
  }
  return 0;
}

export function normalizeDelta(delta) {
  if (delta) {
    const copyDelta = _.cloneDeep(delta);
    if (copyDelta.ops) {
      copyDelta.ops.forEach((el, index) => {
        if (!el) {
          copyDelta.ops[index] = {};
          copyDelta.ops[index].insert = '';
        }
        if (el && !Object.prototype.hasOwnProperty.call(el, 'insert')) {
          copyDelta.ops[index].insert = '';
        }
      });
      return copyDelta;
    }
  }
  return delta;
}

export function sanitizeStringForRrule(string) {
  const newString = string.charAt(0).toUpperCase() + string.slice(1);
  const lastChar = newString.slice(-1);
  if (lastChar === '°') {
    return newString.substring(0, newString.length - 1);
  }
  return newString;
}

export const BY_WEEK_DAY_ID = {
  dom: RRule.SU,
  seg: RRule.MO,
  ter: RRule.TU,
  qua: RRule.WE,
  qui: RRule.TH,
  sex: RRule.FR,
  sáb: RRule.SA,
};

export const PORTUGUESE = {
  dayNames: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dzembro',
  ],
};

export const tokensPT = {
  '(~ approximate)': '(~ aproximado)',
  th: '°',
  nd: '°',
  rd: '°',
  st: '°',
  and: 'e',
  every: 'cada',
  days: 'dia(s)',
  weekdays: 'dia(s) útil(eis)',
  weeks: 'semana(s)',
  hours: 'hora(s)',
  minutes: 'minuto(s)',
  months: 'mês(es)',
  years: 'ano(s)',
  day: 'dia',
  weekday: 'dia útil',
  week: 'semana',
  hour: 'hora',
  minute: 'minuto',
  month: 'mês',
  year: 'ano',
  on: 'em',
  in: 'em',
  'on the': 'no(a)',
  at: 'às',
  the: 'o(s)',
  first: 'primeiro(a)',
  second: 'segundo(a)',
  third: 'terceiro(a)',
  last: 'último(a)',
  for: 'por',
  time: 'vez',
  times: 'vezes',
  until: 'até',
  monday: 'segunda-feira',
  tuesday: 'terça-feira',
  wednesday: 'quarta-feira',
  thursday: 'quinta-feira',
  friday: 'sexta-feira',
  saturday: 'sábado',
  sunday: 'domingo',
  january: 'janeiro',
  february: 'fevereiro',
  march: 'março',
  april: 'abril',
  may: 'maio',
  june: 'junho',
  july: 'julho',
  august: 'agosto',
  september: 'setembro',
  october: 'outubro',
  november: 'novembro',
  december: 'dezembro',
};

import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  prescriptions: {},
  newPrescription: {},
  newPrescriptionGeneralObs: {},
  updatingPrescriptionModel: false,
  removingPrescriptionModel: false,
  updatingMedicineModel: false,
  removingMedicineModel: false,
  lastPrescription: {},
  noNextPrescriptionPage: {},
};

export default function prescriptionsReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SET_NEW_PRESCRIPTION:
      return {
        ...state,
        newPrescription: _.cloneDeep(action.payload.newPrescription),
      };
    case actions.SET_NEW_PRESCRIPTION_GENERAL_OBS:
      return {
        ...state,
        newPrescriptionGeneralObs: action.payload.newPrescriptionGeneralObs,
      };
    case actions.PATIENT_PRESCRIPTIONS_FETCH_SUCCESS:
      return {
        ...state,
        updatingPrescriptions: false,
        savingHidePrescription: false,
        prescriptions: {
          ...state.prescriptions,
          [action.payload.id]: action.payload.prescriptions,
        },
        lastPrescription: {
          ...state.lastPrescription,
          [action.payload.id]: action.payload.lastPrescription,
        },
        noNextPrescriptionPage: {
          ...state.noNextPrescriptionPage,
          [action.payload.id]: action.payload.noNextPrescriptionPage,
        },
      };
    case actions.PRESCRIPTION_SECTION_START_UPDATE:
      return {
        ...state,
        updatingPrescriptions: true,
      };
    case actions.SAVE_PRESCRIPTION_ERROR:
      return {
        ...state,
        updatingPrescriptions: null,
      };
    case actions.CHANGE_PRESCRIPTION:
      return {
        ...state,
        discardedNewPrescription: false,
        newPrescription: {
          ...state.newPrescription,
          [action.payload.id]: action.payload.prescriptions,
          // [action.payload.id]: _.orderBy(action.payload.prescriptions, ['id'], ['desc']),
        },
        newPrescriptionCopy: action.payload.copy,
      };
    case actions.CHANGE_PRESCRIPTION_GENERAL_OBS:
      return {
        ...state,
        discardedNewPrescription: false,
        newPrescriptionGeneralObs: {
          ...state.newPrescriptionGeneralObs,
          [action.payload.id]: action.payload.obs,
        },
      };
    case actions.DISCARD_PRESCRIPTION: {
      const newPrescription = _.cloneDeep(state.newPrescription);
      delete newPrescription[action.payload.id];
      const newPrescriptionGeneralObs = _.cloneDeep(state.newPrescriptionGeneralObs);
      delete newPrescriptionGeneralObs[action.payload.id];
      return {
        ...state,
        discardedNewPrescription: true,
        // newPrescription: {
        //   ...state.newPrescription,
        //   [action.payload.id]: [],
        // },
        newPrescription,
        newPrescriptionGeneralObs,
        newPrescriptionCopy: false,
      };
    }
    case actions.RESET_PRESCRIPTION:
      return {
        ...state,
        newPrescription: {
          ...state.newPrescription,
          [action.payload.id]: [],
        },
        newPrescriptionCopy: false,
      };
    case actions.RESET_PRESCRIPTION_GENERAL_OBS:
      return {
        ...state,
        newPrescriptionGeneralObs: {
          ...state.newPrescriptionGeneralObs,
          [action.payload.id]: [],
        },
      };
    case actions.PRESCRIPTION_MODELS_FETCH_SUCCESS:
      return {
        ...state,
        prescriptionModels: action.payload.models,
        updatingPrescriptionModel: false,
        removingPrescriptionModel: false,
      };
    case actions.SAVE_PRESCRIPTION_MODEL_ERROR:
      return {
        ...state,
        updatingPrescriptionModel: null,
      };
    case actions.REMOVE_PRESCRIPTION_MODEL_ERROR:
      return {
        ...state,
        removingPrescriptionModel: null,
      };
    case actions.START_SAVING_PRESCRIPTION_MODEL:
      return {
        ...state,
        updatingPrescriptionModel: true,
      };
    case actions.START_REMOVING_PRESCRIPTION_MODEL:
      return {
        ...state,
        removingPrescriptionModel: action.payload,
      };
    case actions.FETCH_PRESCRIPTION_SETTINGS_SUCCESS:
      return {
        ...state,
        prescriptionSettings: action.payload,
        updatingUseDefaultPrescription: false,
      };
    case actions.UPDATING_USE_DEFAULT_PRESCRIPTION:
      return {
        ...state,
        updatingUseDefaultPrescription: true,
      };
    case actions.CHANGE_USE_DEFAULT_PRESCRIPTION_ERROR:
      return {
        ...state,
        updatingUseDefaultPrescription: null,
      };
    case actions.GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS_SUCCESS:
      return {
        ...state,
        showHiddenPrescriptions: action.value,
        savingHidePrescription: false,
      };
    case actions.CHANGING_HIDE_PRESCRIPTION:
      return {
        ...state,
        savingHidePrescription: action.payload,
      };
    case actions.CHANGE_HIDE_PRESCRIPTION_ERROR:
      return {
        ...state,
        savingHidePrescription: null,
      };
    default:
      return state;
  }
}

const actions = {
  PATIENT_APPOINTMENTS_FETCH: 'PATIENT_APPOINTMENTS_FETCH',
  PATIENT_PENDING_FETCH_SUCCESS: 'PATIENT_PENDING_FETCH_SUCCESS',
  PATIENT_CONFIRMED_FETCH_SUCCESS: 'PATIENT_CONFIRMED_FETCH_SUCCESS',
  PATIENT_REJECTED_FETCH_SUCCESS: 'PATIENT_REJECTED_FETCH_SUCCESS',
  APPOINTMENT_INFO_REQUEST: 'APPOINTMENT_INFO_REQUEST',
  APPOINTMENT_INFO_REQUEST_SUCCESS: 'APPOINTMENT_INFO_REQUEST_SUCCESS',
  APPOINTMENT_INFO_REQUEST_PENDING: 'APPOINTMENT_INFO_REQUEST_PENDING',
  APPOINTMENT_INFO_REQUEST_REJECTED: 'APPOINTMENT_INFO_REQUEST_REJECTED',
  PATIENT_RESPONDED_REQUEST: 'PATIENT_RESPONDED_REQUEST',
  PATIENT_RESPONDED_REQUEST_SUCCESS: 'PATIENT_RESPONDED_REQUEST_SUCCESS',
  PATIENT_RESPONDED_REQUEST_PENDING: 'PATIENT_RESPONDED_REQUEST_PENDING',
  PATIENT_RESPONDED_REQUEST_REJECTED: 'PATIENT_RESPONDED_REQUEST_REJECTED',
  PATIENT_RESPONDED_REQUEST_ERROR: 'PATIENT_RESPONDED_REQUEST_ERROR',
  FETCH_TELEMED_DOCUMENT_REQUEST: 'FETCH_TELEMED_DOCUMENT_REQUEST',
  FETCH_TELEMED_DOCUMENT_SUCCESS: 'FETCH_TELEMED_DOCUMENT_SUCCESS',
  FETCH_TELEMED_DOCUMENT_ERROR: 'FETCH_TELEMED_DOCUMENT_ERROR',
  FETCHING_TELEMED_DOCUMENT: 'FETCHING_TELEMED_DOCUMENT',
  ACCEPT_TELEMED_COMPLIANCE_REQUEST: 'ACCEPT_TELEMED_COMPLIANCE_REQUEST',
  ACCEPT_TELEMED_COMPLIANCE_SUCCESS: 'ACCEPT_TELEMED_COMPLIANCE_SUCCESS',
  ACCEPT_TELEMED_COMPLIANCE_ERROR: 'ACCEPT_TELEMED_COMPLIANCE_ERROR',
  UPDATING_TELEMED_COMPLIANCE: 'UPDATING_TELEMED_COMPLIANCE',
  requestsFetch: () => ({
    type: actions.PATIENT_APPOINTMENTS_FETCH,
  }),
  requestAppointmentInfo: (shortLink) => ({
    type: actions.APPOINTMENT_INFO_REQUEST,
    payload: shortLink,
  }),
  respondAppointment: (shortLink, action, userLocal, appointment) => ({
    type: actions.PATIENT_RESPONDED_REQUEST,
    payload: {
      shortLink,
      action,
      userLocal,
      appointment,
    },
  }),
  fetchTelemedDocument: () => ({
    type: actions.FETCH_TELEMED_DOCUMENT_REQUEST,
  }),
  acceptTelemedCompliance: () => ({
    type: actions.ACCEPT_TELEMED_COMPLIANCE_REQUEST,
  }),
};
export default actions;

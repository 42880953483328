import {
  all,
  takeEvery,
  fork,
  call,
  select,
} from 'redux-saga/effects';
import actions from './actions';

const getGisClientFromStore = (state) => state.Gapi.gisClient;

const getGisUserPayloadFromStore = (state) => state.Gapi.gisUserPayload;

function requestAccessToken(gisClient, OverridableTokenClientConfig = {}) {
  return gisClient.requestAccessToken(OverridableTokenClientConfig);
}

export function* gisAuthorizationRequest() {
  yield takeEvery(actions.GIS_AUTHORIZATION_REQUEST, function* () {
    try {
      const gisClient = yield select(getGisClientFromStore);
      const gisUserPayload = yield select(getGisUserPayloadFromStore);
      if (gisClient) {
        if (gisUserPayload?.email) {
          const OverridableTokenClientConfig = {
            hint: gisUserPayload.email,
          };
          yield call(requestAccessToken, gisClient, OverridableTokenClientConfig);
        } else {
          yield call(requestAccessToken, gisClient);
        }
      }
      // yield put({
      //   type: actions.GIS_AUTHORIZATION_SUCCESS,
      // });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(gisAuthorizationRequest),
  ]);
}

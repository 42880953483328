import _ from 'lodash';
import {
  getDatabase,
  ref,
  onValue,
} from 'firebase/database';
import {
  all,
  takeEvery,
  fork,
  call,
  put,
} from 'redux-saga/effects';
import actions from './actions';

function getCidFromDB() {
  const db = getDatabase();
  return new Promise((resolve) => {
    onValue(ref(db, 'utils/cid'), resolve, { onlyOnce: true });
  });
}

export function* getCidRequest() {
  yield takeEvery(actions.CID_FETCH_REQUEST, function* () {
    let cidFromDB = null;
    try {
      // yield localStorage.removeItem('cid');
      const cid = yield localStorage.getItem('cid');
      if (_.isNull(cid)) {
        // No CID saved yet
        cidFromDB = yield call(getCidFromDB);
        if (cidFromDB.val()) {
          yield localStorage.setItem('cid', JSON.stringify(cidFromDB.val()));
          let formatedCid = [];
          formatedCid = cidFromDB.val().map((el) => (
            {
              ...el,
              value: `${el.id} - ${el.name}`,
            }
          ));
          yield put({
            type: actions.CID_FETCH_SUCCESS,
            payload: formatedCid,
          });
        }
      } else {
        // CID is in localStorage
        let formatedCid = [];
        formatedCid = JSON.parse(cid).map((el) => (
          {
            ...el,
            value: `${el.id} - ${el.name}`,
          }
        ));
        yield put({
          type: actions.CID_FETCH_SUCCESS,
          payload: formatedCid,
        });
      }
    } catch (error) {
      console.warn(error);
      if (cidFromDB && cidFromDB.val()) {
        // If fails to save in localStorage but already downloaded the data,
        // send it to reducer so user can use it.
        let formatedCid = [];
        formatedCid = cidFromDB.val().map((el) => (
          {
            ...el,
            value: `${el.id} - ${el.name}`,
          }
        ));
        yield put({
          type: actions.CID_FETCH_SUCCESS,
          payload: formatedCid,
        });
      } else {
        yield put({ type: actions.CID_FETCH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCidRequest),
  ]);
}

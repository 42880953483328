import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  get,
  update,
  push,
  child,
  // startAfter,
  limitToFirst,
  query as dbQuery,
  orderByChild,
  startAt,
  // equalTo,
  serverTimestamp as dbServerTimestamp,
} from 'firebase/database';
import {
  getStorage,
  ref as sRef,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  // deleteObject,
  listAll,
} from 'firebase/storage';
import moment from 'moment-timezone';
import axios from 'axios';
import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  takeLatest,
  take,
  spawn,
} from 'redux-saga/effects';
import { v1 as uuidv1 } from 'uuid';
import { notification } from '../../components';
import actions from './actions';
import appActions from '../app/actions';
import authActions from '../auth/actions';
import profileActions from '../profile/actions';
import { normalizeDelta } from '../../helpers/utility';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getIsProfessionalStore = (state) => state.Auth.professional;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getProfileFromStore = (state) => state.Profile.profile;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const newRecordFromStore = (state) => state.Records.newRecord;

const newRecordMaskOnDBFromStore = (state) => state.Records.newRecordMaskOnDB;

const getCustomUserFromStore = (state) => state.CustomUsers.customUsers;

const getRecordsFromStore = (state) => state.Records.records;

const getLastRecordFromStore = (state) => state.Records.lastRecord;

const getPatientFilesFromStore = (state) => state.Records.patientFiles;

function getRecordsFromDB(seectedId, addressUid, unified = {}, mainUser, lastItem) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (lastItem) {
      const databaseRef = ref(db, `unified/${unified.id}/records/${seectedId}`);
      return get(dbQuery(
        databaseRef,
        orderByChild('queryTimestamp'),
        startAt(lastItem.queryTimestamp, lastItem.id),
        limitToFirst(6),
      ));
    }
    const databaseRef = ref(db, `unified/${unified.id}/records/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      limitToFirst(5),
    ));
  }
  if (lastItem) {
    const databaseRef = ref(db, `users/${uid}/records/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      startAt(lastItem.queryTimestamp, lastItem.id),
      limitToFirst(6),
    ));
  }
  const databaseRef = ref(db, `users/${uid}/records/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    limitToFirst(5),
  ));
}

function getPediatricGraphDataFromDB(seectedId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/customRecords/pediatric/${seectedId}`));
  }
  return get(child(dbRef, `users/${uid}/customRecords/pediatric/${seectedId}`));
}

function getPatientRecordFormFromDB(seectedId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/recordForm/data/${seectedId}`));
    // return db.ref(`/unified/${unified.id}/recordForm/data/${seectedId}`).once('value');
  }
  return get(child(dbRef, `users/${uid}/recordForm/data/${seectedId}`));
  // return db.ref(`users/${uid}/recordForm/data/${seectedId}`).once('value');
}

function getPatientFilesFromDB(seectedId, folder, addressUid, unified = {}, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const storage = getStorage();
  let path = `users/${uid}/records/${seectedId}`;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    path = `unified/${unified.id}/records/${seectedId}`;
  }
  const listRef = sRef(storage, folder || path);
  return listAll(listRef);
}

function getUrlForImage(path) {
  const storage = getStorage();
  return getDownloadURL(sRef(storage, path));
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function getMetadataForImage(path) {
  const storage = getStorage();
  return getMetadata(sRef(storage, path));
}

function convertBase64ToStorage(
  mode,
  patientId,
  recordId,
  index,
  image,
  idToken,
  addressUid,
  unified = {},
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let unifiedId = false;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    unifiedId = unified.id;
  }
  const bodyParameters = {
    uid,
    unifiedId,
    mode,
    patientId,
    recordId,
    index,
    image,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/convertBase64ToStorage`,
    bodyParameters,
    config,
  );
}

export function* getFiles() {
  yield takeEvery(actions.GET_PATIENT_FILES_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const filesFolders = yield call(getPatientFilesFromDB, seectedId, '', addressUid, unified, mainUser);
      let files = [];
      const filesAux = yield all(filesFolders?.prefixes.map((folderRef) => call(getPatientFilesFromDB, seectedId, folderRef.fullPath, addressUid, unified, mainUser)));
      filesAux.forEach((filesFolder) => {
        const filesArray = filesFolder.items.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const path = item._location.path_;
          // const bucket = item['_location']['bucket'];
          const pathSplit = path.split('/');
          const name = pathSplit.pop();
          const uid = uuidv1();
          // const nameSplit = name.split('.');
          // const format = nameSplit.pop();
          // let normalizedFormat = '';
          // if (format === 'pdf') {
          //   normalizedFormat = `application/${format}`;
          // } else if (format === 'csv' || format === 'xls') {
          //   normalizedFormat = `text/${format}`;
          // } else {
          //   normalizedFormat = `image/${format}`;
          // }
          const object = {
            name,
            // type: normalizedFormat,
            uid,
            // url: `gs://${bucket}/${path}`,
            url: path,
          };
          return object;
        });
        files = [...files, ...filesArray];
      });
      const filesUrl = yield all(files.map((itemObject) => call(getUrlForImage, itemObject.url)));
      const filesMetadata = yield all(files.map((itemObject) => call(getMetadataForImage, itemObject.url)));
      files = files.map((item, index) => ({
        ...filesMetadata[index],
        ...item,
        type: filesMetadata[index].contentType || 'file',
        url: filesUrl[index],
      }));
      yield put({
        type: actions.GET_PATIENT_FILES_REQUEST_SUCCESS,
        payload: {
          files: _.orderBy(files, ['timeCreated'], ['desc']),
          id: seectedId,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_PATIENT_FILES_REQUEST_ERROR,
      });
    }
  });
}

export function* getRecords() {
  yield takeEvery(actions.GET_PATIENT_RECORDS_REQUEST, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const lastRecordFromStore = yield select(getLastRecordFromStore);
      const currentRecords = yield select(getRecordsFromStore);
      let lastRecordPagination = null;
      if (lastRecordFromStore[seectedId] && action?.payload?.paginate) {
        lastRecordPagination = lastRecordFromStore[seectedId];
      }
      const records = yield call(
        getRecordsFromDB,
        seectedId,
        addressUid,
        unified,
        mainUser,
        lastRecordPagination,
      );
      const pediatricGraphData = yield call(getPediatricGraphDataFromDB, seectedId, addressUid, unified, mainUser);
      const recordForm = yield call(getPatientRecordFormFromDB, seectedId, addressUid, unified, mainUser);
      const recordsArray = [];
      records.forEach((el) => {
        recordsArray.push({
          ...el.val(),
          id: el.key,
          key: el.val().timestamp || el.key,
        });
      });
      const lastRecord = recordsArray[recordsArray.length - 1];

      const recordsLength = recordsArray.length;
      const idToken = yield call(getIdToken);
      for (let i = 0; i < recordsLength; i += 1) {
        if (recordsArray[i]?.text?.ops?.length > 0) {
          const arrLength = recordsArray[i].text.ops.length;
          for (let j = 0; j < arrLength; j += 1) {
            if (recordsArray[i].text.ops[j].insert?.image && recordsArray[i].text.ops[j].insert?.image?.includes('data:image/')) {
              yield spawn(
                convertBase64ToStorage,
                'records',
                seectedId,
                recordsArray[i].id,
                j,
                recordsArray[i].text.ops[j].insert.image,
                idToken,
                addressUid,
                unified,
                mainUser,
              );
            }
          }
        }
      }

      if (lastRecordPagination && currentRecords[seectedId]) {
        yield put({
          type: actions.PATIENT_RECORDS_FETCH_SUCCESS,
          payload: {
            records: { ...currentRecords[seectedId], ...records.val() },
            pediatricGraphData: pediatricGraphData.val(),
            id: seectedId,
            recordForm: recordForm.val()?.list || [],
            lastRecord,
            noNextRecordPage: recordsArray.length < 5,
          },
        });
      } else {
        yield put({
          type: actions.PATIENT_RECORDS_FETCH_SUCCESS,
          payload: {
            records: records.val(),
            pediatricGraphData: pediatricGraphData.val(),
            id: seectedId,
            recordForm: recordForm.val()?.list || [],
            lastRecord,
            noNextRecordPage: recordsArray.length < 5,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function getRecordsMaskFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/recordMask`));
  // return db.ref(`users/${uid}/recordMask`).once('value');
}

function removeRecordEditorModelOnDB(id, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/recordMask/textModels/${id}`] = null;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* getRecordsMask() {
  yield takeLatest([
    actions.GET_RECORDS_MASK_REQUEST,
    authActions.LOGIN_SUCCESS,
  ], function* () {
    try {
      const isProfessional = yield select(getIsProfessionalStore);
      if (isProfessional) {
        yield put({
          type: actions.FETCHING_RECORD_MASK,
        });
        const mainUser = yield select(getMainUserFromStore);
        const records = yield call(getRecordsMaskFromDB, mainUser);
        let normalizedRecords = records.val();
        if (!normalizedRecords?.list) {
          normalizedRecords = {
            ...normalizedRecords,
            list: [{ mode: 'default' }],
          };
        }
        if (normalizedRecords.textModels) {
          const modelsToRemove = [];
          Object.keys(normalizedRecords.textModels).forEach((key) => {
            if (normalizedRecords.textModels[key].customTextField) {
              const foundMode = normalizedRecords.list.find((el) => el.mode === normalizedRecords.textModels[key].customTextField);
              if (!foundMode) {
                modelsToRemove.push(key);
              }
            }
          });
          if (modelsToRemove.length > 0) {
            yield all(modelsToRemove.map((id) => call(removeRecordEditorModelOnDB, id, mainUser)));
            yield put({
              type: actions.GET_RECORDS_MASK_REQUEST,
            });
          }
        }
        yield put({
          type: actions.GET_RECORDS_MASK_SUCCESS,
          payload: {
            records: normalizedRecords,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function saveRecordMaskOnDB(record, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/recordMask/text`] = normalizeDelta(record.text);
  if (record.list) {
    updates[`users/${uid}/recordMask/list`] = record.list;
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveRecordMaskRequest() {
  yield takeEvery(actions.SAVE_RECORD_MASK_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.RECORD_MASK_START_UPDATE,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveRecordMaskOnDB, action.payload, mainUser);
      yield put({
        type: actions.GET_RECORDS_MASK_REQUEST,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function saveOnlyValidRecords(newRecord) {
  let filteredNewRecord = _.cloneDeep(newRecord);
  if (newRecord?.files) {
    filteredNewRecord = _.omit(filteredNewRecord, 'files');
  }
  if (newRecord?.text) {
    const emptyEditor = {
      text: { ops: [] },
      files: [],
    };
    const parsedEmptyEditor = {
      text: { ops: [{ insert: '\n' }] },
      files: [],
    };
    let haveText = false;
    if (newRecord
      && newRecord.text
      && newRecord.text.ops
      && newRecord.text.ops.length > 0) {
      let textLength = false;
      newRecord.text.ops.forEach((item) => {
        if (item.insert) {
          if ((item.insert.image)
            || (item.insert.length > 0 && item.insert !== '\n')) {
            textLength = true;
          }
        }
      });
      if (!_.isEqual(newRecord.text.ops, emptyEditor.text.ops)
        && !_.isEqual(newRecord.text.ops, parsedEmptyEditor.text.ops)
        && textLength) {
        haveText = true;
      }
    }
    Object.keys(newRecord).forEach((field) => {
      if (field.includes('text') && field !== 'text') {
        let haveCustomText = false;
        if (newRecord
          && newRecord?.[field]
          && newRecord[field].ops
          && newRecord[field].ops.length > 0) {
          let textLength = false;
          newRecord[field].ops.forEach((item) => {
            if (item.insert) {
              if (item.insert.length > 0 && item.insert !== '\n') {
                textLength = true;
              }
            }
          });
          if (!_.isEqual(newRecord[field].ops, emptyEditor.text.ops)
            && !_.isEqual(newRecord[field].ops, parsedEmptyEditor.text.ops)
            && textLength) {
            haveCustomText = true;
          }
        }
        if (!haveCustomText) {
          filteredNewRecord = _.omit(filteredNewRecord, field);
        }
      }
    });

    // let textIsMaskOnly = false;
    // if (haveText
    //   && newRecordMaskOnDB
    //   && newRecordMaskOnDB.text
    //   && newRecordMaskOnDB.text.ops) {
    //   textIsMaskOnly = _.isEqual(newRecord.text.ops, newRecordMaskOnDB.text.ops);
    // }
    if (!haveText) {
      filteredNewRecord = _.omit(filteredNewRecord, 'text');
    }
  }
  if (newRecord?.optical) {
    let isValidOptic = false;
    Object.keys(newRecord.optical).forEach((key) => {
      if (key.includes('field') && newRecord.optical[key]) {
        isValidOptic = true;
      }
    });
    if (!isValidOptic) {
      filteredNewRecord = _.omit(filteredNewRecord, 'optical');
    }
  }
  if (newRecord?.pediatric) {
    let isValidPediatric = false;
    if (Object.values(newRecord.pediatric).some((item) => item)) {
      isValidPediatric = true;
    }
    if (!isValidPediatric) {
      filteredNewRecord = _.omit(filteredNewRecord, 'pediatric');
    }
  }
  return filteredNewRecord;
}

function saveRecordOnDB(
  records,
  seectedId,
  pushKey,
  timestamp,
  addressUid,
  unified = {},
  mainProfile = null,
  mainUser,
  customUsers,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const currentUid = currentUser.uid;
  const author = {};
  if (currentUid === mainProfile.id) {
    // It is the main account.
    author.firstName = mainProfile.firstName;
    author.lastName = mainProfile.lastName;
    author.uid = mainProfile.id;
  } else {
    // It is some of the 'customUsers' accounts.
    const foundProfile = customUsers.find((el) => el.id === currentUid);
    if (foundProfile) {
      author.firstName = foundProfile.firstName;
      author.lastName = foundProfile.lastName;
      author.uid = foundProfile.id;
      if (foundProfile.healthProfessional) {
        // It is a health professional account.
      } else {
        // Secretary account.
        author.notHealthProfessional = true;
      }
    }
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (records) {
      updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
      updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
      if (!_.isEmpty(author)) {
        author.unified = true;
        updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/professional`] = author;
      }
      Object.keys(records).forEach((item) => {
        if (item === 'pediatric') {
          const pediatricKey = push(child(ref(db), `customRecords/${unified.id}/pediatric/${seectedId}`)).key;
          updates[`unified/${unified.id}/customRecords/pediatric/${seectedId}/${pediatricKey}`] = { ...records[item], timestamp: timestamp.format() };
        }
        updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/${item}`] = records[item];
      });
    }
  } else if (records) {
    updates[`users/${uid}/records/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`users/${uid}/records/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    Object.keys(records).forEach((item) => {
      if (item === 'pediatric') {
        const pediatricKey = push(child(ref(db), `customRecords/${uid}/pediatric/${seectedId}`)).key;
        updates[`users/${uid}/customRecords/pediatric/${seectedId}/${pediatricKey}`] = { ...records[item], timestamp: timestamp.format() };
      }
      updates[`users/${uid}/records/${seectedId}/${pushKey}/${item}`] = records[item];
    });
    if (!_.isEmpty(author)) {
      updates[`users/${uid}/records/${seectedId}/${pushKey}/professional`] = author;
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function saveFilesOnDB(
  files,
  seectedId,
  pushKey,
  timestamp,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  const storage = getStorage();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return Promise.all(files.map((item) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      const fileRef = sRef(storage, `unified/${unified.id}/records/${seectedId}/${pushKey}/${item.name}`);
      return uploadBytes(fileRef, item.originFileObj);
      // return storageRef.child(`unified/${unified.id}/records/${seectedId}/${pushKey}/${item.name}`)
      //   .put(item.originFileObj);
    }
    const fileRef = sRef(storage, `users/${uid}/records/${seectedId}/${pushKey}/${item.name}`);
    return uploadBytes(fileRef, item.originFileObj);
    // return storageRef.child(`users/${uid}/records/${seectedId}/${pushKey}/${item.name}`)
    //   .put(item.originFileObj);
  }))
    .then((responseFiles) => (
      Promise.all(responseFiles.map((item) => (
        // item.ref.getDownloadURL()
        getDownloadURL(item.ref)
      )))
    ))
    .then((urls) => {
      const newFiles = urls.map((file, i) => ({
        url: file,
        name: files[i].name,
        uid: files[i].uid,
        type: files[i].type,
      }));
      const updates = {};
      if (unified.id && unified.address.some((a) => a === addressUid)) {
        updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/files`] = newFiles;
      } else {
        updates[`users/${uid}/records/${seectedId}/${pushKey}/files`] = newFiles;
      }
      return update(dbRef, updates);
      // return new Promise((resolve, reject) => {
      //   db.ref().update(updates, (error) => {
      //     if (error) {
      //       reject(new Error(error));
      //     } else {
      //       resolve(true);
      //     }
      //     return null;
      //   });
      // });
      // return urls;
    })
    .catch((error) => new Error(error));
}

function generatePushKey(seectedId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let pushKey = '';
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    pushKey = push(child(ref(db), `unified/${unified.id}/records/${seectedId}`)).key;
  } else {
    pushKey = push(child(ref(db), `users/${uid}/records/${seectedId}`)).key;
  }
  return pushKey;
}

export function* saveRecord() {
  yield takeEvery(actions.SAVE_RECORD_REQUEST, function* () {
    try {
      yield put({
        type: actions.RECORDS_SECTION_START_UPDATE,
      });
      yield call(sleep, 500);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const timestamp = moment().tz('America/Sao_Paulo');
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const newRecord = yield select(newRecordFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      // const newRecordMaskOnDB = yield select(newRecordMaskOnDBFromStore);
      const pushKey = generatePushKey(seectedId, addressUid, unified, mainUser);
      const validNewRecords = saveOnlyValidRecords(newRecord[seectedId]);
      const patientFiles = yield select(getPatientFilesFromStore);
      yield call(
        saveRecordOnDB,
        validNewRecords,
        seectedId,
        pushKey,
        timestamp,
        addressUid,
        unified,
        profile, // professional
        mainUser,
        customUsers,
      );
      yield call(
        saveFilesOnDB,
        newRecord[seectedId].files || [],
        seectedId,
        pushKey,
        timestamp,
        addressUid,
        unified,
        mainUser,
      );
      yield all([
        yield put({
          type: actions.GET_PATIENT_RECORDS_REQUEST,
        }),
        // yield put({
        //   type: actions.CHANGE_RECORD,
        //   payload: {
        //     records: {
        //       text: { ops: [] },
        //       files: [],
        //     },
        //     id: seectedId,
        //     textLength: 0,
        //   },
        // }),
        yield put({
          type: actions.DISCARD_NEW_RECORD_CHANGES_AFTER_SAVING,
          payload: {
            id: seectedId,
          },
        }),
        // yield put({
        //   type: actions.DISCARD_CUSTOM_RECORD_VALUE,
        // }),
        // yield put({
        //   type: appActions.CHANGE_SAVED_ALL_CHANGES,
        //   payload: {
        //     id: seectedId,
        //     obj: {
        //       record: true,
        //       recordMask: true,
        //       prescription: true,
        //       certificate: true,
        //       formSettings: true,
        //     },
        //   },
        // }),
        yield put({
          type: appActions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
          payload: {
            id: seectedId,
            field: 'record',
            fieldValue: true,
          },
        }),
      ]);
      if (newRecord[seectedId].files && patientFiles && patientFiles[seectedId]) {
        yield put({
          type: actions.GET_PATIENT_FILES_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      if (error?.message?.includes('contains a string greater than 10485760 utf8 bytes')) {
        notification(
          'error',
          'Não é possível adicionar imagens maiores que 10MB diretamente no editor de texto',
          'Utilize o método de "Anexar arquivo" logo abaixo do editor.',
        );
      } else {
        notification(
          'error',
          'Algo deu errado',
          'Tente novamente mais tarde.',
        );
      }
      yield put({
        type: actions.SAVE_RECORD_ERROR,
      });
    }
  });
}

function getGraphsFromDB(userId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `unified/${unified.id}/graphs/${userId}/pediatric`));
  }
  return get(child(dbRef, `users/${uid}/graphs/${userId}/pediatric`));
}

export function* getGraphs() {
  yield takeEvery(actions.GET_GRAPH_REQUEST, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const records = yield call(getGraphsFromDB, action.payload, addressUid, unified, mainUser);
      yield put({
        type: actions.GET_GRAPH_SUCCESS,
        payload: {
          graphs: {
            pediatric: records.val(),
          },
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function updatePediatricPlotOnDB(payload, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const {
    seectedId,
    key,
    value,
  } = payload;
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/customRecords/pediatric/${seectedId}/${key}/visible`] = value;
  } else {
    updates[`users/${uid}/customRecords/pediatric/${seectedId}/${key}/visible`] = value;
  }
  return update(dbRef, updates);
}

export function* updatePediatricPlot() {
  yield takeLatest(actions.UPDATE_PEDIATRIC_PLOT_POINT, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updatePediatricPlotOnDB,
        action.payload,
        addressUid,
        unified,
        mainUser,
      );
      const pediatricGraphData = yield call(getPediatricGraphDataFromDB, action.payload.seectedId, addressUid, unified, mainUser);
      yield put({
        type: actions.UPDATE_PEDIATRIC_PLOT_POINT_SUCCESS,
        payload: {
          id: action.payload.seectedId,
          pediatricGraphData: pediatricGraphData.val(),
        },
      });
    } catch (error) {
      console.warn(error);
      notification('warning', 'Tivemos um problema ao atualizar o ponto');
      yield put({
        type: actions.UPDATE_PEDIATRIC_PLOT_POINT_ERROR,
      });
    }
  });
}

function saveRecordEditorModelOnDB(model, textFieldId = null, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `users/${uid}/recordMask/textModels`)).key;
  const newModel = { ...model };
  if (textFieldId) {
    newModel.customTextField = textFieldId;
  }
  updates[`users/${uid}/recordMask/textModels/${pushKey}`] = newModel;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveRecordEditorModel() {
  yield takeEvery(actions.SAVE_RECORD_EDITOR_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_RECORD_EDITOR_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveRecordEditorModelOnDB, action.payload.model, action.payload.textFieldId, mainUser);
      yield all([
        yield put({
          type: actions.GET_RECORDS_MASK_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_RECORD_EDITOR_MODEL_ERROR });
    }
  });
}

export function* getRecordEditorModels() {
  yield takeEvery(actions.GET_RECORDS_MASK_SUCCESS, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      let newRecordMaskOnDB = yield select(newRecordMaskOnDBFromStore);
      if (_.isUndefined(newRecordMaskOnDB)) {
        yield take(actions.GET_RECORDS_MASK_SUCCESS);
        newRecordMaskOnDB = yield select(newRecordMaskOnDBFromStore);
      }
      const { textModels } = newRecordMaskOnDB;
      const parsedEmptyEditor = {
        text: { ops: [{ insert: '\n' }] },
      };
      if (!textModels && newRecordMaskOnDB?.text?.ops && !_.isEqual(newRecordMaskOnDB.text, parsedEmptyEditor.text)) {
        const defaultModel = {
          ops: newRecordMaskOnDB.text.ops,
        };
        yield call(
          saveRecordEditorModelOnDB,
          { name: 'Padrão', text: defaultModel },
          mainUser,
        );
        yield put({
          type: actions.GET_RECORDS_MASK_REQUEST,
        });
      } else {
        const editedModels = _.map(textModels, (val, id) => (
          { ...val, id }
        ));
        yield put({
          type: actions.RECORD_EDITOR_MODELS_FETCH_SUCCESS,
          payload: {
            // models: editedModels,
            models: _.orderBy(editedModels, [(el) => el.name.toLowerCase()]),
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.RECORD_EDITOR_MODELS_FETCH_SUCCESS,
        payload: {
          models: [],
        },
      });
      yield put({
        type: actions.RECORD_EDITOR_MODELS_FETCH_ERROR,
      });
    }
  });
}

export function* removeRecordEditorModel() {
  yield takeEvery(actions.REMOVE_RECORD_EDITOR_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_REMOVING_RECORD_EDITOR_MODEL,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeRecordEditorModelOnDB, action.payload.id, mainUser);
      yield all([
        yield put({
          type: actions.GET_RECORDS_MASK_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REMOVE_RECORD_EDITOR_MODEL_ERROR });
    }
  });
}

function updateRecordEditorModelOnDB({ model, id }, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/recordMask/textModels/${id}`] = model;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateRecordEditorModel() {
  yield takeEvery(actions.UPDATE_RECORD_EDITOR_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_RECORD_EDITOR_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(updateRecordEditorModelOnDB, action.payload, mainUser);
      yield all([
        yield put({
          type: actions.GET_RECORDS_MASK_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_RECORD_EDITOR_MODEL_ERROR });
    }
  });
}

function getRecordFormFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/recordForm/list`));
  // return db.ref(`users/${uid}/recordForm/list`).once('value');
}

export function* getRecordForm() {
  yield takeEvery(actions.GET_RECORD_FORM_REQUEST, function* () {
    try {
      yield put({
        type: actions.FETCHING_RECORD_FORM,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      const records = yield call(getRecordFormFromDB, mainUser);
      let normalizedRecords = [];
      if (records.val()) {
        normalizedRecords = [...records.val()];
      }
      yield put({
        type: actions.GET_RECORD_FORM_SUCCESS,
        payload: {
          list: normalizedRecords,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function saveRecordFormOnDB(list, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/recordForm/list`] = list;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveRecordFormRequest() {
  yield takeEvery(actions.SAVE_RECORD_FORM_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.UPDATING_RECORD_FORM,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveRecordFormOnDB, action.payload, mainUser);
      yield put({
        type: actions.GET_RECORD_FORM_REQUEST,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* discardRecordFormRequest() {
  yield takeEvery(actions.DISCARD_RECORD_FORM_REQUEST, function* () {
    yield put({
      type: actions.DISCARDING_RECORD_FORM,
    });
    yield call(sleep, 500);
    yield put({
      type: actions.DISCARDING_RECORD_FORM_SUCCESS,
    });
  });
}

function savePatientRecordFormOnDB(
  list,
  seectedId,
  unified = {},
  addressUid,
  professional = null,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const author = {
    firstName: professional.firstName,
    lastName: professional.lastName,
  };
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (professional) {
      updates[`unified/${unified.id}/recordForm/data/${seectedId}/professional`] = author;
    }
    if (list) {
      updates[`unified/${unified.id}/recordForm/data/${seectedId}/list`] = list;
      updates[`unified/${unified.id}/recordForm/data/${seectedId}/timestamp`] = dbServerTimestamp();
    }
  } else if (list) {
    updates[`users/${uid}/recordForm/data/${seectedId}/list`] = list;
    updates[`users/${uid}/recordForm/data/${seectedId}/timestamp`] = dbServerTimestamp();
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* savePatientRecordFormRequest() {
  yield takeLatest(actions.SAVE_PATIENT_RECORD_FORM_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SAVING_PATIENT_RECORD_FORM });
      yield call(sleep, 1000);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      yield call(
        savePatientRecordFormOnDB,
        action.payload,
        seectedId,
        unified,
        addressUid,
        profile,
        mainUser,
      );
      yield put({
        type: actions.REFETCH_PATIENT_RECORD_FORM,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_PATIENT_RECORD_FORM_ERROR,
      });
    }
  });
}

export function* getPatientRecordForm() {
  yield takeLatest(actions.REFETCH_PATIENT_RECORD_FORM, function* () {
    try {
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const records = yield call(getPatientRecordFormFromDB, seectedId, addressUid, unified, mainUser);
      let normalizedRecords = [];
      if (records.val()?.list) {
        normalizedRecords = [...records.val().list];
      }
      yield put({
        type: actions.SAVE_PATIENT_RECORD_FORM_SUCCESS,
        payload: {
          list: normalizedRecords,
          id: seectedId,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_PATIENT_RECORD_FORM_ERROR,
      });
    }
  });
}

function saveCollapseRecordOnDB(
  value,
  pushKey,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/collapse`] = value;
  } else {
    updates[`users/${uid}/records/${seectedId}/${pushKey}/collapse`] = value;
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* collapseRecord() {
  yield takeLatest(actions.CHANGE_COLLAPSE_RECORD_REQUEST, function* (action) {
    try {
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(sleep, 3000);
      yield call(
        saveCollapseRecordOnDB,
        action.payload.value,
        action.payload.id, // pushKey
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
    }
  });
}

function saveHideRecordOnDB(
  value,
  pushKey,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/records/${seectedId}/${pushKey}/hidden`] = value;
  } else {
    updates[`users/${uid}/records/${seectedId}/${pushKey}/hidden`] = value;
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* hideRecord() {
  yield takeLatest(actions.CHANGE_HIDE_RECORD_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.CHANGING_HIDE_RECORD,
        payload: action.payload.id,
      });
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(
        saveHideRecordOnDB,
        !action.payload.hidden,
        action.payload.id, // pushKey
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
      yield put({
        type: actions.GET_PATIENT_RECORDS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
      yield put({ type: actions.CHANGE_HIDE_RECORD_ERROR });
    }
  });
}

export function* checkShowHiddenRecordsStatus() {
  yield takeEvery(actions.GET_SHOW_HIDDEN_RECORDS_STATUS, function* () {
    try {
      const data = yield localStorage.getItem('show_hidden_records');
      if (_.isNull(data)) {
        yield put({
          type: actions.GET_SHOW_HIDDEN_RECORDS_STATUS_SUCCESS,
          value: false,
        });
      } else {
        yield put({
          type: actions.GET_SHOW_HIDDEN_RECORDS_STATUS_SUCCESS,
          value: JSON.parse(data),
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setShowHiddenRecords() {
  yield takeEvery(actions.SET_SHOW_HIDDEN_RECORDS, function* (action) {
    try {
      yield localStorage.setItem('show_hidden_records', action.value);
      yield put({
        type: actions.GET_SHOW_HIDDEN_RECORDS_STATUS,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function getAllRecordsFromDB(seectedId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/records/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
    ));
    // return db.ref(`/unified/${unified.id}/records/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/records/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
  ));
  // return db.ref(`users/${uid}/records/${seectedId}`)
  //   .orderByChild('queryTimestamp')
  //   .once('value');
}

export function* getAllRecords() {
  yield takeEvery(actions.GET_ALL_RECORDS_REQUEST, function* () {
    try {
      yield put({ type: actions.FETCHING_ALL_RECORDS });
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const records = yield call(
        getAllRecordsFromDB,
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
      const recordsArray = [];
      records.forEach((el) => {
        recordsArray.push({
          ...el.val(),
          id: el.key,
          key: el.val().timestamp || el.key,
        });
      });
      const lastRecord = recordsArray[recordsArray.length - 1];
      yield put({
        type: actions.GET_ALL_RECORDS_SUCCESS,
        payload: {
          records: records.val(),
          id: seectedId,
          lastRecord,
          noNextRecordPage: true,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function logDiscardNewRecordOnDB(
  seectedId,
  addressUid,
  unified = {},
  mainProfile = null,
  mainUser,
  customUsers,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const currentUid = currentUser.uid;
  const author = {};
  if (currentUid === mainProfile.id) {
    // It is the main account.
    author.firstName = mainProfile.firstName;
    author.lastName = mainProfile.lastName;
    author.uid = mainProfile.id;
  } else {
    // It is some of the 'customUsers' accounts.
    const foundProfile = customUsers.find((el) => el.id === currentUid);
    if (foundProfile) {
      author.firstName = foundProfile.firstName;
      author.lastName = foundProfile.lastName;
      author.uid = foundProfile.id;
      if (foundProfile.healthProfessional) {
        // It is a health professional account.
      } else {
        // Secretary account.
        author.notHealthProfessional = true;
      }
    }
  }
  const updates = {};
  const timestamp = moment().tz('America/Sao_Paulo');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const pushKey = push(child(ref(db), `patientsLogs/${unified.id}/records/${seectedId}`)).key;
    if (!_.isEmpty(author)) {
      author.unified = true;
      updates[`unified/${unified.id}/patientsLogs/${seectedId}/${pushKey}/professional`] = author;
    }
    updates[`unified/${unified.id}/patientsLogs/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`unified/${unified.id}/patientsLogs/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`unified/${unified.id}/patientsLogs/${seectedId}/${pushKey}/type`] = 'discardedRecord';
  } else {
    const pushKey = push(child(ref(db), `users/${uid}/patientsLogs/${seectedId}`)).key;
    if (!_.isEmpty(author)) {
      updates[`users/${uid}/patientsLogs/${seectedId}/${pushKey}/professional`] = author;
    }
    updates[`users/${uid}/patientsLogs/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`users/${uid}/patientsLogs/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`users/${uid}/patientsLogs/${seectedId}/${pushKey}/type`] = 'discardedRecord';
  }
  return update(dbRef, updates);
}

export function* discardNewRecord() {
  yield takeLatest(actions.DISCARD_NEW_RECORD_CHANGES, function* () {
    try {
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      yield call(
        logDiscardNewRecordOnDB,
        seectedId,
        addressUid,
        unified,
        profile, // professional
        mainUser,
        customUsers,
      );
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
    }
  });
}

// function getLastRecordOnDB(field, seectedId, addressUid, unified = {}, mainUser, lastItem) {
//   const db = getDatabase();
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const auth = getAuth();
//     const { currentUser } = auth;
//     ({ uid } = currentUser);
//   }
//   if (unified.id && unified.address.some((a) => a === addressUid)) {
//     if (lastItem) {
//       const databaseRef = ref(db, `unified/${unified.id}/records/${seectedId}`);
//       return get(dbQuery(
//         databaseRef,
//         orderByChild('queryTimestamp'),
//         startAt(lastItem.queryTimestamp, lastItem.id),
//         limitToFirst(6),
//       ));
//     }
//     const databaseRef = ref(db, `unified/${unified.id}/records/${seectedId}`);
//     return get(dbQuery(
//       databaseRef,
//       orderByChild('queryTimestamp'),
//       limitToFirst(5),
//     ));
//   }
//   if (lastItem) {
//     const databaseRef = ref(db, `users/${uid}/records/${seectedId}`);
//     return get(dbQuery(
//       databaseRef,
//       orderByChild('queryTimestamp'),
//       startAt(lastItem.queryTimestamp, lastItem.id),
//       limitToFirst(6),
//     ));
//   }
//   const databaseRef = ref(db, `users/${uid}/records/${seectedId}`);
//   return get(dbQuery(
//     databaseRef,
//     orderByChild('queryTimestamp'),
//     limitToFirst(5),
//   ));
// }

export function* fetchLastRecordForSpecificField() {
  yield takeLatest(actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_REQUEST, function* (action) {
    try {
      const { field, lastItem } = action.payload;
      yield put({
        type: actions.FETCHING_LAST_RECORD_FOR_SPECIFIC_FIELD,
        payload: field,
      });
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const records = yield call(
        getRecordsFromDB,
        // field,
        seectedId,
        addressUid,
        unified,
        mainUser,
        lastItem,
      );
      const auth = getAuth();
      const { currentUser } = auth;
      const { uid } = currentUser;
      let foundItem = null;
      const recordsArray = [];
      records.forEach((el) => {
        const data = el.val();
        recordsArray.push({
          ...data,
          id: el.key,
          key: el.val().timestamp || el.key,
        });
        if (data[field] && data.professional?.uid === uid && !foundItem) {
          foundItem = { ...data[field] };
        }
      });
      const lastRecord = recordsArray[recordsArray.length - 1];
      if (!foundItem) {
        if (recordsArray.length < 5) {
          // No more records.
          notification('warning', 'Não foi encontrado nenhum registro passado para este campo');
          yield put({
            type: actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_ERROR,
          });
        } else {
          yield put({
            type: actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_REQUEST,
            payload: {
              field,
              lastItem: lastRecord,
            },
          });
        }
      } else {
        yield put({
          type: actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_SUCCESS,
          payload: {
            seectedId,
            field,
            value: foundItem,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
      yield put({
        type: actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getGraphs),
    fork(getRecords),
    fork(getRecordsMask),
    fork(saveRecordMaskRequest),
    fork(saveRecord),
    fork(updatePediatricPlot),
    fork(getRecordEditorModels),
    fork(saveRecordEditorModel),
    fork(removeRecordEditorModel),
    fork(updateRecordEditorModel),
    fork(getRecordForm),
    fork(saveRecordFormRequest),
    fork(discardRecordFormRequest),
    fork(savePatientRecordFormRequest),
    fork(getPatientRecordForm),
    fork(collapseRecord),
    fork(hideRecord),
    fork(checkShowHiddenRecordsStatus),
    fork(setShowHiddenRecords),
    fork(getAllRecords),
    fork(discardNewRecord),
    fork(getFiles),
    fork(fetchLastRecordForSpecificField),
  ]);
}

/* eslint newline-per-chained-call: ["error", { "ignoreChainWithDepth": 8 }] */
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  getAuth,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  getDocs,
  query,
  collection,
  onSnapshot,
  orderBy,
  limit,
  writeBatch,
  startAfter,
} from 'firebase/firestore';
// import firebase from 'firebase/app';
// import 'firebase/database';
// import 'firebase/firestore';
// import 'firebase/auth';
import { eventChannel } from 'redux-saga';
import {
  all,
  take,
  takeEvery,
  takeLatest,
  fork,
  call,
  put,
  select,
} from 'redux-saga/effects';
import actions from './actions';
import appActions from '../app/actions';
import customUsersActions from '../customUsers/actions';

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getSelectedChatFromStore = (state) => state.Chat.selectedChat;

const getAllChatMessagesFromStore = (state) => state.Chat.allChatMessages;

const getOldChatMessagesFromStore = (state) => state.Chat.oldChatMessages;

const getCustomUsersFromStore = (state) => state.CustomUsers.customUsers;

function createChatListener(addressId, mainUser, selectedChat, limitValue) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const messagesRef = collection(
      fs,
      'professionals',
      uid,
      'addresses',
      addressId,
      'chats',
      selectedChat,
      'messages',
    );
    const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(limitValue));
    const unsubscribe = onSnapshot(q, (req) => {
      emit(req || {});
    });
    // const unsubscribe = fs
    //   .collection('professionals').doc(uid)
    //   .collection('addresses').doc(addressId)
    //   .collection('chats').doc(selectedChat)
    //   .collection('messages')
    //   .orderBy('timestamp', 'desc')
    //   .limit(limit)
    //   .onSnapshot('value', (req) => {
    //     emit(req || {});
    //   });
    return unsubscribe;
  });
  return listener;
}

function generateChatRooms(customUsers, mainUser) {
  const auth = getAuth();
  const { currentUser } = auth;
  const arr = [];
  arr.push('default');
  if (customUsers) {
    customUsers.forEach((el) => {
      if (!_.isUndefined(mainUser) && !mainUser) {
        // It is the main account.
        if (currentUser.uid < el.id) {
          arr.push(`${currentUser.uid}_${el.id}`);
        } else {
          arr.push(`${el.id}_${currentUser.uid}`);
        }
      }
      if (mainUser) {
        // It is a secondary account.
        if (el.id === currentUser.uid) {
          // The customUser in loop is the current connected user.
          if (currentUser.uid < mainUser) {
            arr.push(`${currentUser.uid}_${mainUser}`);
          } else {
            arr.push(`${mainUser}_${currentUser.uid}`);
          }
        } else if (currentUser.uid < el.id) {
          arr.push(`${currentUser.uid}_${el.id}`);
        } else {
          arr.push(`${el.id}_${currentUser.uid}`);
        }
      }
    });
  }
  return arr;
}

export function* getChatMessagesRequest() {
  yield takeEvery([
    actions.MESSAGES_CHAT_FETCH_REQUEST,
    // actions.CHANGE_CHAT_LISTENER_PARAM,
  ], function* (action) {
    try {
      let limitValue = 12;
      if (action.payload) {
        limitValue = action.payload;
      }
      const mainUser = yield select(getMainUserFromStore);
      let selectedAddress = yield select(getSelectedAddressFromStore);
      if (!selectedAddress) {
        yield take(appActions.SELECT_ADDRESS);
        selectedAddress = yield select(getSelectedAddressFromStore);
      }
      const customUsers = yield select(getCustomUsersFromStore);
      const chatRooms = generateChatRooms(customUsers, mainUser);
      // const fullyDownloadedChatRoom = {};
      const chatListenerArr = yield all(chatRooms.map((chatId) => call(createChatListener, selectedAddress, mainUser, chatId, limitValue)));
      yield all(chatListenerArr.map((chatListener) => takeEvery(chatListener, function* (chat) {
        if (!chat.empty) {
          let allMessagesArr = [];
          let chatId = null;

          chat.forEach((document) => {
            chatId = document.ref.parent.parent.id;
            const messageObj = {
              ...document.data(),
              docReference: document,
              id: document.id,
            };
            if (messageObj.timestamp) {
              allMessagesArr.push(messageObj);
            }
          });
          if (chat.size < 12) {
            // No older messages in this chat.
            yield put({
              type: actions.NO_OLDER_CHAT_MESSAGES,
              payload: {
                chatId,
              },
            });
          }
          const allChatMessages = yield select(getAllChatMessagesFromStore);
          if (allChatMessages[chatId]) {
            allMessagesArr = _.uniqBy([...allMessagesArr, ...allChatMessages[chatId]], 'id');
          }
          const orderedAllMessagesArr = _.orderBy(allMessagesArr, ['timestamp']);
          yield all([
            put({
              type: actions.ALL_MESSAGES_CHAT_FETCH_SUCCESS,
              payload: {
                messages: orderedAllMessagesArr,
                chatId,
              },
            }),
            put({
              type: actions.SET_CHAT_LISTENER_LIMIT,
              payload: limit,
            }),
          ]);
        }
      })));
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        customUsersActions.CUSTOM_USERS_FETCH_SUCCESS,
        actions.CHANGE_CHAT_LISTENER_PARAM,
      ]);
      chatListenerArr.forEach((el) => {
        el.close();
      });
      if (actionTriggered?.type === 'CUSTOM_USERS_FETCH_SUCCESS') {
        // Reset chat's listener to new params.
        yield put({
          type: actions.MESSAGES_CHAT_FETCH_REQUEST,
        });
      } else if (actionTriggered?.type === 'CHANGE_CHAT_LISTENER_PARAM') {
        // Increasing listener limit.
        yield put({
          type: actions.MESSAGES_CHAT_FETCH_REQUEST,
          payload: actionTriggered.payload,
        });
      } else {
        // It is the general "cancel all listeners".
        yield all([
          put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
          put({ type: actions.RESET_CHAT }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.MESSAGES_CHAT_FETCH_ERROR });
    }
  });
}

function saveChatMessageOnDB(text, addressId, mainUser, selectedChat, newMessageId) {
  const auth = getAuth();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  const messageObj = {
    user: auth.currentUser.uid,
    text,
    // timestamp: moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    // firestoreTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };
  if (mainUser) {
    messageObj.customUser = true;
  }
  const docRef = doc(
    fs,
    'professionals',
    uid,
    'addresses',
    addressId,
    'chats',
    selectedChat,
    'messages',
    newMessageId,
  );
  return setDoc(docRef, messageObj);
  // return fs.collection('professionals').doc(uid)
  //   .collection('addresses').doc(addressId)
  //   .collection('chats').doc(selectedChat)
  //   .collection('messages').doc(newMessageId)
  //   .set(messageObj);
}

export function* saveChatMessageRequest() {
  yield takeEvery(actions.SAVE_CHAT_MESSAGE_REQUEST, function* (action) {
    try {
      let selectedAddress = yield select(getSelectedAddressFromStore);
      if (!selectedAddress) {
        yield take(appActions.SELECT_ADDRESS);
        selectedAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const selectedChat = yield select(getSelectedChatFromStore);
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const fs = getFirestore();
      // const newMessageRef = firebase.firestore().collection('professionals').doc(uid)
      //   .collection('addresses').doc(selectedAddress)
      //   .collection('chats').doc(selectedChat)
      //   .collection('messages')
      //   .doc();
      const colRef = collection(
        fs,
        'professionals',
        uid,
        'addresses',
        selectedAddress,
        'chats',
        selectedChat,
        'messages',
      );
      const newMessageRef = doc(colRef);
      const newMessageId = newMessageRef.id;
      let allChatMessages = yield select(getAllChatMessagesFromStore);

      let canSaveMessage = false;
      let messagesToSave = [];
      if (allChatMessages && allChatMessages[selectedChat]) {
        messagesToSave = allChatMessages[selectedChat].filter((el) => el.sendingNewMessage);
        if (messagesToSave.length === 0) {
          canSaveMessage = true;
        }
      } else {
        // There is no message yet. Chat is empty.
        canSaveMessage = true;
      }

      const allMessagesArr = [];
      const auth = getAuth();
      allMessagesArr.push({
        user: auth.currentUser.uid,
        text: action.payload,
        timestamp: moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        sendingNewMessage: moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        id: newMessageId,
      });
      if (allChatMessages[selectedChat]) {
        allMessagesArr.unshift(...allChatMessages[selectedChat]);
      }
      yield put({
        type: actions.ALL_MESSAGES_CHAT_FETCH_SUCCESS,
        payload: {
          messages: allMessagesArr,
          chatId: selectedChat,
        },
      });
      allChatMessages = yield select(getAllChatMessagesFromStore);

      while (!canSaveMessage) {
        if (allChatMessages[selectedChat]) {
          messagesToSave = allChatMessages[selectedChat].filter((el) => el.sendingNewMessage);
          if (messagesToSave.length > 0) {
            // There is messages to be saved yet.
            const orderedMessagesToSave = _.orderBy(messagesToSave, ['sendingNewMessage']);
            if (orderedMessagesToSave[0].id === newMessageId) {
              canSaveMessage = true;
            } else {
              yield take(actions.ALL_MESSAGES_CHAT_FETCH_SUCCESS);
              allChatMessages = yield select(getAllChatMessagesFromStore);
            }
          } else {
            canSaveMessage = true;
          }
        }
      }

      if (canSaveMessage) {
        yield call(
          saveChatMessageOnDB,
          action.payload,
          selectedAddress,
          mainUser,
          selectedChat || 'default',
          newMessageId,
        );
      }
      yield put({
        type: actions.SAVE_CHAT_MESSAGE_SUCCESS,
        payload: {
          lastMessageAdded: newMessageId,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_CHAT_MESSAGE_ERROR });
    }
  });
}

function markMessagesReadOnDB(chatMessages, addressId, mainUser, selectedChat) {
  const auth = getAuth();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const fs = getFirestore();
  const batch = writeBatch(fs);
  chatMessages.forEach((el) => {
    if (!el.sendingNewMessage && el.docReference) {
      const ref = doc(
        fs,
        'professionals',
        uid,
        'addresses',
        addressId,
        'chats',
        selectedChat,
        'messages',
        el.id,
      );
      // const ref = fs.collection('professionals').doc(uid)
      //   .collection('addresses').doc(addressId)
      //   .collection('chats').doc(selectedChat)
      //   .collection('messages').doc(el.id);
      batch.set(ref, {
        usersVisualized: {
          [auth.currentUser.uid]: true,
        },
      }, { merge: true });
    }
  });
  return batch.commit();
}

export function* markMessagesReadRequest() {
  // yield takeEvery(actions.MARK_MESSAGES_READ_REQUEST, function* () {
  yield takeLatest(actions.MARK_MESSAGES_READ_REQUEST, function* () {
    try {
      let selectedAddress = yield select(getSelectedAddressFromStore);
      if (!selectedAddress) {
        yield take(appActions.SELECT_ADDRESS);
        selectedAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const selectedChat = yield select(getSelectedChatFromStore);
      const allChatMessages = yield select(getAllChatMessagesFromStore);
      if (allChatMessages[selectedChat]) {
        yield call(
          markMessagesReadOnDB,
          allChatMessages[selectedChat],
          selectedAddress,
          mainUser,
          selectedChat,
        );
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_CHAT_MESSAGE_ERROR });
    }
  });
}

function fetchOlderMessagesFromDB(addressId, mainUser, selectedChat, lastDoc) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const colRef = collection(
    fs,
    'professionals',
    uid,
    'addresses',
    addressId,
    'chats',
    selectedChat,
    'messages',
  );
  const q = query(colRef, orderBy('timestamp', 'desc'), startAfter(lastDoc), limit(12));
  return getDocs(q);
  // return fs
  //   .collection('professionals').doc(uid)
  //   .collection('addresses').doc(addressId)
  //   .collection('chats').doc(selectedChat)
  //   .collection('messages')
  //   .orderBy('timestamp', 'desc')
  //   .startAfter(lastDoc)
  //   .limit(12)
  //   .get();
}

export function* fetchOlderMessagesRequest() {
  yield takeEvery(actions.FETCH_CHAT_OLDER_MESSAGES_REQUEST, function* () {
    try {
      yield put({ type: actions.FETCHING_CHAT_OLDER_MESSAGES });
      let selectedAddress = yield select(getSelectedAddressFromStore);
      if (!selectedAddress) {
        yield take(appActions.SELECT_ADDRESS);
        selectedAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const selectedChat = yield select(getSelectedChatFromStore);
      const allChatMessages = yield select(getAllChatMessagesFromStore);
      const oldChatMessages = yield select(getOldChatMessagesFromStore);
      if (allChatMessages[selectedChat] && allChatMessages[selectedChat].length > 0) {
        // const len = allChatMessages[selectedChat].length;
        let lastDoc = null;
        if (oldChatMessages[selectedChat] && oldChatMessages[selectedChat].length > 0) {
          lastDoc = oldChatMessages[selectedChat][0];
        } else {
          lastDoc = allChatMessages[selectedChat][0];
        }
        const querySnapshot = yield call(
          fetchOlderMessagesFromDB,
          selectedAddress,
          mainUser,
          selectedChat || 'default',
          lastDoc.docReference,
        );
        if (querySnapshot.empty || querySnapshot.size < 12) {
          // No older messages in this chat.
          yield put({
            type: actions.NO_OLDER_CHAT_MESSAGES,
            payload: {
              chatId: selectedChat,
            },
          });
        }
        let messagesArr = [];
        querySnapshot.forEach((document) => {
          messagesArr.push({
            ...document.data(),
            docReference: document,
            id: document.id,
          });
        });
        if (oldChatMessages[selectedChat]) {
          messagesArr = [...messagesArr, ...oldChatMessages[selectedChat]];
        }
        const orderedMessagesArr = _.orderBy(messagesArr, ['timestamp']);
        yield put({
          type: actions.FETCH_CHAT_OLDER_MESSAGES_SUCCESS,
          payload: {
            chatId: selectedChat,
            messages: orderedMessagesArr,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_CHAT_MESSAGE_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    // fork(getChatsRequest),
    fork(getChatMessagesRequest),
    fork(saveChatMessageRequest),
    fork(markMessagesReadRequest),
    fork(fetchOlderMessagesRequest),
  ]);
}

const chatActions = {
  // CHATS_FETCH_REQUEST: 'CHATS_FETCH_REQUEST',
  // CHATS_FETCH_SUCCESS: 'CHATS_FETCH_SUCCESS',
  // CHATS_FETCH_ERROR: 'CHATS_FETCH_ERROR',
  MESSAGES_CHAT_FETCH_REQUEST: 'MESSAGES_CHAT_FETCH_REQUEST',
  MESSAGES_CHAT_FETCH_SUCCESS: 'MESSAGES_CHAT_FETCH_SUCCESS',
  MESSAGES_CHAT_FETCH_ERROR: 'MESSAGES_CHAT_FETCH_ERROR',
  ALL_MESSAGES_CHAT_FETCH_SUCCESS: 'ALL_MESSAGES_CHAT_FETCH_SUCCESS',
  SAVE_CHAT_MESSAGE_REQUEST: 'SAVE_CHAT_MESSAGE_REQUEST',
  SAVE_CHAT_MESSAGE_SUCCESS: 'SAVE_CHAT_MESSAGE_SUCCESS',
  SAVE_CHAT_MESSAGE_ERROR: 'SAVE_CHAT_MESSAGE_ERROR',
  SET_CHAT_BADGE_COUNT: 'SET_CHAT_BADGE_COUNT',
  CHANGE_CHAT: 'CHANGE_CHAT',
  // CHANGE_CHAT_REQUEST: 'CHANGE_CHAT_REQUEST',
  // CHANGE_CHAT_SUCCESS: 'CHANGE_CHAT_SUCCESS',
  // CHANGE_CHAT_ERROR: 'CHANGE_CHAT_ERROR',
  MARK_MESSAGES_READ_REQUEST: 'MARK_MESSAGES_READ_REQUEST',
  MARK_MESSAGES_READ_SUCCESS: 'MARK_MESSAGES_READ_SUCCESS',
  MARK_MESSAGES_READ_ERROR: 'MARK_MESSAGES_READ_ERROR',
  RESET_CHAT: 'RESET_CHAT',
  CHANGE_CHAT_LISTENER_PARAM: 'CHANGE_CHAT_LISTENER_PARAM',
  SET_NEW_CHAT_LISTENER: 'SET_NEW_CHAT_LISTENER',
  SET_CHAT_LISTENER_LIMIT: 'SET_CHAT_LISTENER_LIMIT',
  FETCH_CHAT_OLDER_MESSAGES_REQUEST: 'FETCH_CHAT_OLDER_MESSAGES_REQUEST',
  FETCH_CHAT_OLDER_MESSAGES_SUCCESS: 'FETCH_CHAT_OLDER_MESSAGES_SUCCESS',
  FETCH_CHAT_OLDER_MESSAGES_ERROR: 'FETCH_CHAT_OLDER_MESSAGES_ERROR',
  FETCHING_CHAT_OLDER_MESSAGES: 'FETCHING_CHAT_OLDER_MESSAGES',
  NO_OLDER_CHAT_MESSAGES: 'NO_OLDER_CHAT_MESSAGES',
  getChatMessages: () => ({
    type: chatActions.MESSAGES_CHAT_FETCH_REQUEST,
  }),
  saveChatMessages: (text) => ({
    type: chatActions.SAVE_CHAT_MESSAGE_REQUEST,
    payload: text,
  }),
  setChatBadgeCount: (value) => ({
    type: chatActions.SET_CHAT_BADGE_COUNT,
    payload: value,
  }),
  changeChat: (id) => ({
    type: chatActions.CHANGE_CHAT,
    payload: id,
  }),
  markMessagesRead: () => ({
    type: chatActions.MARK_MESSAGES_READ_REQUEST,
  }),
  changeChatListenerParam: (value) => ({
    type: chatActions.CHANGE_CHAT_LISTENER_PARAM,
    payload: value,
  }),
  fetchOlderChatMessages: () => ({
    type: chatActions.FETCH_CHAT_OLDER_MESSAGES_REQUEST,
  }),
};
export default chatActions;

import _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import actions from './actions';
import calendarActions from '../calendar/actions';
import authActions from '../auth/actions';
import appActions from '../app/actions';

const initState = {
  patients: [],
  patientsOnDB: [],
  deletedPatients: [],
  seectedId: null,
  editView: false,
  financialHistory: {},
  patientDuplicateArr: {},
  patientAvatarModal: false,
  loadingWhatsApp: null,
  noNextPage: false,
  noDeletedNextPage: false,
  zipData: {},
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case appActions.SELECT_ADDRESS:
      return {
        ...initState,
      };
    case actions.RESET_SEARCH_PATIENTS_SETTINGS:
      return {
        ...state,
        noNextPage: false,
        noDeletedNextPage: false,
        searchInput: action.payload,
        lastVisibleDoc: null,
        lastVisibleDeletedDoc: null,
      };
    case actions.REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        referralList: action.payload,
      };
    case actions.PATIENTS_FETCH_SUCCESS:
      return {
        ...state,
        patients: _.cloneDeep(action.payload.patients),
        patientsOnDB: _.cloneDeep(action.payload.patients),
        seectedId: action.payload.seectedId,
        savingPatient: false,
        searchInput: action.payload.searchInput,
        noNextPage: action.payload.noNextPage,
        lastVisibleDoc: action.payload.lastVisibleDoc,
        // noDeletedNextPage: action.payload.noDeletedNextPage,
        // lastVisibleDeletedDoc: action.payload.lastVisibleDeletedDoc,
        // deletedPatients: action.payload.finalArrDeleted,
        reloadingContactList: false,
        avatarFile: null,
        patientDuplicateArr: {},
        searchingPatients: false,
        searchingAppend: false,
        zipData: {},
      };
    case actions.DELETED_PATIENTS_FETCH_SUCCESS:
      return {
        ...state,
        deletedPatients: _.cloneDeep(action.payload.patients),
        seectedId: action.payload.seectedId,
        savingPatient: false,
        searchInput: action.payload.searchInput,
        noDeletedNextPage: action.payload.noNextPage,
        lastVisibleDeletedDoc: action.payload.lastVisibleDoc,
        reloadingContactList: false,
        avatarFile: null,
        patientDuplicateArr: {},
        searchingPatients: false,
        searchingAppend: false,
      };
    case actions.SEARCHING_PATIENTS:
      return {
        ...state,
        searchingPatients: true,
      };
    case actions.SEARCHING_PATIENTS_APPEND:
      return {
        ...state,
        searchingAppend: true,
      };
    case actions.PATIENTS_FETCH_ERROR:
      return {
        ...state,
        patients: [],
        patientsOnDB: [],
        seectedId: null,
        savingPatient: false,
        searchingPatients: false,
        searchingAppend: false,
      };
    case actions.START_SAVE_PATIENT_UPLOAD:
      return {
        ...state,
        savingPatient: true,
      };
    case actions.DISCARD_PATIENT_CHANGES:
      return {
        ...state,
        patients: _.cloneDeep(state.patientsOnDB),
        editView: false,
        avatarFile: null,
      };
    case actions.CHANGE_CONTACT:
      return {
        ...state,
        seectedId: action.id,
        patients: _.cloneDeep(state.patientsOnDB),
        editView: false,
        avatarFile: null,
      };
    case actions.ADD_CONTACT:
      return {
        ...state,
        // contacts: action.contacts,
        patients: action.patients,
        seectedId: action.selectedId,
        editView: 'new',
        // patientDuplicateArr: {
        //   ...state.patientDuplicateArr,
        //   [action.selectedId]: [],
        // },
      };
    case actions.EDIT_CONTACT:
      return {
        ...state,
        // contacts: action.contacts,
        patients: action.patients,
      };
    case actions.SEARCH_ZIP_CODE_SUCCESS:
      return {
        ...state,
        zipData: {
          [action.payload.id]: action.payload.zipData,
        },
      };
    case actions.START_DELETE_CONTACT:
      return {
        ...state,
        deletingPatient: action.payload,
      };
    case actions.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        seectedId: null,
        deletingPatient: false,
        reloadingContactList: true,
      };
    case actions.START_RECOVER_CONTACT:
      return {
        ...state,
        recoveringPatient: action.payload,
      };
    case actions.RECOVER_CONTACT_SUCCESS:
      return {
        ...state,
        seectedId: null,
        recoveringPatient: false,
        reloadingContactList: true,
      };
    case actions.RECOVER_CONTACT_ERROR:
      return {
        ...state,
        recoveringPatient: false,
        eloadingContactList: false,
      };
    case actions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
      };
    case actions.PATIENT_HISTORY_SUCCESS:
      return {
        ...state,
        appoitmentHistory: {
          [action.payload.seectedId]: {
            historic: action.payload.data,
            batchedHistoric: action.payload.batched,
          },
        },
        // historic: action.payload.data,
        // batchedHistoric: action.payload.batched,
        // requestedValue: action.payload.requestedValue,
        isLoadingHistoric: false,
      };
    case actions.PATIENT_HISTORY_REQUEST:
      return {
        ...state,
        isLoadingHistoric: true,
      };
    case actions.PATIENT_HISTORY_ERROR:
      return {
        ...state,
        isLoadingHistoric: false,
      };
    case actions.RESET_PATIENT_HISTORY:
      return {
        ...state,
        appoitmentHistory: undefined,
      };
    case calendarActions.UPDATE_SELECTED_DATA_REQUEST:
      return {
        ...state,
        historic: undefined,
      };
    case actions.FIND_POSSIBLE_MERGE_PATIENTS_SUCCESS:
      return {
        ...state,
        possibleMergePatients: action.payload.arr,
      };
    case actions.START_MERGING_PATIENTS:
      return {
        ...state,
        mergingPatients: true,
      };
    case actions.FINISHED_MERGE_PATIENT_PROCESS:
      return {
        ...state,
        mergingPatients: false,
      };
    case actions.MERGE_PATIENT_ERROR:
      return {
        ...state,
        mergingPatients: null,
      };
    case actions.DISCARD_FORM_SETTINGS:
      return {
        ...state,
        formSettings: _.cloneDeep(state.formSettingsOnDB),
      };
    case actions.GET_FORM_SETTINGS_SUCCESS:
      return {
        ...state,
        formSettings: action.payload.formSettings,
        formSettingsOnDB: action.payload.formSettings,
        savingFormSettings: false,
      };
    case actions.TOGGLE_FORM_SETTING_CHECKBOX:
      return {
        ...state,
        formSettings: {
          ...state.formSettings,
          [action.payload.attribute]: action.payload.value,
        },
      };
    case actions.TOGGLE_FORM_PRINT_SETTING_CHECKBOX:
      return {
        ...state,
        formSettings: {
          ...state.formSettings,
          print: {
            ...state.formSettings.print,
            [action.payload.attribute]: action.payload.value,
          },
        },
      };
    case actions.START_SAVING_FORM_SETTINGS:
      return {
        ...state,
        savingFormSettings: true,
      };
    case actions.SAVE_FORM_SETTINGS_ERROR:
      return {
        ...state,
        savingFormSettings: null,
      };
    case actions.LOADING_PATIENT_FINANCIAL_HISTORY:
      return {
        ...state,
        loadingFinancialHistory: true,
      };
    case actions.GET_PATIENT_FINANCIAL_HISTORY_ERROR:
      return {
        ...state,
        financialHistory: {
          [action.payload.seectedId]: [],
        },
        loadingFinancialHistory: null,
      };
    case actions.GET_PATIENT_FINANCIAL_HISTORY_SUCCESS:
      return {
        ...state,
        financialHistory: {
          [action.payload.seectedId]: action.payload.list,
        },
        loadingFinancialHistory: false,
      };
    case actions.CHECK_FOR_DUPLICATE_PATIENT_SUCCESS:
      return {
        ...state,
        patientDuplicateArr: {
          ...state.patientDuplicateArr,
          [action.payload.cpf]: action.payload.cpfSearchArr,
        },
      };
    case actions.CHANGE_CONTACT_FROM_DUPLICATES:
      return {
        ...state,
        seectedId: action.payload.id,
        patientsOnDB: _.cloneDeep(action.payload.list),
        patients: _.cloneDeep(action.payload.list),
        searchInput: '',
        resetSearchState: uuidv1(),
        editView: false,
        deletedPatients: [],
        noDeletedNextPage: false,
      };
    case actions.TOGGLE_PATIENT_AVATAR_MODAL:
      return {
        ...state,
        patientAvatarModal: !state.patientAvatarModal,
      };
    case actions.SAVE_PATIENT_AVATAR_FILE:
      return {
        ...state,
        avatarFile: action.payload,
        loadingWhatsApp: null,
      };
    case actions.SAVE_PATIENT_ERROR:
      return {
        ...state,
        savingPatient: null,
      };
    case actions.AVATAR_WHATSAPP_FETCH_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.profile,
          avatar: action.payload,
        },
      };
    case actions.SAVE_PATIENT_AVATAR_WAITING:
      return {
        ...state,
        loadingWhatsApp: true,
      };
    case actions.AVATAR_WHATSAPP_FETCH_ERROR:
      return {
        ...state,
        loadingWhatsApp: false,
      };
    case actions.FETCHING_BIRTHDAYS_LIST:
      return {
        ...state,
        loadingBirthdaysList: true,
      };
    case actions.FETCH_BIRTHDAYS_LIST_SUCCESS:
      return {
        ...state,
        birthdaysList: action.payload,
        loadingBirthdaysList: false,
      };
    case actions.FETCH_BIRTHDAYS_LIST_ERROR:
      return {
        ...state,
        loadingBirthdaysList: null,
      };
    case actions.FETCH_BIRTHDAY_MESSAGE_SUCCESS:
      return {
        ...state,
        birthdayMessage: action.payload,
      };
    case actions.START_SAVING_BIRTHDAY_MESSAGE:
      return {
        ...state,
        loadingBirthdayMessage: true,
      };
    case actions.SAVE_BIRTHDAY_MESSAGE_SUCCESS:
      return {
        ...state,
        loadingBirthdayMessage: false,
      };
    case actions.SAVE_BIRTHDAY_MESSAGE_ERROR:
      return {
        ...state,
        loadingBirthdayMessage: null,
      };
    case actions.DISCARD_BIRTHDAY_MESSAGE_CHANGES:
      return {
        ...state,
        birthdayMessage: { ...state.birthdayMessage },
      };
    case actions.START_FETCHING_UNSAVED_CHANGES_PATIENTS_LIST:
      return {
        ...state,
        fetchingUnsavedChangesPatientsList: true,
      };
    case actions.FETCH_UNSAVED_CHANGES_PATIENTS_LIST_SUCCESS:
      return {
        ...state,
        fetchingUnsavedChangesPatientsList: false,
        unsavedChangesPatientsList: action.payload.list,
      };
    case actions.CHANGE_CONTACT_TO_SEE_UNSAVED_CHANGES:
      return {
        ...state,
        seectedId: action.payload.patientId,
        // patients: [action.payload.patientsObj],
        // patientsOnDB: [action.payload.patientsObj],
        patients: _.cloneDeep(action.payload.patientsArr),
        patientsOnDB: _.cloneDeep(action.payload.patientsArr),
      };
    case actions.FETCH_REQUESTING_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        requestingDoctorList: action.payload,
      };
    case actions.START_REMOVING_REFERRAL_HISTORY:
      return {
        ...state,
        removingReferralHistory: action.payload,
      };
    case actions.REMOVE_REFERRAL_HISTORY_SUCCESS:
      return {
        ...state,
        removingReferralHistory: false,
      };
    case actions.REMOVE_REFERRAL_HISTORY_ERROR:
      return {
        ...state,
        removingReferralHistory: false,
      };
    case actions.START_REMOVING_REQUESTING_DOCTOR_HISTORY:
      return {
        ...state,
        removingRequestingDoctorHistory: action.payload,
      };
    case actions.REMOVE_REQUESTING_DOCTOR_HISTORY_SUCCESS:
      return {
        ...state,
        removingRequestingDoctorHistory: false,
      };
    case actions.REMOVE_REQUESTING_DOCTOR_HISTORY_ERROR:
      return {
        ...state,
        removingRequestingDoctorHistory: false,
      };
    case actions.SYNC_PATIENTS_AND_PATIENTS_ON_DB:
      return {
        ...state,
        patients: _.cloneDeep(action.payload.patients),
        patientsOnDB: _.cloneDeep(action.payload.patients),
      };
    case actions.SEARCH_SPOUSE_PROFILE_SUCCESS:
      return {
        ...state,
        patientSpouse: {
          [action.payload.id]: _.cloneDeep(action.payload.patientsArr),
        },
      };
    default:
      return state;
  }
}

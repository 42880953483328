const calendarActions = {
  CALENDAR_VIEW: 'CALENDAR_VIEW',
  // CALENDAR_EVENTS: 'CALENDAR_EVENTS',
  SET_CALENDAR_MODAL_DATA: 'SET_CALENDAR_MODAL_DATA',
  CLEAR_CALENDAR_SELECTED_DATA_REQUEST: 'CLEAR_CALENDAR_SELECTED_DATA_REQUEST',
  CLEAR_CALENDAR_SELECTED_DATA: 'CLEAR_CALENDAR_SELECTED_DATA',
  // CALENDAR_NAVIGATE: 'CALENDAR_NAVIGATE',
  CALENDAR_EVENT_SELECTED: 'CALENDAR_EVENT_SELECTED',
  CALENDAR_SLOT_SELECTED: 'CALENDAR_SLOT_SELECTED',
  SET_CALENDAR_DRAWER_DATA: 'SET_CALENDAR_DRAWER_DATA',
  SET_CANCELED_VISIBILITY: 'SET_CANCELED_VISIBILITY',
  GET_CANCELED_VISIBILITY: 'GET_CANCELED_VISIBILITY',
  SET_CANCELED_VISIBILITY_SUCCESS: 'SET_CANCELED_VISIBILITY_SUCCESS',
  UPDATE_SELECTED_DATA_REQUEST: 'UPDATE_SELECTED_DATA_REQUEST',
  GET_UNUSED_DAYS_VISIBILITY: 'GET_UNUSED_DAYS_VISIBILITY',
  SET_UNUSED_DAYS_VISIBILITY: 'SET_UNUSED_DAYS_VISIBILITY',
  SET_UNUSED_DAYS_VISIBILITY_SUCCESS: 'SET_UNUSED_DAYS_VISIBILITY_SUCCESS',
  SCROLL_TO_TIME: 'SCROLL_TO_TIME',
  CLEAR_SCROLL_TO_TIME: 'CLEAR_SCROLL_TO_TIME',
  NOTIFY_NEW_APPOINTMENT_TIME_RESPONSE: 'NOTIFY_NEW_APPOINTMENT_TIME_RESPONSE',
  changeView: (view) => ({
    type: calendarActions.CALENDAR_VIEW,
    view,
  }),
  setCanceledVisibility: (value) => ({
    type: calendarActions.SET_CANCELED_VISIBILITY,
    value,
  }),
  getCanceledVisibility: () => ({
    type: calendarActions.GET_CANCELED_VISIBILITY,
  }),
  setUnusedDaysVisibility: (value) => ({
    type: calendarActions.SET_UNUSED_DAYS_VISIBILITY,
    value,
  }),
  getUnusedDaysVisibility: () => ({
    type: calendarActions.GET_UNUSED_DAYS_VISIBILITY,
  }),
  setCalendarModalData: (modalVisible) => ({
    type: calendarActions.SET_CALENDAR_MODAL_DATA,
    payload: {
      modalVisible,
    },
  }),
  // navigateCalendar: (date) => ({
  //   type: calendarActions.CALENDAR_NAVIGATE,
  //   date,
  // }),
  selectCalendarEvent: (modalVisible, selectedData) => ({
    type: calendarActions.CALENDAR_EVENT_SELECTED,
    payload: {
      modalVisible,
      selectedData,
    },
  }),
  selectCalendarSlot: (modalVisible, selectedData) => ({
    type: calendarActions.CALENDAR_SLOT_SELECTED,
    payload: {
      modalVisible,
      selectedData,
    },
  }),
  setCalendarDrawerData: (drawerVisible) => ({
    type: calendarActions.SET_CALENDAR_DRAWER_DATA,
    payload: {
      drawerVisible,
    },
  }),
  scrollAgendaToTime: (time) => ({
    type: calendarActions.SCROLL_TO_TIME,
    payload: time,
  }),
  clearScrollToTime: () => ({
    type: calendarActions.CLEAR_SCROLL_TO_TIME,
  }),
  notifyNewAppointmentTimeResponse: (value) => ({
    type: calendarActions.NOTIFY_NEW_APPOINTMENT_TIME_RESPONSE,
    payload: value,
  }),
};
export default calendarActions;

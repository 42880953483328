import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  inventorySettings: {},
  // inventoryItems: {},
  inventoryProviders: {},
  inventoryClasses: {},
  updatingInventory: false,
  inventoryOpsListenerTime: 1,
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.GET_INVENTORY_SETTINGS_SUCCESS:
      return {
        ...state,
        inventorySettings: {
          ...state.inventorySettings,
          [action.payload.address]: action.payload.inventorySettings,
        },
      };
    case actions.INVENTORY_OPS_FETCH_SUCCESS:
      return {
        ...state,
        // inventoryOps: {
        //   ...state.inventoryOps,
        //   [action.payload.address]: _.cloneDeep(action.payload.inventoryOps),
        // },
        inventoryOps: _.cloneDeep(action.payload.inventoryOpsArr),
        // updatingInventory: false,
        cancelingOperation: false,
        fetchingInventoryOps: false,
      };
    case actions.FETCHING_INVENTORY_OPS_LIST:
      return {
        ...state,
        fetchingInventoryOps: true,
      };
    case actions.INVENTORY_OPS_FETCH_ERROR:
      return {
        ...state,
        fetchingInventoryOps: null,
      };
    case actions.INVENTORY_PROVIDERS_FETCH_SUCCESS:
      return {
        ...state,
        inventoryProviders: {
          ...state.inventoryProviders,
          [action.payload.address]: _.cloneDeep(action.payload.inventoryProviders),
        },
        updatingInventory: false,
        removingInventoryProvider: false,
      };
    case actions.INVENTORY_CLASSES_FETCH_SUCCESS:
      return {
        ...state,
        inventoryClasses: {
          ...state.inventoryClasses,
          [action.payload.address]: _.cloneDeep(action.payload.inventoryClasses),
        },
        updatingInventory: false,
        removingInventoryProvider: false,
      };
    case actions.INVENTORY_ITEMS_FETCH_SUCCESS:
      return {
        ...state,
        inventoryItems: action.payload.inventoryItems,
      };
    case actions.RESET_INVENTORY_LISTENER:
      return {
        ...state,
        inventoryItems: undefined,
      };
    case actions.INVENTORY_FETCH_OR_UPDATING_WAITING:
      return {
        ...state,
        updatingInventory: true,
      };
    case actions.INVENTORY_REMOVE_PROVIDER_WAITING:
      return {
        ...state,
        removingInventoryProvider: action.payload,
      };
    case actions.CANCEL_OPERATION_WAITING:
      return {
        ...state,
        cancelingOperation: action.payload,
      };
    case actions.INVENTORY_FETCH_ERROR:
      return {
        ...state,
        inventoryOps: {
          ...state.inventoryOps,
          [action.payload.address]: [],
        },
        inventoryProviders: {
          ...state.inventoryProviders,
          [action.payload.address]: [],
        },
        updatingInventory: null,
      };
    case actions.CREATE_INVENTORY_ITEM_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.REMOVE_INVENTORY_ITEM_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.DEPOSIT_INVENTORY_ITEM_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.CREATE_INVENTORY_PROVIDER_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.REMOVE_INVENTORY_PROVIDER_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.WITHDRAW_INVENTORY_ITEM_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.UPDATE_INVENTORY_DEPOSIT_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.CANCEL_OPERATION_ERROR:
      return {
        ...state,
        cancelingOperation: null,
      };
    case actions.UPDATE_INVENTORY_WITHDRAW_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.CREATE_INVENTORY_CLASS_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.UPDATE_INVENTORY_ITEM_ERROR:
      return {
        ...state,
        updatingInventory: null,
      };
    case actions.CHANGE_INVENTORY_OPS_LISTENER_TIMER:
      return {
        ...state,
        inventoryOpsListenerTime: action.payload.value,
        inventoryOpsListenerTimeRange: action.payload.dateRange,
        inventoryOpsListenerCustomTime: action.payload.customValue,
      };
    case actions.RESET_INVENTORY_OPS:
      return {
        ...state,
        inventoryOps: undefined,
      };
    default:
      return state;
  }
}

import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  allCustomUsers: [],
  customUsers: [],
  fetchingCustomUsers: true,
  updatingCustomUser: false,
  removingCustomUser: false,
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.EMAIL_SIGNIN_SUCCESS:
      return {
        ...state,
        emailLinkSigninSuccess: true,
        updatingCustomUser: false,
        customUserError: {},
        customUserUid: action.payload.uid,
        createdCustomUserProfile: action.payload.profile,
      };
    case actions.EMAIL_SIGNIN_ERROR:
      return {
        ...state,
        emailLinkSigninSuccess: null,
        updatingCustomUser: false,
        customUserError: action.payload,
      };
    case actions.CUSTOM_USERS_FETCH_SUCCESS:
      return {
        ...state,
        allCustomUsers: action.payload.allCustomUsers,
        customUsers: action.payload.customUsers,
        updatingCustomUser: false,
        removingCustomUser: false,
        customUserError: {},
        errorMessage: null,
        fetchingCustomUsers: false,
        initialFetch: true,
      };
    case actions.FETCHING_CUSTOM_USERS:
      return {
        ...state,
        fetchingCustomUsers: true,
      };
    case actions.CUSTOM_USERS_FETCH_OR_UPDATING_WAITING:
      return {
        ...state,
        updatingCustomUser: true,
        customUserError: {},
        emailLinkSigninSuccess: false,
      };
    case actions.CUSTOM_USERS_FETCH_ERROR:
      return {
        ...state,
        allCustomUsers: [],
        customUsers: [],
        updatingCustomUser: null,
        fetchingCustomUsers: null,
      };
    case actions.CREATE_CUSTOM_USER_ERROR:
      return {
        ...state,
        updatingCustomUser: null,
      };
    case actions.CREATE_CUSTOM_USER_ERROR_WITH_MESSAGE:
      return {
        ...state,
        updatingCustomUser: null,
        errorMessage: action.payload,
      };
    case actions.COMPLETE_CUSTOM_USER_EMAIL_LINK_SIGNIN_ERROR:
      return {
        ...state,
        updatingCustomUser: null,
        customUserError: action.payload,
      };
    case actions.SAVE_CUSTOM_USER_ERROR:
      return {
        ...state,
        updatingCustomUser: null,
      };
    case actions.REMOVING_CUSTOM_USERS:
      return {
        ...state,
        removingCustomUser: true,
      };
    case actions.REMOVE_CUSTOM_USER_ERROR:
      return {
        ...state,
        removingCustomUser: null,
      };
    case actions.REMOVE_CUSTOM_USER_ERROR_WITH_MESSAGE:
      return {
        ...state,
        removingCustomUser: null,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

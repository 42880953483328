import {
  all,
  put,
  fork,
  takeEvery,
  select,
} from 'redux-saga/effects';
import _ from 'lodash';
import actions from './actions';

const getConfirmedFromStore = (state) => state.Agenda.confirmed;

const getPastConfirmedFromStore = (state) => state.Agenda.pastConfirmed;

const getModalVisibleFromStore = (state) => state.Calendar.modalVisible;

export function* checkCanceledVisibilityStatus() {
  yield takeEvery(actions.GET_CANCELED_VISIBILITY, function* () {
    try {
      const data = yield localStorage.getItem('canceled_visibility');
      if (_.isNull(data)) {
        yield put({
          type: actions.SET_CANCELED_VISIBILITY,
          value: true,
        });
      } else {
        yield put({
          type: actions.SET_CANCELED_VISIBILITY,
          value: JSON.parse(data),
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setCanceledVisibility() {
  yield takeEvery(actions.SET_CANCELED_VISIBILITY, function* (action) {
    try {
      yield localStorage.setItem('canceled_visibility', action.value);
      yield put({
        type: actions.SET_CANCELED_VISIBILITY_SUCCESS,
        value: action.value,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* updateSelectedData() {
  yield takeEvery(actions.UPDATE_SELECTED_DATA_REQUEST, function* (action) {
    try {
      const confirmed = yield select(getConfirmedFromStore);
      const pastConfirmed = yield select(getPastConfirmedFromStore);
      const modalVisible = yield select(getModalVisibleFromStore);
      const newSelectedData = confirmed.find((el) => el.id === action.payload) || pastConfirmed.find((el) => el.id === action.payload);
      if (newSelectedData && newSelectedData.id) {
        yield put({
          type: actions.CALENDAR_EVENT_SELECTED,
          payload: {
            modalVisible,
            selectedData: newSelectedData,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* checkSundayVisibilityStatus() {
  yield takeEvery(actions.GET_UNUSED_DAYS_VISIBILITY, function* () {
    try {
      // yield localStorage.removeItem('sunday_visibility');
      const data = yield localStorage.getItem('sunday_visibility');
      if (_.isNull(data)) {
        yield put({
          type: actions.SET_UNUSED_DAYS_VISIBILITY,
          value: true,
        });
      } else {
        yield put({
          type: actions.SET_UNUSED_DAYS_VISIBILITY,
          value: JSON.parse(data),
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setUnusedDaysVisibility() {
  yield takeEvery(actions.SET_UNUSED_DAYS_VISIBILITY, function* (action) {
    try {
      yield localStorage.setItem('sunday_visibility', action.value);
      yield put({
        type: actions.SET_UNUSED_DAYS_VISIBILITY_SUCCESS,
        value: action.value,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* clearCalendarSelectedData() {
  yield takeEvery(actions.CLEAR_CALENDAR_SELECTED_DATA_REQUEST, function* () {
    try {
      yield put({
        type: actions.SET_CALENDAR_MODAL_DATA,
        payload: {
          modalVisible: null,
        },
      });
      yield put({
        type: actions.CLEAR_CALENDAR_SELECTED_DATA,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(setCanceledVisibility),
    fork(checkCanceledVisibilityStatus),
    fork(updateSelectedData),
    fork(checkSundayVisibilityStatus),
    fork(setUnusedDaysVisibility),
    fork(clearCalendarSelectedData),
  ]);
}

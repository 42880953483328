import _ from 'lodash';
import React from 'react';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  get,
  update,
  push,
  child,
  // startAfter,
  query as dbQuery,
  orderByChild,
  equalTo,
} from 'firebase/database';
import {
  getFirestore,
  doc,
  getDoc,
} from 'firebase/firestore';
import moment from 'moment-timezone';
import {
  all,
  takeLatest,
  fork,
  call,
  put,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import axios from 'axios';
import { notification } from '../../components';
import actions from './actions';
import authActions from '../auth/actions';
import profileActions from '../profile/actions';
import prescriptionsActions from '../prescriptions/actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getIsProfessionalFromStore = (state) => state.Auth.professional;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getUserFromStore = (state) => state.Auth.user;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getProfileFromStore = (state) => state.Profile.profileOnDB;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getHealthProfessionalFromStore = (state) => state.Auth.healthProfessional;

const getMemedUserTokenFromStore = (state) => state.Memed.memedUserToken;

const getMemedUserDataFromStore = (state) => state.Memed.memedUserData;

const getCustomUserFromStore = (state) => state.CustomUsers.customUsers;

const getCustomHealthProfessionalProfileFromStore = (state) => _.cloneDeep(state.Profile.customHealthProfessionalProfile);

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function getMemedSettingsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/memedSettings`));
}

export function* getMememedSettingsRequest() {
  yield takeLatest([
    authActions.LOGIN_SUCCESS,
    actions.FETCH_MEMED_SETTINGS_REQUEST,
    profileActions.PROFILE_INFO_SUCCESS,
  ], function* (action) {
    try {
      console.info('getMememedSettingsRequest', action);
      const professional = yield select(getIsProfessionalFromStore);
      if (professional) {
        let profile = yield select(getProfileFromStore);
        const mainUser = yield select(getMainUserFromStore);
        const healthProfessional = yield select(getHealthProfessionalFromStore);
        const memedSettings = yield call(getMemedSettingsFromDB, mainUser);
        // const defaultObj = {
        //   useMemed: false,
        // };
        if (_.isEmpty(profile)) {
          yield take(profileActions.PROFILE_INFO_SUCCESS);
          profile = yield select(getProfileFromStore);
        }
        const defaultObj = {};
        if (!mainUser) {
          defaultObj[profile.id] = {
            useMemed: (memedSettings.val() && memedSettings.val().useMemed) || false,
          };
        }
        if (mainUser && healthProfessional) {
          const customHealthProfessionalProfile = yield select(getCustomHealthProfessionalProfileFromStore);
          if (customHealthProfessionalProfile) {
            profile = _.cloneDeep(customHealthProfessionalProfile);
          }
          defaultObj[profile.id] = { useMemed: false };
        }
        // if (profile?.council?.type !== 'crm' && memedSettings.val() && memedSettings.val().useMemed) {
        if (profile?.council?.type !== 'crm' && memedSettings.val()?.[profile.ud]?.useMemed) {
          // yield put({
          //   type: actions.FETCH_MEMED_SETTINGS_SUCCESS,
          //   payload: {
          //     ...defaultObj,
          //   },
          // });
          yield put({
            type: actions.CHANGE_USE_MEMED_REQUEST,
            payload: {
              value: false,
              id: profile.id,
            },
          });
        } else if (memedSettings.val()) {
          const memedObj = {
            ...defaultObj,
            ...memedSettings.val(),
          };
          delete memedObj.useMemed;
          yield put({
            type: actions.FETCH_MEMED_SETTINGS_SUCCESS,
            payload: memedObj,
          });
        } else {
          yield put({
            type: actions.FETCH_MEMED_SETTINGS_SUCCESS,
            payload: {
              ...defaultObj,
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function changeUseMemedOnDB({ value, id }, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/memedSettings/${id}/useMemed`] = value;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeUseMemedRequest() {
  yield takeLatest(actions.CHANGE_USE_MEMED_REQUEST, function* (action) {
    try {
      yield put({ type: actions.UPDATING_USE_MEMED });
      const mainUser = yield select(getMainUserFromStore);
      yield call(changeUseMemedOnDB, action.payload, mainUser);
      yield put({
        type: actions.FETCH_MEMED_SETTINGS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Algo deu errado',
        'Tente novamente mais tarde.',
      );
      yield put({
        type: actions.CHANGE_USE_MEMED_ERROR,
      });
    }
  });
}

function registerMemedUser(idToken, mainUser, addressUid, profile) {
  // let uid;
  // if (mainUser) {
  //   uid = mainUser;
  // } else {
  //   const { currentUser } = firebase.auth();
  //   ({ uid } = currentUser);
  // }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const memedObj = {};
  // CRM and Email field are filled in later calls, because it's senstive fields (throw erros if already on Memed's database)
  memedObj.nome = profile.firstName;
  memedObj.sobrenome = profile.lastName;
  memedObj.data_nascimento = profile.birthday;
  memedObj.cpf = profile.cpf;
  // memedObj.email = profile.email;
  memedObj.email = null;
  if (profile.gender === 'male') {
    memedObj.sexo = 'M';
  } else if (profile.gender === 'female') {
    memedObj.sexo = 'F';
  } else {
    memedObj.sexo = '';
  }
  if (profile.council?.type === 'crm') {
    memedObj.uf = profile.council.region.toUpperCase();
    // memedObj.crm = profile.council.value;
    memedObj.especialidades = profile.council.specialties;
  }
  const addressObj = profile.addresses.find((ad) => ad.id === addressUid);
  if (addressObj) {
    if (addressObj.city?.longName) {
      memedObj.cidade = addressObj.city.longName;
    }
    if (addressObj.state?.shortName) {
      memedObj.estado = addressObj.state.shortName;
    }
  }
  const bodyParameters = {
    uid: profile.id,
    memedObj,
  };
  console.info('bodyParameters registerMemedUser', bodyParameters);
  // return true;
  return axios.post(
    `${ROOT_URL}/registerMemedUser`,
    bodyParameters,
    config,
  );
}

export function* registerMemedUserRequest() {
  yield takeEvery(actions.REGISTER_MEMED_USER_REQUEST, function* (action) {
    try {
      console.info('REGISTER_MEMED_USER_REQUEST', action);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const healthProfessional = yield select(getHealthProfessionalFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      const user = yield select(getUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      if (mainUser) {
        const customHealthProfessionalProfile = yield select(getCustomHealthProfessionalProfileFromStore);
        if (!_.isEmpty(customHealthProfessionalProfile)) {
          profile = _.cloneDeep(customHealthProfessionalProfile);
        } else if (action.payload?.id && !healthProfessional) {
          // It is a non-professional account. Need to use the id provided through paylad.
          const selectedId = action.payload.id;
          if (selectedId === mainUser) {
            // Use the main profile.
          } else {
            const foundUser = customUsers.find((el) => el.id === selectedId);
            if (foundUser) {
              profile = _.cloneDeep(foundUser);
            }
          }
        }
      }
      const response = yield call(
        registerMemedUser,
        idToken,
        mainUser,
        addressUid,
        {
          ...profile,
          email: user.email,
        },
      );
      if (response?.data?.data?.attributes) {
        yield put({
          type: actions.GET_MEMED_USER_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      if (error?.response?.data?.errors) {
        console.warn(error.response.data.errors);
      }
      if (error?.response?.data?.errors?.find((el) => el.code === 'CPF')) {
        notification(
          'error',
          'Ocorreu um erro com a Memed na hora de validar o seu CPF, entre em contato com o suporte',
          <a href="https://ajuda.memed.com.br" target="_blank" rel="noopener noreferrer">Suporte Memed</a>,
          // null,
        );
      }
      yield put({
        type: actions.ERROR_UPDATING_MEMED_USER,
      });
    }
  });
}

function getProfessionalInfoFromDB(uid) {
  const fs = getFirestore();
  const docRef = doc(fs, 'professionals', uid);
  return getDoc(docRef);
}

function getUserEmail(
  idToken,
  uid,
) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/getUserEmail`,
    bodyParameters,
    config,
  );
}

function updateMemedUser(
  idToken,
  mainUser,
  addressUid,
  profile,
  {
    external_id: oldExternalId,
    doNotChangeExternalId = false,
    updateCrm = false,
    updateEmail = false,
  },
) {
  const uid = profile.id;
  // let uid;
  // if (mainUser) {
  //   uid = mainUser;
  // } else {
  //   const { currentUser } = firebase.auth();
  //   ({ uid } = currentUser);
  // }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const memedObj = {};
  memedObj.nome = profile.firstName;
  memedObj.sobrenome = profile.lastName;
  memedObj.data_nascimento = profile.birthday;
  memedObj.cpf = profile.cpf;
  if (updateEmail) {
    memedObj.email = profile.email;
  }
  // memedObj.email = null;
  if (profile.gender === 'male') {
    memedObj.sexo = 'M';
  } else if (profile.gender === 'female') {
    memedObj.sexo = 'F';
  } else {
    memedObj.sexo = '';
  }
  if (profile.council?.type === 'crm') {
    if (updateCrm) {
      memedObj.crm = profile.council.value;
    } else {
      memedObj.uf = profile.council.region.toUpperCase();
    }
    memedObj.especialidades = profile.council.specialties;
  }
  const addressObj = profile.addresses.find((ad) => ad.id === addressUid);
  if (addressObj) {
    if (addressObj.city?.longName) {
      memedObj.cidade = addressObj.city.longName;
    }
    if (addressObj.state?.shortName) {
      memedObj.estado = addressObj.state.shortName;
    }
  }
  const bodyParameters = {
    uid,
    old_external_id: oldExternalId,
    doNotChangeExternalId,
    memedObj,
  };
  console.info('bodyParameters updateMemedUser', bodyParameters);
  // return true;
  return axios.post(
    `${ROOT_URL}/updateMemedUser`,
    bodyParameters,
    config,
  );
}

export function* updateMemedUserRequest() {
  yield takeEvery(actions.UPDATE_MEMED_USER_REQUEST, function* (action) {
    try {
      console.info('UPDATE_MEMED_USER_REQUEST', action);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const healthProfessional = yield select(getHealthProfessionalFromStore);
      // const customUsers = yield select(getCustomUserFromStore);
      // const user = yield select(getUserFromStore);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const { addresses } = profile;
      if (mainUser) {
        const customHealthProfessionalProfile = yield select(getCustomHealthProfessionalProfileFromStore);
        if (!_.isEmpty(customHealthProfessionalProfile)) {
          profile = _.cloneDeep(customHealthProfessionalProfile);
          profile.addresses = _.cloneDeep(addresses);
        } else if (action.payload?.id && !healthProfessional) {
          // It is a non-professional account. Need to use the id provided through paylad.
          const selectedId = action.payload.id;
          if (selectedId === mainUser) {
            // Use the main profile.
          } else {
            const professionalProfile = yield call(getProfessionalInfoFromDB, selectedId);
            if (professionalProfile.exists()) {
              profile = {
                ...professionalProfile.data(),
                id: professionalProfile.id,
              };
              profile.addresses = _.cloneDeep(addresses);
            } else {
              profile = {};
            }
            // const foundUser = customUsers.find((el) => el.id === selectedId);
            // if (foundUser) {
            //   profile = _.cloneDeep(foundUser);
            //   profile.addresses = _.cloneDeep(addresses);
            // }
          }
        }
      }
      const responseEmail = yield call(getUserEmail, idToken, profile.id);
      const response = yield call(
        updateMemedUser,
        idToken,
        mainUser,
        addressUid,
        {
          ...profile,
          email: responseEmail.data,
        },
        action.payload,
      );
      if (response?.data?.data?.attributes) {
        yield put({
          type: actions.GET_MEMED_USER_REQUEST,
          payload: {
            updated: true,
            id: action?.payload?.id,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      if (action.payload.updateEmail) {
        // The requested email already is on use. Need to contact Memed support.
        notification(
          'warning',
          'O seu e-mail já está em uso em outra conta Memed, entre em contato com o suporte',
          <a href="https://ajuda.memed.com.br" target="_blank" rel="noopener noreferrer">Suporte Memed</a>,
          // null,
        );
      } else if (error?.response?.data?.errors?.find((el) => el.code === 'CPF')) {
        notification(
          'error',
          'Ocorreu um erro com a Memed na hora de validar o seu CPF, entre em contato com o suporte',
          <a href="https://ajuda.memed.com.br" target="_blank" rel="noopener noreferrer">Suporte Memed</a>,
          // null,
        );
      }
      yield put({
        type: actions.ERROR_UPDATING_MEMED_USER,
      });
    }
  });
}

function getMemedUser(idToken, mainUser, profile, field = 'uid') {
  // let uid;
  // if (mainUser) {
  //   uid = mainUser;
  // } else {
  //   const { currentUser } = firebase.auth();
  //   ({ uid } = currentUser);
  // }
  const uid = profile.id;
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    field,
    // uid: profile.cpf || UID_TESTE,
    // uid: profile.cpf || uid,
  };
  if (field === 'cpf') {
    bodyParameters.uid = profile.cpf;
  } else if (field === 'crm') {
    if (profile.council?.type === 'crm') {
      const crm = `${profile.council.value}${profile.council.region.toUpperCase()}`;
      bodyParameters.uid = crm;
    } else {
      bodyParameters.uid = uid;
    }
  } else {
    bodyParameters.uid = uid;
  }
  console.info('bodyParameters getMemedUser', bodyParameters);
  return axios.post(
    `${ROOT_URL}/getMemedUser`,
    bodyParameters,
    config,
  );
}

export function* getMemedUserRequest() {
  yield takeLatest([
    actions.GET_MEMED_USER_REQUEST,
    // profileActions.PROFILE_INFO_SUCCESS,
  ], function* (action) {
    let profile;
    try {
      console.info('GET_MEMED_USER_REQUEST', action);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      // const healthProfessional = yield select(getHealthProfessionalFromStore);
      // const customUsers = yield select(getCustomUserFromStore);
      // const user = yield select(getUserFromStore);
      profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const { addresses } = profile;
      if (mainUser) {
        const customHealthProfessionalProfile = yield select(getCustomHealthProfessionalProfileFromStore);
        if (!_.isEmpty(customHealthProfessionalProfile)) {
          profile = _.cloneDeep(customHealthProfessionalProfile);
        }
        // else if (action.payload?.id && !healthProfessional) {
        //   // It is a non-professional account. Need to use the id provided through paylad.
        //   const selectedId = action.payload.id;
        //   if (selectedId === mainUser) {
        //     // Use the main profile.
        //   } else {
        //     const professionalProfile = yield call(getProfessionalInfoFromDB, selectedId);
        //     if (professionalProfile.exists()) {
        //       profile = {
        //         ...professionalProfile.data(),
        //         id: professionalProfile.id,
        //       };
        //       profile.addresses = _.cloneDeep(addresses);
        //     } else {
        //       profile = {};
        //     }
        //   }
        // }
      }
      if (action.payload?.id) {
        // Need to use the id provided through paylad.
        const selectedId = action.payload.id;
        if (selectedId === mainUser) {
          // Use the main profile.
          profile = yield select(getProfileFromStore);
        } else {
          const professionalProfile = yield call(getProfessionalInfoFromDB, selectedId);
          if (professionalProfile.exists()) {
            profile = {
              ...professionalProfile.data(),
              id: professionalProfile.id,
            };
            profile.addresses = _.cloneDeep(addresses);
          } else {
            profile = {};
          }
          // const foundUser = customUsers.find((el) => el.id === selectedId);
          // if (foundUser) {
          //   profile = _.cloneDeep(foundUser);
          // }
        }
      }
      console.info('profile', profile);
      const responseEmail = yield call(getUserEmail, idToken, profile.id);
      let response;
      if (action.payload?.field) {
        // If payload has 'field', it means it is a subsequent fetch try
        response = yield call(getMemedUser, idToken, mainUser, profile, action.payload.field);
      } else {
        response = yield call(getMemedUser, idToken, mainUser, profile);
      }
      console.info('response memed', response);
      if (response?.data?.data?.attributes?.external_id !== profile.id
        && response.data.data.attributes.external_id) {
        // The user retrieved using CPF/CRM does not have Meagenda's id as external_id set.
        // Need to update it using https PATCH request.
        console.info("The user retrieved using CPF/CRM does not have Meagenda's id as external_id set.");
        console.info('Need to update it using https PATCH request.');
        yield put({
          type: actions.UPDATE_MEMED_USER_REQUEST,
          payload: {
            external_id: response.data.data.attributes.external_id,
            id: profile.id,
          },
        });
        return;
      }
      let validCrm = true;
      if ((profile.council?.type === 'crm' || profile.council?.type === 'cro') && profile.council?.value) {
        const crm = profile.council.value
          .replace(/\s+/g, ' ')
          .replace(/^\s+|\s+$/g, '')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^0-9]/g, '');
        if (response?.data?.data?.attributes?.crm !== crm
          && response.data.data.attributes.external_id) {
          // The user retrieved has different CRM saved on Memed profile, need to update it.
          console.info('The user retrieved has different CRM saved on Memed profile, need to update it.');
          validCrm = false;
          yield put({
            type: actions.UPDATE_MEMED_USER_REQUEST,
            payload: {
              external_id: response.data.data.attributes.external_id,
              doNotChangeExternalId: true,
              updateCrm: true,
              id: profile.id,
            },
          });
          return;
        }
        if (response?.data?.data?.attributes?.cpf !== profile.cpf
          && response.data.data.attributes.external_id) {
          // The user retrieved has different CPF saved on Memed profile, need to update it.
          console.info('The user retrieved has different CPF saved on Memed profile, need to update it.');
          yield put({
            type: actions.UPDATE_MEMED_USER_REQUEST,
            payload: {
              external_id: response.data.data.attributes.external_id,
              doNotChangeExternalId: true,
              id: profile.id,
            },
          });
          return;
        }
        if (response?.data?.data?.attributes?.email !== responseEmail.data
          && response.data.data.attributes.external_id) {
          // The user retrieved has different email saved on Memed profile, need to update it.
          console.info('The user retrieved has different email saved on Memed profile, need to update it.');
          yield put({
            type: actions.UPDATE_MEMED_USER_REQUEST,
            payload: {
              external_id: response.data.data.attributes.external_id,
              doNotChangeExternalId: true,
              updateEmail: true,
              id: profile.id,
            },
          });
          return;
        }
        if (validCrm
          && response?.data?.data?.attributes?.token
          && (response?.data?.data?.attributes?.status === 'Ativo' || response?.data?.data?.attributes?.status === 'Em análise')) {
          yield put({
            type: actions.SET_MEMED_USER_TOKEN,
            payload: {
              token: response.data.data.attributes.token,
              user: response.data.data.attributes,
            },
          });
          let message = 'Conta Memed conectada com sucesso!';
          if (action?.payload?.updated) {
            message = 'As informações do seu cadastro Memed foram atualizadas';
          }
          notification(
            'success',
            message,
          );
        }
      } else {
        throw new Error('No valid council.');
      }
    } catch (error) {
      console.warn(error);
      if (error?.message === 'No valid council.') {
        notification(
          'error',
          'Somente contas com CRM ou CRO podem utilizar o módulo de prescrição da Memed',
        );
        yield put({
          type: actions.ERROR_UPDATING_MEMED_USER,
        });
      } else if (!action?.payload?.field) {
        // It was not possible to fetch user using external_id, probably the user already exists
        // with the CPF informed.
        // Need to fetch user again, this time using the CPF itself as param.
        console.info('It was not possible to fetch user using external_id, probably the user already exists with the CPF informed.');
        console.info('Need to fetch user again, this time using the CPF itself as param.');
        yield put({
          type: actions.GET_MEMED_USER_REQUEST,
          payload: {
            field: 'cpf',
            id: action?.payload?.id,
          },
        });
      } else if (error?.response?.status === 404) {
        // Could not find any user with this CPF, proceed to register account.
        console.info('Could not find any user with this CPF, proceed to register account');
        yield put({
          type: actions.REGISTER_MEMED_USER_REQUEST,
          payload: {
            id: action?.payload?.id,
          },
        });
      }
    }
  });
}

function saveMemedPrescriptionOnDB(
  fullObj,
  prescription,
  seectedId,
  memedUserData,
  // timestamp,
  addressUid,
  unified = {},
  mainProfile = null,
  mainUser,
  customUsers,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const currentUid = currentUser.uid;
  const author = {};
  if (currentUid === mainProfile.id) {
    // It is the main account.
    author.firstName = mainProfile.firstName;
    author.lastName = mainProfile.lastName;
    author.uid = mainProfile.id;
  } else {
    // It is some of the 'customUsers' accounts.
    const foundProfile = customUsers.find((el) => el.id === currentUid);
    if (foundProfile) {
      author.firstName = foundProfile.firstName;
      author.lastName = foundProfile.lastName;
      author.uid = foundProfile.id;
      if (foundProfile.healthProfessional) {
        // It is a health professional account.
      } else {
        // Secretary account.
        author.notHealthProfessional = true;
      }
    }
  }
  const updates = {};
  const timestamp = moment(fullObj.created_at, 'DD/MM/YYYY HH:mm:ss').tz('America/Sao_Paulo');
  const prescriptionDate = fullObj.prescriptionDateOriginal;
  let pushKey = '';
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    pushKey = push(child(ref(db), `unified/${unified.id}/prescriptions/${seectedId}`)).key;
  } else {
    pushKey = push(child(ref(db), `users/${uid}/prescriptions/${seectedId}`)).key;
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/memed`] = true;
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/prescriptionDate`] = prescriptionDate;
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/memedId`] = fullObj.id;
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/prescriptionUuid`] = fullObj.prescriptionUuid;
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/items`] = _.orderBy(prescription, ['key'], ['asc']);
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/externalId`] = memedUserData?.external_id || null;
    if (!_.isEmpty(author)) {
      author.unified = true;
      updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/professional`] = author;
    }
  } else {
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/memed`] = true;
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/prescriptionDate`] = prescriptionDate;
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/memedId`] = fullObj.id;
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/prescriptionUuid`] = fullObj.prescriptionUuid;
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/items`] = _.orderBy(prescription, ['key'], ['asc']);
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/externalId`] = memedUserData?.external_id || null;
    if (!_.isEmpty(author)) {
      updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/professional`] = author;
    }
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function normalizePrescription(presc) {
  const arr = [];
  if (presc.medicamentos) {
    presc.medicamentos.forEach((el) => {
      arr.push(el);
    });
  }
  return arr;
}

export function* saveMemedPrescription() {
  yield takeEvery(actions.SAVE_MEMED_PRESCRIPTION_REQUEST, function* (action) {
    try {
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const { values } = action.payload;
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      const memedUserData = yield select(getMemedUserDataFromStore);
      const prescription = yield call(normalizePrescription, values.prescricao);
      yield call(
        saveMemedPrescriptionOnDB,
        values.prescricao,
        prescription,
        values.prescricao.paciente.external_id,
        memedUserData,
        addressUid,
        unified,
        profile,
        mainUser,
        customUsers,
      );
      yield all([
        put({
          type: prescriptionsActions.PATIENT_PRESCRIPTIONS_FETCH_REQUEST,
        }),
        // yield put({
        //   type: actions.RESET_PRESCRIPTION,
        //   payload: {
        //     id: seectedId,
        //   },
        // }),
        // yield put({
        //   type: appActions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
        //   payload: {
        //     id: seectedId,
        //     field: 'prescription',
        //     fieldValue: true,
        //   },
        // }),
      ]);
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Não foi possível salvar a receita Memed em seu histórico do Meagenda',
        'A prescrição gerada ainda estará salva no histórico do paciente ao acessar os módulos Memed.',
      );
    }
  });
}

function getMemedPrescriptionPdf(idToken, mainUser, memedUserToken, memedId) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    // uid,
    memedUserToken,
    memedId,
  };
  return axios.post(
    `${ROOT_URL}/getMemedPrescriptionPdf`,
    bodyParameters,
    config,
  );
}

export function* getMemedPrescriptionPdfRequest() {
  yield takeEvery(actions.GET_MEMED_PRESCRIPTION_PDF_REQUEST, function* (action) {
    try {
      yield put({ type: actions.DOWNLOADING_MEMED_PRESCRIPTION_PDF });
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const memedUserToken = yield select(getMemedUserTokenFromStore);
      const response = yield call(
        getMemedPrescriptionPdf,
        idToken,
        mainUser,
        memedUserToken,
        action.payload.memedId,
      );
      if (response?.data?.data) {
        const arr = [];
        response.data.data.forEach((el) => {
          if (el.attributes?.link) {
            arr.push({
              url: el.attributes.link,
              id: action.payload.id,
              type: 'application/pdf',
            });
          }
        });
        yield put({
          type: actions.GET_MEMED_PRESCRIPTION_PDF_SUCCESS,
          payload: arr,
        });
      } else {
        throw new Error('Failed to fetch prescription.');
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_MEMED_PRESCRIPTION_PDF_ERROR,
      });
      if (error?.response?.data?.errors?.find((el) => el.detail === 'Prescrição não encontrada')) {
        notification(
          'warning',
          'A prescrição não foi encontrada com a conta conectada',
          'Talvez a entrada tenha sido gerada com outra conta Memed.',
          10,
        );
      }
    }
  });
}

function fetchMemedPrescriptionFromDB(addressUid, mainUser, unified = {}, seectedId, memedId) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `unified/${unified.id}/prescriptions/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('memedId'),
      equalTo(memedId),
    ));
    // return db.ref(`unified/${unified.id}/prescriptions/${seectedId}`)
    //   .orderByChild('memedId')
    //   .equalTo(memedId)
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/prescriptions/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('memedId'),
    equalTo(memedId),
  ));
  // return db.ref(`users/${uid}/prescriptions/${seectedId}`)
  //   .orderByChild('memedId')
  //   .equalTo(memedId)
  //   .once('value');
}

function removeMemedPrescriptionFromDB(addressUid, mainUser, unified = {}, seectedId, id) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${id}`] = null;
  } else {
    updates[`users/${uid}/prescriptions/${seectedId}/${id}`] = null;
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeMemedPrescriptionRequest() {
  yield takeEvery(actions.REMOVE_MEMED_PRESCRIPTION_REQUEST, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const prescription = yield call(
        fetchMemedPrescriptionFromDB,
        addressUid,
        mainUser,
        unified,
        seectedId,
        action.payload,
      );
      if (prescription.exists()) {
        let key = null;
        Object.keys(prescription.val()).forEach((id) => {
          key = id;
        });
        yield call(
          removeMemedPrescriptionFromDB,
          addressUid,
          mainUser,
          unified,
          seectedId,
          key,
        );
        yield put({
          type: prescriptionsActions.PATIENT_PRESCRIPTIONS_FETCH_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_MEMED_PRESCRIPTION_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMememedSettingsRequest),
    fork(changeUseMemedRequest),
    fork(registerMemedUserRequest),
    fork(getMemedUserRequest),
    fork(updateMemedUserRequest),
    // fork(initMemedEventsRequest),
    fork(saveMemedPrescription),
    fork(getMemedPrescriptionPdfRequest),
    fork(removeMemedPrescriptionRequest),
  ]);
}

import getDefaultPath from '../../helpers/urlSync';
import actions, { getView } from './actions';
import agendaActions from '../agenda/actions';
import authActions from '../auth/actions';
import recordsActions from '../records/actions';
import prescriptionsActions from '../prescriptions/actions';
import certificateActions from '../certificate/actions';

const preKeys = getDefaultPath();

const initState = {
  collapsed: window.innerWidth < 1220,
  forceOpenSidebar: window.innerWidth >= 1220,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  reloadApp: false,
  generalSettingsKey: '',
  pageWithAbsoluteFooterMounted: {
    '/dashboard/settings': false,
  },
  changeLog: null,
  scrollTrigger: false,
  savedAllChangesProp: {},
  floatButtonValue: ['chat', 'waitList', 'whatsapp', 'block'],
  alertNotice: null,
  updatingAlert: false,
};
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
        maintenance: state.maintenance,
      };
    case actions.CANCEL_LISTENERS_SUCCESS:
      return {
        ...state,
        removeListeners: true,
      };
    case actions.SET_CANCEL_LISTENERS:
      return {
        ...state,
        removeListeners: action.payload,
      };
    case agendaActions.PENDING_FETCH_SUCCESS:
      return {
        ...state,
        removeListeners: false,
      };
    case agendaActions.CONFIRMED_FETCH_SUCCESS:
      return {
        ...state,
        removeListeners: false,
      };
    case agendaActions.REJECTED_FETCH_SUCCESS:
      return {
        ...state,
        removeListeners: false,
      };
    case agendaActions.CANCELED_FETCH_SUCCESS:
      return {
        ...state,
        removeListeners: false,
      };
    case actions.COLLAPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.PERMANENT_COLLAPSE_CHANGE:
      return {
        ...state,
        forceOpenSidebar: action.payload,
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys };
    case actions.CHANGE_CURRENT:
      return { ...state, current: action.current };
    case actions.CLOSE_ALL:
      return { ...state, current: [], openKeys: [] };
    case actions.SELECT_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case actions.NEED_APP_RELOAD:
      return {
        ...state,
        reloadApp: true,
      };
    case actions.CHANGE_GENERAL_SETTINGS_KEY:
      return {
        ...state,
        generalSettingsKey: action.payload,
      };
    case actions.PAGE_WITH_ABSOLUTE_FOOTER_MOUNTED:
      return {
        ...state,
        pageWithAbsoluteFooterMounted: {
          ...state.pageWithAbsoluteFooterMounted,
          [action.payload]: true,
        },
      };
    case actions.PAGE_WITH_ABSOLUTE_FOOTER_UNMOUNTED:
      return {
        ...state,
        pageWithAbsoluteFooterMounted: {
          ...state.pageWithAbsoluteFooterMounted,
          [action.payload]: false,
        },
      };
    case actions.FETCH_SERVICE_CONTRACT_SUCCESS:
      return {
        ...state,
        serviceContractVersion: action.payload.version,
        serviceContractDocument: action.payload.document,
      };
    case actions.SAVING_SERVICE_CONTRACT:
      return {
        ...state,
        savingServiceContract: true,
      };
    case actions.ACCEPT_SERVICE_CONTRACT_SUCCESS:
      return {
        ...state,
        savingServiceContract: false,
      };
    case actions.ACCEPT_SERVICE_CONTRACT_ERROR:
      return {
        ...state,
        savingServiceContract: null,
      };
    case actions.FETCH_SYSTEM_UPDATES_CHANGELOG_SUCCESS:
      return {
        ...state,
        changeLog: action.payload,
      };
    case actions.FETCH_COMPLETE_SYSTEM_UPDATES_CHANGELOG_SUCCESS:
      return {
        ...state,
        completeChangeLog: action.payload,
      };
    case actions.SCROOL_WRAPPER_TO_BOTTOM:
      return {
        ...state,
        scrollTrigger: !state.scrollTrigger,
      };
    case actions.CHANGE_SAVED_ALL_CHANGES:
      return {
        ...state,
        savedAllChangesProp: {
          ...state.savedAllChangesProp,
          [action.payload.id]: { ...action.payload.obj },
        },
      };
    case actions.DISCARD_SAVED_ALL_CHANGES:
      return {
        ...state,
        savedAllChangesProp: {},
      };
    case actions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD:
      return {
        ...state,
        savedAllChangesProp: {
          ...state.savedAllChangesProp,
          [action.payload.id]: {
            ...state.savedAllChangesProp[action.payload.id],
            [action.payload.field]: action.payload.fieldValue,
          },
        },
      };
    case recordsActions.DISCARD_NEW_RECORD_CHANGES:
      return {
        ...state,
        savedAllChangesProp: {
          ...state.savedAllChangesProp,
          [action.payload.id]: {
            ...state.savedAllChangesProp[action.payload.id],
            record: true,
          },
        },
      };
    case prescriptionsActions.DISCARD_PRESCRIPTION:
      return {
        ...state,
        savedAllChangesProp: {
          ...state.savedAllChangesProp,
          [action.payload.id]: {
            ...state.savedAllChangesProp[action.payload.id],
            prescription: true,
          },
        },
      };
    case certificateActions.DISCARD_NEW_CERTIFICATE_CHANGES:
      return {
        ...state,
        savedAllChangesProp: {
          ...state.savedAllChangesProp,
          [action.payload.id]: {
            ...state.savedAllChangesProp[action.payload.id],
            certificate: true,
            medicalReport: true,
            otherDocument: true,
          },
        },
      };
    case actions.SET_MAINTENANCE:
      return {
        ...state,
        maintenance: action.payload,
      };
    case actions.SET_SHOW_POPOVER_COMPONENT_TO_PRINT:
      return {
        ...state,
        alreadyShownPopoverComponentToPrint: action.payload.alreadyShownPopoverComponentToPrint,
      };
    case actions.SET_PREVENT_CLOSE_MODAL_VISIBLE:
      return {
        ...state,
        preventCloseModalVisible: action.payload.value,
      };
    case actions.FETCH_SYSTEM_ALERT_SUCCESS:
      return {
        ...state,
        alertNotice: action.payload,
      };
    case actions.UPDATE_SYSTEM_ALERT:
      return {
        ...state,
        updatingAlert: true,
      };
    case actions.UPDATE_SYSTEM_ALERT_ERROR:
      return {
        ...state,
        updatingAlert: false,
      };
    case actions.UPDATE_SYSTEM_ALERT_SUCCESS:
      return {
        ...state,
        updatingAlert: false,
      };
    case actions.CHANGE_FLOAT_BUTTON_VALUE:
      return {
        ...state,
        floatButtonValue: action.payload,
      };
    default:
      return state;
  }
  return state;
}

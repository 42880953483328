import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  validatingPin: false,
  interalPinError: false,
  wrongPin: false,
};

export default function pinReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.OPEN_CREATE_PIN_MODAL:
      return {
        ...state,
        insertingNewPin: true,
      };
    case actions.OPEN_REMOVE_PIN_MODAL:
      return {
        ...state,
        removingNewPin: true,
      };
    case actions.CLOSE_PIN_MODAL:
      return {
        ...state,
        insertingNewPin: false,
        removingNewPin: false,
        insertingPin: false,
      };
    case actions.OPEN_INSERT_PIN_MODAL:
      return {
        ...state,
        insertingPin: true,
      };
    case actions.VALIDATING_PIN:
      return {
        ...state,
        validatingPin: true,
      };
    case actions.VERIFY_PIN_WAITING:
      return {
        ...state,
        validatingPin: true,
      };
    case actions.CREATE_PIN_SUCCESS:
      return {
        ...state,
        insertingNewPin: false,
      };
    case actions.REMOVE_PIN_SUCCESS:
      return {
        ...state,
        removingNewPin: false,
      };
    case actions.REMOVE_PIN_ERROR:
      return {
        ...state,
        validatingPin: false,
        validatedPin: true,
        interalPinError: false,
        wrongPin: true,
        missingPin: false,
      };
    case actions.VERIFY_PIN_VALIDATED:
      return {
        ...state,
        validatingPin: false,
        validatedPin: true,
        interalPinError: false,
        wrongPin: false,
        missingPin: false,
        insertingPin: false,
      };
    case actions.VERIFY_PIN_INTERNAL_ERROR:
      return {
        ...state,
        validatingPin: false,
        validatedPin: false,
        interalPinError: true,
        wrongPin: false,
        missingPin: false,
      };
    case actions.VERIFY_PIN_INVALIDATED:
      return {
        ...state,
        validatingPin: false,
        validatedPin: false,
        interalPinError: false,
        wrongPin: true,
        missingPin: false,
      };
    case actions.PIN_IS_NECESSARY:
      return {
        ...state,
        validatingPin: false,
        validatedPin: false,
        interalPinError: false,
        wrongPin: false,
        missingPin: false,
      };
    case actions.VERIFY_NO_PIN_CREATED:
      return {
        ...state,
        validatingPin: false,
        validatedPin: true,
        interalPinError: false,
        wrongPin: false,
        missingPin: true,
      };
    default:
      return state;
  }
}

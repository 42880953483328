import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  specialties: {
    crm: {},
    cro: {},
    crn: {},
    crp: {},
    crfa: {},
    crbm: {},
    crefito: {},
    croo: {},
    crf: {},
  },
  searchByName: {
    professionals: null,
    searchText: '',
  },
  searchResults: {
    professionals: null,
    searchText: '',
  },
  searchFilters: {},
};

export default function searchesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SEARCH_BY_NAME_FETCH_SUCCESS:
      return {
        ...state,
        searchByName: {
          professionals: action.payload.data,
          searchText: action.payload.search,
        },
        searchingByName: false,
      };
    case actions.SEARCH_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        searchResults: {
          professionals: action.payload.data,
          searchText: action.payload.search,
        },
        searchingProfessionals: false,
      };
    case actions.SEARCH_BY_NAME_FETCH_ERROR:
      return {
        ...state,
        searchByName: {
          ...state.searchByName,
          professionals: null,
        },
        searchingByName: false,
      };
    case actions.SEARCH_PROFESSIONALS_ERROR:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          professionals: null,
        },
        searchingProfessionals: false,
      };
    case actions.SEARCHING_BY_NAME:
      return {
        ...state,
        searchByName: {
          ...state.searchByName,
          professionals: null,
        },
      };
    case actions.SEARCHING_PROFESSIONALS:
      return {
        ...state,
        // searchResults: {
        //   ...state.searchResults,
        //   professionals: null,
        // },
        searchingProfessionals: true,
      };
    // case actions.CLEAR_SEARCH_BY_NAME:
    //   return {
    //     ...state,
    //     searchByName: INITIAL_STATE.searchByName,
    //   };
    case actions.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchByName: {
          ...state.searchByName,
          professionals: [],
        },
        searchResults: {
          ...state.searchResults,
          professionals: [],
        },
      };
    case actions.GET_SEARCH_FILTERS_SUCCESS:
      return {
        ...state,
        searchFilters: action.payload,
      };
    default:
      return state;
  }
}

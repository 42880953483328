import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  avatarBase64: null,
  avatarFile: null,
  profile: {},
  profileOnDB: {},
  searching: false,
  historic: null,
  requestedValue: null,
  isLoadingHistoric: false,
  customHealthProfessionalProfile: {},
  customHealthProfessionalProfileOnDB: {},
};

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.PROFILE_INFO_SUCCESS:
      return {
        ...state,
        updating: false,
        avatarBase64: action.payload.avatarBase64,
        profile: _.cloneDeep(action.payload.profile),
        profileOnDB: _.cloneDeep(action.payload.profile),
        customUserProfile: _.cloneDeep(action.payload.customUserProfile),
        customHealthProfessionalProfile: _.cloneDeep(action.payload.customHealthProfessionalProfile),
        customHealthProfessionalProfileOnDB: _.cloneDeep(action.payload.customHealthProfessionalProfile),
        savingProfile: false,
      };
    case actions.SAVING_PROFILE:
      return {
        ...state,
        savingProfile: true,
      };
    case actions.PROFILE_INFO_ERROR:
      return {
        ...state,
        savingProfile: null,
      };
    case actions.WHATSAPP_PHONE_CHANGED:
      return {
        ...state,
        profile: {
          ...state.profile,
          mobile: action.payload,
        },
      };
    case actions.PATIENT_CPF_CHANGED:
      return {
        ...state,
        profile: {
          ...state.profile,
          cpf: action.payload,
        },
      };
    case actions.ADDED_OR_REMOVED_PATIENT_PHONE:
      return {
        ...state,
        profile: {
          ...state.profile,
          phones: action.payload,
        },
      };
    case actions.PATIENT_PROFILE_CHANGED:
      return {
        ...state,
        profile: action.payload,
      };
    // case actions.SAVE_BIRTHDAY:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       birthday: action.payload,
    //     },
    //   };
    // case actions.CHANGE_CPF:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       cpf: action.payload,
    //     },
    //   };
    // case actions.SAVE_GENDER:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       gender: action.payload,
    //     },
    //   };
    // case actions.DESCRIPTION_CHANGED:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       description: action.payload,
    //     },
    //   };
    // case actions.ADDRESSES_CHANGED:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       addresses: action.payload,
    //     },
    //   };
    // case actions.ADD_PHONE_INPUT:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       addresses: action.payload,
    //     },
    //   };
    // case actions.SAVE_PHONE_SUCCESS:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       addresses: action.payload,
    //     },
    //   };
    case actions.START_SEARCHING_ADDRESS:
      return {
        ...state,
        searching: action.payload,
      };
    case actions.END_SEARCHING_ADDRESS:
      return {
        ...state,
        searching: false,
      };
    // case actions.SAVE_ADDRESS_SUCCESS:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       addresses: action.payload,
    //     },
    //   };
    case actions.SPECIALTIES_FETCH_SUCCESS:
      return {
        ...state,
        specialties: action.payload,
        profileOnDB: {
          ...state.profileOnDB,
          specialties: action.payload,
        },
        profile: {
          ...state.profile,
          specialties: action.payload,
        },
      };
    // case actions.SAVE_SPECIALTY:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       council: {
    //         ...state.profile.council,
    //         specialties: action.payload,
    //       },
    //     },
    //   };
    // case actions.SAVE_PLAN:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       plans: action.payload,
    //     },
    //   };
    // case actions.COUNCIL_SWITCH:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       specialties: [],
    //       council: {
    //         ...state.profile.council,
    //         type: action.payload,
    //         value: '',
    //         specialties: [],
    //       },
    //     },
    //   };
    // case actions.COUNCIL_REGION_SWITCH:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       council: {
    //         ...state.profile.council,
    //         region: action.payload,
    //       },
    //     },
    //   };
    // case actions.COUNCIL_CHANGED:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       council: {
    //         ...state.profile.council,
    //         value: action.payload,
    //       },
    //     },
    //   };
    // case actions.COUNCIL_RQE_CHANGED:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       council: {
    //         ...state.profile.council,
    //         rqe: action.payload,
    //       },
    //     },
    //   };
    case actions.DOWNLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          avatar: action.payload,
        },
      };
    case actions.SAVE_AVATAR:
      return {
        ...state,
        avatarFile: action.payload,
      };
    case actions.SAVE_AVATAR_AS_BASE64:
      return {
        ...state,
        avatarBase64: action.payload,
      };
    case actions.SAVE_CUSTOM_PROFESSIONAL_AVATAR:
      return {
        ...state,
        avatarFile: action.payload,
      };
    case actions.SAVE_CUSTOM_PROFESSIONAL_AVATAR_AS_BASE64:
      return {
        ...state,
        avatarBase64: action.payload,
      };
    case actions.AVATAR_ERROR:
      return {
        ...state,
        avatar: null,
        avatarOnDB: null,
      };
    // case actions.DISCARD_PROFILE_CHANGES:
    //   return {
    //     ...state,
    //     profile: _.cloneDeep(state.profileOnDB),
    //     avatarBase64: _.cloneDeep(state.profile.avatar),
    //     avatarFile: null,
    //   };
    case actions.DISCARD_AVATAR_CHANGES:
      return {
        ...state,
        avatarBase64: _.cloneDeep(action.payload),
        avatarFile: null,
      };
    case actions.SAVE_PROFILE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case actions.PLANS_FETCH_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    case actions.SAVE_PROFILE_ERROR:
      return {
        ...state,
        updating: null,
      };
    case actions.GET_CUSTOM_PLANS_SUCCESS:
      return {
        ...state,
        profileOnDB: {
          ...state.profileOnDB,
          plans: [...state.profileOnDB.plans, ...action.payload.stringArr],
          customPlans: [...action.payload.objArr],
        },
        profile: {
          ...state.profile,
          plans: [...state.profile.plans, ...action.payload.stringArr],
          customPlans: [...action.payload.objArr],
        },
        updatingCustomPlan: false,
      };
    case actions.CREATING_CUSTOM_PLAN:
      return {
        ...state,
        updatingCustomPlan: true,
      };
    case actions.CREATE_CUSTOM_PLAN_ERROR:
      return {
        ...state,
        updatingCustomPlan: null,
      };
    default:
      return state;
  }
}

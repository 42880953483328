import actions from './actions';

export default function contactReducer(state = {}, action) {
  switch (action.type) {
    case actions.CID_FETCH_SUCCESS:
      return {
        ...state,
        cid: action.payload,
      };
    case actions.CID_FETCH_ERROR:
      return {
        cid: [],
      };
    default:
      return state;
  }
}

const actions = {
  CHANGE_NEW_RECORD_TAB: 'CHANGE_NEW_RECORD_TAB',
  TOGGLE_NEW_ENTRANCE_DRAWER: 'TOGGLE_NEW_ENTRANCE_DRAWER',
  TOGGLE_NEW_ENTRANCE_GRAPH: 'TOGGLE_NEW_ENTRANCE_GRAPH',
  changeNewEntranceTab: (activeTab) => ({
    type: actions.CHANGE_NEW_RECORD_TAB,
    payload: activeTab,
  }),
  toggleNewEntranceDrawer: (mode) => ({
    type: actions.TOGGLE_NEW_ENTRANCE_DRAWER,
    payload: mode,
  }),
  toggleNewEntranceDrawerGraph: () => ({
    type: actions.TOGGLE_NEW_ENTRANCE_GRAPH,
  }),
};
export default actions;

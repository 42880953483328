import actions from './actions';
import authActions from '../auth/actions';
import agendaActions from '../agenda/actions';

const initState = {
  view: 'week',
  modalVisible: false,
  selectedData: null,
};

export default function calendarReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.CALENDAR_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case actions.SET_CALENDAR_MODAL_DATA:
      return {
        ...state,
        modalVisible: action.payload.modalVisible,
        // selectedData: action.payload.selectedData,
      };
    case actions.CLEAR_CALENDAR_SELECTED_DATA:
      return {
        ...state,
        selectedData: null,
      };
    // case actions.CALENDAR_NAVIGATE:
    //   return {
    //     ...state,
    //     selectedData: action.date,
    //   };
    case actions.CALENDAR_EVENT_SELECTED:
      return {
        ...state,
        modalVisible: action.payload.modalVisible,
        selectedData: action.payload.selectedData,
      };
    case actions.CALENDAR_SLOT_SELECTED:
      return {
        ...state,
        modalVisible: action.payload.modalVisible,
        selectedData: action.payload.selectedData,
      };
    case actions.SET_CALENDAR_DRAWER_DATA:
      return {
        ...state,
        drawerVisible: action.payload.drawerVisible,
      };
    case actions.SET_CANCELED_VISIBILITY_SUCCESS:
      return {
        ...state,
        canceledVisible: action.value,
      };
    case actions.SET_UNUSED_DAYS_VISIBILITY_SUCCESS:
      return {
        ...state,
        unusedDaysVisible: action.value,
      };
    case actions.SCROLL_TO_TIME:
      return {
        ...state,
        scrollTime: action.payload,
      };
    case actions.CLEAR_SCROLL_TO_TIME:
      return {
        ...state,
        scrollTime: null,
      };
    case agendaActions.MANUALLY_SET_NEW_APPOINTMENT_SUCCESS:
      return {
        ...state,
        modalVisible: null,
      };
    default:
      return state;
  }
}

const recordsActions = {
  GET_PATIENT_RECORDS_REQUEST: 'GET_PATIENT_RECORDS_REQUEST',
  PATIENT_RECORDS_FETCH_SUCCESS: 'PATIENT_RECORDS_FETCH_SUCCESS',
  GET_RECORDS_MASK_REQUEST: 'GET_RECORDS_MASK_REQUEST',
  GET_RECORDS_MASK_SUCCESS: 'GET_RECORDS_MASK_SUCCESS',
  FETCHING_RECORD_MASK: 'FETCHING_RECORD_MASK',
  SAVE_RECORD_REQUEST: 'SAVE_RECORD_REQUEST',
  SAVE_RECORD_ERROR: 'SAVE_RECORD_ERROR',
  RECORDS_SECTION_START_UPDATE: 'RECORDS_SECTION_START_UPDATE',
  RECORD_MASK_START_UPDATE: 'RECORD_MASK_START_UPDATE',
  CHANGE_RECORD: 'CHANGE_RECORD',
  DISCARD_NEW_RECORD_CHANGES: 'DISCARD_NEW_RECORD_CHANGES',
  DISCARD_NEW_RECORD_CHANGES_AFTER_SAVING: 'DISCARD_NEW_RECORD_CHANGES_AFTER_SAVING',
  CHANGE_RECORD_MASK: 'CHANGE_RECORD_MASK',
  DISCARD_RECORD_MASK_CHANGES: 'DISCARD_RECORD_MASK_CHANGES',
  SAVE_RECORD_MASK_REQUEST: 'SAVE_RECORD_MASK_REQUEST',
  CREATE_GRAPH_REQUEST: 'CREATE_GRAPH_REQUEST',
  CREATE_GRAPH_SUCCESS: 'CREATE_GRAPH_SUCCESS',
  CREATE_GRAPH_ERROR: 'CREATE_GRAPH_ERROR',
  CREATE_GRAPH_WAITING: 'CREATE_GRAPH_WAITING',
  GET_GRAPH_REQUEST: 'GET_GRAPH_REQUEST',
  GET_GRAPH_SUCCESS: 'GET_GRAPH_SUCCESS',
  GET_GRAPH_ERROR: 'GET_GRAPH_ERROR',
  GET_GRAPH_UPDATE_SUCCESS: 'GET_GRAPH_UPDATE_SUCCESS',
  UPDATE_PEDIATRIC_PLOT_POINT: 'UPDATE_PEDIATRIC_PLOT_POINT',
  UPDATE_PEDIATRIC_PLOT_POINT_SUCCESS: 'UPDATE_PEDIATRIC_PLOT_POINT_SUCCESS',
  UPDATE_PEDIATRIC_PLOT_POINT_ERROR: 'UPDATE_PEDIATRIC_PLOT_POINT_ERROR',
  TOGGLE_PEDIATRIC_MODAL_GRAPH: 'TOGGLE_PEDIATRIC_MODAL_GRAPH',
  TOGGLE_NEW_POINT_PEDIATRIC_MODAL_GRAPH: 'TOGGLE_NEW_POINT_PEDIATRIC_MODAL_GRAPH',
  CHANGE_RECORDS_PAGE_MODE: 'CHANGE_RECORDS_PAGE_MODE',
  // STORE_CUSTOM_RECORD_VALUE: 'STORE_CUSTOM_RECORD',
  RESET_CUSTOM_RECORD_MASK: 'RESET_CUSTOM_RECORD_MASK',
  // DISCARD_CUSTOM_RECORD_VALUE: 'DISCARD_CUSTOM_RECORD_VALUE',
  RECORD_EDITOR_MODELS_FETCH_REQUEST: 'RECORD_EDITOR_MODELS_FETCH_REQUEST',
  RECORD_EDITOR_MODELS_FETCH_SUCCESS: 'RECORD_EDITOR_MODELS_FETCH_SUCCESS',
  RECORD_EDITOR_MODELS_FETCH_ERROR: 'RECORD_EDITOR_MODELS_FETCH_ERROR',
  RECORD_EDITOR_SECTION_START_UPDATE: 'RECORD_EDITOR_SECTION_START_UPDATE',
  SAVE_RECORD_EDITOR_MODEL_REQUEST: 'SAVE_RECORD_EDITOR_MODEL_REQUEST',
  SAVE_RECORD_EDITOR_MODEL_ERROR: 'SAVE_RECORD_EDITOR_MODEL_ERROR',
  UPDATE_RECORD_EDITOR_MODEL_REQUEST: 'UPDATE_RECORD_EDITOR_MODEL_REQUEST',
  START_SAVING_RECORD_EDITOR_MODEL: 'START_SAVING_RECORD_EDITOR_MODEL',
  REMOVE_RECORD_EDITOR_MODEL_REQUEST: 'REMOVE_RECORD_EDITOR_MODEL_REQUEST',
  REMOVE_RECORD_EDITOR_MODEL_ERROR: 'REMOVE_RECORD_EDITOR_MODEL_ERROR',
  START_REMOVING_RECORD_EDITOR_MODEL: 'START_REMOVING_RECORD_EDITOR_MODEL',
  GET_RECORD_FORM_REQUEST: 'GET_RECORD_FORM_REQUEST',
  GET_RECORD_FORM_SUCCESS: 'GET_RECORD_FORM_SUCCESS',
  GET_RECORD_FORM_ERROR: 'GET_RECORD_FORM_ERROR',
  FETCHING_RECORD_FORM: 'FETCHING_RECORD_FORM',
  SAVE_RECORD_FORM_REQUEST: 'SAVE_RECORD_FORM_REQUEST',
  SAVE_RECORD_FORM_SUCCESS: 'SAVE_RECORD_FORM_SUCCESS',
  SAVE_RECORD_FORM_ERROR: 'SAVE_RECORD_FORM_ERROR',
  UPDATING_RECORD_FORM: 'UPDATING_RECORD_FORM',
  DISCARD_RECORD_FORM_REQUEST: 'DISCARD_RECORD_FORM_REQUEST',
  DISCARDING_RECORD_FORM: 'DISCARDING_RECORD_FORM',
  DISCARDING_RECORD_FORM_SUCCESS: 'DISCARDING_RECORD_FORM_SUCCESS',
  // GET_PATIENT_RECORD_FORM_REQUEST: 'GET_PATIENT_RECORD_FORM_REQUEST',
  // GET_PATIENT_RECORD_FORM_SUCCESS: 'GET_PATIENT_RECORD_FORM_SUCCESS',
  // GET_PATIENT_RECORD_FORM_ERROR: 'GET_PATIENT_RECORD_FORM_ERROR',
  SAVE_PATIENT_RECORD_FORM_REQUEST: 'SAVE_PATIENT_RECORD_FORM_REQUEST',
  SAVE_PATIENT_RECORD_FORM_SUCCESS: 'SAVE_PATIENT_RECORD_FORM_SUCCESS',
  SAVE_PATIENT_RECORD_FORM_ERROR: 'SAVE_PATIENT_RECORD_FORM_ERROR',
  REFETCH_PATIENT_RECORD_FORM: 'REFETCH_PATIENT_RECORD_FORM',
  SAVING_PATIENT_RECORD_FORM: 'SAVING_PATIENT_RECORD_FORM',
  CHANGE_COLLAPSE_RECORD_REQUEST: 'CHANGE_COLLAPSE_RECORD_REQUEST',
  CHANGE_COLLAPSE_RECORD_SUCCESS: 'CHANGE_COLLAPSE_RECORD_SUCCESS',
  CHANGE_COLLAPSE_RECORD_ERROR: 'CHANGE_COLLAPSE_RECORD_ERROR',
  CHANGE_HIDE_RECORD_REQUEST: 'CHANGE_HIDE_RECORD_REQUEST',
  CHANGE_HIDE_RECORD_SUCCESS: 'CHANGE_HIDE_RECORD_SUCCESS',
  CHANGE_HIDE_RECORD_ERROR: 'CHANGE_HIDE_RECORD_ERROR',
  CHANGING_HIDE_RECORD: 'CHANGING_HIDE_RECORD',
  GET_SHOW_HIDDEN_RECORDS_STATUS: 'GET_SHOW_HIDDEN_RECORDS_STATUS',
  GET_SHOW_HIDDEN_RECORDS_STATUS_SUCCESS: 'GET_SHOW_HIDDEN_RECORDS_STATUS_SUCCESS',
  SET_SHOW_HIDDEN_RECORDS: 'SET_SHOW_HIDDEN_RECORDS',
  SET_SHOW_HIDDEN_RECORDS_SUCCESS: 'SET_SHOW_HIDDEN_RECORDS_SUCCESS',
  GET_ALL_RECORDS_REQUEST: 'GET_ALL_RECORDS_REQUEST',
  GET_ALL_RECORDS_SUCCESS: 'GET_ALL_RECORDS_SUCCESS',
  GET_ALL_RECORDS_ERROR: 'GET_ALL_RECORDS_ERROR',
  FETCHING_ALL_RECORDS: 'FETCHING_ALL_RECORDS',
  SET_NEW_RECORD: 'SET_NEW_RECORD',
  GET_DISCARDED_RECORDS_REQUEST: 'GET_DISCARDED_RECORDS_REQUEST',
  GET_DISCARDED_RECORDS_SUCCESS: 'GET_DISCARDED_RECORDS_SUCCESS',
  GET_PATIENT_FILES_REQUEST: 'GET_PATIENT_FILES_REQUEST',
  GET_PATIENT_FILES_REQUEST_SUCCESS: 'GET_PATIENT_FILES_REQUEST_SUCCESS',
  GET_PATIENT_FILES_REQUEST_ERROR: 'GET_PATIENT_FILES_REQUEST_ERROR',
  FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_REQUEST: 'FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_REQUEST',
  FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_SUCCESS: 'FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_SUCCESS',
  FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_ERROR: 'FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_ERROR',
  FETCHING_LAST_RECORD_FOR_SPECIFIC_FIELD: 'FETCHING_LAST_RECORD_FOR_SPECIFIC_FIELD',
  getGraphs: (userId) => ({
    payload: userId,
    type: recordsActions.GET_GRAPH_REQUEST,
  }),
  updatePediatricPlot: (seectedId, key, value) => ({
    type: recordsActions.UPDATE_PEDIATRIC_PLOT_POINT,
    payload: {
      seectedId,
      key,
      value,
    },
  }),
  resetCustomRecord: () => ({
    type: recordsActions.RESET_CUSTOM_RECORD_MASK,
  }),
  togglePediatricGraph: (value) => ({
    type: recordsActions.TOGGLE_PEDIATRIC_MODAL_GRAPH,
    payload: value,
  }),
  toggleNewPointPediatricGraph: (value) => ({
    type: recordsActions.TOGGLE_NEW_POINT_PEDIATRIC_MODAL_GRAPH,
    payload: value,
  }),
  changeRecordsPageMode: (value) => ({
    type: recordsActions.CHANGE_RECORDS_PAGE_MODE,
    payload: value,
  }),
  getRecords: (paginate = false) => ({
    type: recordsActions.GET_PATIENT_RECORDS_REQUEST,
    payload: {
      paginate,
    },
  }),
  getFiles: () => ({
    type: recordsActions.GET_PATIENT_FILES_REQUEST,
  }),
  getRecordsMask: () => ({
    type: recordsActions.GET_RECORDS_MASK_REQUEST,
  }),
  // saveRecord: (text, files) => ({
  //   type: recordsActions.SAVE_RECORD_REQUEST,
  //   payload: {
  //     text,
  //     files,
  //   },
  // }),
  saveRecord: () => ({
    type: recordsActions.SAVE_RECORD_REQUEST,
  }),
  // changeRecord: (text, files, id) => ({
  //   type: recordsActions.CHANGE_RECORD,
  //   payload: {
  //     records: {
  //       text,
  //       files,
  //     },
  //     id,
  //   },
  // }),
  changeRecord: (mode, values, id) => ({
    type: recordsActions.CHANGE_RECORD,
    payload: {
      mode,
      values,
      id,
    },
  }),
  discardNewRecordChanges: (id) => ({
    type: recordsActions.DISCARD_NEW_RECORD_CHANGES,
    payload: {
      id,
    },
  }),
  saveRecordMask: (text, list = false) => ({
    type: recordsActions.SAVE_RECORD_MASK_REQUEST,
    payload: {
      text,
      list,
    },
  }),
  changeRecordMask: (text) => ({
    type: recordsActions.CHANGE_RECORD_MASK,
    payload: {
      text,
    },
  }),
  discardRecordMaskChanges: () => ({
    type: recordsActions.DISCARD_RECORD_MASK_CHANGES,
  }),
  getRecordEditorModels: () => ({
    type: recordsActions.RECORD_EDITOR_MODELS_FETCH_REQUEST,
  }),
  saveRecordEditorModel: (model, textFieldId) => ({
    type: recordsActions.SAVE_RECORD_EDITOR_MODEL_REQUEST,
    payload: {
      model,
      textFieldId,
    },
  }),
  removeRecordEditorModel: (id) => ({
    type: recordsActions.REMOVE_RECORD_EDITOR_MODEL_REQUEST,
    payload: {
      id,
    },
  }),
  updateRecordEditorModel: (model, id) => ({
    type: recordsActions.UPDATE_RECORD_EDITOR_MODEL_REQUEST,
    payload: {
      model,
      id,
    },
  }),
  getRecordForm: () => ({
    type: recordsActions.GET_RECORD_FORM_REQUEST,
  }),
  saveRecordForm: (list) => ({
    type: recordsActions.SAVE_RECORD_FORM_REQUEST,
    payload: list,
  }),
  discardRecordFormChanges: () => ({
    type: recordsActions.DISCARD_RECORD_FORM_REQUEST,
  }),
  // getPatientRecordForm: () => ({
  //   type: recordsActions.GET_PATIENT_RECORD_FORM_REQUEST,
  // }),
  savePatientRecordForm: (list) => ({
    type: recordsActions.SAVE_PATIENT_RECORD_FORM_REQUEST,
    payload: list,
  }),
  changeCollapseRecord: (id, value) => ({
    type: recordsActions.CHANGE_COLLAPSE_RECORD_REQUEST,
    payload: {
      id,
      value,
    },
  }),
  changeHideRecord: (item) => ({
    type: recordsActions.CHANGE_HIDE_RECORD_REQUEST,
    payload: item,
  }),
  getShowHiddenRecords: () => ({
    type: recordsActions.GET_SHOW_HIDDEN_RECORDS_STATUS,
  }),
  toggleShowHiddenRecords: (value) => ({
    type: recordsActions.SET_SHOW_HIDDEN_RECORDS,
    value,
  }),
  getAllRecords: () => ({
    type: recordsActions.GET_ALL_RECORDS_REQUEST,
  }),
  getDiscardedRecords: () => ({
    type: recordsActions.GET_DISCARDED_RECORDS_REQUEST,
  }),
  fetchLastRecordForSpecificField: (field) => ({
    type: recordsActions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_REQUEST,
    payload: {
      field,
    },
  }),
};
export default recordsActions;

const actions = {
  FETCH_MEMED_SETTINGS_REQUEST: 'FETCH_MEMED_SETTINGS_REQUEST',
  FETCH_MEMED_SETTINGS_SUCCESS: 'FETCH_MEMED_SETTINGS_SUCCESS',
  FETCH_MEMED_SETTINGS_ERROR: 'FETCH_MEMED_SETTINGS_ERROR',
  CHANGE_USE_MEMED_REQUEST: 'CHANGE_USE_MEMED_REQUEST',
  CHANGE_USE_MEMED_ERROR: 'CHANGE_USE_MEMED_ERROR',
  UPDATING_USE_MEMED: 'UPDATING_USE_MEMED',
  REGISTER_MEMED_USER_REQUEST: 'REGISTER_MEMED_USER_REQUEST',
  REGISTER_MEMED_USER_SUCCESS: 'REGISTER_MEMED_USER_SUCCESS',
  REGISTER_MEMED_USER_ERROR: 'REGISTER_MEMED_USER_ERROR',
  FETCHING_MEMED_USER: 'FETCHING_MEMED_USER',
  UPDATE_MEMED_USER_REQUEST: 'UPDATE_MEMED_USER_REQUEST',
  UPDATE_MEMED_USER_SUCCESS: 'UPDATE_MEMED_USER_SUCCESS',
  UPDATE_MEMED_USER_ERROR: 'UPDATE_MEMED_USER_ERROR',
  GET_MEMED_USER_REQUEST: 'GET_MEMED_USER_REQUEST',
  SET_MEMED_USER_TOKEN: 'SET_MEMED_USER_TOKEN',
  ERROR_UPDATING_MEMED_USER: 'ERROR_UPDATING_MEMED_USER',
  CLEAR_MEMED_USER_ERROR: 'CLEAR_MEMED_USER_ERROR',
  INIT_MEMED_REQUEST: 'INIT_MEMED_REQUEST',
  INIT_MEMED_EVENTS_REQUEST: 'INIT_MEMED_EVENTS_REQUEST',
  MEMED_INITIALIZED: 'MEMED_INITIALIZED',
  SET_OPEN_MEMED: 'SET_OPEN_MEMED',
  SAVE_MEMED_PRESCRIPTION_REQUEST: 'SAVE_MEMED_PRESCRIPTION_REQUEST',
  SAVE_MEMED_PRESCRIPTION_SUCCESS: 'SAVE_MEMED_PRESCRIPTION_SUCCESS',
  SAVE_MEMED_PRESCRIPTION_ERROR: 'SAVE_MEMED_PRESCRIPTION_ERROR',
  // MEMED_PRESCRIPTION_SECTION_START_UPDATE: 'MEMED_PRESCRIPTION_SECTION_START_UPDATE',
  PRINT_MEMED_PRESCRIPTION: 'PRINT_MEMED_PRESCRIPTION',
  GET_MEMED_PRESCRIPTION_PDF_REQUEST: 'GET_MEMED_PRESCRIPTION_PDF_REQUEST',
  GET_MEMED_PRESCRIPTION_PDF_SUCCESS: 'GET_MEMED_PRESCRIPTION_PDF_SUCCESS',
  GET_MEMED_PRESCRIPTION_PDF_ERROR: 'GET_MEMED_PRESCRIPTION_PDF_ERROR',
  DOWNLOADING_MEMED_PRESCRIPTION_PDF: 'DOWNLOADING_MEMED_PRESCRIPTION_PDF',
  REMOVE_MEMED_PRESCRIPTION_REQUEST: 'REMOVE_MEMED_PRESCRIPTION_REQUEST',
  REMOVE_MEMED_PRESCRIPTION_SUCCESS: 'REMOVE_MEMED_PRESCRIPTION_SUCCESS',
  REMOVE_MEMED_PRESCRIPTION_ERROR: 'REMOVE_MEMED_PRESCRIPTION_ERROR',
  SELECT_THIRD_PARTY_MEMED_USER: 'SELECT_THIRD_PARTY_MEMED_USER',
  changeUseMemed: (value, id) => ({
    type: actions.CHANGE_USE_MEMED_REQUEST,
    payload: {
      value,
      id,
    },
  }),
  registerMemedUser: () => ({
    type: actions.REGISTER_MEMED_USER_REQUEST,
  }),
  getMemedUser: (id = null) => ({
    type: actions.GET_MEMED_USER_REQUEST,
    payload: {
      id,
    },
  }),
  initMemed: () => ({
    type: actions.INIT_MEMED_REQUEST,
  }),
  initEventsMemed: () => ({
    type: actions.INIT_MEMED_EVENTS_REQUEST,
  }),
  setMemedIntialized: (value = true) => ({
    type: actions.MEMED_INITIALIZED,
    payload: value,
  }),
  setOpenMemed: (value) => ({
    type: actions.SET_OPEN_MEMED,
    payload: value,
  }),
  saveMemedPrescription: (values) => ({
    type: actions.SAVE_MEMED_PRESCRIPTION_REQUEST,
    payload: {
      values,
      // patientId,
    },
  }),
  printMemedPrescription: (memedId) => ({
    type: actions.PRINT_MEMED_PRESCRIPTION,
    payload: memedId,
  }),
  getMemedPrescriptionPdf: (memedId, id) => ({
    type: actions.GET_MEMED_PRESCRIPTION_PDF_REQUEST,
    payload: {
      memedId,
      id,
    },
  }),
  removeMemedPrescription: (memedId) => ({
    type: actions.REMOVE_MEMED_PRESCRIPTION_REQUEST,
    payload: memedId,
  }),
  clearMemedUserError: () => ({
    type: actions.CLEAR_MEMED_USER_ERROR,
  }),
  selectThirdPartyMemedUser: (id) => ({
    type: actions.SELECT_THIRD_PARTY_MEMED_USER,
    payload: {
      id,
    },
  }),
  setMemedUserToken: (token, user = null) => ({
    type: actions.SET_MEMED_USER_TOKEN,
    payload: {
      token,
      user,
    },
  }),
};
export default actions;

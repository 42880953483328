const actions = {
  PROFILE_INFO_REQUEST: 'PROFILE_INFO_REQUEST',
  PROFILE_INFO_SUCCESS: 'PROFILE_INFO_SUCCESS',
  PROFILE_INFO_ERROR: 'PROFILE_INFO_ERROR',
  SAVING_PROFILE: 'SAVING_PROFILE',
  // DESCRIPTION_CHANGED: 'DESCRIPTION_CHANGED',
  // SAVE_BIRTHDAY: 'SAVE_BIRTHDAY',
  // CHANGE_CPF: 'CHANGE_CPF',
  // SAVE_GENDER: 'SAVE_GENDER',
  // ADDRESSES_CHANGED: 'ADDRESSES_CHANGED',
  // PHONE_CHANGED: 'PHONE_CHANGED',
  // PHONE_MODE_CHANGED: 'PHONE_MODE_CHANGED',
  // ADD_PHONE_INPUT: 'ADD_PHONE_INPUT',
  // SAVE_PHONE_SUCCESS: 'SAVE_PHONE_SUCCESS',
  START_SEARCHING_ADDRESS: 'START_SEARCHING_ADDRESS',
  END_SEARCHING_ADDRESS: 'END_SEARCHING_ADDRESS',
  // SAVE_ADDRESS_REQUEST: 'SAVE_ADDRESS_REQUEST',
  // SAVE_ADDRESS_SUCCESS: 'SAVE_ADDRESS_SUCCESS',
  // SAVE_ADDRESS_ERROR: 'SAVE_ADDRESS_ERROR',
  SPECIALTIES_REQUEST: 'SPECIALTIES_REQUEST',
  SPECIALTIES_FETCH_SUCCESS: 'SPECIALTIES_FETCH_SUCCESS',
  SPECIALTIES_ERROR: 'SPECIALTIES_ERROR',
  // SAVE_SPECIALTY: 'SAVE_SPECIALTY',
  // COUNCIL_SWITCH: 'COUNCIL_SWITCH',
  // COUNCIL_REGION_SWITCH: 'COUNCIL_REGION_SWITCH',
  // COUNCIL_CHANGED: 'COUNCIL_CHANGED',
  // COUNCIL_RQE_CHANGED: 'COUNCIL_RQE_CHANGED',
  // SAVE_PLAN: 'SAVE_PLAN',
  DOWNLOAD_AVATAR_REQUEST: 'DOWNLOAD_AVATAR_REQUEST',
  DOWNLOAD_AVATAR_SUCCESS: 'DOWNLOAD_AVATAR_SUCCESS',
  AVATAR_ERROR: 'AVATAR_ERROR',
  SAVE_AVATAR: 'SAVE_AVATAR',
  SAVE_CUSTOM_PROFESSIONAL_AVATAR: 'SAVE_CUSTOM_PROFESSIONAL_AVATAR',
  UPLOAD_AVATAR_SUCCESS: 'UPLOAD_AVATAR_SUCCESS',
  SAVE_AVATAR_AS_BASE64: 'SAVE_AVATAR_AS_BASE64',
  SAVE_CUSTOM_PROFESSIONAL_AVATAR_AS_BASE64: 'SAVE_CUSTOM_PROFESSIONAL_AVATAR_AS_BASE64',
  IGNORE_AVATAR_UPLOAD: 'IGNORE_AVATAR_UPLOAD',
  // DISCARD_PROFILE_CHANGES: 'DISCARD_PROFILE_CHANGES',
  DISCARD_AVATAR_CHANGES: 'DISCARD_AVATAR_CHANGES',
  SAVE_PROFILE_REQUEST: 'SAVE_PROFILE_REQUEST',
  SAVE_PROFILE_SUCCESS: 'SAVE_PROFILE_SUCCESS',
  SAVE_PROFILE_ERROR: 'SAVE_PROFILE_ERROR',
  WHATSAPP_PHONE_CHANGED: 'WHATSAPP_PHONE_CHANGED',
  PATIENT_CPF_CHANGED: 'PATIENT_CPF_CHANGED',
  ADDED_OR_REMOVED_PATIENT_PHONE: 'ADDED_OR_REMOVED_PATIENT_PHONE',
  PATIENT_PHONE_CHANGED: 'PATIENT_PHONE_CHANGED',
  PATIENT_PHONE_MODE_CHANGED: 'PATIENT_PHONE_MODE_CHANGED',
  PATIENT_PROFILE_CHANGED: 'PATIENT_PROFILE_CHANGED',
  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR',
  PLANS_FETCH_REQUEST: 'PLANS_FETCH_REQUEST',
  PLANS_FETCH_SUCCESS: 'PLANS_FETCH_SUCCESS',
  PLANS_FETCH_ERROR: 'PLANS_FETCH_ERROR',
  GET_CUSTOM_PLANS_REQUEST: 'GET_CUSTOM_PLANS_REQUEST',
  GET_CUSTOM_PLANS_SUCCESS: 'GET_CUSTOM_PLANS_SUCCESS',
  GET_CUSTOM_PLANS_ERROR: 'GET_CUSTOM_PLANS_ERROR',
  NO_CUSTOM_PLANS_SAVED: 'NO_CUSTOM_PLANS_SAVED',
  CREATE_CUSTOM_PLAN_REQUEST: 'CREATE_CUSTOM_PLAN_REQUEST',
  CREATE_CUSTOM_PLAN_SUCCESS: 'CREATE_CUSTOM_PLAN_SUCCESS',
  CREATE_CUSTOM_PLAN_ERROR: 'CREATE_CUSTOM_PLAN_ERROR',
  CREATING_CUSTOM_PLAN: 'CREATING_CUSTOM_PLAN',
  profileInfoFetch: () => ({
    type: actions.PROFILE_INFO_REQUEST,
  }),
  // changeDescription: (value) => ({
  //   type: actions.DESCRIPTION_CHANGED,
  //   payload: value,
  // }),
  // saveBirthday: (date) => ({
  //   type: actions.SAVE_BIRTHDAY,
  //   payload: date,
  // }),
  // editProfessionalCpf: (cpf) => ({
  //   type: actions.CHANGE_CPF,
  //   payload: cpf,
  // }),
  // changeAddresses: (addresses) => ({
  //   type: actions.ADDRESSES_CHANGED,
  //   payload: addresses,
  // }),
  // saveGender: (gender) => ({
  //   type: actions.SAVE_GENDER,
  //   payload: gender,
  // }),
  sendEmail: (date) => ({
    type: actions.SEND_EMAIL_REQUEST,
    payload: date,
  }),
  changePatientWhatssApp: (phone) => ({
    type: actions.WHATSAPP_PHONE_CHANGED,
    payload: phone,
  }),
  changePatientCpf: (value) => ({
    type: actions.PATIENT_CPF_CHANGED,
    payload: value,
  }),
  addPatientPhone: (phone) => ({
    type: actions.ADDED_OR_REMOVED_PATIENT_PHONE,
    payload: phone,
  }),
  removePatientPhone: (phone) => ({
    type: actions.ADDED_OR_REMOVED_PATIENT_PHONE,
    payload: phone,
  }),
  changePatientPhones: (phone, phoneIndex) => ({
    type: actions.PATIENT_PHONE_CHANGED,
    payload: {
      phone,
      phoneIndex,
    },
  }),
  changePatientPhoneMode: (mode, phoneIndex) => ({
    type: actions.PATIENT_PHONE_MODE_CHANGED,
    payload: {
      mode,
      phoneIndex,
    },
  }),
  // changePhones: (phone, addressIndex, phoneIndex) => ({
  //   type: actions.PHONE_CHANGED,
  //   payload: {
  //     phone,
  //     addressIndex,
  //     phoneIndex,
  //   },
  // }),
  // changePhoneMode: (mode, addressIndex, phoneIndex) => ({
  //   type: actions.PHONE_MODE_CHANGED,
  //   payload: {
  //     mode,
  //     addressIndex,
  //     phoneIndex,
  //   },
  // }),
  startSearchingAddress: (index) => ({
    type: actions.START_SEARCHING_ADDRESS,
    payload: index,
  }),
  endSearchingAddress: () => ({
    type: actions.END_SEARCHING_ADDRESS,
  }),
  // saveAddress: (addressObj, index) => ({
  //   type: actions.SAVE_ADDRESS_REQUEST,
  //   payload: {
  //     addressObj,
  //     index,
  //   },
  // }),
  specialtiesFetch: () => ({
    type: actions.SPECIALTIES_REQUEST,
  }),
  // saveSpecialty: (value) => ({
  //   type: actions.SAVE_SPECIALTY,
  //   payload: value,
  // }),
  // savePlan: (plans) => ({
  //   type: actions.SAVE_PLAN,
  //   payload: plans,
  // }),
  // councilTypeChange: (value) => ({
  //   type: actions.COUNCIL_SWITCH,
  //   payload: value,
  // }),
  // councilRegionChange: (value) => ({
  //   type: actions.COUNCIL_REGION_SWITCH,
  //   payload: value,
  // }),
  // councilChanged: (value) => ({
  //   type: actions.COUNCIL_CHANGED,
  //   payload: value,
  // }),
  // councilRqeChanged: (value) => ({
  //   type: actions.COUNCIL_RQE_CHANGED,
  //   payload: value,
  // }),
  avatarFetch: () => ({
    type: actions.DOWNLOAD_AVATAR_REQUEST,
  }),
  saveAvatar: (file) => ({
    type: actions.SAVE_AVATAR,
    payload: file,
  }),
  saveAvatarAsFile: (file) => ({
    type: actions.SAVE_AVATAR_AS_BASE64,
    payload: file,
  }),
  saveCustomProfessionalAvatar: (file) => ({
    type: actions.SAVE_CUSTOM_PROFESSIONAL_AVATAR,
    payload: file,
  }),
  saveCustomProfessionalAvatarAsFile: (file) => ({
    type: actions.SAVE_CUSTOM_PROFESSIONAL_AVATAR_AS_BASE64,
    payload: file,
  }),
  // discardProfileChanges: () => ({
  //   type: actions.DISCARD_PROFILE_CHANGES,
  // }),
  discardAvatarChanges: (avatar) => ({
    type: actions.DISCARD_AVATAR_CHANGES,
    payload: avatar,
  }),
  saveProfile: (profile, isCustomHealthProfessional = false) => ({
    type: actions.SAVE_PROFILE_REQUEST,
    payload: {
      profile,
      isCustomHealthProfessional,
    },
  }),
  savePatientProfile: () => ({
    type: actions.SAVE_PROFILE_REQUEST,
    payload: {
      patient: true,
    },
  }),
  getPlans: () => ({
    type: actions.PLANS_FETCH_REQUEST,
  }),
  // createCustomPlan: (value) => ({
  //   type: actions.CREATE_CUSTOM_PLAN_REQUEST,
  //   payload: {
  //     value,
  //   },
  // }),
};

export default actions;

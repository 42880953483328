import {
  getFirestore,
  doc,
  getDoc,
} from 'firebase/firestore';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

function getSearchByNameFromCloudFunction(search) {
  return axios.post(
    `${ROOT_URL}/searchByName`,
    {
      ...search,
      search: search.search.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
    },
  );
}

export function* searchByNameRequest() {
  yield takeLatest(actions.SEARCH_BY_NAME_FETCH_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SEARCHING_BY_NAME });
      const { data } = yield call(getSearchByNameFromCloudFunction, action.payload);
      yield put({
        type: actions.SEARCH_BY_NAME_FETCH_SUCCESS,
        payload: {
          data,
          search: action.payload,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SEARCH_BY_NAME_FETCH_ERROR,
      });
    }
  });
}

function getSearchFiltersFromDB() {
  const fs = getFirestore();
  const docRef = doc(fs, 'filters', 'counters');
  return getDoc(docRef);
}

export function* searchFiltersRequest() {
  yield takeLatest(actions.GET_SEARCH_FILTERS_REQUEST, function* () {
    try {
      yield put({ type: actions.GETTING_SEARCH_FILTERS });
      const document = yield call(getSearchFiltersFromDB);
      if (document.exists()) {
        yield put({
          type: actions.GET_SEARCH_FILTERS_SUCCESS,
          payload: document.data(),
        });
      } else {
        throw new Error('Failed to reach database.');
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_SEARCH_FILTERS_ERROR,
      });
    }
  });
}

function getSearchProfessionalsFromCloudFunction(filters) {
  const formattedFilters = { ...filters };
  if (formattedFilters.name) {
    formattedFilters.name = formattedFilters.name
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .replace(/^\s+|\s+$/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  return axios.post(
    `${ROOT_URL}/searchProfessionals`,
    {
      filters: {
        ...formattedFilters,
      },
    },
  );
}

export function* searchProfessionalsRequest() {
  yield takeLatest(actions.SEARCH_PROFESSIONALS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SEARCHING_PROFESSIONALS });
      const { data } = yield call(getSearchProfessionalsFromCloudFunction, action.payload);
      yield put({
        type: actions.SEARCH_PROFESSIONALS_SUCCESS,
        payload: {
          data,
          search: action.payload.name || '',
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SEARCH_PROFESSIONALS_ERROR,
      });
    }
  });
}

// function filterProfessionalsLocally({ data, filters }) {
//   const result = [];
//   data.forEach((el) => {
//     if (filters.specialties && filters.plans) {
//       let specialtiesArr = [];
//       let plansArr = [];
//       if (el.council
//         && el.council.specialties) {
//         specialtiesArr = filters.specialties.filter((s) => el.council.specialties.includes(s));
//       }
//       if (el.plans) {
//         plansArr = filters.plans.filter((p) => el.plans.includes(p));
//       }
//       if (specialtiesArr.length > 0
//         && plansArr.length > 0) {
//         result.push(el);
//       }
//     } else if (filters.specialties) {
//       let specialtiesArr = [];
//       if (el.council
//         && el.council.specialties) {
//         specialtiesArr = filters.specialties.filter((s) => el.council.specialties.includes(s));
//       }
//       if (specialtiesArr.length > 0) {
//         result.push(el);
//       }
//     } else if (filters.plans) {
//       let plansArr = [];
//       if (el.plans) {
//         plansArr = filters.plans.filter((p) => el.plans.includes(p));
//       }
//       if (plansArr.length > 0) {
//         result.push(el);
//       }
//     }
//   });
//   return result;
// }

// export function* filterProfessionalsLocallyRequest() {
//   yield takeLatest(actions.FILTER_PROFESSIONALS_LOCALLY, function* (action) {
//     try {
//       yield put({ type: actions.SEARCHING_PROFESSIONALS });
//       yield call(sleep, 500);
//       const data = yield call(filterProfessionalsLocally, action.payload);
//       yield put({
//         type: actions.SEARCH_PROFESSIONALS_SUCCESS,
//         payload: {
//           data,
//           search: action.payload.filters.name || '',
//         },
//       });
//     } catch (error) {
//       console.warn(error);
//       yield put({
//         type: actions.SEARCH_PROFESSIONALS_ERROR,
//       });
//     }
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(searchByNameRequest),
    fork(searchFiltersRequest),
    fork(searchProfessionalsRequest),
    // fork(filterProfessionalsLocallyRequest),
  ]);
}

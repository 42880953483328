import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  procedures: {},
  // defaultProcedures: {},
  updatingProcedures: false,
  removingProcedures: false,
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.PROCEDURES_FETCH_SUCCESS:
      return {
        ...state,
        procedures: {
          ...state.procedures,
          [action.payload.address]: _.cloneDeep(action.payload.procedures),
        },
        updatingProcedures: false,
        removingProcedures: false,
      };
    // case actions.DEFAULT_PROCEDURES_FETCH_SUCCESS:
    //   return {
    //     ...state,
    //     defaultProcedures: {
    //       ...state.defaultProcedures,
    //       [action.payload.address]: _.cloneDeep(action.payload.procedures),
    //     },
    //     updatingProcedures: false,
    //     removingProcedures: false,
    //   };
    case actions.SAVE_PROCEDURES_SUCCESS:
      return {
        ...state,
        updatingProcedures: false,
      };
    case actions.PROCEDURES_FETCH_OR_UPDATING_WAITING:
      return {
        ...state,
        updatingProcedures: true,
      };
    case actions.PROCEDURES_FETCH_ERROR:
      return {
        ...state,
        procedures: {
          ...state.procedures,
          [action.payload.address]: [],
        },
        updatingProcedures: null,
      };
    case actions.REMOVING_PROCEDURES:
      return {
        ...state,
        removingProcedures: true,
      };
    case actions.REMOVE_PROCEDURES_ERROR:
      return {
        ...state,
        removingProcedures: null,
      };
    case actions.FETCH_TUSS_SUCCESS:
      return {
        ...state,
        tuss: action.payload,
        waitingTuss: false,
      };
    case actions.FETCH_TUSS_WAITING:
      return {
        ...state,
        waitingTuss: true,
        tuss: [],
      };
    case actions.FETCH_TUSS_ERROR:
      return {
        ...state,
        waitingTuss: false,
      };
    default:
      return state;
  }
}

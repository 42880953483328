const pinActions = {
  VERIFY_PIN_WAITING: 'VERIFY_PIN_WAITING',
  VERIFY_PIN_REQUEST: 'VERIFY_PIN_REQUEST',
  VERIFY_PIN_VALIDATED: 'VERIFY_PIN_VALIDATED',
  VERIFY_PIN_INVALIDATED: 'VERIFY_PIN_INVALIDATED',
  VERIFY_NO_PIN_CREATED: 'VERIFY_NO_PIN_CREATED',
  VERIFY_PIN_INTERNAL_ERROR: 'VERIFY_PIN_INTERNAL_ERROR',
  OPEN_INSERT_PIN_MODAL: 'OPEN_INSERT_PIN_MODAL',
  CREATE_PIN_REQUEST: 'CREATE_PIN_REQUEST',
  CREATE_PIN_SUCCESS: 'CREATE_PIN_SUCCESS',
  REMOVE_PIN_REQUEST: 'REMOVE_PIN_REQUEST',
  REMOVE_PIN_SUCCESS: 'REMOVE_PIN_SUCCESS',
  REMOVE_PIN_ERROR: 'REMOVE_PIN_ERROR',
  PIN_IS_NECESSARY: 'PIN_IS_NECESSARY',
  VALIDATING_PIN: 'VALIDATING_PIN',
  OPEN_CREATE_PIN_MODAL: 'OPEN_CREATE_PIN_MODAL',
  OPEN_REMOVE_PIN_MODAL: 'OPEN_REMOVE_PIN_MODAL',
  CLOSE_PIN_MODAL: 'CLOSE_PIN_MODAL',
  createPin: (pin) => ({
    type: pinActions.CREATE_PIN_REQUEST,
    payload: pin,
  }),
  removePin: (pin) => ({
    type: pinActions.REMOVE_PIN_REQUEST,
    payload: pin,
  }),
  openCreatePinModal: () => ({
    type: pinActions.OPEN_CREATE_PIN_MODAL,
  }),
  openRemovePinModal: () => ({
    type: pinActions.OPEN_REMOVE_PIN_MODAL,
  }),
  closePinModal: () => ({
    type: pinActions.CLOSE_PIN_MODAL,
  }),
  openInsertPinModal: () => ({
    type: pinActions.OPEN_INSERT_PIN_MODAL,
  }),
  verifyPin: (pin) => ({
    type: pinActions.VERIFY_PIN_REQUEST,
    pin,
  }),
};
export default pinActions;

import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  get,
  update,
  push,
  child,
  // startAfter,
  limitToFirst,
  query as dbQuery,
  orderByChild,
  startAt,
  // equalTo,
} from 'firebase/database';
import moment from 'moment-timezone';
import { v1 as uuidv1 } from 'uuid';
import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { notification } from '../../components';
import actions from './actions';
import authActions from '../auth/actions';
import appActions from '../app/actions';
import profileActions from '../profile/actions';

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getIsProfessionalFromStore = (state) => state.Auth.professional;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getProfileFromStore = (state) => state.Profile.profile;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getCustomUserFromStore = (state) => state.CustomUsers.customUsers;

const getLastPrescriptionFromStore = (state) => state.Prescriptions.lastPrescription;

const getPrescriptionsFromStore = (state) => state.Prescriptions.prescriptions;

const getPrescriptionGeneralObsFromStore = (state) => state.Prescriptions.newPrescriptionGeneralObs;

function getPrescriptionsFromDB(seectedId, addressUid, unified = {}, mainUser, lastItem) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (lastItem) {
      const databaseRef = ref(db, `unified/${unified.id}/prescriptions/${seectedId}`);
      return get(dbQuery(
        databaseRef,
        orderByChild('queryTimestamp'),
        startAt(lastItem.queryTimestamp, lastItem.id),
        limitToFirst(6),
      ));
      // return db.ref(`unified/${unified.id}/prescriptions/${seectedId}`)
      //   .orderByChild('queryTimestamp')
      //   .startAfter(lastItem.queryTimestamp, lastItem.id)
      //   .limitToFirst(5)
      //   .once('value');
    }
    const databaseRef = ref(db, `unified/${unified.id}/prescriptions/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      limitToFirst(5),
    ));
    // return db.ref(`unified/${unified.id}/prescriptions/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .limitToFirst(5)
    //   .once('value');
  }
  if (lastItem) {
    const databaseRef = ref(db, `users/${uid}/prescriptions/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      startAt(lastItem.queryTimestamp, lastItem.id),
      limitToFirst(6),
    ));
    // return db.ref(`users/${uid}/prescriptions/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .startAfter(lastItem.queryTimestamp, lastItem.id)
    //   .limitToFirst(5)
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/prescriptions/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    limitToFirst(5),
  ));
  // return db.ref(`users/${uid}/prescriptions/${seectedId}`)
  //   .orderByChild('queryTimestamp')
  //   .limitToFirst(5)
  //   .once('value');
}

export function* getPrescriptions() {
  yield takeEvery(actions.PATIENT_PRESCRIPTIONS_FETCH_REQUEST, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const lastPrescriptionFromStore = yield select(getLastPrescriptionFromStore);
      const currentPrescriptions = yield select(getPrescriptionsFromStore);
      let lastPrescriptionPagination = null;
      if (lastPrescriptionFromStore[seectedId] && action?.payload?.paginate) {
        lastPrescriptionPagination = lastPrescriptionFromStore[seectedId];
      }
      const prescriptions = yield call(
        getPrescriptionsFromDB,
        seectedId,
        addressUid,
        unified,
        mainUser,
        lastPrescriptionPagination,
      );
      const prescriptionsArray = [];
      prescriptions.forEach((el) => {
        prescriptionsArray.push({
          ...el.val(),
          id: el.key,
          key: el.val().timestamp || el.key,
        });
      });
      const lastPrescription = prescriptionsArray[prescriptionsArray.length - 1];

      if (lastPrescriptionPagination && currentPrescriptions[seectedId]) {
        yield put({
          type: actions.PATIENT_PRESCRIPTIONS_FETCH_SUCCESS,
          payload: {
            prescriptions: { ...currentPrescriptions[seectedId], ...prescriptions.val() },
            id: seectedId,
            lastPrescription,
            noNextPrescriptionPage: prescriptionsArray.length < 5,
          },
        });
      } else {
        yield put({
          type: actions.PATIENT_PRESCRIPTIONS_FETCH_SUCCESS,
          payload: {
            prescriptions: prescriptions.val(),
            id: seectedId,
            lastPrescription,
            noNextPrescriptionPage: prescriptionsArray.length < 5,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function normalizePrescription(presc) {
  return presc.filter((el) => el.name || el.description);
}

function savePrescriptionOnDB(
  prescription,
  generalObs = '',
  seectedId,
  timestamp,
  addressUid,
  unified = {},
  mainProfile = null,
  mainUser,
  customUsers,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const currentUid = currentUser.uid;
  const author = {};
  if (currentUid === mainProfile.id) {
    // It is the main account.
    author.firstName = mainProfile.firstName;
    author.lastName = mainProfile.lastName;
    author.uid = mainProfile.id;
  } else {
    // It is some of the 'customUsers' accounts.
    const foundProfile = customUsers.find((el) => el.id === currentUid);
    if (foundProfile) {
      author.firstName = foundProfile.firstName;
      author.lastName = foundProfile.lastName;
      author.uid = foundProfile.id;
      if (foundProfile.healthProfessional) {
        // It is a health professional account.
      } else {
        // Secretary account.
        author.notHealthProfessional = true;
      }
    }
  }
  if (!prescription || _.isEmpty(prescription)) {
    throw new Error('Missing prescription');
  }
  const updates = {};
  let pushKey = '';
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    pushKey = push(child(ref(db), `unified/${unified.id}/prescriptions/${seectedId}`)).key;
  } else {
    pushKey = push(child(ref(db), `users/${uid}/prescriptions/${seectedId}`)).key;
  }

  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/items`] = prescription;
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/obs`] = generalObs;
    if (!_.isEmpty(author)) {
      author.unified = true;
      updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/professional`] = author;
    }
  } else {
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/items`] = prescription;
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/obs`] = generalObs;
    if (!_.isEmpty(author)) {
      updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/professional`] = author;
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* savePrescription() {
  yield takeEvery(actions.SAVE_PRESCRIPTION_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.PRESCRIPTION_SECTION_START_UPDATE,
      });
      yield call(sleep, 500);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const timestamp = moment().tz('America/Sao_Paulo');
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      const prescription = yield call(normalizePrescription, action.payload);
      let generalObs = '';
      const prescriptionGeneralObs = yield select(getPrescriptionGeneralObsFromStore);
      if (prescriptionGeneralObs[seectedId]) {
        generalObs = prescriptionGeneralObs[seectedId];
      }
      yield call(
        savePrescriptionOnDB,
        prescription.slice().reverse(),
        generalObs,
        seectedId,
        timestamp,
        addressUid,
        unified,
        profile,
        mainUser,
        customUsers,
      );
      yield all([
        yield put({
          type: actions.PATIENT_PRESCRIPTIONS_FETCH_REQUEST,
        }),
        yield put({
          type: actions.RESET_PRESCRIPTION,
          payload: {
            id: seectedId,
          },
        }),
        yield put({
          type: appActions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
          payload: {
            id: seectedId,
            field: 'prescription',
            fieldValue: true,
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Algo deu errado',
        'Tente novamente mais tarde.',
      );
      yield put({ type: actions.SAVE_PRESCRIPTION_ERROR });
    }
  });
}

function getPrescriptionModelsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/prescriptionModels`));
  // return db.ref(`users/${uid}/prescriptionModels`).once('value');
}

export function* getPrescriptionModels() {
  yield takeEvery(actions.PRESCRIPTION_MODELS_FETCH_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const models = yield call(getPrescriptionModelsFromDB, mainUser);
      if (!models.val()) {
        yield put({
          type: actions.PRESCRIPTION_MODELS_FETCH_SUCCESS,
          payload: {
            models: [],
          },
        });
      } else {
        const editedModels = _.map(models.val(), (val, id) => (
          { ...val, id }
        ));
        const modifiedId = editedModels.map((model) => {
          const customModelPrescription = model.prescription.map((prescription) => (
            {
              ...prescription,
              id: uuidv1(),
            }
          ));
          return {
            ...model,
            prescription: customModelPrescription,
          };
        });
        yield put({
          type: actions.PRESCRIPTION_MODELS_FETCH_SUCCESS,
          payload: {
            models: modifiedId,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.PRESCRIPTION_MODELS_FETCH_ERROR,
      });
    }
  });
}

function savePrescriptionModelOnDB(prescription, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `users/${uid}/prescriptionModels`)).key;
  updates[`users/${uid}/prescriptionModels/${pushKey}`] = prescription;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* savePrescriptionModel() {
  yield takeEvery(actions.SAVE_PRESCRIPTION_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_PRESCRIPTION_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(savePrescriptionModelOnDB, action.payload.model, mainUser);
      yield all([
        yield put({
          type: actions.PRESCRIPTION_MODELS_FETCH_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_PRESCRIPTION_MODEL_ERROR });
    }
  });
}

function removePrescriptionModelOnDB(prescriptionId, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/prescriptionModels/${prescriptionId}`] = null;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removePrescriptionModel() {
  yield takeEvery(actions.REMOVE_PRESCRIPTION_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_REMOVING_PRESCRIPTION_MODEL,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(removePrescriptionModelOnDB, action.payload.id, mainUser);
      yield all([
        put({
          type: actions.PRESCRIPTION_MODELS_FETCH_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REMOVE_PRESCRIPTION_MODEL_ERROR });
    }
  });
}

function updatePrescriptionModelOnDB({ model, id }, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/prescriptionModels/${id}`] = model;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updatePrescriptionModel() {
  yield takeEvery(actions.UPDATE_PRESCRIPTION_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_PRESCRIPTION_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(updatePrescriptionModelOnDB, action.payload, mainUser);
      yield all([
        put({
          type: actions.PRESCRIPTION_MODELS_FETCH_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_PRESCRIPTION_MODEL_ERROR });
    }
  });
}

function getPrescriptionSettingsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/prescriptionSettings`));
}

export function* getPrescriptionSettingsRequest() {
  yield takeLatest([
    authActions.LOGIN_SUCCESS,
    actions.FETCH_PRESCRIPTION_SETTINGS_REQUEST,
    profileActions.PROFILE_INFO_SUCCESS,
  ], function* () {
    try {
      const professional = yield select(getIsProfessionalFromStore);
      if (professional) {
        let profile = yield select(getProfileFromStore);
        const mainUser = yield select(getMainUserFromStore);
        const prescriptionSettings = yield call(getPrescriptionSettingsFromDB, mainUser);
        const defaultObj = {
          useDefaultPrescription: true,
        };
        if (_.isEmpty(profile)) {
          yield take(profileActions.PROFILE_INFO_SUCCESS);
          profile = yield select(getProfileFromStore);
        }
        if (prescriptionSettings.val()) {
          yield put({
            type: actions.FETCH_PRESCRIPTION_SETTINGS_SUCCESS,
            payload: {
              ...defaultObj,
              ...prescriptionSettings.val(),
            },
          });
        } else {
          yield put({
            type: actions.FETCH_PRESCRIPTION_SETTINGS_SUCCESS,
            payload: {
              ...defaultObj,
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function changeUseDefaultPrescriptionOnDB(value, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/prescriptionSettings/useDefaultPrescription`] = value;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeUseDefaultPrescriptionRequest() {
  yield takeLatest(actions.CHANGE_USE_DEFAULT_PRESCRIPTION_REQUEST, function* (action) {
    try {
      yield put({ type: actions.UPDATING_USE_DEFAULT_PRESCRIPTION });
      const mainUser = yield select(getMainUserFromStore);
      yield call(changeUseDefaultPrescriptionOnDB, action.payload, mainUser);
      yield put({
        type: actions.FETCH_PRESCRIPTION_SETTINGS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Algo deu errado',
        'Tente novamente mais tarde.',
      );
      yield put({
        type: actions.CHANGE_USE_DEFAULT_PRESCRIPTION_ERROR,
      });
    }
  });
}

function saveCollapsePrescriptionsOnDB(
  value,
  pushKey,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/collapse`] = value;
  } else {
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/collapse`] = value;
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* collapsePrescription() {
  yield takeLatest(actions.CHANGE_COLLAPSE_PRESCRIPTION_REQUEST, function* (action) {
    try {
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(sleep, 3000);
      yield call(
        saveCollapsePrescriptionsOnDB,
        action.payload.value,
        action.payload.id, // pushKey
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
    }
  });
}

function saveHidePrescriptionOnDB(
  value,
  pushKey,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/prescriptions/${seectedId}/${pushKey}/hidden`] = value;
  } else {
    updates[`users/${uid}/prescriptions/${seectedId}/${pushKey}/hidden`] = value;
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* hidePrescription() {
  yield takeLatest(actions.CHANGE_HIDE_PRESCRIPTION_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.CHANGING_HIDE_PRESCRIPTION,
        payload: action.payload.id,
      });
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(
        saveHidePrescriptionOnDB,
        !action.payload.hidden,
        action.payload.id, // pushKey
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
      yield put({
        type: actions.PATIENT_PRESCRIPTIONS_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
      yield put({ type: actions.CHANGE_HIDE_PRESCRIPTION_ERROR });
    }
  });
}

export function* checkShowHiddenPrescriptionsStatus() {
  yield takeEvery(actions.GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS, function* () {
    try {
      const data = yield localStorage.getItem('show_hidden_prescriptions');
      if (_.isNull(data)) {
        yield put({
          type: actions.GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS_SUCCESS,
          value: false,
        });
      } else {
        yield put({
          type: actions.GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS_SUCCESS,
          value: JSON.parse(data),
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setShowHiddenPrescriptions() {
  yield takeEvery(actions.SET_SHOW_HIDDEN_PRESCRIPTIONS, function* (action) {
    try {
      yield localStorage.setItem('show_hidden_prescriptions', action.value);
      yield put({
        type: actions.GET_SHOW_HIDDEN_PRESCRIPTIONS_STATUS,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPrescriptions),
    fork(savePrescription),
    fork(getPrescriptionModels),
    fork(savePrescriptionModel),
    fork(removePrescriptionModel),
    fork(updatePrescriptionModel),
    fork(getPrescriptionSettingsRequest),
    fork(changeUseDefaultPrescriptionRequest),
    fork(collapsePrescription),
    fork(hidePrescription),
    fork(checkShowHiddenPrescriptionsStatus),
    fork(setShowHiddenPrescriptions),
  ]);
}

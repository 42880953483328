const theme = {};

theme.palette = {
  primary: [
    '#007EEC', // 0: Default
    '#0074E2', // 1: Darken 4%
    '#0071DF', // 2: Darken 5%
    'rgba(0, 126, 236, 0.2)', // 3: Fade 20%
    '#0886F4', // 4: Lighten 3%
    'rgba(0, 126, 236, 0.75)', // 5: Fade 75%
    '#0886F4', // 6: Lighten 15%
    '#1F9DFF', // 7: Lighten 12%
    '#0079E7', // 8: Darken 2%
    '#3369e7', // 9: Algolia color
    '#1492FF', // 10: Lighten 8%
    '#2b69e6', // 11:
    '#0065D3', // 12: darken 10%
    '#0D8BF9', // 13: Lighten 5%
  ],
  secondary: [
    '#4C99EE', // 0
    // '#2d3446', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
  ],
  color: [
    '#FEAC01', // 0: Orange
    '#42299a', // 1: Purple
    '#F75D81', // 2: Pink
    '#7ED321', // 3: LimeGreen
    '#39435f', // 4: BlueShade
    '#FFCA28', // 5: Yellow
    '#F2BD1B', // 6: Yellow Darken 5%
    '#3b5998', // 7: Facebook
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
  ],
  warning: [
    '#ffbf00', // 0: Warning
  ],
  success: [
    '#00b16a', // 0: Success
    '#7BD948', // 1: Add element
    'rgba(123, 217, 72, 0.65)', // 2: Add element hover
  ],
  error: [
    '#f64744', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
    '#d9534f', // 3: Button missed appointment
  ],
  information: [
    '#1890FF', // 0: Information
  ],
  grayscale: [
    '#bababa', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
  ],
  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    '#b1b1b1', // 5: Light Text
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
    '#cecece', // 5: Agenda slots border
  ],
  calendar: [
    '#905', // 0:
    '#690', // 1: Finalized success border
    '#a67f59', // 2:
    '#07a', // 3:
    '#dd4a68', // 4:
    '#e90', // 5: Waiting room
    '#5789FB', // 6: Private appointment
    '#456dc8', // 7: Private appointment (border) - third color on color-hex shades
    '#7D3ABB', // 8: Plan appointment
    '#642e95', // 9: Plan appointment (border)
    'rgba(152, 152, 152, 1)', // 10: Not confirmed yet
    'rgba(121, 121, 121, 1)', // 11: Not confirmed yet (border)
    '#345296', // 12: Private finished - 6th color com color-hex shades
    '#223664', // 13: Private finished (border)
    '#4b2270', // 14: Plan finished - 6th color com color-hex shades
    '#32174a', // 15: Plan finished (border)
    '#ffa200', // 16: Waiting room (border)
    '#7fbf00', // 17: Finalized success backgorund,
    '#ffda94', // 18: NewMessage on waiting room,
    '#c6fe57', // 19: NewMessage on finalized,
    '#a5c0fd', // 20: NewMessage on confirmed,
    '#d3d3d3', // 21: NewMessage on appointment,
    'rgb(0, 199, 255)', // 22: Appointment in progress
    '#00b3e5', // 23: Appointment in progress (border)
    '#ff3333', // 24: 'Patient did not show' appointment
    '#cc2828', // 25: 'Patient did not show' appointment (border) - third color on color-hex shades
    '#ae61a0', // 26: Surgery appointment
    '#8b4d80', // 27: Surgery appointment (border) - third color on color-hex shades
  ],
  agenda: [
    '#ffa39e', // 0
    '#ffd591', // 1
    '#fffb8f', // 2
    '#b7eb8f', // 3
    '#91d5ff', // 4
    '#d3adf7', // 5
    '#ffadd2', // 6
    '#f5222d', // 7
    '#fa8c16', // 8
    '#fadb14', // 9
    '#52c41a', // 10
    '#1890ff', // 11
    '#722ed1', // 12
    '#eb2f96', // 13
    '#820014', // 14
    '#873800', // 15
    '#876800', // 16
    '#135200', // 17
    '#003a8c', // 18
    '#22075e', // 19
    '#780650', // 20
  ],
};

theme.fonts = {
  primary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default theme;

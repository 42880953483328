export default {
  apiUrl: 'http://yoursite.com/api/',
};

const siteLogo = require('./images/icons/logobranco.png');
const siteTitle = require('./images/icons/tituloBranco.png');

const siteConfig = {
  siteName: 'MEAGENDA',
  siteIcon: 'ion-flash',
  footerText: `MEAGENDA ${process.env.REACT_APP_VERSION} ©${new Date().getFullYear()}`,
  siteLogo,
  siteTitle,
  appVersion: process.env.REACT_APP_VERSION,
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'portuguese';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  authDomain: process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const googleConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API,
};

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

export {
  siteConfig,
  language,
  themeConfig,
  firebaseConfig,
  googleConfig,
  jwtConfig,
};

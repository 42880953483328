import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  get,
  child,
  query as dbQuery,
  orderByChild,
  startAt,
  endAt,
} from 'firebase/database';
import moment from 'moment-timezone';
import {
  all,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import appActions from '../app/actions';

// const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getInventoryOpsListenerTimeFromStore = (state) => state.Inventory.inventoryOpsListenerTime;

const getInventoryOpsListenerCustomTimeFromStore = (state) => state.Inventory.inventoryOpsListenerCustomTime;

const getInventoryOpsListenerTimeRangeFromStore = (state) => state.Inventory.inventoryOpsListenerTimeRange;

function getInventorySettingsFromDB(addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `inventory/${uid}/${addressUid}/settings`));
}

export function* getInventorySettingsRequest() {
  yield takeLatest(actions.GET_INVENTORY_SETTINGS_REQUEST, function* () {
    try {
      // yield put({ type: actions.CHECKING_FINANCIAL_PAYMENTS });
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const settings = yield call(
        getInventorySettingsFromDB,
        addressUid,
        mainUser,
      );
      const auth = getAuth();
      const { currentUser } = auth;
      const obj = {
        invoiceAutomation: false,
        showFinalizedAppointmentModal: {
          [currentUser.uid]: false,
        },
      };
      if (settings.val()) {
        _.merge(obj, settings.val());
      }
      yield put({
        type: actions.GET_INVENTORY_SETTINGS_SUCCESS,
        // payload: {
        //   invoiceAutomation: invoiceAutomation.val(),
        //   address: addressUid,
        // },
        payload: {
          inventorySettings: obj,
          address: addressUid,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_INVENTORY_SETTINGS_ERROR,
      });
    }
  });
}

function saveInvoiceAutomationOnDB(value, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/settings/invoiceAutomation`] = value;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeInvoiceAutomationRequest() {
  yield takeLatest(actions.CHANGE_INVENTORY_INVOICE_AUTOMATION_REQUEST, function* (action) {
    try {
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        saveInvoiceAutomationOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_INVENTORY_SETTINGS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHANGE_INVENTORY_INVOICE_AUTOMATION_ERROR,
      });
    }
  });
}

function saveShowFinalizedAppointmentModalOnDB(value, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/settings/showFinalizedAppointmentModal/${currentUser.uid}`] = value;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeShowFinalizedAppointmentModalRequest() {
  yield takeLatest(actions.CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST, function* (action) {
    try {
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        saveShowFinalizedAppointmentModalOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_INVENTORY_SETTINGS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHANGE_INVENTORY_SHOW_FINALIZED_APPOINTMENT_MODAL_ERROR,
      });
    }
  });
}

function createInventoryOnDB(item, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/inventory/${uid}/${currentAddress}/items`)).key;
  updates[`/inventory/${uid}/${currentAddress}/items/${pushKey}`] = item;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createInventoryItemRequest() {
  yield takeLatest(actions.CREATE_INVENTORY_ITEM_REQUEST, function* (action) {
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(createInventoryOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_INVENTORY_ITEM_ERROR,
      });
    }
  });
}

// function getInventoryOpsFromDB(currentAddress, mainUser) {
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const { currentUser } = firebase.auth();
//     ({ uid } = currentUser);
//   }
//   const db = firebase.database();
//   return db.ref(`inventory/${uid}/${currentAddress}/ops`).once('value');
// }

function getTransactionFromDB(transactionId, costCenterProfile, addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${transactionId}`));
}

function* fetchTransactionsArray(arr, addressId, mainUser) {
  return yield all(arr.map((s) => call(getTransactionFromDB, s.key, s.costCenterKey, addressId, mainUser)));
}

function createInventoryOpsListener(addressUid, mainUser, time, timeRange = []) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (timeRange?.length > 0) {
    const [startToUse, endToUse] = timeRange;
    const listener = eventChannel((emit) => {
      const databaseRef = ref(db, `inventory/${uid}/${addressUid}/ops`);
      const queryRef = dbQuery(
        databaseRef,
        orderByChild('timestamp'),
        startAt(startToUse),
        endAt(`${endToUse}\uf8ff`),
      );
      const unsubscribe = onValue(queryRef, (req) => (
        emit(req.val() || {})
      ));
      return () => unsubscribe();
    });
    return listener;
  }
  const startToUse = moment()
    .tz('America/Sao_Paulo')
    .subtract(time || 1, 'months')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `inventory/${uid}/${addressUid}/ops`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('timestamp'),
      startAt(startToUse),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getInventoryOpsRequest() {
  yield takeLatest([
    // actions.INVENTORY_FETCH_REQUEST,
    actions.INVENTORY_OPS_FETCH_REQUEST,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_INVENTORY_OPS_LIST });
      let addressUid = yield select(getSelectedAddressFromStore);
      if (!addressUid) {
        yield take(appActions.SELECT_ADDRESS);
        addressUid = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const listenerTime = yield select(getInventoryOpsListenerTimeFromStore);
      const listenerCustomTime = yield select(getInventoryOpsListenerCustomTimeFromStore);
      const listenerTimeRange = yield select(getInventoryOpsListenerTimeRangeFromStore);
      const inventoryOpsListener = yield call(
        createInventoryOpsListener,
        addressUid,
        mainUser,
        listenerCustomTime || listenerTime,
        listenerTimeRange,
      );
      yield takeEvery(inventoryOpsListener, function* (manualOps) {
        const inventoryOpsArr = _.map(manualOps, (val, opsId) => (
          {
            ...val,
            id: opsId,
          }
        ));
        const transactionsArr = [];
        inventoryOpsArr.forEach((el) => {
          if (el.transactions) {
            Object.keys(el.transactions).forEach((costCenterKey) => {
              Object.keys(el.transactions[costCenterKey]).forEach((transactionKey) => {
                transactionsArr.push({
                  key: transactionKey,
                  costCenterKey,
                });
              });
            });
          }
        });
        const transactionsResponses = yield call(fetchTransactionsArray, transactionsArr, addressUid, mainUser);
        transactionsResponses.forEach((response) => {
          if (response.val()) {
            const transaction = response.val();
            const index = inventoryOpsArr.findIndex((el) => el.id === transaction.key);
            if (index > -1) {
              if (!inventoryOpsArr[index].transactionsArr) {
                inventoryOpsArr[index].transactionsArr = [];
              }
              inventoryOpsArr[index].transactionsArr.push({
                ...transaction,
                id: response.key,
              });
            }
          }
        });
        yield put({
          type: actions.INVENTORY_OPS_FETCH_SUCCESS,
          payload: {
            inventoryOpsArr,
            address: addressUid,
          },
        });
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        actions.CHANGE_INVENTORY_OPS_LISTENER_TIMER,
      ]);
      inventoryOpsListener.close();
      if (actionTriggered?.type === 'CHANGE_INVENTORY_OPS_LISTENER_TIMER') {
        // Resetting listener to filter new 'costProfile'.
        yield put({
          type: actions.INVENTORY_OPS_FETCH_REQUEST,
        });
      } else {
        // It is the general "cancel all listeners".
        yield all([
          put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
          put({ type: actions.RESET_INVENTORY_OPS }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.INVENTORY_OPS_FETCH_ERROR,
      });
    }
  });
}

function getInventoryProvidersFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `inventory/${uid}/${currentAddress}/providers`));
}

export function* getInventoryProvidersRequest() {
  yield takeLatest([
    actions.INVENTORY_FETCH_REQUEST,
    actions.INVENTORY_PROVIDERS_FETCH_REQUEST,
  ], function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const inventory = yield call(getInventoryProvidersFromDB, currentAddress, mainUser);
      if (inventory.val()) {
        let inventoryProviders = [];
        if (inventory.val()) {
          inventoryProviders = _.map(inventory.val(), (val, providersId) => (
            { ...val, id: providersId }
          ));
        }
        yield put({
          type: actions.INVENTORY_PROVIDERS_FETCH_SUCCESS,
          payload: {
            inventoryProviders,
            address: currentAddress,
          },
        });
      } else {
        yield put({
          type: actions.INVENTORY_PROVIDERS_FETCH_SUCCESS,
          payload: {
            inventoryProviders: [],
            address: currentAddress,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.INVENTORY_FETCH_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function getInventoryClassesFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `inventory/${uid}/${currentAddress}/classes`));
}

export function* getInventoryClassesRequest() {
  yield takeLatest([
    actions.INVENTORY_FETCH_REQUEST,
    actions.INVENTORY_CLASSES_FETCH_REQUEST,
  ], function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const inventory = yield call(getInventoryClassesFromDB, currentAddress, mainUser);
      if (inventory.val()) {
        let inventoryClasses = [];
        if (inventory.val()) {
          inventoryClasses = _.map(inventory.val(), (val, classId) => (
            { ...val, id: classId }
          ));
        }
        yield put({
          type: actions.INVENTORY_CLASSES_FETCH_SUCCESS,
          payload: {
            inventoryClasses,
            address: currentAddress,
          },
        });
      } else {
        yield put({
          type: actions.INVENTORY_CLASSES_FETCH_SUCCESS,
          payload: {
            inventoryClasses: [],
            address: currentAddress,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.INVENTORY_FETCH_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function createInventoryItemsListener(addressUid, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `inventory/${uid}/${addressUid}/items`);
    const unsubscribe = onValue(databaseRef, (req) => (
      emit(req.val() || 0)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getInventoryItemsRequest() {
  yield takeLatest(actions.INVENTORY_ITEMS_LISTENER_REQUEST, function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const inventoryItemsListener = yield call(
        createInventoryItemsListener,
        currentAddress,
        mainUser,
      );
      yield takeEvery(inventoryItemsListener, function* (items) {
        const normalizedInventoryItems = _.map(items, (val, itemId) => (
          { ...val, id: itemId }
        ));
        yield put({
          type: actions.INVENTORY_ITEMS_FETCH_SUCCESS,
          payload: {
            inventoryItems: normalizedInventoryItems,
          },
        });
      });
      yield take(appActions.CANCEL_LISTENERS);
      inventoryItemsListener.close();
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_INVENTORY_LISTENER }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.INVENTORY_FETCH_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function removeInventoryOnDB(key, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/items/${key}/disabled`] = true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeInventoryRequest() {
  yield takeLatest(actions.REMOVE_INVENTORY_ITEM_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeInventoryOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_INVENTORY_ITEM_ERROR,
      });
    }
  });
}

function depositInventoryItemOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const updates = {};
  if (values.products) {
    const pushKey = push(child(ref(db), `/inventory/${uid}/${currentAddress}/ops`)).key;
    updates[`/inventory/${uid}/${currentAddress}/ops/${pushKey}`] = {
      ...values,
      id: null,
      // products: null,
      // itemId: el.id,
      type: 'deposit',
      user: currentUser.uid,
    };
    // values.products.forEach((el) => {
    //   const pushKey = db.ref().child(`/inventory/${uid}/${currentAddress}/ops`).push().key;
    //   updates[`/inventory/${uid}/${currentAddress}/ops/${pushKey}`] = {
    //     ...values,
    //     ...el,
    //     id: null,
    //     products: null,
    //     itemId: el.id,
    //     type: 'deposit',
    //     user: firebase.auth().currentUser.uid,
    //   };
    // });
  } else {
    throw new Error('No products!');
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* depositInventoryItemRequest() {
  yield takeLatest(actions.DEPOSIT_INVENTORY_ITEM_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        depositInventoryItemOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.DEPOSIT_INVENTORY_ITEM_ERROR,
      });
    }
  });
}

function createProviderOnDB(item, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/inventory/${uid}/${currentAddress}/providers`)).key;
  updates[`/inventory/${uid}/${currentAddress}/providers/${pushKey}`] = item;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createInventoryProviderRequest() {
  yield takeLatest(actions.CREATE_INVENTORY_PROVIDER_REQUEST, function* (action) {
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(createProviderOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_INVENTORY_PROVIDER_ERROR,
      });
    }
  });
}

function removeProviderOnDB(key, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/providers/${key}/disabled`] = true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeInventoryProviderRequest() {
  yield takeLatest(actions.REMOVE_INVENTORY_PROVIDER_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.INVENTORY_REMOVE_PROVIDER_WAITING,
        payload: action.payload,
      });
      yield call(sleep, 500);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeProviderOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_INVENTORY_PROVIDER_ERROR,
      });
    }
  });
}

function withdrawInventoryItemOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/inventory/${uid}/${currentAddress}/ops`)).key;
  updates[`/inventory/${uid}/${currentAddress}/ops/${pushKey}`] = {
    ...values,
    type: 'withdraw',
    user: currentUser.uid,
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* withdrawInventoryItemRequest() {
  yield takeLatest(actions.WITHDRAW_INVENTORY_ITEM_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        withdrawInventoryItemOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.WITHDRAW_INVENTORY_ITEM_ERROR,
      });
    }
  });
}

function updateInventoryDepositOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  // const updates = {};
  let obj = {};
  if (values.products && values.products.length > 0) {
    obj = {
      ...values,
      id: null,
      // products: null,
      // itemId: values.products[0].id,
      transactionsArr: null,
    };
    // values.products.forEach((el) => {
    //   updates[`/inventory/${uid}/${currentAddress}/ops/${values.id}`] = {
    //     ...values,
    //     ...el,
    //     id: null,
    //     products: null,
    //     itemId: el.id,
    //   };
    // });
  } else {
    throw new Error('No products!');
  }
  return update(ref(db, `/inventory/${uid}/${currentAddress}/ops/${values.id}`), obj);
  // return new Promise((resolve, reject) => {
  //   db.ref(`/inventory/${uid}/${currentAddress}/ops/${values.id}`).update(obj, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateInventoryDepositRequest() {
  yield takeLatest(actions.UPDATE_INVENTORY_DEPOSIT_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updateInventoryDepositOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.UPDATE_INVENTORY_DEPOSIT_ERROR,
      });
    }
  });
}

function cancelOperationOnDB(key, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/ops/${key}/canceled`] = true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* cancelOperationRequest() {
  yield takeLatest(actions.CANCEL_OPERATION_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.CANCEL_OPERATION_WAITING,
        payload: action.payload,
      });
      yield call(sleep, 500);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(cancelOperationOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CANCEL_OPERATION_ERROR,
      });
    }
  });
}

function updateWithdrawInventoryOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/ops/${values.id}`] = {
    ...values,
    id: null,
    // type: 'withdraw',
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateWithdrawInventoryRequest() {
  yield takeLatest(actions.UPDATE_INVENTORY_WITHDRAW_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updateWithdrawInventoryOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.UPDATE_INVENTORY_WITHDRAW_ERROR,
      });
    }
  });
}

function createClassOnDB(item, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/inventory/${uid}/${currentAddress}/classes`)).key;
  updates[`/inventory/${uid}/${currentAddress}/classes/${pushKey}`] = item;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createInventoryClassRequest() {
  yield takeLatest(actions.CREATE_INVENTORY_CLASS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(createClassOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_INVENTORY_CLASS_ERROR,
      });
    }
  });
}

function removeInventoryClassOnDB(key, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/inventory/${uid}/${currentAddress}/classes/${key}/disabled`] = true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeInventoryClassRequest() {
  yield takeLatest(actions.REMOVE_INVENTORY_CLASS_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      yield call(removeInventoryClassOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_INVENTORY_ITEM_ERROR,
      });
    }
  });
}

function updateInventoryOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let obj = {};
  obj = {
    ...values,
    id: null,
  };
  return update(ref(db, `/inventory/${uid}/${currentAddress}/items/${values.id}`), obj);
  // return new Promise((resolve, reject) => {
  //   db.ref(`/inventory/${uid}/${currentAddress}/items/${values.id}`).update(obj, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateInventoryItemRequest() {
  yield takeLatest(actions.UPDATE_INVENTORY_ITEM_REQUEST, function* (action) {
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(updateInventoryOnDB, action.payload, currentAddress, mainUser);
      yield put({
        type: actions.INVENTORY_FETCH_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.UPDATE_INVENTORY_ITEM_ERROR,
      });
    }
  });
}

function updateInventoryProceduresArrOnDB(
  values,
  finalizeAppointment,
  currentAddress,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  let copyProceduresArr = [];
  if (finalizeAppointment?.proceduresArr && finalizeAppointment?.id) {
    copyProceduresArr = _.cloneDeep(finalizeAppointment.proceduresArr);
    const foundProArrIndex = copyProceduresArr.findIndex((el) => {
      if (el.procedureId === values.procedureId) {
        return true;
      }
      return false;
    });
    if (foundProArrIndex > -1) {
      // const copyProceduresArr = _.cloneDeep(finalizeAppointment.proceduresArr);
      if (copyProceduresArr[foundProArrIndex].inventory) {
        const foundInventoryIndex = copyProceduresArr[foundProArrIndex].inventory.findIndex((el) => {
          if (el.key === values.inventoryArrKey) {
            return true;
          }
          return false;
        });
        if (foundInventoryIndex > -1) {
          // copyProceduresArr[foundProArrIndex].inventory[foundInventoryIndex].amount = values.amount;
          copyProceduresArr[foundProArrIndex].inventory[foundInventoryIndex].amount = values.amount;
          copyProceduresArr[foundProArrIndex].inventory[foundInventoryIndex].id = values.itemId;
        }
      }
    }
    updates[`/finalizedAppointments/${uid}/${currentAddress}/${finalizeAppointment.id}/proceduresArr`] = copyProceduresArr;
  } else {
    throw new Error('proceduresArr not found in finalizeAppointment.');
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateInventoryProceduresArrRequest() {
  yield takeLatest(actions.UPDATE_INVENTORY_PROCEDURES_ARR_REQUEST, function* (action) {
    try {
      yield put({ type: actions.INVENTORY_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const response = yield call(
        updateInventoryProceduresArrOnDB,
        action.payload.values,
        action.payload.finalizeAppointment,
        currentAddress,
        mainUser,
      );
      if (response) {
        yield put({
          type: actions.INVENTORY_FETCH_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.UPDATE_INVENTORY_WITHDRAW_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getInventorySettingsRequest),
    fork(changeInvoiceAutomationRequest),
    fork(changeShowFinalizedAppointmentModalRequest),
    fork(getInventoryItemsRequest),
    fork(getInventoryOpsRequest),
    fork(getInventoryProvidersRequest),
    fork(getInventoryClassesRequest),
    fork(createInventoryItemRequest),
    fork(removeInventoryRequest),
    fork(depositInventoryItemRequest),
    fork(createInventoryProviderRequest),
    fork(removeInventoryProviderRequest),
    fork(withdrawInventoryItemRequest),
    fork(updateInventoryDepositRequest),
    fork(cancelOperationRequest),
    fork(updateWithdrawInventoryRequest),
    fork(createInventoryClassRequest),
    fork(removeInventoryClassRequest),
    fork(updateInventoryItemRequest),
    fork(updateInventoryProceduresArrRequest),
  ]);
}

import React from 'react';
import './styles/pageLoading.css';

const logo = require('../../settings/images/icons/logopreto.png');

function PageLoading() {
  return (
    <div className="main-container">
      <img alt="meagenda" src={logo} />
    </div>
  );
}

export default PageLoading;

import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  records: {},
  newRecord: {},
  recordsPageMode: 'record',
  graphs: {
    pediatric: false,
  },
  // recordList: [{ mode: 'default' }],
  recordList: [],
  newPointPediatricGraphVisible: false,
  pediatricGraphData: {},
  updatingRecordEditorModel: false,
  removingRecordEditorModel: false,
  patientsRecordForm: {},
  lastRecord: {},
  noNextRecordPage: {},
};

export default function recordsReducer(state = initState, action) {
  // console.log('Records state', state);
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SET_NEW_RECORD:
      return {
        ...state,
        newRecord: _.cloneDeep(action.payload.newRecord),
      };
    case actions.DISCARD_NEW_RECORD_CHANGES: {
      const newRecord = _.cloneDeep(state.newRecord);
      delete newRecord[action.payload.id];
      return {
        ...state,
        discardedNewRecord: true,
        // newRecord: {
        //   ...state.newRecord,
        //   [action.payload.id]: {
        //     text: { ops: [] },
        //     files: [],
        //   },
        // },
        newRecord,
      };
    }
    case actions.DISCARD_NEW_RECORD_CHANGES_AFTER_SAVING: {
      const newRecord = _.cloneDeep(state.newRecord);
      delete newRecord[action.payload.id];
      return {
        ...state,
        newRecord,
      };
    }
    case actions.PATIENT_RECORDS_FETCH_SUCCESS:
      return {
        ...state,
        updatingRecords: false,
        savingHideRecord: false,
        records: {
          ...state.records,
          [action.payload.id]: action.payload.records,
        },
        pediatricGraphData: {
          ...state.pediatricGraphData,
          [action.payload.id]: action.payload.pediatricGraphData,
        },
        patientsRecordForm: {
          ...state.patientsRecordForm,
          [action.payload.id]: action.payload.recordForm,
        },
        lastRecord: {
          ...state.lastRecord,
          [action.payload.id]: action.payload.lastRecord,
        },
        noNextRecordPage: {
          ...state.noNextRecordPage,
          [action.payload.id]: action.payload.noNextRecordPage,
        },
      };
    case actions.GET_PATIENT_FILES_REQUEST_SUCCESS:
      return {
        ...state,
        patientFiles: {
          ...state.patientFiles,
          isLoading: false,
          [action.payload.id]: action.payload.files,
        },
      };
    case actions.GET_PATIENT_FILES_REQUEST:
      return {
        ...state,
        patientFiles: {
          ...state.patientFiles,
          isLoading: true,
        },
      };
    case actions.GET_PATIENT_FILES_REQUEST_ERROR:
      return {
        ...state,
        patientFiles: {
          ...state.patientFiles,
          isLoading: false,
        },
      };
    case actions.RECORDS_SECTION_START_UPDATE:
      return {
        ...state,
        updatingRecords: true,
      };
    case actions.SAVE_RECORD_ERROR:
      return {
        ...state,
        updatingRecords: null,
      };
    // case actions.CHANGE_RECORD:
    //   return {
    //     ...state,
    //     discardedNewRecord: false,
    //     newRecord: {
    //       ...state.newRecord,
    //       [action.payload.id]: action.payload.records,
    //     },
    //   };
    case actions.CHANGE_RECORD:
      return {
        ...state,
        discardedNewRecord: false,
        newRecord: {
          ...state.newRecord,
          [action.payload.id]: {
            ...state.newRecord[action.payload.id],
            [action.payload.mode]: action.payload.values,
          },
        },
      };
    case actions.GET_RECORDS_MASK_SUCCESS:
      return {
        ...state,
        newRecordMask: _.cloneDeep(action.payload.records),
        newRecordMaskOnDB: _.cloneDeep(action.payload.records),
        updatingMask: false,
        fetchingRecordMask: false,
        recordList: _.cloneDeep(action.payload.records.list),
        discardedRecordMaskChanges: false,
      };
    case actions.RECORD_MASK_START_UPDATE:
      return {
        ...state,
        updatingMask: true,
      };
    case actions.FETCHING_RECORD_MASK:
      return {
        ...state,
        fetchingRecordMask: true,
      };
    case actions.CHANGE_RECORD_MASK:
      return {
        ...state,
        newRecordMask: action.payload,
      };
    case actions.DISCARD_RECORD_MASK_CHANGES:
      return {
        ...state,
        newRecordMask: _.cloneDeep(state.newRecordMaskOnDB),
        newEntranceTab: '1',
        discardedRecordMaskChanges: true,
      };
    case actions.GET_GRAPH_REQUEST:
      return {
        ...state,
        waitingGraph: true,
        graphSuccess: undefined,
      };
    case actions.GET_GRAPH_SUCCESS:
      return {
        ...state,
        graphs: action.payload.graphs,
      };
    case actions.GET_GRAPH_ERROR:
      return {
        ...state,
        waitingGraph: false,
        graphSuccess: false,
      };
    case actions.CREATE_GRAPH_SUCCESS:
      return {
        ...state,
        waitingGraph: false,
        graphSuccess: true,
      };
    case actions.TOGGLE_PEDIATRIC_MODAL_GRAPH:
      return {
        ...state,
        pediatricModalVisibility: action.payload,
      };
    case actions.TOGGLE_NEW_POINT_PEDIATRIC_MODAL_GRAPH:
      return {
        ...state,
        newPointPediatricGraphVisible: action.payload,
      };
    case actions.CHANGE_RECORDS_PAGE_MODE:
      return {
        ...state,
        recordsPageMode: action.payload,
      };
    case actions.CREATE_GRAPH_WAITING:
      return {
        ...state,
        updatingCustomRecord: true,
      };
    case actions.RESET_CUSTOM_RECORD_MASK:
      return {
        ...state,
        recordList: _.cloneDeep(state.recordList),
      };
    case actions.RECORD_EDITOR_MODELS_FETCH_SUCCESS:
      return {
        ...state,
        recordEditorModels: action.payload.models,
        updatingRecordEditorModel: false,
        removingRecordEditorModel: false,
      };
    case actions.SAVE_RECORD_EDITOR_MODEL_ERROR:
      return {
        ...state,
        updatingRecordEditorModel: null,
      };
    case actions.REMOVE_RECORD_EDITOR_MODEL_ERROR:
      return {
        ...state,
        removingRecordEditorModel: null,
      };
    case actions.START_SAVING_RECORD_EDITOR_MODEL:
      return {
        ...state,
        updatingRecordEditorModel: true,
      };
    case actions.START_REMOVING_RECORD_EDITOR_MODEL:
      return {
        ...state,
        removingRecordEditorModel: action.payload,
      };
    case actions.GET_RECORD_FORM_SUCCESS:
      return {
        ...state,
        recordForm: _.cloneDeep(action.payload.list),
        updatingForm: false,
        fetchingRecordForm: false,
      };
    case actions.UPDATING_RECORD_FORM:
      return {
        ...state,
        updatingForm: true,
      };
    case actions.FETCHING_RECORD_FORM:
      return {
        ...state,
        fetchingRecordForm: true,
      };
    case actions.DISCARDING_RECORD_FORM:
      return {
        ...state,
        discardingRecordForm: true,
      };
    case actions.DISCARDING_RECORD_FORM_SUCCESS:
      return {
        ...state,
        discardingRecordForm: false,
      };
    case actions.SAVING_PATIENT_RECORD_FORM:
      return {
        ...state,
        savingPatientRecordForm: true,
      };
    case actions.SAVE_PATIENT_RECORD_FORM_SUCCESS:
      return {
        ...state,
        patientsRecordForm: {
          ...state.patientsRecordForm,
          [action.payload.id]: action.payload.list,
        },
        savingPatientRecordForm: false,
      };
    case actions.SAVE_PATIENT_RECORD_FORM_ERROR:
      return {
        ...state,
        savingPatientRecordForm: null,
      };
    case actions.GET_SHOW_HIDDEN_RECORDS_STATUS_SUCCESS:
      return {
        ...state,
        showHiddenRecords: action.value,
        savingHideRecord: false,
      };
    case actions.CHANGING_HIDE_RECORD:
      return {
        ...state,
        savingHideRecord: action.payload,
      };
    case actions.CHANGE_HIDE_RECORD_ERROR:
      return {
        ...state,
        savingHideRecord: null,
      };
    case actions.GET_ALL_RECORDS_SUCCESS:
      return {
        ...state,
        updatingRecords: false,
        savingHideRecord: false,
        records: {
          ...state.records,
          [action.payload.id]: action.payload.records,
        },
        lastRecord: {
          ...state.lastRecord,
          [action.payload.id]: action.payload.lastRecord,
        },
        noNextRecordPage: {
          ...state.noNextRecordPage,
          [action.payload.id]: action.payload.noNextRecordPage,
        },
        fetchingAllRecords: false,
      };
    case actions.FETCHING_ALL_RECORDS:
      return {
        ...state,
        fetchingAllRecords: true,
      };
    case actions.UPDATE_PEDIATRIC_PLOT_POINT:
      return {
        ...state,
        updatingRecords: true,
      };
    case actions.UPDATE_PEDIATRIC_PLOT_POINT_ERROR:
      return {
        ...state,
        updatingRecords: false,
      };
    case actions.UPDATE_PEDIATRIC_PLOT_POINT_SUCCESS:
      return {
        ...state,
        updatingRecords: false,
        savingHideRecord: false,
        pediatricGraphData: {
          ...state.pediatricGraphData,
          [action.payload.id]: action.payload.pediatricGraphData,
        },
      };
    case actions.FETCHING_LAST_RECORD_FOR_SPECIFIC_FIELD:
      return {
        ...state,
        fetchingLastField: action.payload,
        lastRecordForSpecificField: undefined,
      };
    case actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_SUCCESS:
      return {
        ...state,
        fetchingLastField: false,
        lastRecordForSpecificField: {
          [action.payload.seectedId]: {
            field: action.payload.field,
            value: action.payload.value,
          },
        },
      };
    case actions.FETCH_LAST_RECORD_FOR_SPECIFIC_FIELD_ERROR:
      return {
        ...state,
        fetchingLastField: null,
      };
    default:
      return state;
  }
}
